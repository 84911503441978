import { useState, forwardRef, useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Skeleton from 'react-loading-skeleton'
import { useSnackbar } from 'notistack'
import { AssociatedTasks } from './styles'
import { Requests } from '../../../pages/Requisicoes/styles'
import { MdSubject, SiOpslevel, FiGitPullRequest, BsPerson, FaRegCheckCircle, IoIosArrowBack, IoIosArrowForward } from 'react-icons/all'

import IconButton from "@material-ui/core/IconButton"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormControl from "@material-ui/core/FormControl"
import SearchIcon from '@material-ui/icons/Search'
import randomColor from "randomcolor"

import { v4 as uuid } from 'uuid'
import api from '../../../services/api'
import { capitalize, limitChar, convertISODateToDateView } from '../../../functions'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogAssociateTask({
    idRequisicao,
    openDialogTarefaAssociada,
    setOpenDialogTarefaAssociada,
    setOpenDialogTarefasAssociadas
}) {
    const optionsColors = {
        luminosity: 'light'
    }

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [textMessage, setTextMessage] = useState('')
    const [tarefas, setTarefas] = useState([])
    const [loading, setLoading] = useState(true)
    const { enqueueSnackbar } = useSnackbar()
    const [searchField, setSearchField] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [qtdeRegistros, setQtdeRegistros] = useState(10)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(Number(Math.round(totalRegistros / qtdeRegistros)))
    const timer = useRef(null);

    const handleSelected = item => {

        setTarefas((prevState) =>
            tarefas.map(tarefa => {
                if (tarefa.idTarefa === item.idTarefa) {
                    tarefa.checked = !item.checked
                }
                return tarefa
            })
        )
    }

    const getTarefasLoad = () => {
        let itens = [];

        for (let index = 0; index < 3; index++) {
            itens.push(
                <Button className="btn-acesso-requisicao">
                    <Requests.ListItemContent id="Request_ListItemContent">
                        <Requests.ListItemInformations id="Requests_ListItemInformations">
                            <Requests.ListItemInfo id="Request_ListItemInfo">
                                <Requests.ListItemTitle id="Request_ListItemTitle"><Skeleton width={560} height={18} /></Requests.ListItemTitle>
                                <Requests.Subject id="Requests_Subject" ><Skeleton width={760} height={18} /></Requests.Subject>
                            </Requests.ListItemInfo>
                            <Requests.ListItemReq id="Request_ListItemReq">
                                <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest"><Skeleton width={50} height={30} /></Requests.ListItemNumberRequest>
                                <Requests.ListItemData id="Request_ListItemData"><Skeleton width={100} height={20} /></Requests.ListItemData>
                            </Requests.ListItemReq>
                        </Requests.ListItemInformations>
                        <Requests.ListItemSubInfo id="Request_ListItemSubInfo">
                            <Requests.ListItemTask id="Request_ListItemTask"><Skeleton width={100} height={18} /></Requests.ListItemTask>
                            <Requests.ListItemTask id="Request_ListItemTask"><Skeleton width={150} height={18} /></Requests.ListItemTask>
                            <Requests.ListItemAttach id="Request_ListItemAttach"><Skeleton width={350} height={18} /></Requests.ListItemAttach>
                            <Requests.ListItemTask id="Request_ListItemTask"><Skeleton width={50} height={18} /></Requests.ListItemTask>
                        </Requests.ListItemSubInfo>
                    </Requests.ListItemContent>
                </Button>
            );
        }
        return itens;

    }

    useEffect(() => {
        if (openDialogTarefaAssociada)
            consultarTarefas()
    }, [openDialogTarefaAssociada])

    const handleClose = () => {
        setOpenDialogTarefaAssociada(false)
        setOpenDialogTarefasAssociadas(true)
    }

    const handleFilter = e => {

        if (timer.current)
            clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            setLoading(true)
            setPaginaAtual(0);
            consultarTarefas();
        }, 600);

    }

    const consultarTarefas = () => {
        let search = document.querySelector('#outlined-adornment-text-filter')?.value

        if (search?.length <= 0 && getPaginaAtual() <= 0) {
            api.get(`/jucerja/v1/Tarefa/${idRequisicao}/tarefasemassociacao`)
                .then(response => {
                    setTarefas(response.data.tarefasSemAssociacao ?? [])
                    setTotalRegistros(response.data.totalRecords)
                    setTotalPaginas(Number(Math.ceil((Number(response.data.totalRecords ?? 0) / Number(qtdeRegistros)))))
                })
                .catch(error => {
                    setError(true)
                    setErrorMessage(error.toString())
                })
                .finally(() => setLoading(false))
        }
        else {
            api.post(`/jucerja/v1/Tarefa/tarefasemassociacao`,
                {
                    search,
                    requisicaoId: idRequisicao,
                    firstResult: ((getPaginaAtual() - 1) * qtdeRegistros) + 1,
                    maxResults: qtdeRegistros
                }
            )
                .then(response => {

                    setTarefas(response.data.tarefasSemAssociacao ?? [])
                    setTotalRegistros(response.data.totalRecords)
                    setTotalPaginas(Number(Math.ceil((Number(response.data.totalRecords ?? 0) / Number(qtdeRegistros)))))
                })
                .catch(error => {
                    setError(true)
                    setErrorMessage(error.toString())
                })
                .finally(() => setLoading(false))
        }
    }

    const handleSubmit = async () => {
        setLoading(true)

        let data = new FormData()
        data.append('requisicaoId', idRequisicao)

        tarefas.forEach(tarefa => {
            if (tarefa.checked === true) {
                data.append('tarefasid', tarefa.idTarefa)
            }
        })

        await api({
            method: 'post',
            url: '/jucerja/v1/requisicoes/associar/tarefa',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        })
            .then(response => {
                handleClose()
            })
            .catch(error => {

                switch (error?.response?.status) {
                    case 403:
                        enqueueSnackbar('Usuário não tem permissão para associar tarefas a solicitação.', { variant: 'error' });
                        break
                    case 422:
                    case 500:
                        enqueueSnackbar(error.response.data.messages[0].mensagem, { variant: 'error' })
                        break
                    default:
                        enqueueSnackbar('Não foi possível enviar sua mensagem. Por favor tente novamente', { variant: 'error' })
                        break
                }
            })
            .finally(() => setLoading(false))
    }

    const getPaginaAtual = () => {
        return (paginaAtual + 1);
    }

    useEffect(() => {
        if (getPaginaAtual() > 0)
            consultarTarefas();
    }, [paginaAtual])

    return (
        <Draggable        
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogTarefaAssociada}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-associated-tasks-request-slide-title"
            aria-describedby="dialog-associated-tasks-request-dialog-slide-description"
            id="dialog-associated-tasks"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">Associar Tarefa</DialogTitle>
            <DialogContent>
                <AssociatedTasks id="AssociatedTasks">
                    <AssociatedTasks.Form id="AssociatedTasks_Form">

                        <FormControl variant="outlined" style={{ margin: '0 0 25px 0' }}>
                            <InputLabel htmlFor="outlined-adornment-password">Pesquisar</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-text-filter"
                                type="text"
                                value={textMessage}
                                onChange={e => handleFilter(setTextMessage(e.target.value))}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleFilter}
                                            onMouseDown={handleFilter}
                                            edge="end"
                                            className="btn-input-adornment-search"
                                        >
                                            <SearchIcon style={{ color: 'var(--white)' }} />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                        <AssociatedTasks.Pagination id="AssociatedTasks_Pagination">
                            {
                                loading
                                    ?
                                    <Skeleton width={180} height={30} />
                                    :
                                    <>
                                        Página {getPaginaAtual()} de {totalPaginas} de {totalRegistros} {totalRegistros <= 1 ? 'registro' : 'registros'}
                                        <Button className="btnPagination btnPaginationPrev" onClick={() => setPaginaAtual(paginaAtual - 1)} disabled={getPaginaAtual() === 1 ? true : false}><IoIosArrowBack /></Button>
                                        <Button className="btnPagination btnPaginationNext" onClick={() => setPaginaAtual(paginaAtual + 1)} disabled={getPaginaAtual() === totalPaginas || totalPaginas === 0 ? true : false}><IoIosArrowForward /></Button>
                                    </>
                            }
                        </AssociatedTasks.Pagination>
                        {
                            loading ? getTarefasLoad() :
                                tarefas && tarefas.length > 0
                                    ?
                                    tarefas.map(tarefa => (
                                        <Button className={`btn-acesso-requisicao ${tarefa.checked ? 'btn-acesso-requisicao-selecionado' : ''}`} key={uuid()} onClick={() => handleSelected(tarefa)}>
                                            <Requests.ListItemContent id="Request_ListItemContent">
                                                <Requests.ListItemInformations id="Requests_ListItemInformations">
                                                    <Requests.ListItemInfo id="Request_ListItemInfo">
                                                        <Requests.ListItemTitle id="Request_ListItemTitle">{tarefa.assunto}</Requests.ListItemTitle>
                                                        <Requests.Subject id="Requests_Subject" ><MdSubject />{limitChar(100, tarefa.previaMensagem)}</Requests.Subject>
                                                    </Requests.ListItemInfo>
                                                    <Requests.ListItemReq id="Request_ListItemReq">
                                                        <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest">{tarefa.idTarefa}</Requests.ListItemNumberRequest>
                                                        <Requests.ListItemData id="Request_ListItemData">{convertISODateToDateView(tarefa.dataAbertura)}</Requests.ListItemData>
                                                    </Requests.ListItemReq>
                                                </Requests.ListItemInformations>
                                                <Requests.ListItemSubInfo id="Request_ListItemSubInfo">
                                                    <div style={{ display: 'flex' }}>
                                                        <Requests.ListItemStatus id="Request_ListItemStatus">{tarefa.status}</Requests.ListItemStatus>
                                                        <Requests.ListItemStatus id="Request_ListItemStatus2">{tarefa.setor}</Requests.ListItemStatus>
                                                    </div>
                                                    <Requests.ListItemAttach id="Request_ListItemAttach"><SiOpslevel />{capitalize(tarefa.nomeAtendente)}</Requests.ListItemAttach>
                                                    <Requests.ListItemTask id="Request_ListItemTask"><SiOpslevel />{capitalize(tarefa.prioridade)}</Requests.ListItemTask>
                                                    <Requests.ListItemMessage id="Request_ListItemMessage"><FiGitPullRequest /> {tarefa.quantidadeRequisicao} {tarefa.quantidadeRequisicao <= 1 ? 'Solicitação' : 'Solicitações'}</Requests.ListItemMessage>
                                                </Requests.ListItemSubInfo>
                                            </Requests.ListItemContent>
                                        </Button>
                                    ))
                                    :
                                    <span style={{ width: '100%' }}>{"Nenhum registro encontrado."}</span>
                        }

                        <Button className={`btn-acesso-requisicao`} style={{ opacity: 0, height: '1px', overflow: 'auto' }} key={uuid()}>
                            <Requests.ListItemContent id="Request_ListItemContent">
                                <Requests.ListItemInformations id="Requests_ListItemInformations">
                                    <Requests.ListItemInfo id="Request_ListItemInfo">
                                        <Requests.ListItemTitle id="Request_ListItemTitle">asdfasdfasdf</Requests.ListItemTitle>
                                        <Requests.Subject id="Requests_Subject" ><MdSubject />asdfasdfasdf</Requests.Subject>
                                    </Requests.ListItemInfo>
                                    <Requests.ListItemReq id="Request_ListItemReq">
                                        <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest">asdfasdfasddfasdf</Requests.ListItemNumberRequest>
                                        <Requests.ListItemData id="Request_ListItemData">asdfasdfasddfasdf</Requests.ListItemData>
                                    </Requests.ListItemReq>
                                </Requests.ListItemInformations>
                                <Requests.ListItemSubInfo id="Request_ListItemSubInfo">
                                    <div style={{ display: 'flex' }}>
                                        <Requests.ListItemStatus id="Request_ListItemStatus">asdfasdfasdfasdfasdf</Requests.ListItemStatus>
                                        <Requests.ListItemStatus id="Request_ListItemStatus2">asdfadsfasdfasdfasdf</Requests.ListItemStatus>
                                    </div>
                                    <Requests.ListItemAttach id="Request_ListItemAttach"><SiOpslevel />asdfasdfasdfasdf</Requests.ListItemAttach>
                                    <Requests.ListItemTask id="Request_ListItemTask"><SiOpslevel />asdfasdfasdfasdfasdf</Requests.ListItemTask>
                                    <Requests.ListItemMessage id="Request_ListItemMessage"><FiGitPullRequest /> asdfasddfasdfasdfasdf</Requests.ListItemMessage>
                                </Requests.ListItemSubInfo>
                            </Requests.ListItemContent>
                        </Button>
                    </AssociatedTasks.Form>
                </AssociatedTasks>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} className="btn-close-actions">Cancelar</Button>
                {
                    tarefas && tarefas.filter(x => x.checked).length > 0
                        ?
                        <Button onClick={() => handleSubmit()} className="btn-primary-actions">{tarefas.filter(x => x.checked).length > 1 ? "Associar Tarefas Selecionadas" : "Associar Tarefa Selecionada"}</Button>
                        :
                        <></>
                }
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}