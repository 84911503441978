import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import NavBar from '../../navigations/Navbar/index'
import Menu from '../../navigations/Menu'
import Breadcrumbs from '../../navigations/Breadcrumbs'
import { Title } from '../../elements-ui/Title'
import { ContainerComponent } from './styles'

import IconUrlBack from '../../../assets/img/icon-url-back.svg'

export default function Container(props) {
    return (
        <>
            <NavBar />
            <Menu />
            <ContainerComponent id="ContainerComponent">
                <ContainerComponent.Content id="Content">
                    <Title>
                        {
                            props.urlEnabledBack
                                ?
                                <Link to={props.urlBack}>
                                    <Button className="btnUrlBackContainer"><ContainerComponent.ImgIconUrlBack id="ContainerComponentImgIconUrlBack" src={IconUrlBack} /></Button>
                                </Link>
                                :
                                null
                        }
                        {props.title}
                    </Title>
                    <Breadcrumbs crumbs={props.crumbs} />
                    {props.children}
                </ContainerComponent.Content>
            </ContainerComponent>
            <ContainerComponent.Copyright id="Copyright">{new Date().getFullYear()} <span>©</span> Junta Comercial do Estado do Rio de Janeiro</ContainerComponent.Copyright>
        </>
    )
}