import { useState, useEffect, forwardRef, useRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from "@material-ui/core/InputLabel"

import FormControl from "@material-ui/core/FormControl"
import DateFnsUtils from "@date-io/date-fns"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers"
import ptBRLocale from "date-fns/locale/pt-BR"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { DialogSecao } from './styles'
import api from '../../../services/api'
import TextField from "@material-ui/core/TextField"
import { Autocomplete, Chip, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid'
import { setRef, Grid } from '@material-ui/core'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogSecaoConfirm({
    openDialogSecao,
    setOpenDialogSecao,
    secaoEditar,
    setSecaoConfirmation
}) {
    const [ativo, setAtivo] = useState(true);
    const [resumo, setResumo] = useState('');
    const [titulo, setTitulo] = useState('');
    const [erroResumo, setErroResumo] = useState('');
    const [erroTitulo, setErroTitulo] = useState('');
    const [loading, setLoading] = useState(false);

    const heightResumo = 60

    const handleClose = (e) => {
        e.preventDefault()

        if (loading) return;

        setOpenDialogSecao(false);
        setSecaoConfirmation(undefined);
    }

    const handleConfirm = (e) => {
        let retornar = false;

        e?.preventDefault();
        setErroResumo('')
        setErroTitulo('')

        let secao = {
            id: secaoEditar?.id,
            ordem: secaoEditar?.ordem,
            resumo: resumo,
            titulo: titulo,
            ativo: ativo
        };

        if (!resumo || !isNaN(resumo)) {
            setErroResumo("O resumo é obrigatório.");
            retornar = true;
        }

        if (!titulo || !isNaN(titulo)) {
            setErroTitulo('O título é obrigatório.')
            retornar = true;
        }

        if (retornar)
            return;

        setLoading(true);

        setSecaoConfirmation(secao);
    }

    useEffect(() => {
        if (secaoEditar) {
            setResumo(secaoEditar.resumo);
            setTitulo(secaoEditar.titulo);
            setAtivo(secaoEditar.ativo);
        }

        if (!openDialogSecao) {
            setLoading(false);
            setResumo('');
            setTitulo('');
            setAtivo(true);
            setErroResumo('')
            setErroTitulo('')
        }

    }, [openDialogSecao])

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogSecao}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-secao"

        >
            <DialogTitle id="alert-dialog-slide-title">{secaoEditar ? "Alterar Seção" : "Cadastrar Seção"}</DialogTitle>
            <DialogContent>
                <DialogSecao id="DialogSecao">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl variant="outlined">
                                <TextField
                                    id="outlined-number"
                                    label="Título"
                                    variant="outlined"
                                    error={erroTitulo}
                                    helperText={erroTitulo}
                                    required
                                    name="titulo"
                                    value={titulo}
                                    onChange={e => { setTitulo(e.target.value); }}

                                    inputProps={{ maxLength: 500 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} gap={20}>
                            <FormControl variant="outlined" >
                                <div style={{ minHeight: heightResumo }}>
                                    <TextField
                                        id="outlined-number"
                                        label="Resumo"
                                        variant="outlined"
                                        required
                                        error={erroResumo}
                                        helperText={erroResumo}
                                        name="resumo"
                                        style={{ minHeight: heightResumo }}
                                        multiline
                                        value={resumo}
                                        size={'medium'}
                                        onChange={e => { setResumo(e.target.value); }}
                                        inputProps={{ maxLength: 1000, style: { minHeight: heightResumo } }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControlLabel control={<Checkbox checked={ativo} onChange={e => setAtivo(e.currentTarget.checked)} color='default' />} label="Ativo" />
                        </Grid>
                    </Grid>
                </DialogSecao>
            </DialogContent>
            <DialogActions>
                <DialogSecao.Actions id="DialogAusencia.Actions">
                    <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
                        <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                    </div>
                    {
                        loading
                            ?
                            <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                            :
                            <Button className="btn-primary-actions" onClick={handleConfirm}> {secaoEditar ? 'Alterar' : 'Cadastrar'}</Button>
                    }

                </DialogSecao.Actions>
            </DialogActions>
        </Dialog >
        </Draggable>
    )
}