import { Paper } from '@material-ui/core';
import { useEffect } from 'react';
import { createRef } from 'react';
import { useState } from 'react';
import Draggable from 'react-draggable';
import { uniqueId } from 'lodash';
import { getRToken } from '../services/auth';

export const capitalize = (str) => {
    if (Boolean(str)) {
        let splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ')
    }
    else {
        return ""
    }
}

export const limitChar = (nChar, str) => {
    if (str.length > Number(nChar)) {
        return str.substring(0, Number(nChar)) + '...'
    }
    else {
        return str
    }
}

export const eventFire = (el, etype) => {
    if (el.fireEvent) {
        el.fireEvent('on' + etype)
    }
    else {
        let evObj = document.createEvent('Events')
        evObj.initEvent(etype, true, false)
        el.dispatchEvent(evObj)
    }
}

export const convertISODateToDateView = (nDate, retirarHoraMinuto) => {
    if (Boolean(nDate)) {
        let data = new Date(nDate),
            dia = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(),
            mesF = (mes.length == 1) ? '0' + mes : mes,
            anoF = (data.getFullYear()),
            horaF = data.getHours() < 10 ? '0' + data.getHours() : data.getHours(),
            minutoF = data.getMinutes() < 10 ? '0' + data.getMinutes() : data.getMinutes()
        return `${diaF}/${mesF}/${anoF} ${retirarHoraMinuto ? '' : `${horaF}:${minutoF}`}`
    }
    else {
        return "-"
    }
}

export const formataTelefone = tel => {
    if (Boolean(tel)) {
        tel = tel.replace(/\D/g, "")
        tel = tel.replace(/^(\d)/, "($1")
        tel = tel.replace(/(.{3})(\d)/, "$1)$2")
        if (tel.length == 9) {
            tel = tel.replace(/(.{1})$/, "-$1")
        } else if (tel.length == 10) {
            tel = tel.replace(/(.{2})$/, "-$1")
        } else if (tel.length == 11) {
            tel = tel.replace(/(.{3})$/, "-$1")
        } else if (tel.length == 12) {
            tel = tel.replace(/(.{4})$/, "-$1")
        } else if (tel.length > 12) {
            tel = tel.replace(/(.{4})$/, "-$1")
        }
        return tel
    }
    else {
        return "-"
    }
}

export const formataCNPJ = cnpj => {
    if (Boolean(cnpj)) {
        cnpj = cnpj.replace(/\D/g, "")
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2")
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")
        return cnpj
    }
    else {
        return "-"
    }
}

export const formataCPF = cpf => {
    if (Boolean(cpf)) {
        cpf = cpf.replace(/\D/g, "")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        return cpf
    }
    else {
        return "-"
    }
}

export const formataCEP = cep => {
    if (Boolean(cep)) {
        cep = cep.replace(/\D/g, "")
        cep = cep.replace(/^(\d{2})(\d)/, "$1.$2")
        cep = cep.replace(/\.(\d{3})(\d)/, ".$1-$2")
        return cep
    }
    else {
        return "-"
    }
}

export const removeDuplicatesArray = (originalArray, prop) => {
    let newArray = []
    let lookupObject = {}

    for (let i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i]
    }

    for (let i in lookupObject) {
        newArray.push(lookupObject[i])
    }
    return newArray
}

export const removeDuplicatesArraySimple = (arr) => {
    let seen = {}
    let ret_arr = []
    for (let i = 0; i < arr.length; i++) {
        if (!(arr[i] in seen)) {
            ret_arr.push(arr[i])
            seen[arr[i]] = true
        }
    }
    return ret_arr
}

export const bytesToSize = bytes => {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes == 0) return '0 Byte'
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

export const validaEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
}

export const acaoPermitida = (acao, acoesPermitidas, rederizar) => {
    if (acoesPermitidas.indexOf(acao) > -1)
        return rederizar

    return null
}

export const crieRequisicaoObj = (idRequisicao, textMessage, tipoPrivacidade, attachs) =>
({
    id: uniqueId("mensagem"),
    idRequisicao: String(idRequisicao),
    mensagem: textMessage,
    dataOperacao: (new Date).toISOString(),
    tipo: 'mensagens',
    ehMensagemCliente: false,
    tipoPrivacidade,
    usuarioId: String(getRToken().id),
    anexos: attachs.map(a => ({ nome: a.name, id: a.id, url: a.url, extensao: a.type.split('/')[1] }))
});