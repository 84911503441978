import { useState, useEffect } from 'react'

import { Link, Redirect } from 'react-router-dom'

import { withStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    IoPersonOutline,
    IoLockClosedOutline,
    IoEyeOutline,
    IoEyeOffOutline
} from 'react-icons/io5'

import ImageApresentation from '../../assets/img/login-img.svg'
import ImageLogo from '../../assets/img/logo.svg'
import TextField from '@material-ui/core/TextField'

import { Login } from './styles'
import { Button } from '@material-ui/core'

import api from '../../services/api'
import { login, logout, refreshToken, getPerfisUsuario, removeLocalStorageData } from '../../services/auth'

const CustomCheckbox = withStyles({
    root: {
        "&$checked": {
            color: 'var(--secondary)'
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />)

export default function LoginPage() {
    const [year] = useState(new Date().getFullYear())

    const [usuario, setUsuario] = useState('')
    const [erroUsuario, setErroUsuario] = useState(false)
    const [errorUsuarioHelper, setErrorUsuarioHelper] = useState('')

    const [senha, setSenha] = useState({
        senha: "",
        mostrarSenha: false
    })
    const [erroSenha, setErroSenha] = useState(false)
    const [errorSenhaHelper, setErroSenhaHelper] = useState('')

    const [lembrarSenha, setLembrarSenha] = useState({
        checked: true
    })

    const [loading, setLoading] = useState(false)
    const [logIn, setLogIn] = useState(false)
    const [mostrarErro, setMostrarErro] = useState(false)
    const [erroMensagem, setErroMensagem] = useState({
        codigo: '',
        mensagem: '',
    })

    const handleChange = (prop) => (event) => {
        setSenha({ ...senha, [prop]: event.target.value })
    }

    const handleClickMostrarSenha = () => {
        setSenha({ ...senha, mostrarSenha: !senha.mostrarSenha })
    }

    const handleMouseDownSenha = (event) => {
        event.preventDefault()
    }

    const handleChangeLembrarSenha = (event) => {
        setLembrarSenha({
            [event.target.name]: event.target.checked
        })
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    function validaForm() {
        if (!usuario) {
            setErroUsuario(true)
            setErrorUsuarioHelper("O usuário não pode ficar em branco")
            return false
        }

        if (!senha.senha) {
            setErroSenha(true)
            setErroSenhaHelper("A senha não pode ficar em branco")
            return false
        }

        return (true)
    }

    const handleSubmit = async () => {
        if (validaForm()) {
            if (usuario) {
                setErroUsuario(false)
                setErrorUsuarioHelper("")
            }

            if (senha.senha) {
                setErroSenha(false)
                setErroSenhaHelper("")
            }

            setLoading(true)

            await api.post(`/jucerja/v1/autenticacao/login`, {
                usuario: usuario,
                senha: senha.senha,
                grantType: "password"
            })
                .then(response => {
                    localStorage.clear();
                    setMostrarErro(false)
                    setErroMensagem('')
                    login(response.data.accessToken)
                    refreshToken(response.data.refreshToken)
                    setLogIn(true)
                    setLoading(false)
                })
                .catch(error => {
                    if (error.response) {
                        if (Boolean(error.response.data.messages)) {
                            setErroMensagem({
                                codigo: '',
                                mensagem: '',
                            })
                            let arrErros = []
                            error.response.data.messages.map(obj => {
                                arrErros.push({
                                    codigo: obj.codigoErro,
                                    mensagem: obj.mensagem
                                })
                            })
                            setLoading(false)
                            setErroMensagem({
                                codigo: arrErros.map(obj => obj.codigo),
                                mensagem: arrErros.map(obj => obj.mensagem),
                            })
                            setMostrarErro(true)
                        }
                        else if (Boolean(error.response.data.codigo) && Boolean(error.response.data.mensagem)) {
                            setLoading(false)
                            setErroMensagem({
                                codigo: error.response.data.codigo,
                                mensagem: error.response.data.mensagem,
                            })
                            setMostrarErro(true)
                        }
                        else {
                            setLoading(false)
                            setMostrarErro(true)
                            setErroMensagem({
                                codigo: null,
                                mensagem: "Erro não identificado. Por favor tente novamente!",
                            })
                        }
                    }
                    else {
                        setLoading(false)
                        setMostrarErro(true)
                        setErroMensagem({
                            codigo: null,
                            mensagem: "Erro não identificado. Por favor tente novamente!",
                        })
                    }
                })
        }
    }

    useEffect(async () => {
        await removeLocalStorageData();
    }, [])

    return (
        <Login>

            {
                logIn ?
                    <Redirect to="/" />
                    :
                    null}

            <Login.Form>
                <Login.FormContent>
                    <Login.ImgLogoMob src={ImageLogo} />
                    <Login.FormContentTitle>Entrar</Login.FormContentTitle>
                    <Login.FormContentDescription>Faça login para começar!</Login.FormContentDescription>

                    {
                        mostrarErro
                            ?
                            <Login.Errors id="LoginErrors">
                                <Login.ErrorTitle id="LoginErrorTitle">Houve um erro!</Login.ErrorTitle>
                                {erroUsuario ? <Login.ErrorItem id="LoginErrorItem">{errorUsuarioHelper}</Login.ErrorItem> : null}
                                {erroSenha ? <Login.ErrorItem id="LoginErrorItem">{errorSenhaHelper}</Login.ErrorItem> : null}
                                {erroMensagem.codigo === null ? null : <p className="mensagem-error-login">Cod.: {erroMensagem.codigo}</p>}
                                <p className="mensagem-error-login">{erroMensagem.mensagem}</p>
                            </Login.Errors>
                            :
                            null
                    }

                    <Login.FormInputs id="FormInputs">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl>
                                    <TextField
                                        id="filled-adornment-senha"
                                        type="text"
                                        value={usuario}
                                        label="Usuário"
                                        variant="outlined"
                                        onChange={e => setUsuario(e.target.value)}
                                        onKeyPress={handleKeyDown}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <IoPersonOutline />
                                            </InputAdornment>
                                        }}
                                        error={erroUsuario}
                                        helperText={errorUsuarioHelper}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="filled">
                                    <TextField
                                        id="filled-adornment-senha"
                                        type={senha.mostrarSenha ? "text" : "password"}
                                        value={senha.senha}
                                        label="Senha"
                                        variant="outlined"
                                        onKeyPress={handleKeyDown}
                                        onChange={handleChange("senha")}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <IoLockClosedOutline />
                                            </InputAdornment>,
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle senha visibility"
                                                    onClick={handleClickMostrarSenha}
                                                    onMouseDown={handleMouseDownSenha}
                                                    edge="end"
                                                >
                                                    {senha.mostrarSenha ? <IoEyeOutline /> : <IoEyeOffOutline />}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        error={erroSenha}
                                        helperText={errorSenhaHelper}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {
                                    loading
                                        ?
                                        <Button className="btnLoginUser btnDefault btnDefaultPrimary"><CircularProgress size={22} style={{ color: '#fff' }} /></Button>
                                        :
                                        <Button className="btnLoginUser btnDefault btnDefaultPrimary" onClick={() => handleSubmit()}>Login</Button>
                                }
                            </Grid>
                        </Grid>
                    </Login.FormInputs>
                    <Login.Copyright>{year} <span>©</span> Junta Comercial do Estado do Rio de Janeiro</Login.Copyright>
                </Login.FormContent>
            </Login.Form>
            <Login.Apresentation>
                <Login.LogoImgJucerja src={ImageLogo} />
                <Login.ApresentationImage src={ImageApresentation} />
            </Login.Apresentation>
        </Login>
    )
}