import { useState } from 'react'
import { useSnackbar } from 'notistack'
import ReactHtmlParser from 'react-html-parser'
import { convertISODateToDateView, limitChar } from '../../../functions'
import { Message } from './styles'
import IconPdf from '../../../assets/img/icon-pdf.svg'
import IconImage from '../../../assets/img/icon-image.svg'
import { CircularProgress, Button } from '@material-ui/core'
import { HiDownload, RiDeleteBin2Fill } from 'react-icons/all'
import Fab from '@mui/material/Fab';

import Badge from '@mui/material/Badge';
import { v4 as uuid } from 'uuid'
import PreviewFileImage from '../PreviewFileImage'

import api from '../../../services/api'
import MessageDelete from '../MessageDelete'

export default function AttendantMessage({ mensagem }) {
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const [openDialogPreviewFileImage, setOpenDialogPreviewFileImage] = useState(false)
    const [preview, setPreview] = useState({
        urlFile: null,
        openClose: null
    })

    const previewFile = async (idAnexo) => {
        setOpenDialogPreviewFileImage(true)
        setLoading(true)

        if (mensagem.memoria) {
            let anexo = mensagem.anexos.find(a => a.id == idAnexo);
            setPreview({
                base64: true,
                url: anexo.url,
                name: anexo.nome
            })

            setLoading(false)
        } else {

            await api.get(`/jucerja/v1/arquivos/${idAnexo}`)
                .then(response => {
                    let extensionFile = response.data.extensao.toLowerCase().includes('pdf') ? 'application/pdf' : `image/${response.data.extensao.replace('.', '')}`

                    setPreview({
                        base64: true,
                        url: `data:${extensionFile};base64,${response.data.arquivo.files}`,
                        name: response.data.nome
                    })
                    setLoading(false)
                })
                .catch(error => {
                    setOpenDialogPreviewFileImage(false)
                    setLoading(false)
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                })
        }
    }

    const downloadFile = async (idAnexo) => {
        document.querySelector(`[btn-download-attach-id="${idAnexo}"]`).classList.add("displayNone")
        document.querySelector(`[btn-download-loading-attach-id="${idAnexo}"]`).classList.remove("displayNone")


        if (mensagem.memoria) {
            let anexo = mensagem.anexos.find(a => a.id == idAnexo);
            let a = document.createElement("a")
            a.href = anexo.url
            a.download = anexo.nome
            a.click()

            document.querySelector(`[btn-download-attach-id="${idAnexo}"]`).classList.remove("displayNone")
            document.querySelector(`[btn-download-loading-attach-id="${idAnexo}"]`).classList.add("displayNone")
            
        }else{
        

        await api.get(`/jucerja/v1/arquivos/${idAnexo}`)
            .then(response => {
                let extensionFile = response.data.extensao.includes('pdf') ? 'application/pdf' : `image/${response.data.extensao.replace('.', '')}`
                let a = document.createElement("a")
                a.href = `data:${extensionFile};base64,${response.data.arquivo.files}`
                a.download = response.data.nome
                a.click()

                document.querySelector(`[btn-download-attach-id="${idAnexo}"]`).classList.remove("displayNone")
                document.querySelector(`[btn-download-loading-attach-id="${idAnexo}"]`).classList.add("displayNone")
            })
            .catch(error => {
                document.querySelector(`[btn-download-attach-id="${idAnexo}"]`).classList.remove("displayNone")
                document.querySelector(`[btn-download-loading-attach-id="${idAnexo}"]`).classList.add("displayNone")
                enqueueSnackbar(error.toString(), { variant: 'error' })
            })
        }
    }

    return (
        <>
            <PreviewFileImage
                openDialogPreviewFileImage={openDialogPreviewFileImage}
                setOpenDialogPreviewFileImage={setOpenDialogPreviewFileImage}
                preview={preview}
                setPreview={setPreview}
                loading={loading}
                setLoading={setLoading}
            />

            <Message.AttendantInformation id="Request_AttendantInformation">
                <Message.AttendantNameDate id="Request_AttendantNameDate">{convertISODateToDateView(mensagem.dataOperacao)}</Message.AttendantNameDate>
                <Message.AttendantName id="Request_AttendantName">{mensagem.nomeUsuario}</Message.AttendantName>
                <Message.AttendantBadge id="AttendantBadge"></Message.AttendantBadge>
            </Message.AttendantInformation>
            <Message.Message id="Request_Message" type="jucerja">
                <Message.MessageItem id="Request_MessageItem" type="jucerja">
                    {
                        mensagem.ehAnexo
                            ?
                            mensagem.extensao === "pdf"
                                ?
                                <Message.MessageAttachItem key={uuid()} id="Request_MessageAttachItem" onlyAttach={true}>
                                    <MessageDelete ehAnexo mensagem={mensagem} />
                                    <Button id="Request_MessageAttachBox" onClick={() => previewFile(mensagem.id)}>
                                        <Message.MessageAttachPDFIcon id="Request_MessageAttachPDFIcon">
                                            <Message.MessageAttachPDFIconFile id="Request_MessageAttachPDFIconFile" src={IconPdf} />
                                        </Message.MessageAttachPDFIcon>
                                        <Message.MessageAttachItemName id="Request_MessageAttachItemName" type="jucerja">{limitChar(15, mensagem.nome)}</Message.MessageAttachItemName>
                                    </Button>
                                    <Button className="btn-message-attach-icon-download" onClick={() => downloadFile(mensagem.id)} btn-download-attach-id={mensagem.id}><HiDownload /></Button>
                                    <Button className="btn-message-attach-icon-download displayNone" btn-download-loading-attach-id={mensagem.id}><CircularProgress size={22} style={{ color: 'var(--primary)' }} /></Button>
                                </Message.MessageAttachItem>
                                :
                                <Message.MessageAttachItem key={uuid()} id="Request_MessageAttachItem" onlyAttach={true}>
                                    <MessageDelete ehAnexo mensagem={mensagem} />
                                    <Button id="Request_MessageAttachBox" onClick={() => previewFile(mensagem.id)}>
                                        <Message.MessageAttachImageIcon id="Request_MessageAttachImageIcon">
                                            <Message.MessageAttachPDFIconFile id="Request_MessageAttachPDFIconFile" src={IconImage} />
                                        </Message.MessageAttachImageIcon>
                                        <Message.MessageAttachItemName id="Request_MessageAttachItemName" type="jucerja">{limitChar(15, mensagem.nome)}</Message.MessageAttachItemName>
                                    </Button>
                                    <Button className="btn-message-attach-icon-download" onClick={() => downloadFile(mensagem.id)} btn-download-attach-id={mensagem.id}><HiDownload /></Button>
                                    <Button className="btn-message-attach-icon-download displayNone" btn-download-loading-attach-id={mensagem.id}><CircularProgress size={22} style={{ color: 'var(--primary)' }} /></Button>
                                </Message.MessageAttachItem>
                            :
                            mensagem.mensagem && mensagem.mensagem.length > 0
                                ?
                                <Message.TextMessage id="Request_TextMessage" type="jucerja">
                                    <MessageDelete mensagem={mensagem} />
                                    {ReactHtmlParser(mensagem.mensagem)}
                                </Message.TextMessage>
                                :
                                <></>
                    }
                    {
                        mensagem.anexos
                            ?
                            mensagem.anexos.length > 0
                                ?
                                <Message.MessageAttach id="Request_MessageAttach" type="jucerja">

                                    {mensagem.anexos.map(anexo => (
                                        anexo.extensao === "pdf"
                                            ?
                                            <Message.MessageAttachItem key={uuid()} id="Request_MessageAttachItem">
                                                <MessageDelete ehAnexo mensagem={mensagem} />
                                                <Button id="Request_MessageAttachBox" onClick={() => previewFile(anexo.id)}>
                                                    <Message.MessageAttachPDFIcon id="Request_MessageAttachPDFIcon">
                                                        <Message.MessageAttachPDFIconFile id="Request_MessageAttachPDFIconFile" src={IconPdf} />
                                                    </Message.MessageAttachPDFIcon>
                                                    <Message.MessageAttachItemName id="Request_MessageAttachItemName" type="jucerja">{limitChar(15, anexo.nome)}</Message.MessageAttachItemName>
                                                </Button>
                                                <Button className="btn-message-attach-icon-download" onClick={() => downloadFile(anexo.id)} btn-download-attach-id={anexo.id}><HiDownload /></Button>
                                                <Button className="btn-message-attach-icon-download displayNone" btn-download-loading-attach-id={anexo.id}><CircularProgress size={22} style={{ color: 'var(--primary)' }} /></Button>
                                            </Message.MessageAttachItem>
                                            :
                                            <Message.MessageAttachItem key={uuid()} id="Request_MessageAttachItem">
                                                <MessageDelete ehAnexo mensagem={mensagem} />
                                                <Button id="Request_MessageAttachBox" onClick={() => previewFile(anexo.id)}>
                                                    <Message.MessageAttachImageIcon id="Request_MessageAttachImageIcon">
                                                        <Message.MessageAttachPDFIconFile id="Request_MessageAttachPDFIconFile" src={IconImage} />
                                                    </Message.MessageAttachImageIcon>
                                                    <Message.MessageAttachItemName id="Request_MessageAttachItemName" type="jucerja">{limitChar(15, anexo.nome)}</Message.MessageAttachItemName>
                                                </Button>
                                                <Button className="btn-message-attach-icon-download" onClick={() => downloadFile(anexo.id)} btn-download-attach-id={anexo.id}><HiDownload /></Button>
                                                <Button className="btn-message-attach-icon-download displayNone" btn-download-loading-attach-id={anexo.id}><CircularProgress size={22} style={{ color: 'var(--primary)' }} /></Button>
                                            </Message.MessageAttachItem>
                                    ))}
                                </Message.MessageAttach>
                                :
                                null
                            :
                            null
                    }
                </Message.MessageItem>
            </Message.Message>
        </>
    )
}