import styled, { css } from 'styled-components'

export const Message = styled.div`

`

Message.AttendantInformation = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    margin: 5px 0 4px 0;
    color: var(--alternative3);
`

Message.AttendantNameDate = styled.div`
    font-weight: 400;
`

Message.AttendantName = styled.div`
    margin: 0 5px 0 10px;
    font-weight: 600;
`

Message.AttendantBadge = styled.div`
    background-color: var(--green);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 4px 0 0;
`

Message.Message = styled.div`
    width: 100%;
    margin: 0 0 25px 0;
    display: flex;
    justify-content: ${props => props.type === "solicitation" ? "flex-start" : "flex-end"};

    @media (max-width: 420px) {
        /* margin-bottom: 10px; */
    }

    @media (min-width: 994px) {
        
    }
`

Message.MessageItem = styled.div`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    background-color: ${props => props.type === "solicitation" ? "var(--white)" : "var(--primary)"};
    width: fit-content;
    max-width: 50%;

    @media (max-width: 994px) {
        max-width: 100%;
    }
`

Message.TextMessage = styled.div`
    font-weight: 500;
    padding: 20px;
    color: ${props => props.type === "solicitation" ? "var(--grey)" : "var(--black)"};
    font-size: 13px;
    word-wrap: break-word;
    
    ${props => props.type === 'jucerja' && css`
            a {
                color: var(--lightorange) !important;
                font-weight: 700 !important;
            }
        `
    }

    ${props => props.type === 'solicitation' && css`
            a {
                color: var(--primary) !important;
                font-weight: 700 !important;
            }
        `
    }

    ${props => props.type === 'loading' && css`
            position: relative;
            min-width: 200px;
            overflow: hidden;

            span.react-loading-skeleton {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        `
    }
    
    p span {
        background-color: transparent !important;
        color: var(--black) !important;
    }
`

Message.MessageAttach = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px 20px 5px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`

Message.MessageAttachItem = styled.div`
    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    margin: ${props => props.onlyAttach ? '25px' : '0 15px 15px 0'};
    background: var(--white);

    @media (max-width: 764px) {
        margin: 0 0 15px 0;
        width: 100%;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-message-attach-icon-download {
        width: 40px;
        height: 50px;
        background: var(--white);
        display: flex;align-items: center;
        justify-content: center;
        min-width: 0;
    }

    button#Request_MessageAttachBox {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        padding: 0;
        text-transform: none !important;
        line-height: 15px;
        text-align: left;
        background: var(--white) !important;
    }

    @media (max-width: 764px) {
        button#Request_MessageAttachBox {
            width: calc(100% - 40px);
            display: flex;
            justify-content: flex-start;
        }
    }
`

Message.MessageAttachPDFIcon = styled.div`
    width: 40px;
    height: 50px;
    background: var(--bg-red);
    display: flex;
    align-items: center;
    justify-content: center;
`

Message.MessageAttachPDFIconFile = styled.img`
`

Message.MessageAttachImageIcon = styled.div`
    width: 40px;
    height: 50px;
    background: var(--bg-yellow);
    display: flex;
    align-items: center;
    justify-content: center;
`

Message.MessageAttachItemName = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    font-size: 10px;
    font-weight: 500;
    color: var(--lightgrey);
    max-width: 100px;

    @media (max-width: 764px) {
        max-width: calc(100% - 40px);
    }
`

Message.MessageReminder = styled.div`
    width: 100%;
    margin: 0 0 25px 0;
    display: flex;
    justify-content: ${props => props.type === "solicitation" ? "flex-start" : "flex-end"};

    div#Request_MessageItem {
        background: var(--yellow);
        position: relative;
        overflow: visible;
        transition: all ease .35s;
        min-width: 140px;
        max-width: calc(100% - 25px);
        width: fit-content;
    }

    div#Request_MessageItem div#Request_MessageIconReminder {
        position: absolute;
        bottom: -15px;
        left: -15px;
        width: 34px;
        height: 34px;
        background: #fffcda;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        overflow: hidden;
        transition: all ease .35s;
        cursor: pointer;
        box-shadow: 1px 1px 11px 0px rgba(0,0,0,0.34);
        -webkit-box-shadow: 1px 1px 11px 0px rgba(0,0,0,0.34);
        -moz-box-shadow: 1px 1px 11px 0px rgba(0,0,0,0.34);
    }

    div#Request_MessageItem div#Request_MessageIconReminder svg {
        width: 13px;
        position: absolute;
        left: 10px;
        transition: all ease .35s;
    }

    div#Request_MessageItem div#Request_MessageIconReminder span {
        position: absolute;
        left: 36px;
        font-size: 11px;
        font-weight: 700;
        color: var(--yellow);
        min-width: 102px;
        opacity: 0;
        transition: all ease .35s;
    }

    div#Request_MessageItem div#Request_MessageIconReminder:hover {
        width: 101px;
        justify-content: flex-start;
        transition: all ease .35s;
    }

    div#Request_MessageItem div#Request_MessageIconReminder:hover span {
        opacity: 1;
        transition: all ease .35s;
    }

    @media (max-width: 994px) {
        div#Request_MessageItem {
        }
    }
`

Message.MessageIconReminder = styled.div`

`