import { Fragment, useState, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from "./draw"
import "@reach/dialog/styles.css"
import Button from '@material-ui/core/Button'
import IconMenu from '../../../assets/img/menu-icon2.svg'
import MenuContent from './index'
import ImgLogo from '../../../assets/img/logo-dashboard.svg'
import IconUser from '../../../assets/img/icon-user-v2.svg'
import { userData } from '../../../services/services'

const useStyles = makeStyles({
  list: {
    width: '100%',
  },
  fullList: {
    width: 'auto',
  },
})

export default function TemporaryDrawer() {
  const classes = useStyles()
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const [user, setUser] = useState({
    name: '',
    email: '',
    rules: [],
  })

  const [opened, setOpened] = useState(false);
  const open = useCallback(() => setOpened(true), []);
  const close = useCallback(() => setOpened(false), []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const loadUserData = async() => {
    await userData()
        .then(response => {
            setUser({
                name: response.data.name,
                email: response.data.email,
                rules: response.data.rules
            })
        })
  }

  useEffect(() => {
      loadUserData()
      setOpened(false)
  }, [])

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      }, 'menu-mobile-drawer-jucerja')}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="logo-sys-jucerja-mobile">
        <img src={ImgLogo} className="img-logo-sys-mob no-select" alt=""/>
      </div>
      <div className="dados-usuario-mobile-drawer">
        <div className="icon-usuario-mobile-drawer no-select" style={{ backgroundImage: `url(${IconUser})` }}></div>
        <p className="nome-usuario-mobile-drawer no-select">{user.name === '' ? '-' : user.name}</p>
        <p className="email-usuario-mobile-drawer no-select">{user.email === '' ? '-' : user.email}</p>
      </div>
      <MenuContent drawer={true}/>
    </div>
  )

  return (
    <>
      {['left'].map((anchor) => (
        <Fragment key={anchor}>
          <Button className="btnMenuMobile" onClick={open}><img src={IconMenu} alt="" /></Button>
          <Drawer open={opened} onDismiss={close}>
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </>
  )
}
