import { useState, useEffect } from 'react'
import { Dialog, AppBar, Slide, Box, Grid, Typography, CircularProgress, TextField, Button } from '@material-ui/core'
import Container from '../../components/layout/Container'
import { useSnackbar } from 'notistack'
import { Requests } from './styles'
import { MdSubject, IoIosArrowBack, IoIosArrowForward, GrAttachment, FaTasks, AiOutlineMessage, RiMapPinUserLine } from 'react-icons/all'
import FilterRequests from '../../components/elements-ui/FilterRequests'
import DialogConfirmation from '../../components/elements-ui/DialogConfirmation'
import DialogFilterConfirm from '../../components/elements-ui/DialogFilterConfirm'
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { capitalize, limitChar, convertISODateToDateView } from '../../functions'

import { Chip, Stack } from '@mui/material';
import api from '../../services/api'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import NoResult from '../../components/animations/NoResults'
import ErrorRequest from '../../components/animations/ErrorRequest'
import { AiOutlineSearch, IoCloseCircleSharp, GoPlus } from 'react-icons/all'

export default function Requisicoes(props) {
    let itemsLoading = []

    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams();
    const parametroFiltro = props.match.params.filtro;

    const [filtroUrl, setFiltroUrl] = useState(Boolean(props.match.params.filtro))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [aplicarFiltro, setAplicarFiltro] = useState({});
    const [errorMessage, setErrorMessage] = useState('')
    const [requisicoes, setRequisicoes] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1)
    const [qtdeRegistros, setQtdeRegistros] = useState(10)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(Number(Math.round(totalRegistros / qtdeRegistros)))
    const [progress, setProgress] = useState(0)
    const [textSlastic, setTextSlastic] = useState('');
    const [aplicarSlastic, setAplicarSlastic] = useState(false)
    const [executarSlastic, setExecutarSlastic] = useState(false)

    const [salvarFiltro, setSalvarFiltro] = useState({});
    const [openDialogFilterConfirm, setOpenDialogFilterConfirm] = useState(false);
    const [dialogFilterConfirm, setDialogFilterConfirm] = useState(false);
    const [filtroDescricao, setFiltroDescricao] = useState('');

    const [filtrosUsuario, setFiltrosUsuario] = useState([]);

    const [openFiltroDeleteConfirm, setOpenFiltroDeleteConfirm] = useState(false);
    const [filtroDeleteConfirm, setFiltroDeleteConfirm] = useState(false);
    const [filtroDeleteObj, setFiltroDeleteObj] = useState({});


    const getRequisicoes = async (type) => {

        let options = {
            filtros: [],
            opcao: Number(props.match.params.tipo),
            firstResult: type ? 1 : ((paginaAtual - 1) * qtdeRegistros) + 1,
            maxResults: qtdeRegistros,
            gerarDadosComplementares: true
        }

        if (props.match.params.tipo === "todas") {
            options = {
                filtros: [],
                firstResult: ((paginaAtual - 1) * qtdeRegistros) + 1,
                maxResults: qtdeRegistros,
                gerarDadosComplementares: true
            }
        }

        if (Object.keys(aplicarFiltro).length > 0) {
            aplicarFiltro.firstResult = ((paginaAtual - 1) * qtdeRegistros) + 1;
            aplicarFiltro.maxResults = qtdeRegistros;
            aplicarFiltro.gerarDadosComplementares = true;
            aplicarFiltro.paginaAtual = paginaAtual;
            aplicarFiltro.qtdeRegistros = qtdeRegistros;

            options = aplicarFiltro;

            const path = generatePath(`/requisicoes/\\(:filtro\\)`, { filtro: JSON.stringify(aplicarFiltro) });
            props.history.replace(path);
        } else if (!filtroUrl && !props.match.params.tipo) {
            const path = generatePath(`/requisicoes`);
            props.history.replace(path);
        } else if (props.match.params.filtro) {
            options = JSON.parse(props.match.params.filtro);

            setPaginaAtual(options.paginaAtual);
            setQtdeRegistros(options.qtdeRegistros);

            setAplicarFiltro(options);
        }

        let urlPayload = getUrlPayLoad(options);

        setLoading(true)
        await api.post(urlPayload.url, urlPayload.payLoad)
            .then(async response => {
                setRequisicoes(response.data.requisicoes)
                setTotalRegistros(response.data.totalRecords)
                setTotalPaginas(Number(Math.ceil((Number(response.data.totalRecords) / Number(qtdeRegistros)))))

                await consultarFiltrosUsuario();

                setLoading(false)
                setError(false)
            })
            .catch(error => {
                setLoading(false)
                setError(true)
                setErrorMessage(error.toString())
            })
    }

    const getUrlPayLoad = (options) => {
        let url = '/jucerja/v1/Requisicoes/filtro';
        let payLoad = options;

        if (aplicarSlastic) {
            url = '/jucerja/v1/Requisicoes/buscainforequisicao';
            payLoad = { search: textSlastic, firstResult: options.firstResult, maxResults: options.maxResults };
        }

        return { url, payLoad }
    }

    const consulteRequisicoesComResetPaginaAtual = () => {
        if (paginaAtual == 1) {
            getRequisicoes(false);
        } else {
            setPaginaAtual(1);
        }
    }

    const consultarFiltrosUsuario = async () => {
        await api.get(`/jucerja/v1/filtro/1`)
            .then(response => {
                setFiltrosUsuario(response.data.reverse());
            })
            .catch(error => {
                enqueueSnackbar('Não foi possível consultar lista de filtros do usuário.', { variant: 'error' })
            });
    }

    useEffect(() => {
        if (filtroUrl && Object.keys(aplicarFiltro).length > 0) {
            setAplicarSlastic(false);
            setFiltroUrl(false);
            return;
        }

        consulteRequisicoesComResetPaginaAtual();
    }, [aplicarFiltro])

    useEffect(() => {
        if (aplicarSlastic)
            consulteRequisicoesComResetPaginaAtual();
    }, [executarSlastic])

    useEffect(() => {
        getRequisicoes(false)
    }, [paginaAtual])

    useEffect(() => {
        setPaginaAtual(1)
        getRequisicoes(true)
    }, [props.match.params.tipo])

    useEffect(() => {

        if (!salvarFiltro || Object.keys(salvarFiltro).length <= 0)
            return;

        setOpenDialogFilterConfirm(true);


    }, [salvarFiltro])

    useEffect(async () => {
        if (!dialogFilterConfirm) {
            setSalvarFiltro({});
        } else {
            setLoading(true);

            await api.post(`/jucerja/v1/filtro`, {
                "descricao": filtroDescricao,
                "parametro": JSON.stringify(salvarFiltro),
                "tipoFiltroId": 1
            })
                .then(async response => {
                    setAplicarFiltro(salvarFiltro);
                    await consultarFiltrosUsuario();
                })
                .catch(error => {
                    enqueueSnackbar('Não foi possível cadastrar o filtro para o usuário.', { variant: 'error' });
                })
                .finally(() => { setLoading(false); setOpenDialogFilterConfirm(false); });
        }

        setDialogFilterConfirm(false);
    }, [dialogFilterConfirm])

    useEffect(async () => {
        if (!filtroDeleteConfirm)
            return;

        await api.delete(`/jucerja/v1/filtro/${filtroDeleteObj.id}`)
            .then(response => {
                setFiltrosUsuario(filtrosUsuario.filter(f => f.id != filtroDeleteObj.id));
            })
            .catch(error => {
                enqueueSnackbar('Não foi possível excluir o filtro para o usuário.', { variant: 'error' });
            })
            .finally(() => { setOpenFiltroDeleteConfirm(false); });

        setFiltroDeleteConfirm(false);

    }, [filtroDeleteConfirm])

    const LoadingRequisicoes = () => (
        <a key={uuid()} className="no-select requests-listitem">
            <Button className="btn-acesso-requisicao" disableRipple={true} disableTouchRipple={true}>
                <Requests.ListItemContent id="Request_ListItemContent">
                    <Requests.ListItemInformations id="Requests_ListItemInformations">
                        <Requests.ListItemInfo id="Request_ListItemInfo">
                            <Requests.ListItemTitle id="Request_ListItemTitle"><Skeleton width={200} height={30} /></Requests.ListItemTitle>
                            <Requests.ListItemDescription id="Request_ListItemDescription"><Skeleton width={250} height={30} style={{ position: 'relative', top: '10px' }} /></Requests.ListItemDescription>
                        </Requests.ListItemInfo>
                        <Requests.ListItemReq id="Request_ListItemReq">
                            <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest"><Skeleton width={280} height={30} /></Requests.ListItemNumberRequest>
                            <Requests.ListItemData id="Request_ListItemData"><Skeleton width={50} height={30} /></Requests.ListItemData>
                        </Requests.ListItemReq>
                    </Requests.ListItemInformations>
                    <Requests.ListItemSubInfo id="Request_ListItemSubInfo" style={{ width: '100%' }}>
                        <Skeleton width={180} height={30} style={{ position: 'relative', top: '-10px', width: '100%' }} />
                    </Requests.ListItemSubInfo>
                </Requests.ListItemContent>
            </Button>
        </a>
    )

    for (var i = 0; i < 4; i++) {
        itemsLoading.push(LoadingRequisicoes())
    }

    const CircularProgressWithLabel = props => (
        <Box
            id="circular-progress-send-attachment"
            sx={{ position: 'relative', display: 'inline-flex' }}
        >
            <CircularProgress
                variant="determinate"
                {...props}
                style={{ visibility: 'hidden', color: 'var(--green)', borderRadius: '100px' }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div">
                    {
                        false
                            ?
                            <Button
                                className="btn-send-message-fast-request btnDefault btnDefaultPrimary"
                                disableRipple={true}
                                loading={loading ? 'true' : 'false'}
                            >
                                {`${Math.round(props.value)}%`}
                            </Button>
                            :
                            <Button
                                // onClick={() => handleSubmitMessageAndAttachment()}
                                className="btn-send-message-fast-request btnDefault btnDefaultPrimary"
                            >
                                <AiOutlineSearch id="icon-send-message" />
                            </Button>
                    }
                </Typography>
            </Box>
        </Box>
    )

    CircularProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    }

    return (
        <>
            <DialogConfirmation
                openDialog={openFiltroDeleteConfirm}
                setOpenDialog={setOpenFiltroDeleteConfirm}
                setConfirm={setFiltroDeleteConfirm}
                txtConfirm="Sim"
                txtCancel="Não"
                txtTitle="Confirmar exclusão do filtro"
                txtContainer={`Deseja realmente excluir o filtro '${filtroDeleteObj.descricao}' ?`}
                closeControlOutsideOnConfirm={true}
            />

            <DialogFilterConfirm
                openDialogFilterConfirm={openDialogFilterConfirm}
                setDialogFilterConfirm={setDialogFilterConfirm}
                setOpenDialogFilterConfirm={setOpenDialogFilterConfirm}
                setFiltroDescricao={setFiltroDescricao}
                filtroDescricao={filtroDescricao}
            />

            <div style={{
                width: "90%",
                position: "fixed",
                zIndex: "100"
            }}>
                <Requests.BarraSlastic>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> */}

                    <Requests.AttachmentListAddAndMessageBox>
                        <TextField
                            style={{ zIndex: 100 }}
                            id="txt-send-message-request"
                            variant="outlined"
                            value={textSlastic}
                            onChange={e => setTextSlastic(e.target.value)}

                            onKeyUp={e => {
                                setAplicarSlastic(false);

                                if (e?.code && (e.code.toLowerCase() === 'enter' || e.code.toLowerCase() === 'numpadenter') && textSlastic.trim().length > 0) {
                                    setAplicarSlastic(true);
                                    setAplicarFiltro({});
                                    setExecutarSlastic(!executarSlastic);

                                }
                            }}
                            placeholder="Pesquisar em solicitações"
                            disabled={loading}
                        />
                    </Requests.AttachmentListAddAndMessageBox>

                    {/* </Grid> */}
                </Requests.BarraSlastic>
            </div>
            <Container title="Solicitações">
                <Requests id="Requests">
                    {
                        error
                            ?
                            <ErrorRequest errorMessage={errorMessage} />
                            :
                            <>
                                <Requests.Action id="Requests_Action">
                                    {
                                        loading
                                            ?
                                            <Skeleton width={116} height={40} />
                                            :
                                            <>
                                                <Grid
                                                    spacing={1}
                                                    container
                                                    direction="row">

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                    >
                                                        <div>
                                                            <div style={{ display: 'inline-flex', padding: '0 5px 10px 0' }}>
                                                                <FilterRequests
                                                                    aplicarFiltro={aplicarFiltro}
                                                                    setAplicarFiltro={setAplicarFiltro}
                                                                    todosAssuntos
                                                                    setSalvarFiltro={setSalvarFiltro}
                                                                />
                                                            </div>
                                                            {
                                                                filtrosUsuario.map(filtro => (
                                                                    <>
                                                                        <Chip
                                                                            key={filtro.id}
                                                                            label={filtro.descricao}
                                                                            onClick={() => setAplicarFiltro(JSON.parse(filtro.parametro))}
                                                                            onDelete={() => { setFiltroDeleteObj(filtro); setOpenFiltroDeleteConfirm(true); }}
                                                                            // deleteIcon={<DeleteIcon />}
                                                                            variant={filtro.parametro == JSON.stringify({ ...aplicarFiltro, paginaAtual: 1 }) ? 'filled' : "outlined"}

                                                                        />&nbsp;
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                        <Requests.Pagination id="Request_Pagination">
                                                            {
                                                                loading
                                                                    ?
                                                                    <Skeleton width={180} height={30} />
                                                                    :
                                                                    requisicoes.length === 0
                                                                        ?
                                                                        null
                                                                        :
                                                                        <>
                                                                            Página {paginaAtual} de {totalPaginas} de {totalRegistros} {totalRegistros <= 1 ? 'registro' : 'registros'}
                                                                            <Button className="btnPagination btnPaginationPrev" onClick={() => setPaginaAtual(paginaAtual - 1)} disabled={paginaAtual === 1 ? true : false}><IoIosArrowBack /></Button>
                                                                            <Button className="btnPagination btnPaginationNext" onClick={() => setPaginaAtual(paginaAtual + 1)} disabled={paginaAtual === totalPaginas ? true : false}><IoIosArrowForward /></Button>
                                                                        </>
                                                            }
                                                        </Requests.Pagination>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> */}
                                                        {/* </Grid>
                                                    <Grid item >

                                                    </Grid> */}

                                                    </Grid>
                                                </Grid>
                                            </>
                                    }

                                </Requests.Action>
                                <Requests.List id="Request_List" loading={String(loading)}>
                                    {
                                        loading
                                            ?
                                            itemsLoading
                                            :
                                            requisicoes.length === 0
                                                ?
                                                <NoResult />
                                                :
                                                requisicoes.map(requisicao => (
                                                    <Link key={uuid()} to={`/requisicao/${requisicao.numeroRequisicao}`} className="no-select requests-listitem">
                                                        <Button className="btn-acesso-requisicao">
                                                            <Requests.ListItemContent id="Request_ListItemContent">
                                                                <Requests.ListItemInformations id="Requests_ListItemInformations">
                                                                    <Requests.ListItemInfo id="Request_ListItemInfo">
                                                                        <Requests.ListItemTitle id="Request_ListItemTitle">{capitalize(requisicao.nomeCliente)}</Requests.ListItemTitle>
                                                                        <Requests.Subject id="Requests_Subject" ><MdSubject /> {requisicao.assunto !== "" ? capitalize(requisicao.assunto) : requisicao.assunto}</Requests.Subject>
                                                                        <Requests.ListItemDescription id="Request_ListItemDescription">{limitChar(100, requisicao.previaMensagem)}</Requests.ListItemDescription>
                                                                    </Requests.ListItemInfo>
                                                                    <Requests.ListItemReq id="Request_ListItemReq">
                                                                        <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest">{requisicao.numeroRequisicaoFormatada}</Requests.ListItemNumberRequest>
                                                                        <Requests.ListItemData id="Request_ListItemData">{convertISODateToDateView(requisicao.dataAbertura)}</Requests.ListItemData>
                                                                    </Requests.ListItemReq>
                                                                </Requests.ListItemInformations>
                                                                <Requests.ListItemSubInfo id="Request_ListItemSubInfo">
                                                                    <Requests.ListItemStatus id="Request_ListItemStatus">{requisicao.status}</Requests.ListItemStatus>
                                                                    {
                                                                        requisicao.nomeAtendente && requisicao.nomeAtendente.length > 0 ? <Requests.ListItemResponsavel id="Request_ListItemResponsavel"><RiMapPinUserLine id="RiMapPinUserLine" /> {requisicao.nomeAtendente}</Requests.ListItemResponsavel> : <></>
                                                                    }
                                                                    {
                                                                        requisicao.quantidadeAnexos && requisicao.quantidadeAnexos > 0 ? <Requests.ListItemAttach id="Request_ListItemAttach"><GrAttachment /> {requisicao.quantidadeAnexos} arquivos</Requests.ListItemAttach> : <></>
                                                                    }
                                                                    {
                                                                        requisicao.quantidadeTarefas && requisicao.quantidadeTarefas > 0 ? <Requests.ListItemTask id="Request_ListItemTask"><FaTasks /> {requisicao.quantidadeTarefas} tarefas</Requests.ListItemTask> : <></>
                                                                    }
                                                                    {
                                                                        requisicao.quantidadeMensagensAposUltimoAlteracaoStatus && requisicao.quantidadeMensagensAposUltimoAlteracaoStatus > 0 ? <Requests.ListItemMessage id="Request_ListItemMessage"><AiOutlineMessage /> {requisicao.quantidadeMensagensAposUltimoAlteracaoStatus} novas mensagens</Requests.ListItemMessage> : <></>
                                                                    }
                                                                </Requests.ListItemSubInfo>
                                                            </Requests.ListItemContent>
                                                        </Button>
                                                    </Link>
                                                ))
                                    }
                                </Requests.List>
                            </>
                    }
                </Requests>
            </Container >
        </>
    )
}