import Container from '../../components/layout/Container'
import Grid from '@material-ui/core/Grid'
import { useEffect, useRef, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../../components/elements-ui/Card"
import { Badge } from "../../components/elements-ui/Badge"
import { ActionTable } from "../../components/elements-ui/ActionTable"
import { Button, Checkbox } from '@material-ui/core'
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from 'react-icons/all'
import { HiOutlineArrowsExpand, TiEquals } from 'react-icons/all'
import { Requests } from './styles'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../services/api'
import FilterRequests from '../../components/elements-ui/FilterRequests'

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
            display: "none"
        }
    }
}));


export default function Redistribuicoes() {
    const [aplicarFiltro, setAplicarFiltro] = useState({});
    const [requisicoes, setRequisicoes] = useState([])
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(undefined);
    const [rowCountState, setRowCountState] = useState(rowCount || 0);
    const [selectionModel, setSelectionModel] = useState([]);
    const prevSelectionModel = useRef([]);
    const [rowsState, setRowsState] = useState({
        page: 1,
        pageSize: 50,
    });

    const getRequisicoes = async () => {
        let options = {
            filtros: [],
            opcao: 1,
            firstResult: ((rowsState.page - 1) * rowsState.pageSize) + 1,
            maxResults: rowsState.pageSize,
            gerarDadosComplementares: false
        }

        if (Object.keys(aplicarFiltro).length > 0) {
            aplicarFiltro.firstResult = options.firstResult;
            aplicarFiltro.maxResults = rowsState.pageSize;
            aplicarFiltro.gerarDadosComplementares = false;
            options = aplicarFiltro;
        }

        setLoading(true);
        setRowCount(undefined);
        await api.post('/jucerja/v1/Requisicoes/filtro', options)
            .then(response => {
                setRequisicoes(response.data.requisicoes);
                setRowCount(response.data.totalRecords);
            })
            .catch(error => {
                // setLoading(false)
                // setError(true)
                // setErrorMessage(error.toString())
            })
            .finally(() => {
                setLoading(false);
                setTimeout(() => {
                    setSelectionModel(prevSelectionModel.current.filter(x => x.page === rowsState.page).map(x => x.valor));
                });
            });
    }

    const columns = [
        {
            field: 'numeroRequisicaoFormatada',
            headerName: 'N°',
            width: 110
        },
        {
            field: 'setor',
            headerName: 'Setor',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'assunto',
            headerName: 'Assunto',
            width: 200,
        },
        {
            field: 'nomeAtendente',
            headerName: 'Atendente',
            width: 200,
        },
        {
            field: 'dataAberturaFormatada',
            headerName: 'Abertura',
            type: 'date',
            width: 200,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 200,
        }
    ]


    useEffect(() => {
        if (rowsState.page === 0) {

            setRowsState(prev => ({ ...prev, page: 1 }));
            return;
        }

        getRequisicoes();

    }, [rowsState.page])

    useEffect(() => {
        prevSelectionModel.current = [];
        setRowsState(prev => ({ ...prev, page: 0 }));
    }, [aplicarFiltro])


    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    return (
        <Container title="Redistribuir Solicitações">
            <Requests.Action id="Requests_Action">
                <FilterRequests
                    disabled={loading}
                    aplicarFiltro={aplicarFiltro}
                    setAplicarFiltro={setAplicarFiltro}
                    todosAssuntos
                />
                
                {
                    prevSelectionModel.current.length <= 0
                        ?
                        <Button className="btnFilter" disabled={prevSelectionModel.current.length <= 0}><HiOutlineArrowsExpand id="icon-link-nav" />Editar Solicitações</Button>
                        :
                        <Link to={`/Redistribuicao/${prevSelectionModel.current.map(x => x.valor)}`}>
                            <Button className="btnFilter" disabled={prevSelectionModel.current.length <= 0}><HiOutlineArrowsExpand id="icon-link-nav" />Editar Solicitações</Button>
                        </Link>
                }
                
            </Requests.Action>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                        <Card.Content>
                            <div style={{ height: 700, width: '100%' }}>
                                <DataGrid
                                    // className={classes.root}
                                    getRowId={row => row.numeroRequisicao}
                                    rowCount={rowCountState}
                                    rows={requisicoes}
                                    columns={columns}
                                    disableColumnMenu
                                    hideFooterPagination={loading}
                                    loading={loading}
                                    rowsPerPageOptions={[50]}
                                    pageSize={50}
                                    page={rowsState.page > 0 ? rowsState.page - 1 : 0}
                                    pagination
                                    hideFooterSelectedRowCount
                                    disableExtendRowFullWidth={false}
                                    paginationMode="server"
                                    onPageChange={(page) => {
                                        setRowsState((prev) => ({ ...prev, page: page + 1 }));
                                        setSelectionModel([]);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        let listaSelecao = prevSelectionModel.current.filter(x => x.page !== rowsState.page);

                                        newSelectionModel.forEach(element => {
                                            listaSelecao.push({ page: rowsState.page, valor: element });
                                        });

                                        prevSelectionModel.current = listaSelecao;
                                        setSelectionModel(listaSelecao.filter(x => x.page === rowsState.page).map(x => x.valor));
                                    }}
                                    selectionModel={selectionModel}
                                    checkboxSelection
                                    localeText={ptBR.props.MuiDataGrid.localeText}
                                />

                            </div>
                        </Card.Content>
                    </Card>
                </Grid>
            </Grid>
        </Container >
    )
}