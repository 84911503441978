import { useEffect, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../Card"
import { Badge } from "../Badge"
import { ActionTable } from "../ActionTable"
import { Button } from '@material-ui/core'
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from 'react-icons/all'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../../services/api'

const PrimaryTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
    },
}))(Tooltip);

export default function TableEmployees() {
    const [funcionarios, setFuncionarios] = useState([])
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 110
        },
        {
            field: 'nome',
            headerName: 'Nome',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'login',
            headerName: 'Login',
            width: 150,
        },
        {
            field: 'setor',
            headerName: 'Setor',
            width: 150,
        },
        {
            field: 'supervisor',
            headerName: 'Supervisor',
            width: 170,
            valueGetter: (params) => params.value ? 'Sim' : 'Não',
            renderCell: (params) => {
                return <Badge type={params.value == "Sim"} className="no-select">{params.value}</Badge>
            }
        },
        {
            field: 'distribuicao',
            headerName: 'Distribuição',
            width: 170,
            renderCell: (params) => {
                return <Badge type={params.value == "Sim"} className="no-select">{params.value}</Badge>
            },

            valueGetter: (params) => params.value ? 'Sim' : 'Não',
        },
        {
            field: 'acoes',
            headerName: 'Ações',
            width: 129,
            filterable: false,
            renderCell: (params) => {
                return (
                    <ActionTable>
                        {/* <Link to="/configuracoes">
                            <PrimaryTooltip title="Visualizar" placement="top">
                                <Button className="btn-open"><AiOutlineEye/></Button>
                            </PrimaryTooltip>
                        </Link> */}
                        <Link to={`/Funcionario/${params.id}`}>
                            <PrimaryTooltip title="Editar" placement="top">
                                <Button className="btn-edit"><AiOutlineEdit /></Button>
                            </PrimaryTooltip>
                        </Link>
                        {/* <PrimaryTooltip title="Deletar" placement="top">
                            <Button onClick={() => handleDelete(1)} className="btn-delete"><AiOutlineDelete/></Button>
                        </PrimaryTooltip> */}
                    </ActionTable>
                )
            }
        },
    ]


    useEffect(async () => {
        await api.post('/jucerja/v1/Funcionario/funcionarios-configuracao', {})
            .then(response => {
                setFuncionarios(response.data);
            })
            .catch(error => {
            })
            .finally(() => setLoading(false))
    }, [])

    return (
        <Card>
            <Card.Content>
                <div style={{ height: 700, width: '100%' }}>
                    <DataGrid
                        loading={loading}
                        rows={funcionarios}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        disableExtendRowFullWidth={false}
                        disableSelectionOnClick
                        localeText={ptBR.props.MuiDataGrid.localeText}
                    />

                </div>
            </Card.Content>
        </Card>
    );
}
