import { useState, forwardRef } from 'react'
import { useSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

import { CircularProgress, Tooltip, withStyles, Box, Typography } from "@material-ui/core"
import { VscFilePdf, RiCloseCircleFill, IoImageOutline, CgClose, GrAttachment, AiTwotoneLock, AiOutlineUnlock, IoChatboxEllipsesSharp, IoChatboxEllipsesOutline, AiOutlineSend, BsFileText } from 'react-icons/all'
import { Request } from './styles'

import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import htmlToDraft from 'html-to-draftjs'

import PreviewFileImage from '../PreviewFileImage'

import api from '../../../services/api'
import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { crieRequisicaoObj, limitChar } from '../../../functions'

import { getRToken } from '../../../services/auth'

import DialogStandardText from '../DialogStandardText'
import _ from 'lodash';
import { FileDragDropStyle } from '../../animations/FileDragDrop/styles'
import FileDragDrop from '../../animations/FileDragDrop'
import { LoadMessagesChat } from '../../animations/LoadingMessagesChat/styles'
import Draggable from 'react-draggable'
import { useDispatch } from 'react-redux'
import { storeMessage } from '../../../slices/requestMessageSlice'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        color: 'var(--white)',
    },
}))(Tooltip)

export default function DialogSendMessageEditor({
    idRequisicao,
    openDialogEditor,
    setOpenDialogEditor,
    idAssunto
}) {
    
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const [loadingNoAttachment, setLoadingNoAttachment] = useState(false)
    const [privateMessage, setPrivateMessage] = useState(false)
    const [disabledPrivateMessage, setDisabledPrivateMessage] = useState(false)
    const [reminderMessage, setReminderMessage] = useState(false)
    const [disabledReminderMessage, setDisabledReminderMessage] = useState(false)
    const [description, setDescription] = useState({
        htmlValue: "",
        editorState: EditorState.createEmpty()
    })
    const [attachs, setAttachs] = useState([])
    const [valueAttachInputFile, setValueAttachInputFile] = useState()
    const [openDialogPreviewFileImage, setOpenDialogPreviewFileImage] = useState(false)
    const [preview, setPreview] = useState(null)
    const [progress, setProgress] = useState(0)
    const [openDialogStandardText, setOpenDialogStandardText] = useState(false)
    const [dragAtivo, setDragAtivo] = useState(false)

    const desmontarAnimacao = _.debounce(() => {
        setDragAtivo(false);
    }, 200);

    const handleOpenStandardText = () => {
        setOpenDialogStandardText(true)
    }

    const handleCloseDialogEditor = () => {
        setOpenDialogEditor(false)
        setAttachs([])
        setValueAttachInputFile()
        setPreview(null)
        setProgress(0)
        setDescription({
            htmlValue: "",
            editorState: EditorState.createEmpty()
        })
    }

    const onEditorStateChange = editorValue => {
        const editorStateInHtml = draftToHtml(
            convertToRaw(editorValue.getCurrentContent())
        )

        setDescription({
            htmlValue: editorStateInHtml,
            editorState: editorValue
        })
    }

    const handleChangePrivateAndReminder = (change) => {
        if (change === "enablePrivate") {
            setPrivateMessage(true)
            setDisabledPrivateMessage(false)
            setReminderMessage(false)
            setDisabledReminderMessage(true)
        }
        else if (change === "disablePrivate") {
            setPrivateMessage(false)
            setDisabledPrivateMessage(false)
            setReminderMessage(false)
            setDisabledReminderMessage(false)
        }
        else if (change === "enableReminder") {
            setPrivateMessage(false)
            setDisabledPrivateMessage(true)
            setReminderMessage(true)
            setDisabledReminderMessage(false)
        }
        else if (change === "disableReminder") {
            setPrivateMessage(false)
            setDisabledPrivateMessage(false)
            setReminderMessage(false)
            setDisabledReminderMessage(false)
        }
    }

    const handleAttachment = event => {
        Array.from(event.target.files).map((file, index) => {
            if (Boolean(file)) {
                if (Number(file.size) <= 10000000) {
                    if (
                        file.type === "application/pdf"
                        || file.type === "image/jpg"
                        || file.type === "image/jpeg"
                        || file.type === "image/jpe"
                        || file.type === "image/jfif"
                        || file.type === "image/png"
                        || file.type === "image/bmp"
                        || file.type === "image/gif"
                        || file.type === "application/pkcs7-signature"
                    ) {
                        const fileReader = new window.FileReader()
                        fileReader.onload = (fileLoad) => {
                            const { result } = fileLoad.target
                            setAttachs((prevState) => [...prevState, {
                                id: uuid(),
                                file: file,
                                name: file.name,
                                type: file.type,
                                url: result
                            }])
                        }

                        fileReader.readAsDataURL(file)
                    }
                    else {
                        enqueueSnackbar('A extensão do arquivo não é suportado', { variant: 'error' })
                    }
                }
                else {
                    enqueueSnackbar('O arquivo não pode ser maior que 10mb', { variant: 'error' })
                }
            }
        })
    }

    const handleDeleteAttach = file => {
        setAttachs((attachs) => attachs.filter((attach) => attach.id !== file.id))
    }

    const handlePreview = file => {
        setPreview(file)
        setOpenDialogPreviewFileImage(true)
    }

    const handleSubmitMessage = async () => {
        let lengthAttachs = attachs.length

        if (lengthAttachs === 0) {
            setLoadingNoAttachment(true)
        } else {
            setLoading(true)
        }        

        dispatch(storeMessage(crieRequisicaoObj(idRequisicao, description.htmlValue, (privateMessage ? 3 : reminderMessage ? 2 : 1), attachs)));

        setLoadingNoAttachment(false)
        setLoading(false)
        handleCloseDialogEditor()
    }

    useEffect(() => {
        if (openDialogEditor) {
            setLoading(false)
            setPrivateMessage(false)
            setDisabledPrivateMessage(false)
            setReminderMessage(false)
            setDisabledReminderMessage(false)
            setDescription({
                htmlValue: "",
                editorState: EditorState.createEmpty()
            })
        }
    }, [openDialogEditor])

    const CircularProgressWithLabel = props => (
        <Box
            id="circular-progress-send-attachment"
            sx={{ position: 'relative', display: 'inline-flex' }}
        >
            <CircularProgress
                variant="determinate"
                {...props}
                style={{ visibility: loading ? 'visible' : 'hidden', color: 'var(--green)', borderRadius: '100px' }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div">
                    {
                        loading
                            ?
                            <Button
                                className={`btnDefault btnDefaultPrimary btn-send-message-with-editor-and-attachment btn-loading-message-editor-attachment`}
                            >
                                {`${Math.round(props.value)}%`}
                            </Button>
                            :
                            loadingNoAttachment
                                ?
                                <Button
                                    className={`btnDefault btnDefaultPrimary btn-send-message-with-editor-and-attachment`}
                                >
                                    <CircularProgress size={20} style={{ color: 'var(--white)' }} />
                                </Button>
                                :
                                null
                    }
                </Typography>
            </Box>
        </Box>
    )

    CircularProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    }

    return (
        <>
            <DialogStandardText
                idAssunto={idAssunto}
                setOpenDialogEditor={setOpenDialogEditor}
                openDialogStandardText={openDialogStandardText}
                setOpenDialogStandardText={setOpenDialogStandardText}
                setDescription={setDescription}
            />

            <PreviewFileImage
                openDialogPreviewFileImage={openDialogPreviewFileImage}
                setOpenDialogPreviewFileImage={setOpenDialogPreviewFileImage}
                preview={preview}
                setPreview={setPreview}
                loading={loading}
                setLoading={setLoading}
            />

        <Draggable
                    handle={'[class*="MuiDialog-root"]'} 
                    cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
                open={openDialogEditor}
                TransitionComponent={Transition}
                keepMounted
                onClose={loading || loadingNoAttachment ? null : handleCloseDialogEditor}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className="dialog-editor-message-request"
                maxWidth="md"
                id="dialog-enviar-mensagem-editor"
            >
                <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">
                    Enviar Mensagem
                    <Button onClick={loading || loadingNoAttachment ? null : handleCloseDialogEditor} disabled={loading || loadingNoAttachment} className="btn-close-dialog-message-request"><CgClose /></Button>
                </DialogTitle>
                <DialogContent
                    onDragEnter={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDragAtivo(true);
                    }}
                    onDragOver={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        desmontarAnimacao();
                    }}
                    onDrop={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleAttachment({ target: { files: e.dataTransfer.files } });
                    }}
                    onDropCapture={e => { }}
                >
                    {
                        dragAtivo ?
                            <FileDragDropStyle id="LoadMessagesChat">
                                <FileDragDropStyle.Animation id="LoadMessagesChat_Animation">
                                    <FileDragDrop />
                                </FileDragDropStyle.Animation>
                                <LoadMessagesChat.Message id="LoadMessagesChat_Message">anexe arquivos ao solta-los sobre a tela</LoadMessagesChat.Message>
                            </FileDragDropStyle>
                            :
                            <Request.SendMessageDialog id="Request_SendMessageDialog">
                                <Request.SendMessageContentDialog id="Request_SendMessageContentDialog">
                                    <Request.SendMessageContainerContentDialog id="Request_SendMessageContainerContentDialog">
                                        <Editor
                                            spellCheck
                                            toolbarHidden={false}
                                            editorState={description.editorState}
                                            onEditorStateChange={onEditorStateChange}
                                            localization={{
                                                locale: 'pt',
                                            }}
                                            toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded'/*, 'emoji'*/, 'image', 'remove', 'history'] }}
                                            readOnly={loading}
                                        />
                                        <Request.SendMessageActionsDialog id="Request.SendMessageActionsDialog">
                                            <Request.SendMessageActionsAttachmentDialog id="Request_SendMessageActionsAttachmentDialog">
                                                <HtmlTooltip title="Anexar Arquivos" placement="top">
                                                    <Button className="btn-send-attach btn-send-attach-mobile" onBlur={() => setPreview(null)}>
                                                        <input
                                                            accept=".jpg, jpeg, jpe, .jfif, .png, .bmp, .gif, .pdf, .p7s"
                                                            id="input-file-send-message-editor"
                                                            type="file"
                                                            multiple
                                                            onClick={e => (e.target.value = null)}
                                                            onChange={handleAttachment}
                                                            onBlur={() => setPreview(null)}
                                                        />
                                                        <GrAttachment id="icon-attach-message" />
                                                    </Button>
                                                </HtmlTooltip>
                                                <HtmlTooltip title="Texto Padrão" placement="top">
                                                    <Button className="btn-standard-text" onClick={() => handleOpenStandardText()}>
                                                        <BsFileText />
                                                    </Button>
                                                </HtmlTooltip>
                                                <Request.PrivateAndReminder id="Request.PrivateAndReminder">
                                                    {
                                                        privateMessage
                                                            ?
                                                            <HtmlTooltip title="Desabilitar Mensagem Privada" placement="top">
                                                                <Button
                                                                    className="btn-actions-privatereminder btn-send-message-private-enable"
                                                                    onClick={() => handleChangePrivateAndReminder('disablePrivate')}
                                                                    disabled={disabledPrivateMessage}
                                                                >
                                                                    <AiTwotoneLock />
                                                                </Button>
                                                            </HtmlTooltip>
                                                            :
                                                            <HtmlTooltip title="Habilitar Mensagem Privada" placement="top">
                                                                <Button
                                                                    className="btn-actions-privatereminder btn-send-message-private-disable"
                                                                    onClick={() => handleChangePrivateAndReminder('enablePrivate')}
                                                                    disabled={disabledPrivateMessage}
                                                                >
                                                                    <AiOutlineUnlock />
                                                                </Button>
                                                            </HtmlTooltip>
                                                    }
                                                    {
                                                        reminderMessage
                                                            ?
                                                            <HtmlTooltip title="Desabilitar Lembrete" placement="top">
                                                                <Button
                                                                    className="btn-actions-privatereminder btn-send-reminder-private-disable"
                                                                    onClick={() => handleChangePrivateAndReminder('disableReminder')}
                                                                    disabled={disabledReminderMessage}
                                                                >
                                                                    <IoChatboxEllipsesSharp />
                                                                </Button>
                                                            </HtmlTooltip>
                                                            :
                                                            <HtmlTooltip title="Habilitar Lembrete" placement="top">
                                                                <Button
                                                                    className="btn-actions-privatereminder btn-send-reminder-private-enable"
                                                                    onClick={() => handleChangePrivateAndReminder('enableReminder')}
                                                                    disabled={disabledReminderMessage}
                                                                >
                                                                    <IoChatboxEllipsesOutline />
                                                                </Button>
                                                            </HtmlTooltip>
                                                    }
                                                </Request.PrivateAndReminder>
                                            </Request.SendMessageActionsAttachmentDialog>
                                            <Request.SendMessageActionsSendMessage id="Request_SendMessageActionsSendMessageDialog">
                                                {
                                                    loading || loadingNoAttachment
                                                        ?
                                                        <CircularProgressWithLabel value={progress} />
                                                        :
                                                        <Button
                                                            onClick={() => handleSubmitMessage()}
                                                            className={`btnDefault btnDefaultPrimary btn-send-message-with-editor-and-attachment`}
                                                        >
                                                            <AiOutlineSend id="icon-send-message" /> Enviar
                                                        </Button>
                                                }
                                            </Request.SendMessageActionsSendMessage>
                                        </Request.SendMessageActionsDialog>
                                        {
                                            attachs.length > 0
                                                ?
                                                <Request.Attachments id="Request_Attachments">
                                                    <Request.TitleContainer id="Request_TitleContainer">
                                                        <Request.TitleText id="Request_TitleText">Anexos Adicionados</Request.TitleText>
                                                    </Request.TitleContainer>
                                                    <Request.AttachmentContainer id="Request_AttachmentContainer">
                                                        <Request.AttachmentContent id="Request_AttachmentContainer">
                                                            {attachs.map(attach => (
                                                                attach.type === "application/pdf"
                                                                    ?
                                                                    <Request.AttachmentItem key={uuid()} id="Request_AttachmentItemPdf">
                                                                        <Request.AttachmentItemImage onClick={() => handlePreview(attach)} id="Request_AttachmentItemImage"><VscFilePdf /></Request.AttachmentItemImage>
                                                                        <Request.AttachmentItemText onClick={() => handlePreview(attach)} id="Request_AttachmentItemText">{limitChar(100, attach.name)}</Request.AttachmentItemText>
                                                                        <Button className="Request_AttachmentItemClose" onClick={() => handleDeleteAttach(attach)}><RiCloseCircleFill /></Button>
                                                                    </Request.AttachmentItem>
                                                                    :
                                                                    <Request.AttachmentItem key={uuid()} id="Request_AttachmentItemImg">
                                                                        <Request.AttachmentItemImage onClick={() => handlePreview(attach)} id="Request_AttachmentItemImage"><IoImageOutline /></Request.AttachmentItemImage>
                                                                        <Request.AttachmentItemText onClick={() => handlePreview(attach)} id="Request_AttachmentItemText">{limitChar(100, attach.name)}</Request.AttachmentItemText>
                                                                        <Button className="Request_AttachmentItemClose" onClick={() => handleDeleteAttach(attach)}><RiCloseCircleFill /></Button>
                                                                    </Request.AttachmentItem>
                                                            ))}
                                                        </Request.AttachmentContent>
                                                    </Request.AttachmentContainer>
                                                </Request.Attachments>
                                                :
                                                null
                                        }
                                    </Request.SendMessageContainerContentDialog>
                                </Request.SendMessageContentDialog>
                            </Request.SendMessageDialog>

                    }
                </DialogContent>
            </Dialog>
            </Draggable>
        </>
    )
}