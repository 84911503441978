import styled, { css } from 'styled-components'

export const Request = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`

Request.Top = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    

    @media (max-width: 764px) {
    }
`

Request.ListOfMessages = styled.div`
    width: 100%;
`

Request.ListOfMessagesInside = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    position: absolute;
    top: 90px;
    width: calc(100% - 20px);
    height: ${props => props.acao === "EnviarMensagem" ? 'calc(100% - 226px)' : 'calc(100% - 100px)'};
    left: 10px;
    
    @media (max-width: 764px) {
        top: 76px;
        height: ${props => props.acao === "EnviarMensagem" ? 'calc(100% - 194px)' : 'calc(100% - 80px)'};
    }

    &[type="loading"] {
        align-items: center;
        justify-content: center;
    }
`

Request.TopLeft = styled.div`
    width: calc(100% - 250px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    min-height: 75px;

    @media (max-width: 600px) {
        /* flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start; */
    }

    @media (max-width: 764px) {
        width: 100%;
    }
`

Request.NavName = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-arrow-back {
        min-width: 0;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #bdbdbd4f;
        color: #383838;
        border-radius: 100%;
        padding: 0;
        font-size: 20px;
        margin: 0 15px 0 0;
    }

    @media (max-width: 764px) {
        width: 100%;
    }
`

Request.Information = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

Request.Name = styled.div`
    color: var(--primary);
    font-weight: 900;
    font-size: 16px;
    max-width: auto;
    line-height: 16px;

    @media (max-width: 764px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 170px;
        width: 100%;
    }
`

Request.Number = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: var(--lightgrey);

    @media (max-width: 764px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 170px;
        width: 100%;
    }
`

Request.BadgeStatus = styled.div`
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: var(--bg-green);
    color: var(--green);
    padding: 5px 10px 5px 10px;
    border-radius: 100px;
    margin: 0 13px;

    @media (max-width: 764px) {
        margin-left: 0;
    }
`

Request.Attendant = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-gree);
    margin: 0 10px 0 0;
    max-width: 180px;

    svg {
        margin: 0 5px 0 0;
        color: var(--primary);
    }

    @media (max-width: 600px) {
        padding: 13px 0 5px 0;
    }
`

Request.Stars = styled.div`
`

Request.TopRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 250px;
    margin: 0;
    min-height: 75px;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request {
        min-width: 0;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f080804f;
        color: red;
        border-radius: 100%;
        padding: 0;
        margin-left: 5px;
        font-size: 20px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-arrow-back.no-select {
        min-width: 0;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #bdbdbd4f;
        color: #383838;
        border-radius: 100%;
        padding: 0;
        font-size: 20px;
        margin: 0 15px 0 0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-01 {
        background: var(--bg-green);
        color: var(--green);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-02 {
        background: var(--bg-red);
        color: var(--red);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-03 {
        background: var(--bg-yellow);
        color: #afaf00;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-04 {
        background: var(--bg-orange);
        color: var(--orange);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-05 {
        background: transparent;
        color: var(--grey);
    }

    @media (max-width: 764px) {
        width: 100%;

        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request {
            margin-left: 0px;
            margin-right: 5px;
        }

        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-01,
        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-02,
        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-03,
        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-default-action-request.item-04 {
            display: none;
        }
    }
`

Request.Messages = styled.div`
    /* display: flex;
    flex-direction: column; */

    /* width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    float: right;
    overflow: auto; */

    /* width: 100%; */
    /* height: 250px; */
    /* margin: 0 0 35px 0; */

    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    flex-basis: 100%;
    height: 100%;
    align-content: flex-end;
    min-height: min-content; */
    /* display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    float: right;
    overflow: auto; */

    width: calc(100% - 5px);
    padding-bottom: 20px;

    &> div:last-child {
        margin-bottom: 5px;
    }
`

Request.MessageStatus = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 25px 0;
`

Request.MessageDate = styled.div`
    font-weight: 400;
    font-size: 14px;
`

Request.MessageBadge = styled.div`  
    width: auto;
    color: var(--white);
    text-transform: uppercase;
    font-size: 11px;
    padding: 5px 15px;
    border-radius: 100px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 7px 0 0 0;
    text-align: center;

    ${props => {
        switch (props.type) {
            case 'assuntos':
                return `
                        background-color: var(--alternative3);
                    `
            case `ABERTA`:
                return `
                        background-color: var(--green);
                    `
            case `ANDAMENTO`:
                return `
                        background-color: var(--blue);
                    `
            case `CANCELADA`:
                return `
                        background-color: var(--red);
                    `
            case `AGUARDANDO SOLICITANTE`:
                return `
                        background-color: var(--purple);
                    `
            case `AGUARDANDO ÓRGÃO EXTERNO`:
                return `
                        background-color: var(--purple);
                    `
            case `EM VERIFICAÇÃO INTERNA`:
                return `
                        background-color: var(--orange);
                    `
            case `FECHADA PELO CLIENTE`:
                return `
                        background-color: var(--red);
                    `
            case `FECHADA AUTOMATICAMENTE`:
                return `
                        background-color: var(--red);
                    `
            case `FECHAMENTO PELO ATENDENTE`:
                return `
                        background-color: var(--red);
                    `
            default:
                return `
                        background-color: var(--dark-brown);
                    `
        }
    }
    }
`

Request.Message = styled.div`
    width: 100%;
    margin: 0 0 25px 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: ${props => props.type === "solicitation" ? "flex-start" : "flex-end"};

    @media (max-width: 420px) {
        /* margin-bottom: 10px; */
    }

    @media (min-width: 994px) {
        
    }
`

Request.MessagePrivate = styled.div`
    width: 100%;
    margin: 0 0 25px 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: ${props => props.type === "solicitation" ? "flex-start" : "flex-end"};

    div#Request_MessageItem {
        background: var(--red);
        position: relative;
        overflow: visible;
        transition: all ease .35s;
        min-width: 140px;
        max-width: calc(100% - 25px);
        width: fit-content;
    }

    div#Request_MessageItem div#Request_MessageIconPrivate {
        position: absolute;
        bottom: -15px;
        left: -15px;
        width: 34px;
        height: 34px;
        background: #ffdada;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        overflow: hidden;
        transition: all ease .35s;
        cursor: pointer;
        box-shadow: 1px 1px 11px 0px rgba(0,0,0,0.34);
        -webkit-box-shadow: 1px 1px 11px 0px rgba(0,0,0,0.34);
        -moz-box-shadow: 1px 1px 11px 0px rgba(0,0,0,0.34);
    }

    div#Request_MessageItem div#Request_MessageIconPrivate svg {
        width: 13px;
        position: absolute;
        left: 10px;
        transition: all ease .35s;
    }

    div#Request_MessageItem div#Request_MessageIconPrivate span {
        position: absolute;
        left: 36px;
        font-size: 11px;
        font-weight: 700;
        color: var(--red);
        min-width: 102px;
        opacity: 0;
        transition: all ease .35s;
    }

    div#Request_MessageItem div#Request_MessageIconPrivate:hover {
        width: 152px;
        justify-content: flex-start;
        transition: all ease .35s;
    }

    div#Request_MessageItem div#Request_MessageIconPrivate:hover span {
        opacity: 1;
        transition: all ease .35s;
    }

    @media (max-width: 994px) {
        div#Request_MessageItem {
           
        }
    }
`

Request.MessageIconPrivate = styled.div`

`

Request.MessageReminder = styled.div`
    width: 100%;
    margin: 0 0 25px 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: ${props => props.type === "solicitation" ? "flex-start" : "flex-end"};

    div#Request_MessageItem {
        background: var(--yellow);
        position: relative;
        overflow: visible;
        transition: all ease .35s;
        min-width: 140px;
        max-width: calc(100% - 25px);
        width: fit-content;
    }

    div#Request_MessageItem div#Request_MessageIconReminder {
        position: absolute;
        bottom: -15px;
        left: -15px;
        width: 34px;
        height: 34px;
        background: #fffcda;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        overflow: hidden;
        transition: all ease .35s;
        cursor: pointer;
        box-shadow: 1px 1px 11px 0px rgba(0,0,0,0.34);
        -webkit-box-shadow: 1px 1px 11px 0px rgba(0,0,0,0.34);
        -moz-box-shadow: 1px 1px 11px 0px rgba(0,0,0,0.34);
    }

    div#Request_MessageItem div#Request_MessageIconReminder svg {
        width: 13px;
        position: absolute;
        left: 10px;
        transition: all ease .35s;
    }

    div#Request_MessageItem div#Request_MessageIconReminder span {
        position: absolute;
        left: 36px;
        font-size: 11px;
        font-weight: 700;
        color: var(--yellow);
        min-width: 102px;
        opacity: 0;
        transition: all ease .35s;
    }

    div#Request_MessageItem div#Request_MessageIconReminder:hover {
        width: 101px;
        justify-content: flex-start;
        transition: all ease .35s;
    }

    div#Request_MessageItem div#Request_MessageIconReminder:hover span {
        opacity: 1;
        transition: all ease .35s;
    }

    @media (max-width: 994px) {
        div#Request_MessageItem {
        }
    }
`

Request.MessageIconReminder = styled.div`

`

Request.MessageItem = styled.div`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    background-color: ${props => props.type === "solicitation" ? "var(--white)" : "var(--primary)"};
    width: fit-content;
    max-width: 50%;

    @media (max-width: 994px) {
        max-width: 100%;
    }
`

Request.TextMessage = styled.div`
    font-weight: 500;
    padding: 20px;
    color: ${props => props.type === "solicitation" ? "var(--grey)" : "var(--white)"};
    font-size: 13px;
    word-wrap: break-word;
    
    ${props => props.type === 'jucerja' && css`
            a {
                color: var(--lightorange) !important;
                font-weight: 700 !important;
            }
        `
    }

    ${props => props.type === 'solicitation' && css`
            a {
                color: var(--primary) !important;
                font-weight: 700 !important;
            }
        `
    }

    ${props => props.type === 'loading' && css`
            position: relative;
            min-width: 200px;
            overflow: hidden;

            span.react-loading-skeleton {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        `
    }
    
    p span {
        background-color: transparent !important;
        color: var(--white) !important;
    }
`

Request.MessageAttach = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px 20px 5px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`

Request.MessageAttachItem = styled.div`
    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 15px 15px 0;
    background: var(--white);

    @media (max-width: 764px) {
        margin: 0 0 15px 0;
        width: 100%;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-message-attach-icon-download {
        width: 40px;
        height: 50px;
        background: var(--white);
        display: flex;align-items: center;
        justify-content: center;
        min-width: 0;
    }

    button#Request_MessageAttachBox {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        padding: 0;
        text-transform: none !important;
        line-height: 15px;
        text-align: left;
        background: var(--white) !important;
    }

    @media (max-width: 764px) {
        button#Request_MessageAttachBox {
            width: calc(100% - 40px);
            display: flex;
            justify-content: flex-start;
        }
    }
`

Request.MessageAttachBox = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 0;
    text-transform: none !important;
    line-height: 15px;
    text-align: left;

    @media (max-width: 764px) {
        width: calc(100% - 40px);

        span.MuiButton-label {
            justify-content: flex-start;
        }
    }
`

Request.MessageAttachPDFIcon = styled.div`
    width: 40px;
    height: 50px;
    background: var(--bg-red);
    display: flex;
    align-items: center;
    justify-content: center;
`

Request.MessageAttachImageIcon = styled.div`
    width: 40px;
    height: 50px;
    background: var(--bg-yellow);
    display: flex;
    align-items: center;
    justify-content: center;
`

Request.MessageAttachPDFIconFile = styled.img`
`

Request.MessageAttachItemName = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    font-size: 10px;
    font-weight: 500;
    color: var(--lightgrey);
    max-width: 100px;

    @media (max-width: 764px) {
        max-width: calc(100% - 40px);
    }
`

Request.MessageAttachImage = styled.div`
`

Request.MessageAttachImageIconFile = styled.img`
`

Request.MessageAttachImageName = styled.div`
`

Request.SendMessage = styled.div`
    position: absolute;
    bottom: 25px;
    left: 10px;
    width: calc(100% - 20px);
    height: 320px;
`

Request.SendMessageContent = styled.div`
    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
    border-radius: 10px;
    background-color: var(--white);
    margin-bottom: 25px;
    overflow: hidden;
`

Request.SendMessageContainerContent = styled.div`
    .rdw-editor-toolbar {
        border-bottom: 1px solid #cecece;
    }

    .DraftEditor-editorContainer {
        width: calc(100% - 50px);
        border: 0;
        margin: 0 auto;
        height: 90px;
    }

    .DraftEditor-root {
        display: contents;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    main {
        height: 180px !important;
    }

    .rdw-editor-main {
        height: 130px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 18px;
    }

    .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
        padding: 0 24px;
    }
`

Request.SendMessageActions = styled.div`
    border-top: 1px solid #cecece;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        font-size: 21px;
        margin-right: 10px;
        background: var(--lightgreen);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach-image {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        font-size: 21px;
        background: var(--lightorange);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach svg#icon-attach-message path {
        stroke: #004e34;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach-image svg#icon-attach-image-message {
        color: #402400;
    }

    .rdw-option-wrapper[title="Monospace"],
    .rdw-option-wrapper[title="Sobrescrito"],
    .rdw-option-wrapper[title="Subscrito"],
    .rdw-fontsize-wrapper[aria-label="rdw-font-size-control"]
    {
        display: none !important;
    }
`

Request.SendMessageActionsAttachment = styled.div`
    padding: 15px 21px;
`

Request.SendMessageActionsSendMessage = styled.div`
    padding: 10px 21px;

    @media (max-width: 420px) {
        width: calc(100% - 20px);
        margin-bottom: 6px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message.btnDefault.btnDefaultPrimary {
        padding: 0 19px;
        height: 42px;
        border-radius: 36px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message.btnDefault.btnDefaultPrimary svg {
        margin: 0 0 0 9px;
    }
`

Request.SendFastMessage = styled.div`
    background: var(--white);
    position: absolute;
    bottom: 20px;
    width: calc(100% - 20px);
    left: 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);

    @media (max-width: 764px) {
        height: 80px;
    }
`

Request.SendFastMessageContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);

    @media (max-width: 764px) {
        width: calc(100% - 20px);
    }

    ${props => props.type === 'loading' && css`
            position: relative;
            min-width: 200px;
            overflow: hidden;

            span.react-loading-skeleton {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        `
    }
`
Request.SendFastMessageGroupButtons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 8px 0 0;

    @media (max-width: 764px) {
        display: none;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        font-size: 21px;
        margin-right: 10px;
        background: var(--lightgreen);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach-image {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        font-size: 21px;
        background: var(--lightorange);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-editor-message {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        font-size: 21px;
        background: var(--bg-blue);
        margin-left: 10px;
    }
`

Request.SendFastMessageBtnDialogMobileMessage = styled.div`
    min-width: 50px;
    width: 50px;
    max-width: 50px;
    display: flex;
    justify-content: flex-start;

    @media (min-width: 765px) {
        display: none;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message-fast-request-mobile.btnDefault {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        background: var(--lightgreen);
    }
`

Request.SendFastMessageBoxMessage = styled.div`
    width: 100%;
	margin: 0 0px 0 0px;

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        border-radius: 100px;
    }

    @media (max-width: 764px) {
        margin: 0;
    }
`

Request.SendFastMessageBtnSendMessage = styled.div`
    min-width: 50px;
    width: 50px;
    max-width: 50px;
    display: flex;
    justify-content: flex-end;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message-fast-request.btnDefault.btnDefaultPrimary {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
`

Request.SendMessageDialog = styled.div`
    width: 100%;
`

Request.SendMessageContentDialog = styled.div`
    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
    border-radius: 10px;
    background-color: var(--white);
    margin-bottom: 25px;
    overflow: hidden;
`

Request.SendMessageContainerContentDialog = styled.div`
    .rdw-editor-toolbar {
        border-bottom: 1px solid #cecece;
    }

    .DraftEditor-editorContainer {
        width: calc(100% - 50px);
        border: 0;
        margin: 0 auto;
        height: 90px;
    }

    .DraftEditor-root {
        display: contents;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    main {
        height: 180px !important;
    }

    .rdw-editor-main {
        height: 130px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 18px;
    }

    .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
        padding: 0 24px;
    }
`

Request.SendMessageActionsDialog = styled.div`
    border-top: 1px solid #cecece;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 420px) {
        flex-direction: column;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        font-size: 21px;
        margin-right: 10px;
        background: var(--lightgreen);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach-image {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        font-size: 21px;
        background: var(--lightorange);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach svg#icon-attach-message path {
        stroke: #004e34;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach-image svg#icon-attach-image-message {
        color: #402400;
    }

    .rdw-option-wrapper[title="Monospace"],
    .rdw-option-wrapper[title="Sobrescrito"],
    .rdw-option-wrapper[title="Subscrito"],
    .rdw-fontsize-wrapper[aria-label="rdw-font-size-control"]
    {
        display: none !important;
    }
`

Request.SendMessageActionsAttachmentDialog = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 0 16px;

    @media (max-width: 420px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% - 20px);
    }
`

Request.SendMessageActionsSendMessage = styled.div`
    padding: 10px 21px;

    @media (max-width: 420px) {
        width: calc(100% - 20px);
        margin-bottom: 5px;

        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message.btnDefault.btnDefaultPrimary {
            width: 100%;
        }
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message.btnDefault.btnDefaultPrimary {
        padding: 0 19px;
        height: 42px;
        border-radius: 36px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message.btnDefault.btnDefaultPrimary svg {
        margin: 0 0 0 9px;
    }
`

Request.Attachment = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ececec;
`

Request.AttachmentArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 900px;
    width: 100%;
    position: absolute;
    top: 66px;
    height: calc(100% - 318px);
`

Request.AttachmentPreviewActive = styled.div`
    width: 100%;
    max-height: 550px;
    margin: 0 0 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    /* img {
        width: auto !important;
        max-height: 550px;
    } */

    canvas {
        transform: scale(0.55);
    }
`

Request.ImgAttachmentPreviewActive = styled.img`
    max-height: 550px;
    max-width: 900px;

    @media (max-width: 900px) {
        width: calc(100% - 40px);
        height: auto;
    }
`

Request.AttachmentListAddAndMessageContainer = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    background-color: var(--white);
`

Request.AttachmentListAddAndMessage = styled.div`
    max-width: 900px;
    width: 100%;

    @media (max-width: 900px) {
        width: calc(100% - 40px);
    }
`

Request.AttachmentListAddAndMessageBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiFormControl-root.MuiTextField-root {
        width: calc(100% - 80px);
    }
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-message-fast-request.btnDefault.btnDefaultPrimary {
        padding: 0;
        min-width: 0;
        width: 56px;
        height: 56px;
        border-radius: 100%;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        border-radius: 100px;
    }
`

Request.AttachmentListAddItems = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 120px;
    margin: 28px 0 0 0;

    div#btn-add-attachment {
        min-width: 100px;
    }
`

Request.AttachmentListAdd = styled.div`
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    overflow-x: auto;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-item-attachment-adding {
        min-width: 100px;
        min-height: 100px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-item-attachment-adding-type-pdf {
        min-width: 100px;
        min-height: 100px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-item-attachment-adding {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100px;
        height: 100px;
        position: relative;
        -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.23);
        -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.23);
        box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.23);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-remove-item-attachment {
        background: var(--white);
        min-width: 0;
        padding: 0;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        font-size: 30px;
        color: var(--red);
        position: absolute;
        top: 5px;
        right: 5px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-add-more-attachments {
        min-width: 0;
        padding: 0;
        width: 100px;
        height: 100px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--quarterly);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-add-more-attachments span.MuiButton-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-add-more-attachments span.text-add-more-attachments {
        font-size: 13px;
        line-height: 13px;
        font-weight: 700;
        font-family: var(--font-regular) !important;
        color: var(--primary);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-add-more-attachments span.MuiButton-label svg {
        font-size: 25px;
        margin: 0 0 5px 0;
        color: var(--primary);
    }

    div#btn-add-attachment span.MuiButtonBase-root.MuiIconButton-root.btn-add-more-attachments.MuiIconButton-colorPrimary {
        margin: 0 !important;
        padding: 0 !important;
        min-width: 0;
        width: 100px;
        height: 100px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--quarterly);
        border-radius: 4px;

        .MuiIconButton-label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    div#btn-add-attachment span.MuiButtonBase-root.MuiIconButton-root.btn-add-more-attachments.MuiIconButton-colorPrimary span.MuiIconButton-label span.text-add-more-attachments {
        font-size: 13px;
        line-height: 13px;
        font-weight: 700;
        font-family: var(--font-regular) !important;
        color: var(--primary);
        text-transform: uppercase;
    }

    div#btn-add-attachment span.MuiButtonBase-root.MuiIconButton-root.btn-add-more-attachments.MuiIconButton-colorPrimary span.MuiIconButton-label svg {
        font-size: 25px;
        margin: 0 0 5px 0;
        color: var(--primary);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-item-attachment-adding-type-pdf {
        width: 100px;
        height: 100px;
        background: var(--dark-red);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-item-attachment-adding-type-pdf img {
        width: 100%;
        height: auto;
    }
`

Request.AttendantInformation = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    margin: 5px 0 4px 0;
    color: var(--alternative3);
`

Request.AttendantBadge = styled.div`
    background-color: var(--green);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 4px 0 0;
`

Request.AttendantName = styled.div`
    margin: 0 5px 0 10px;
    font-weight: 600;
`

Request.AttendantNameDate = styled.div`
    font-weight: 400;
`

Request.LoadingSendMessageBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`

Request.LoadingSendMessageBoxCircle = styled.div`
    height: 42px;
    width: 42px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 0 0 10px;

    span.react-loading-skeleton {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

Request.LoadingSendMessageBoxField = styled.div`
    height: 42px;
    position: relative;
    width: calc(100% - 156px);
    margin: 0 10px;
    overflow: hidden;
    border-radius: 100px;
`

Request.PrivateAndReminder = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    justify-content: center;
    margin: 0 0px 0 -11px;

    .btn-actions-privatereminder {
        width: 42px;
        height: 42px;
        padding: 0;
        margin: 0 0 0 5px;
        border-radius: 100%;
        font-size: 21px;
        min-width: 0;
    }

    &> button:nth-child(1n) {
        margin-bottom: 0;
    }

    &> button:nth-child(2n) {
        margin-top: 0 !important;
    }

    .btn-actions-privatereminder.btn-send-message-private-enable {
        background-color: var(--red);
        color: var(--white);
    }

    .btn-actions-privatereminder.btn-send-message-private-disable {
        color: var(--red);
        border-color: var(--red);
        border: 1px solid;
    }

    .btn-actions-privatereminder.btn-send-reminder-private-enable {
        color: var(--yellow);
        border-color: var(--yellow);
        border: 1px solid;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-actions-privatereminder.btn-send-reminder-private-enable.Mui-disabled.Mui-disabled {
        color: #b1b1b1;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-actions-privatereminder.btn-send-reminder-private-disable {
        background: var(--yellow);
        color: var(--white);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-actions-privatereminder.btn-send-message-private-disable.Mui-disabled.Mui-disabled {
        color: #b1b1b1;
    }
`