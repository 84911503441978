import styled from 'styled-components'

export const NavBarComponent = styled.div`
    position: fixed;
    top: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 15;

    @media (max-width: 993px) {
        display: none;
    }
`

NavBarComponent.ItemLeft = styled.div`
    background: var(--background-body);
    width: 50%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

NavBarComponent.ItemRight = styled.div`
    background: var(--background-body);
    width: 50%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 25px 0 0;

    div#NotificationCount {
        width: 10px !important;
        height: 10px !important;
        right: 13px !important;
        top: 2px !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnNavBar {
        background: var(--white);
        position: relative;
        padding: 0;
        min-width: 0;
        width: 48px;
        height: 48px;
        margin: 0 0 0 15px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        color: var(--black);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnNavBar span.MuiButton-label {
        position: relative;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnNavBar span.MuiButton-label > div {
        background: red;
        position: absolute;
        font-size: 8px;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        top: -9px;
        right: 7px;
        transform: scale(0.85);
    }
`

NavBarComponent.Logo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 380px;
    /* margin: 0 0 40px 25px; */
`

NavBarComponent.LogoImage = styled.img`
    width: 195px;
    height: auto;
    /* margin: 30px 0 0px 0; */
`

NavBarComponent.LogoTitle = styled.p`
    font-size: 35px;
    color: var(--primary);
    margin: 0 0 0 12px;
    font-weight: 600;
`

NavBarComponent.NotificationCount = styled.div`

`

export const NavBarComponentMobile = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    background: var(--background-body);

    @media (min-width: 994px) {
        display: none;
    }
`

NavBarComponentMobile.Left = styled.div`
    width: 100px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnMenuMobile {
        width: 48px;
        height: 48px;
        padding: 0;
        border-radius: 100%;
        min-width: 0;
        font-size: 13px;
        margin: 0 0 0 25px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnMenuMobile img {width: 30px;}

`

NavBarComponentMobile.Right = styled.div`
    width: calc(100% - 100px);
    display: flex;
    flex-direction: row;
    height: 60px;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px 0 0;
    
    button.btnNavBar {
        background: var(--white);
        position: relative;
        padding: 0;
        min-width: 0;
        width: 48px;
        height: 48px;
        margin: 0 0 0 15px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        color: var(--black);
    }

    button.btnNavBar span.MuiButton-label {
        position: relative;
    }

    button.btnNavBar span.MuiButton-label > div {
        background: red;
        position: absolute;
        font-size: 8px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        top: 2px;
        right: 13px;
        transform: scale(0.85);
    }
}
`

NavBarComponentMobile.IconMenuImage = styled.img`

`

NavBarComponentMobile.LogoImage = styled.img`
    width: 48px;
    margin: 0 25px 0 15px;
`