import { useState, useEffect, forwardRef, useRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Select from "@material-ui/core/Select"
import { Card } from "../../../components/elements-ui/Card"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { DialogDefaultText } from './styles'
import api from '../../../services/api'
import TextField from "@material-ui/core/TextField"
import { Autocomplete, Chip, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid'
import DialogConfirmation from '../../../components/elements-ui/DialogConfirmation'
import { setRef, Grid } from '@material-ui/core'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import { Request } from '../DialogSendMessageEditor/styles'
import draftToHtml from "draftjs-to-html"
import htmlToDraft from 'html-to-draftjs'
import _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Badge } from "../../../components/elements-ui/Badge"
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const defaultTextTemplate = { id: undefined, assuntoId: undefined, titulo: '', descricao: '' };

export default function DialogDefaultTextConfirm({
    openDialogDefaultText,
    setOpenDialogDefaultText,
    idAssunto }) {
    const [errorTitulo, setErrorTitulo] = useState(false);
    const [errorEditor, setErrorEditor] = useState(false);
    const [errorEditorDescricao, setErrorEditorDescricao] = useState('');
    const [listaTextoPadrao, setListaTextoPadrao] = useState([]);

    const [defaultText, setDefaultText] = useState(defaultTextTemplate);
    const [loading, setLoading] = useState(false);

    const [selectedRowDefaultText, setSelectedRowDefaultText] = useState(undefined);
    const [editModeDefaultTextContent, setEditModeDefaultTextContent] = useState(false);
    const [showRemoveDefaultText, setShowRemoveDefaultText] = useState(false);
    const [confirmRemoveDefaultTextMessage, setConfirmRemoveDefaultTextMessage] = useState(false);

    const [showGrid, setShowGrid] = useState(true);
    const [efeitoMostrar, setEfeitoMostrar] = useState(true);

    const [cenarioForm, setCenarioForm] = useState(false);

    const [description, setDescription] = useState({
        htmlValue: "",
        editorState: EditorState.createEmpty()
    })

    const errorEditorDebounce = _.debounce(() => {
        enqueueSnackbar("O máximo de caracteres é 4000!", { variant: 'error' });
    }, 100);

    const { enqueueSnackbar } = useSnackbar()

    const handleClose = (e) => {
        e.preventDefault();

        if (loading) return;

        setOpenDialogDefaultText(false);
    }

    const handleConfirm = async (e) => {
        e?.preventDefault();

        if (!defaultText.titulo || defaultText.titulo.length <= 0) {
            setErrorTitulo(true);
            return
        }

        if (!defaultText.descricao || !description.editorState.getCurrentContent().hasText()) {
            setErrorEditorDescricao('Descrição do texto padrão deve ser informado!');
            setErrorEditor(true);
            return
        }

        setLoading(true);

        await cadastreTexto();
        await atualizeTexto();
    }

    const cadastreTexto = async () => {
        if (editModeDefaultTextContent) return;

        await api.post(`/jucerja/v1/textoPadrao`, { ...defaultText, id: 0, assuntoId: idAssunto })
            .then(response => {
                enqueueSnackbar(`Texto Padrão cadastrado com sucesso!`, { variant: 'success' })
                setCenarioForm(false);
                carregueTextosPadrao();
                limparTela();
            })
            .catch(() => { enqueueSnackbar("Houve um erro ao cadastrar o texto padrão.", { variant: 'error' }); })
            .finally(() => setLoading(false));
    }

    const atualizeTexto = async () => {
        if (!editModeDefaultTextContent) return;

        await api.put(`/jucerja/v1/textoPadrao`, { ...defaultText, assuntoId: idAssunto })
            .then(response => {
                enqueueSnackbar(`Texto Padrão atualizado com sucesso!`, { variant: 'success' })
                setCenarioForm(false);
                carregueTextosPadrao();
                limparTela();
            })
            .catch(() => { enqueueSnackbar("Houve um erro ao cadastrar o texto padrão.", { variant: 'error' }); })
            .finally(() => setLoading(false));
    }

    const carregueTextosPadrao = () => {
        setLoading(true);

        api.get(`/jucerja/v1/textoPadrao/assunto/${idAssunto}`)
            .then(response => {
                setListaTextoPadrao(response.data);

            })
            .finally(() => setLoading(false));
    }

    const onEditorStateChange = editorValue => {
        const editorStateInHtml = draftToHtml(
            convertToRaw(editorValue.getCurrentContent())
        )
        setDefaultText(values => ({ ...values, descricao: editorStateInHtml }));
        setDescription({
            htmlValue: editorStateInHtml,
            editorState: editorValue
        })
        const textLength = editorStateInHtml.length;

        if (textLength >= 4000) {
            setErrorEditor(true);
            setErrorEditorDescricao(`Quantidade Máxima: ${textLength}`);
        } else {
            setErrorEditor(false);
            setErrorEditorDescricao(`Quantidade Caracteres: ${textLength}`);
        }
    }

    const onRowSelect = (row) => {
        setDefaultText(
            {
                id: row.id,
                assuntoId: row.assunto.id,
                descricao: row.descricao,
                titulo: row.titulo
            }
        )

        const contentBlock = htmlToDraft(row.descricao)
        const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
        )
        const defaultEditorState = EditorState.createWithContent(contentState)

        setDescription({
            htmlValue: row.descricao,
            editorState: defaultEditorState
        })
    }

    const limparTela = () => {
        setSelectedRowDefaultText(undefined);
        setDefaultText(defaultTextTemplate);
        setDescription({
            htmlValue: "",
            editorState: EditorState.createEmpty()
        });
    }

    useEffect(() => {
        setCenarioForm(false);
        limparTela();
    }, [openDialogDefaultText])

    useEffect(() => {

        carregueTextosPadrao();

    }, [idAssunto])

    useEffect(() => {

        setShowGrid(true);
        setTimeout(() => {

            setEfeitoMostrar(true);
        }, 600);

        if (cenarioForm) {
            setEfeitoMostrar(false);
            setTimeout(() => {

                setShowGrid(false);
            }, 600);
        }

    }, [cenarioForm])

    useEffect(() => {

        if (!confirmRemoveDefaultTextMessage)
            return;

        setConfirmRemoveDefaultTextMessage(false);

        api.delete(`/jucerja/v1/textoPadrao?idTextoPadrao=${defaultText.id}`)
            .then(response => {
                limparTela();
                carregueTextosPadrao();
                enqueueSnackbar(`Texto Padrão excluído com sucesso!`, { variant: 'success' })

            })
            .catch(() => { enqueueSnackbar("Houve um erro ao excluir o registro selecionado.", { variant: 'error' }); })
            .finally(() => setShowRemoveDefaultText(false));


    }, [confirmRemoveDefaultTextMessage])

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogDefaultText}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-setor"
            fullWidth
            maxWidth="md"

        // PaperComponent={props => instanciaDragg.componentDraggable("alert-dialog-slide-title", props)}
        >
            <DialogConfirmation
                openDialog={showRemoveDefaultText}
                setOpenDialog={setShowRemoveDefaultText}
                setConfirm={setConfirmRemoveDefaultTextMessage}
                txtConfirm="Sim"
                txtCancel="Não"
                txtTitle="Confirmar exclusão do texto padrão"
                txtContainer={`Deseja realmente excluir o texto padrão '${selectedRowDefaultText?.titulo}' do assunto '${selectedRowDefaultText?.assunto.descricao}' ?`}
                closeControlOutsideOnConfirm={true}
            />

            <DialogDefaultText>

                {
                    showGrid ?

                        <DialogContent className={efeitoMostrar ? "mostrarAnimacao" : "esconderAnimacao"}>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Card>

                                    <DataGrid
                                        onRowClick={e => { setSelectedRowDefaultText(e.row); onRowSelect(e.row); }}
                                        onSelectionModelChange={e => { if (e.length <= 0) { setSelectedRowDefaultText(undefined) } }}

                                        getRowId={(r) => r.id}
                                        style={{ height: '500px', width: '100%' }}
                                        columns={
                                            [
                                                { "field": "id", "headerName": 'Código', "width": 150, "align": "center", "editable": false },
                                                { "field": "titulo", "headerName": 'Titulo', "width": 200, "align": "center", "editable": false },
                                                { "field": "descricao", "headerName": 'Texto', "width": 400, "align": "center", "editable": false },
                                                {
                                                    "field": "excluido", "headerName": "Ativo", "width": 130, "align": "center", "editable": false,
                                                    renderCell: (params) => {
                                                        return params.value ? <span style={{ color: 'red' }}>{'Não'}</span> : <span style={{ color: 'green' }}>{'Sim'}</span>
                                                    }
                                                },
                                            ]}

                                        rows={listaTextoPadrao}
                                        density={'small'}
                                        loading={loading}
                                        localeText={ptBR.props.MuiDataGrid.localeText}
                                    />
                                    <DialogDefaultText.GridActions>

                                        <div className={selectedRowDefaultText && !selectedRowDefaultText.excluido ? "mostrarAnimacao" : "esconderAnimacao"}>
                                            <Button disabled={!selectedRowDefaultText || selectedRowDefaultText.excluido} className="btn-close-actions" onClick={e => setShowRemoveDefaultText(true)}>{"remover"}</Button>
                                        </div>
                                        <div className={selectedRowDefaultText && !selectedRowDefaultText.excluido ? "mostrarAnimacao" : "esconderAnimacao"}>
                                            <Button disabled={!selectedRowDefaultText || selectedRowDefaultText.excluido} className="btn-primary-actions" onClick={e => { setEditModeDefaultTextContent(true); setCenarioForm(true); }}>{"Alterar"}</Button>
                                        </div>
                                        <Button className="btn-close-actions" onClick={e => setOpenDialogDefaultText(false)}>{"cancelar"}</Button>
                                        <Button className="btn-primary-actions" onClick={e => { limparTela(); setEditModeDefaultTextContent(false); setCenarioForm(true); }}>{"adicionar"}</Button>
                                    </DialogDefaultText.GridActions>
                                </Card>
                            </Grid>

                        </DialogContent>
                        :
                        <></>
                }
            </DialogDefaultText>
            {
                !showGrid ?
                    <div className={efeitoMostrar ? "mostrarAnimacao" : "esconderAnimacao"}>
                        <DialogTitle style={{ 'cursor': 'move' }} id="alert-dialog-slide-title">{editModeDefaultTextContent ? "Atualizar texto padrão" : "Cadastrar texto padrão"}</DialogTitle>
                        <DialogContent>
                            <DialogDefaultText.Container id="DialogDefaultText_Container">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl></FormControl>
                                        <TextField
                                            id="outlined-basic-titulo"
                                            inputProps={{ maxLength: 40 }}
                                            label="Título"
                                            variant="outlined"
                                            helperText={errorTitulo ? "O Título deve ser informado!" : ""}
                                            name="titulo"
                                            value={defaultText.titulo}
                                            onChange={e => { setDefaultText({ ...defaultText, titulo: e.target.value }); setErrorTitulo(false); }}
                                            error={errorTitulo}
                                            disabled={loading}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Request.SendMessageDialog id="Request_SendMessageDialog">
                                            <Request.SendMessageContentDialog id="Request_SendMessageContentDialog">
                                                <DialogDefaultText.Editor id="Request_SendMessageContainerContentDialog">
                                                    <Editor
                                                        handleBeforeInput={val => {
                                                            const textLength = draftToHtml(convertToRaw(description.editorState.getCurrentContent())).length;

                                                            if (val && textLength >= 4000) {
                                                                return 'handled';
                                                            }
                                                            return 'not-handled';
                                                        }}
                                                        handlePastedText={val => {
                                                            const textLength = draftToHtml(convertToRaw(description.editorState.getCurrentContent())).length;
                                                            const valido = ((val.length + textLength) >= 4000);

                                                            if (valido) {

                                                                errorEditorDebounce();
                                                            }

                                                            return valido;
                                                        }}
                                                        spellCheck
                                                        toolbarHidden={false}
                                                        editorState={description.editorState}
                                                        onEditorStateChange={onEditorStateChange}
                                                        localization={{
                                                            locale: 'pt',
                                                        }}
                                                        toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded'/*, 'emoji'*/, 'image', 'remove', 'history'] }}
                                                        readOnly={loading}
                                                    />

                                                </DialogDefaultText.Editor>
                                            </Request.SendMessageContentDialog>
                                        </Request.SendMessageDialog>

                                        <FormHelperText error={errorEditor}>{errorEditorDescricao}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </DialogDefaultText.Container>
                        </DialogContent>
                        <DialogActions>
                            <DialogDefaultText.Actions id="DialogSetor.Actions">
                                <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
                                    <Button onClick={e => { setCenarioForm(false); limparTela(); }} className="btn-close-actions">Voltar</Button>
                                </div>
                                {
                                    loading
                                        ?
                                        <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                                        :
                                        <Button className="btn-primary-actions" onClick={handleConfirm}>{editModeDefaultTextContent ? "Atualizar" : "Cadastrar"}</Button>
                                }

                            </DialogDefaultText.Actions>
                        </DialogActions>
                    </div>
                    :
                    <></>
            }

        </Dialog >
        </Draggable>
    )
}