import Container from '../../components/layout/Container'
import Grid from '@material-ui/core/Grid'
import { useEffect, useRef, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../../components/elements-ui/Card"
import { Badge } from "../../components/elements-ui/Badge"
import { ActionTable } from "../../components/elements-ui/ActionTable"
import { Button, Checkbox } from '@material-ui/core'
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from 'react-icons/all'
import { HiOutlineArrowsExpand } from 'react-icons/all'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../services/api'
import FilterRequests from '../../components/elements-ui/FilterRequests'
import { login, logout, refreshToken, getPerfisUsuario, removeLocalStorageData } from '../../services/auth'

export default function LocalHost() {
    return (
        <>
            <Redirect to={
                getPerfisUsuario().length > 0 ?
                    `/dashboard/${getPerfisUsuario()[0]}` :
                    `/requisicoes/1`
            } />
        </>
    )
}