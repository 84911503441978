import { useState, useEffect, forwardRef } from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { ItemSelected } from '../ItemSelected'
import { BsFileText, MdSubject, AiOutlineUser, FaTasks, AiOutlineMessage, BsPerson, SiOpslevel, FiGitPullRequest } from 'react-icons/all'
import api from '../../../services/api'
import randomColor from "randomcolor"
import Skeleton from 'react-loading-skeleton'
import { v4 as uuid } from 'uuid'
import { capitalize, limitChar, convertISODateToDateView } from '../../../functions'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogTaskAssociatedRequests({
    idTarefa,
    openDialogTaskAssociatedRequests,
    setOpenDialogTaskAssociatedRequests
}) {

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(true);
    const [requisicoes, setRequisicoes] = useState([])

    const optionsColors = {
        luminosity: 'light'
    }

    const handleClose = () => {
        setOpenDialogTaskAssociatedRequests(false)
    }


    const getRequisicoes = () => {
        setLoading(true);
        api.get(`/jucerja/v1/Tarefa/${idTarefa}/requisicaoassociadatarefa`)
            .then(response => {

                setRequisicoes(response.data.requisicoes ?? [])
            })
            .catch(error => {
                setError(true)
                setErrorMessage(error.toString())
            })
            .finally(() => setLoading(false));
    }

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
    <Dialog
        hideBackdrop
        disableBackdropClick
            open={openDialogTaskAssociatedRequests}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            onEnter={getRequisicoes}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-detail-request"
        >
            <DialogTitle style={{ 'cursor': 'move' }} id="alert-dialog-slide-title">Solicitações Associadas</DialogTitle>
            <DialogContent>
                <ItemSelected style={{ margin: '0' }}>
                    {
                        loading ?
                            [...Array(3)].map(() => <Button className="btn-acesso-requisicao" key={uuid()}>
                                <ItemSelected.ListItemContent id="Request_ListItemContent">
                                    <ItemSelected.ListItemInformations id="Requests_ListItemInformations">
                                        <ItemSelected.ListItemInfo id="Request_ListItemInfo">
                                            <ItemSelected.ListItemTitle id="Request_ListItemTitle"><Skeleton width={500} height={20} /></ItemSelected.ListItemTitle>
                                            <ItemSelected.Subject id="Requests_Subject" ><Skeleton width={600} height={18} /></ItemSelected.Subject>
                                        </ItemSelected.ListItemInfo>
                                        <ItemSelected.ListItemReq id="Request_ListItemReq">
                                            <ItemSelected.ListItemNumberRequest id="Request_ListItemNumberRequest"><Skeleton width={90} height={18} /></ItemSelected.ListItemNumberRequest>
                                            <ItemSelected.ListItemData id="Request_ListItemData"><Skeleton width={130} height={18} /></ItemSelected.ListItemData>
                                        </ItemSelected.ListItemReq>
                                    </ItemSelected.ListItemInformations>
                                    <ItemSelected.ListItemSubInfo id="Request_ListItemSubInfo"><Skeleton width={500} height={18} />
                                    </ItemSelected.ListItemSubInfo>
                                </ItemSelected.ListItemContent>
                            </Button>)
                            :

                            requisicoes.map(requisicao => (
                                <Link key={uuid()} to={`/requisicao/${requisicao.numeroRequisicao}`} className="no-select requests-listitem">
                                    <Button className="btn-acesso-requisicao" key={uuid()}>
                                        <ItemSelected.ListItemContent id="Request_ListItemContent">
                                            <ItemSelected.ListItemInformations id="Requests_ListItemInformations">
                                                <ItemSelected.ListItemInfo id="Request_ListItemInfo">
                                                    <ItemSelected.ListItemTitle id="Request_ListItemTitle">{requisicao.assunto}</ItemSelected.ListItemTitle>
                                                    <ItemSelected.Subject id="Requests_Subject" ><MdSubject /> {limitChar(100, requisicao.previaMensagem)}</ItemSelected.Subject>
                                                </ItemSelected.ListItemInfo>
                                                <ItemSelected.ListItemReq id="Request_ListItemReq">
                                                    <ItemSelected.ListItemNumberRequest id="Request_ListItemNumberRequest">{requisicao.numeroRequisicao}</ItemSelected.ListItemNumberRequest>
                                                    <ItemSelected.ListItemData id="Request_ListItemData">{convertISODateToDateView(requisicao.dataAbertura)}</ItemSelected.ListItemData>
                                                </ItemSelected.ListItemReq>
                                            </ItemSelected.ListItemInformations>
                                            <ItemSelected.ListItemSubInfo id="Request_ListItemSubInfo">
                                                <ItemSelected.ListItemStatusCustom style={{ background: randomColor(optionsColors) }}>{requisicao.status}</ItemSelected.ListItemStatusCustom>
                                                <ItemSelected.ListItemTask id="Request_ListItemAttach"><BsPerson /> {requisicao.nomeAtendente}</ItemSelected.ListItemTask>

                                                <ItemSelected.ListItemTask id="Request_ListItemTask"><BsFileText /> {requisicao.quantidadeAnexos} {requisicao.quantidadeAnexos <= 1 ? 'Arquivo' : 'Arquivos'}</ItemSelected.ListItemTask>
                                                <ItemSelected.ListItemMessage id="Request_ListItemMessage"><FiGitPullRequest /> {requisicao.quantidadeTarefas} {requisicao.quantidadeTarefas <= 1 ? 'Tarefa' : 'Tarefas'}</ItemSelected.ListItemMessage>
                                            </ItemSelected.ListItemSubInfo>
                                        </ItemSelected.ListItemContent>
                                    </Button>
                                </Link>
                            ))}
                </ItemSelected>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} className="btn-close-actions">Cancelar</Button>
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}