import Button from '@material-ui/core/Button'

import { IoSettingsOutline } from 'react-icons/io5'
import ImageLogo from '../../../assets/img/logo-dashboard.svg'

import { NavBarComponent, NavBarComponentMobile } from './styles'
import Drawer from '../Menu/drawer'
import Notification from '../../elements-ui/Notification'
import UserIcon from '../../elements-ui/UserIcon'

export default function Navbar() {
    return (
        <>
            <NavBarComponent>
                <NavBarComponent.ItemLeft>
                    <NavBarComponent.Logo>
                        <NavBarComponent.LogoImage className="no-select" src={ImageLogo} />
                    </NavBarComponent.Logo>
                </NavBarComponent.ItemLeft>
                <NavBarComponent.ItemRight id="NavBarComponent_ItemRight">
                    {/* <Notification/> */}
                    <UserIcon />
                </NavBarComponent.ItemRight>
            </NavBarComponent>
            <NavBarComponentMobile id="NavBarComponentMobile">
                <NavBarComponentMobile.Left id="Left">
                    <Drawer />
                </NavBarComponentMobile.Left>
                <NavBarComponentMobile.Right id="Right">
                    {/* <Notification/> */}
                    <UserIcon />
                </NavBarComponentMobile.Right>
            </NavBarComponentMobile>
        </>
    )
}