import { useState, useEffect, forwardRef, useRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from "@material-ui/core/InputLabel"

import FormControl from "@material-ui/core/FormControl"
import DateFnsUtils from "@date-io/date-fns"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers"
import ptBRLocale from "date-fns/locale/pt-BR"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { DialogFilterRequest } from './styles'
import api from '../../../services/api'
import TextField from "@material-ui/core/TextField"
import { Autocomplete, Chip, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid'
import { setRef, Grid } from '@material-ui/core'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogFilterConfirm({
    openDialogFilterConfirm,
    setOpenDialogFilterConfirm,
    setDialogFilterConfirm,
    setFiltroDescricao,
    filtroDescricao
}) {
    const [erroDescricao, setErroDescricao] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClose = (e) => {
        e.preventDefault()

        if (loading) return;

        setOpenDialogFilterConfirm(false);
        setDialogFilterConfirm(undefined);
    }

    const handleConfirm = (e) => {
        let retornar = false;

        e?.preventDefault();

        if (!filtroDescricao || !isNaN(filtroDescricao)) {
            setErroDescricao("A descrição é obrigatório.");
            retornar = true;
        }

        if (retornar)
            return;

        setLoading(true);
        setDialogFilterConfirm(true);
    }

    useEffect(() => {

        if (!openDialogFilterConfirm) {
            setLoading(false);
            setFiltroDescricao('');
        }

    }, [openDialogFilterConfirm])

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogFilterConfirm}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-secao"

        >
            <DialogTitle id="alert-dialog-slide-title">Castrar Filtro</DialogTitle>
            <DialogContent>
                <DialogFilterRequest id="DialogSecao">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl variant="outlined">
                                <TextField
                                    id="outlined-number"
                                    label="Descrição"
                                    variant="outlined"
                                    error={erroDescricao}
                                    helperText={erroDescricao}
                                    required
                                    name="descricao"
                                    value={filtroDescricao}
                                    onChange={e => { setFiltroDescricao(e.target.value); }}

                                    inputProps={{ maxLength: 20 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogFilterRequest>
            </DialogContent>
            <DialogActions>
                <DialogFilterRequest.Actions id="DialogAusencia.Actions">
                    <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
                        <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                    </div>
                    {
                        loading
                            ?
                            <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                            :
                            <Button className="btn-primary-actions" onClick={handleConfirm}>Cadastrar</Button>
                    }

                </DialogFilterRequest.Actions>
            </DialogActions>
        </Dialog >
        </Draggable>
    )
}