import styled from 'styled-components'

export const Title = styled.h1`
    margin: 0 0 15px 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (max-width: 600px) {
        font-size: 1.75rem;
    }
`