import styled from 'styled-components'

export const UserIcon = styled.div`
    border-top: 4px solid var(--primary);
`

UserIcon.Account = styled.div`
    min-width: 278px;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnLogout {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnLogout span.MuiButton-label {
        padding: 6px 20px 3px 20px;
        text-transform: capitalize;
        font-size: 15px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnLogout span.MuiButton-label svg {
        margin: 0 10px 0 0;
        font-size: 19px;
    }
`

UserIcon.AccountContent = styled.div`
    background-color: #f5f6fa;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px 25px;
`

UserIcon.AccountIcon = styled.div`
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #798bff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.06em;
    flex-shrink: 0;
    position: relative;
    margin: 0 12px 0 0;
    background: var(--primary) !important;
`

UserIcon.AccountInfo = styled.div`
    width: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

UserIcon.AccountName = styled.div`
    font-size: 0.875rem;
    font-weight: 700;
    color: #364a63;
`

UserIcon.AccountEmail = styled.div`
    font-size: 12px;
    color: #8094ae;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 180px;
`
