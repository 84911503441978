import { useState, forwardRef, useEffect, useRef } from 'react'
import Container from '../../../components/layout/Container'

import { Badge } from "../../../components/elements-ui/Badge"
import { useSnackbar } from 'notistack'
import FormControl from "@material-ui/core/FormControl"
import Tooltip from '@mui/material/Tooltip';

import { ComponenteRedistribuicao } from './styles'
import { Button, Grid, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, withStyles, TextField } from '@material-ui/core'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import FormControlLabel from '@mui/material/FormControlLabel';
import { Card } from "../../../components/elements-ui/Card"
import DialogDefaultTextConfirm from '../../../components/elements-ui/DialogDefaultTextConfirm'
import DialogConfirmation from '../../../components/elements-ui/DialogConfirmation'
import DialogAssuntoSetorConfirm from '../../../components/elements-ui/DialogAssuntoSetorConfirm'
import _ from 'lodash'
import api from '../../../services/api'
import { convertISODateToDateView } from '../../../functions/index'

import { Link, useHistory } from 'react-router-dom'

import { BiArrowBack, BsBoxArrowInDown } from 'react-icons/all'
import { Autocomplete } from '@mui/material'

export default function Redistribuicao(props) {
    const history = useHistory();
    const [requisicoes, setRequisicoes] = useState(props.match.params.id.split(',').map(x => ({ id: x, descricao: x.substring(0, 4).concat('-', x.substring(4)), error: undefined, sucesso: undefined })));
    const [errorAtentende, setErrorAtendente] = useState(false);
    const [informativoRequisicoes, setInformativoRequisicoes] = useState('');
    const [listaDeAtendentes, setListaDeAtendentes] = useState([]);
    const [modelRedistribuicao, setModelRedistribuicao] = useState({ Requisicoes: requisicoes.map(x => x.id), UsuarioId: null });
    const [description, setDescription] = useState({
        htmlValue: "",
        editorState: EditorState.createEmpty()
    })

    const { enqueueSnackbar } = useSnackbar();

    const [showConfirmRedistribuicao, setShowConfirmRedistribuicao] = useState(false);
    const [confirmRedistribuicao, setConfirmRedistribuicao] = useState(false);

    const [showConfirmRetorno, setShowConfirmRetorno] = useState(false);
    const [confirmRetorno, setConfirmRetorno] = useState(false);

    const [buttonDownloadErrors, setButtonDownloadErrors] = useState(false);

    const onEditorStateChange = editorValue => {
        const editorStateInHtml = draftToHtml(
            convertToRaw(editorValue.getCurrentContent())
        )
        // setInputs(values => ({ ...values, "Mensagem": editorStateInHtml }));
        setDescription({
            htmlValue: editorStateInHtml,
            editorState: editorValue
        })
    }

    const carregueListaDeAtendentes = async () => {
        setListaDeAtendentes([]);

        api.post(`/jucerja/v1/funcionario/atendentes-redistribuicao`, {})
            .then(response => {

                let listaAtendentes = (response.data ?? []).map(x => ({ referenciaUsuarioId: x.id, nome: x.nome }));

                setListaDeAtendentes(
                    _(listaAtendentes)
                        .groupBy('referenciaUsuarioId')
                        .map(x => x[0])
                        .groupBy('nome')
                        .map(x => x[0])
                        .orderBy(['nome'], 'asc')
                        .value()
                );
            })
            .catch(error => {

            })
    }

    const peloMenosUmaRequisicaoNaoProcessada = (lista) => {
        if (requisicoesProcessamento(lista.map(x => x.id)).length <= 0) {
            setInformativoRequisicoes("Pelo menos uma solicitação que não foi processada deve ser selecionada.");
            return true;
        }

        return false;
    }
    const onChangeRequisicoes = (v) => {

        setInformativoRequisicoes('');

        if (v && v.length <= 0) {
            setInformativoRequisicoes("Pelo menos uma solicitação deve ser selecionada.");
            return;
        }

        if (peloMenosUmaRequisicaoNaoProcessada(v)) {
            return;
        }

        let listaRequisicoes = v.filter(x => !x.sucesso).map(x => x.id).concat(requisicoes.filter(x => x.sucesso).map(x => x.id));

        setModelRedistribuicao(r => ({ ...r, Requisicoes: listaRequisicoes }));
    }

    const redistribuir = () => {

        if (!modelRedistribuicao.UsuarioId || modelRedistribuicao.UsuarioId <= 0) {
            setErrorAtendente(true);
            return;
        }

        if (peloMenosUmaRequisicaoNaoProcessada(modelRedistribuicao.Requisicoes.map(x => ({ id: x })))) {
            return;
        }

        setShowConfirmRedistribuicao(true);
    }

    const requisicoesProcessadasComSucesso = (requisicoesInterna) => {
        var listaRequisicoesSucesso = requisicoesInterna.filter(x => !x.error);

        if (!listaRequisicoesSucesso || listaRequisicoesSucesso.length <= 0) {
            return;
        }

        listaRequisicoesSucesso.forEach(element => element.sucesso = true);

    }

    const requisicoesProcessamento = (listaSelecionados) => {
        let listaRequisicoesSucesso = requisicoes.filter(x => x.sucesso).map(x => x.id);

        return listaSelecionados.filter(x => listaRequisicoesSucesso.indexOf(x) < 0);
    }

    const downloadTxtErros = () => {
        const element = document.createElement("a");
        const errosJoin = requisicoes.filter(x => x.error).map(x => x.error).join('\n');
        const file = new Blob([errosJoin], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "Erros.txt";
        document.body.appendChild(element);
        element.click();
    }

    useEffect(() => {
        carregueListaDeAtendentes();
    }, [])

    useEffect(() => {
        if (!confirmRedistribuicao) return;

        let listaProcessamento = requisicoesProcessamento(modelRedistribuicao.Requisicoes);
        let listaRequisicoesProcessamento = requisicoes.filter(x => listaProcessamento.indexOf(x.id) > -1);
        let listaRequisicoesForaProcessamento = requisicoes.filter(x => listaRequisicoesProcessamento.indexOf(x) < 0);

        let modelRedistribuicaoInterno = {
            ...modelRedistribuicao,
            Requisicoes: listaProcessamento,
            Mensagem: {
                Mensagem: description.htmlValue,
                TipoPrivacidade: 1
            }
        }

        listaRequisicoesProcessamento.filter(x => x.error).forEach(element => element.error = undefined);

        api.put(`/jucerja/v1/requisicoes/alteraratendenterequisicao`, modelRedistribuicaoInterno)
            .then(response => {
                let inconsistencias = response?.data?.inconsistencias;

                if (inconsistencias) {
                    requisicoes.forEach(element => {
                        let resultado = inconsistencias.filter(x => x.indexOf(element.descricao) > -1);

                        inconsistencias = inconsistencias.filter(x => resultado.indexOf(x) <= -1);

                        if (resultado.length > 0) {
                            listaRequisicoesProcessamento = listaRequisicoesProcessamento.filter(x => x.id !== element.id);

                            listaRequisicoesProcessamento.push({ ...element, error: resultado.join('\n') });
                        }

                    });
                }

                inconsistencias.forEach(element => enqueueSnackbar(element, { variant: 'error' }));
                requisicoesProcessadasComSucesso(listaRequisicoesProcessamento);
            })
            .catch(error => {
                error?.response?.data?.messages?.forEach(element => enqueueSnackbar(element?.mensagem, { variant: 'error' }));
            })
            .finally(() => {
                setShowConfirmRedistribuicao(false);
                setConfirmRedistribuicao(false);
                setRequisicoes(listaRequisicoesForaProcessamento.concat(listaRequisicoesProcessamento));
            });


    }, [confirmRedistribuicao])

    useEffect(() => {

        if (requisicoes.length == requisicoes.filter(x => x.sucesso).length)
            setShowConfirmRetorno(true);

        if (requisicoes.filter(x => x.error).length > 0)
            setButtonDownloadErrors(true);

    }, [requisicoes])

    useEffect(() => {
        if (!confirmRetorno) return;

        props.history.push('/Redistribuicoes');

    }, [confirmRetorno])

    return (

        <ComponenteRedistribuicao>

            <DialogConfirmation
                openDialog={showConfirmRedistribuicao}
                setOpenDialog={setShowConfirmRedistribuicao}
                setConfirm={setConfirmRedistribuicao}
                txtConfirm="Sim"
                txtCancel="Não"
                txtTitle="Confirmar redistribuição"
                txtContainer={`Deseja realmente redistribuir solicitações ?`}
                closeControlOutsideOnConfirm={true}
            />

            <DialogConfirmation
                openDialog={showConfirmRetorno}
                setOpenDialog={setShowConfirmRetorno}
                setConfirm={setConfirmRetorno}
                txtConfirm="Voltar para listagem"
                txtTitle="Processamento"
                txtContainer={`Todas as solicitações foram processadas com sucesso.`}
                disableBackdropClick={true}
                closeControlOutsideOnConfirm={true}
            />

            <Container title={
                <>
                    {/* <Link to="/Redistribuicoes"> */}
                    <Button className="btn-arrow-back" onClick={() => history.goBack()}><BiArrowBack /> </Button>
                    {/* </Link> */}
                    Redistribuição
                </>
            }>
                <Card>
                    <Card.Content>
                        <ComponenteRedistribuicao.informacaoAtendente>
                            <FormControl>
                                < Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={requisicoes}
                                    getOptionLabel={(option: string) => option.descricao}
                                    onChange={(e, v) => onChangeRequisicoes(v)}
                                    value={[...requisicoes.filter(x => modelRedistribuicao.Requisicoes.filter(y => y === x.id).length > 0)]}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Tooltip title={option.error ?? ""} arrow={true} placement='top'>
                                                <Chip
                                                    style={{ backgroundColor: option.sucesso ? '#8ae38e' : (option.error ? '#e38a8a' : '#ebebeb') }}
                                                    variant="outlined"
                                                    label={option.descricao}
                                                    {...{ ...getTagProps({ index }), deleteIcon: option.sucesso ? <></> : undefined }}
                                                />
                                            </Tooltip>
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            helperText={informativoRequisicoes}
                                            variant="standard"
                                            label="Solicitações"
                                        />
                                    )}
                                />
                            </FormControl>
                        </ComponenteRedistribuicao.informacaoAtendente>
                        <ComponenteRedistribuicao.Actions >
                            <div className={buttonDownloadErrors ? "mostrarAnimacao" : "esconderAnimacao"}>
                                <Button onClick={e => { downloadTxtErros() }} variant="outlined" color="red" className="btn-error">Download Erros <BsBoxArrowInDown /></Button>
                            </div>
                        </ComponenteRedistribuicao.Actions>
                    </Card.Content>
                </Card>
                <Card>
                    <Card.Content>

                        <ComponenteRedistribuicao.informacao>
                            <FormControl>
                                {
                                    listaDeAtendentes.length > 0
                                        ?
                                        <FormControl>
                                            < Autocomplete
                                                title='Responsáveis'
                                                id="tags-standard"
                                                options={listaDeAtendentes}
                                                value={listaDeAtendentes.filter(x => x.referenciaUsuarioId == modelRedistribuicao.UsuarioId)[0] ?? null}
                                                getOptionLabel={(option) => option.nome}
                                                onChange={(e, v) => { setModelRedistribuicao(r => ({ ...r, UsuarioId: v?.referenciaUsuarioId })); setErrorAtendente(false); }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={errorAtentende}
                                                        {...params}
                                                        variant="standard"
                                                        label="Selecione o responsável"
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                        :
                                        <></>
                                }

                            </FormControl>
                        </ComponenteRedistribuicao.informacao>
                        <ComponenteRedistribuicao.informacao>
                            <ComponenteRedistribuicao.mensagemDialog>
                                <ComponenteRedistribuicao.mensagem>

                                    {/* <Editor 
                                        toolbarHidden={false}

                                        editorState={description.editorState}
                                        onEditorStateChange={onEditorStateChange}
                                        handlePastedText={(input) => {
                                            let caracteres = draftToHtml(convertToRaw(description.editorState.getCurrentContent())).length;
                                            let inputCaracteres = input.length;
                                            if ((caracteres + inputCaracteres) >= 4000) {
                                                return 'handled';
                                            }

                                        }}
                                        handleBeforeInput={(input) => {
                                            let caracteres = draftToHtml(convertToRaw(description.editorState.getCurrentContent())).length;
                                            let inputCaracteres = input.length;
                                            if ((caracteres + inputCaracteres) >= 4000) {
                                                return 'handled';
                                            }

                                        }}
                                        localization={{
                                            locale: 'pt',
                                        }}
                                        toolbar={{
                                            image: false
                                        }}
                                        readOnly={false}
                                    /> */}
                                </ComponenteRedistribuicao.mensagem>
                            </ComponenteRedistribuicao.mensagemDialog>
                        </ComponenteRedistribuicao.informacao>
                        <ComponenteRedistribuicao.bottom>
                            <Button onClick={e => { setDescription(d => ({ ...d, editorState: EditorState.createEmpty() })); setModelRedistribuicao(r => ({ ...r, UsuarioId: null })); }} className="btn-close-actions">Limpar</Button>
                            <Button onClick={e => { redistribuir() }} className="btn-primary-actions">Alterar</Button>
                        </ComponenteRedistribuicao.bottom>
                    </Card.Content>
                </Card>

            </Container>

        </ComponenteRedistribuicao >
    )
}