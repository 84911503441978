import styled from 'styled-components'

export const AssociatedTasks = styled.div`
    
`

AssociatedTasks.Form = styled.div`
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-acesso-requisicao {
        -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
        border-radius: 10px;
        margin: 0 0 20px 0;
        text-transform: none;
        padding: 0;
        width: 100%;
    }

    div#AssociatedTasks_Form {
        width: 100%;
    }

    div#Request_ListItemSubInfo {
        margin: 0;
    }
`

AssociatedTasks.Actions = styled.div`

`