import { useState, useEffect, forwardRef, useRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import TextField from "@material-ui/core/TextField"
import Switch from '@mui/material/Switch';
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Select from "@material-ui/core/Select"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { DialogAssuntoSetor } from './styles'
import api from '../../../services/api'
import { Autocomplete, Chip, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid'
import { setRef, Grid } from '@material-ui/core'
import InputLabel from "@material-ui/core/InputLabel"
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const objAssuntoInicial = { id: undefined, setorId: undefined, mensagemId: 0, mensagemAutomatica: '', descricao: '', habilitar: false, desativado: false };

export default function DialogAssuntoSetorConfirm({
    setOpenDialogAssuntoSetorConfirm,
    openDialogAssuntoSetorConfirm,
    setAssuntoSetorConfirm,
    assuntoSetor,
    supervisores
}) {
    const [errorAssunto, setErrorAssunto] = useState(undefined);
    const [assunto, setAssunto] = useState(objAssuntoInicial);
    const [listaAssuntos, setListaAssuntos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limpar, setLimpar] = useState(false);


    const handleClose = (e) => {
        e.preventDefault();

        if (loading) return;

        setOpenDialogAssuntoSetorConfirm(false);
    }

    const handleConfirm = async (e) => {
        e?.preventDefault();

        if (!assunto.descricao || assunto.descricao.length <= 0) {
            setErrorAssunto(true);
            return
        }

        setLoading(true);
        setAssuntoSetorConfirm(assunto);
    }

    useEffect(() => {
        setAssunto(objAssuntoInicial);

        if (openDialogAssuntoSetorConfirm) {
            if (assuntoSetor)
                setAssunto({ ...assuntoSetor, habilitar: !assuntoSetor.desativado, desativado: assuntoSetor.desativado, supervisorId: assuntoSetor.supervisorId });

            setLimpar(!limpar);
        } else {
            setLoading(false);
        }
    }, [openDialogAssuntoSetorConfirm])

    return (
        
        <Draggable      
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogAssuntoSetorConfirm}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-setor"
        // PaperComponent={props => instanciaDragg.componentDraggable("alert-dialog-slide-title", props)}
        >
            <DialogTitle id="alert-dialog-slide-title">{assuntoSetor ? "Atualizar Assunto" : "Cadastrar Assunto"}</DialogTitle>
            <DialogContent>
                <DialogAssuntoSetor id="DialogSetor">
                    <DialogAssuntoSetor.Container id="DialogSetor_Container">
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    id="outlined-basic-titulo"
                                    inputProps={{ maxLength: 255 }}
                                    label="Descrição Assunto"
                                    variant="outlined"
                                    helperText={errorAssunto}
                                    name="titulo"
                                    value={assunto.descricao}
                                    onChange={e => { setAssunto({ ...assunto, descricao: e.target.value }); setErrorAssunto(false) }}
                                    error={errorAssunto}
                                />


                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    id="outlined-basic-titulo"
                                    inputProps={{ maxLength: 255 }}
                                    multiline
                                    rows={4}
                                    label="Mensagem Automática"
                                    variant="outlined"
                                    name="titulo"
                                    value={assunto.mensagemAutomatica}
                                    onChange={e => { setAssunto({ ...assunto, mensagemAutomatica: e.target.value }); }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl variant="outlined">
                                    <InputLabel id="demo-simple-select-outlined-label">Supervisor</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={assunto?.supervisorId ?? " "}
                                        onChange={e => { setAssunto({ ...assunto, supervisorId: e.target.value }); }}
                                        label="Supervisor"
                                    >
                                        <MenuItem value=" ">Selecione um supervisor</MenuItem>
                                        {

                                            (supervisores ?? []).map(x => <MenuItem value={x.id}>{x.descricao}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                assuntoSetor ?
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                        <FormControlLabel control={<Switch defaultChecked color='success' checked={assunto.habilitar} onChange={e => setAssunto({ ...assunto, habilitar: e.target.checked, desativado: !e.target.checked })} />} label="Habilitar" />

                                    </Grid>
                                    :
                                    <></>
                            }
                        </Grid>
                    </DialogAssuntoSetor.Container>
                </DialogAssuntoSetor>
            </DialogContent>
            <DialogActions>
                <DialogAssuntoSetor.Actions id="DialogSetor.Actions">
                    <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
                        <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                    </div>
                    {
                        loading
                            ?
                            <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                            :
                            <Button className="btn-primary-actions" onClick={handleConfirm}>Confirmar</Button>
                    }

                </DialogAssuntoSetor.Actions>
            </DialogActions>
        </Dialog >
        </Draggable>
			
    )
}