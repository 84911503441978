import { BiCalendarEvent, HiOutlineMail, FiPhone } from 'react-icons/all'
import { capitalize, convertISODateToDateView, formataTelefone, formataCNPJ, formataCPF, bytesToSize } from '../../../functions'
import { PrintTask } from './styles'
import { v4 as uuid } from 'uuid'
import ReactStars from 'react-stars'
import Logo from '../../../assets/img/logo.svg'
import ReactHtmlParser from 'react-html-parser'

export default function HistoryPrintTask({ mensagens }) {
    const valueAssessment = note => {
        let value = String(note)
        if (Boolean(value)) {
            if (value.length === 1)
                return value + '.0'
            else
                return value
        }
    }

    return (
        <PrintTask id="PrintRequest">
            <PrintTask.Content id="PrintRequest_Content">
                <PrintTask.Logo id="PrintRequest_Logo" src={Logo} />
                <PrintTask.Title id="PrintRequest_Title">Histórico da Tarefa</PrintTask.Title>

                <PrintTask.Information id="PrintRequest_Information">
                    <PrintTask.SubTitle id="PrintRequest_SubTitle">{mensagens.titulo}</PrintTask.SubTitle>
                    <PrintTask.Group id="PrintRequest_Group">
                        {Boolean(mensagens.email) ? <PrintTask.Email id="PrintRequest_Email"><HiOutlineMail /> {mensagens.email}</PrintTask.Email> : null}
                        {Boolean(mensagens.telefone) ? <PrintTask.Phone id="PrintRequest_Phone"><FiPhone /> {formataTelefone(mensagens.telefone)}</PrintTask.Phone> : null}
                    </PrintTask.Group>
                    <PrintTask.GroupData id="PrintRequest_GroupData">
                        {Boolean(mensagens.numeroProtocolo) ? <PrintTask.GroupDataItem id="PrintRequest_GroupDataItem"><label>Protocolo</label> {mensagens.numeroProtocolo}</PrintTask.GroupDataItem> : null}
                        {Boolean(mensagens.cpf) ? <PrintTask.GroupDataItem id="PrintRequest_GroupDataItem"><label>CPF</label> {formataCPF(mensagens.cpf)}</PrintTask.GroupDataItem> : null}
                        {Boolean(mensagens.nire) ? <PrintTask.GroupDataItem id="PrintRequest_GroupDataItem"><label>Nire</label> {mensagens.nire}</PrintTask.GroupDataItem> : null}
                        {Boolean(mensagens.cnpj) ? <PrintTask.GroupDataItem id="PrintRequest_GroupDataItem"><label>CNPJ</label> {formataCNPJ(mensagens.cnpj)}</PrintTask.GroupDataItem> : null}
                    </PrintTask.GroupData>
                    <PrintTask.GroupData id="PrintRequest_GroupData">
                        <PrintTask.GroupDataItem id="PrintRequest_GroupDataItem"><label>N° Tarefa </label> <p style={{ margin: 0 }}>{mensagens.id}</p></PrintTask.GroupDataItem>
                        {
                            Boolean(mensagens.status)
                                ?
                                <PrintTask.GroupDataItem id="PrintRequest_GroupDataItem">
                                    <label>Data Abertura</label>
                                    <p style={{ margin: 0 }}>
                                        <BiCalendarEvent style={{ position: 'relative', top: 2 }} /> {convertISODateToDateView(mensagens.dataCriacao)}
                                    </p>
                                </PrintTask.GroupDataItem>
                                :
                                null
                        }
                    </PrintTask.GroupData>
                </PrintTask.Information>

                {
                    Boolean(mensagens.avaliacao)
                        ?
                        <PrintTask.Evaluation id="PrintRequest_Evaluation">
                            <PrintTask.SubTitle id="PrintRequest_SubTitle">Avaliação</PrintTask.SubTitle>
                            <PrintTask.EvaluationInformation id="PrintRequest_EvaluationInformation">
                                {
                                    Boolean(mensagens.avaliacao.nota)
                                        ?
                                        <PrintTask.EvaluationStars id="PrintRequest_EvaluationStars">
                                            <ReactStars
                                                className="no-select"
                                                count={5}
                                                value={Number(mensagens.avaliacao.nota)}
                                                edit={false}
                                                size={24}
                                                color2={'#ffd700'}
                                            />
                                            <span>{valueAssessment(mensagens.avaliacao.nota)}</span>
                                        </PrintTask.EvaluationStars>
                                        :
                                        null
                                }
                                {
                                    Boolean(mensagens.avaliacao.data)
                                        ?
                                        <PrintTask.EvaluationStars id="PrintTask.EvaluationStars">
                                            <p><BiCalendarEvent /> {convertISODateToDateView(mensagens.avaliacao.data)}</p>
                                        </PrintTask.EvaluationStars>
                                        :
                                        null
                                }
                            </PrintTask.EvaluationInformation>
                            {Boolean(mensagens.avaliacao.descricao) ? <PrintTask.Typograph id="PrintRequest_Typograph"><strong>{mensagens.avaliacao.descricao}</strong></PrintTask.Typograph> : null}
                            {Boolean(mensagens.avaliacao.comentario) ? <PrintTask.Typograph id="PrintRequest_Typograph">{mensagens.avaliacao.comentario}</PrintTask.Typograph> : null}
                        </PrintTask.Evaluation>
                        :
                        null
                }


                <PrintTask.Messages id="PrintRequest_Messages">
                    <PrintTask.SubTitle id="PrintRequest_SubTitle">Mensagens</PrintTask.SubTitle>

                    {
                        Boolean(mensagens.mensagens)
                            ?
                            mensagens.mensagens.map(mensagem => {
                                switch (mensagem.tipo) {
                                    case 'status':
                                        return (
                                            <PrintTask.MessageItem key={uuid()} id="PrintRequest_MessageItem">
                                                <PrintTask.MessageItemColumn1 id="PrintRequest_MessageItemColumn1">
                                                    <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                        <label className="label-message-item-request">OPERAÇÃO</label>
                                                        <p className="typograph-message-item-request">Status</p>
                                                    </PrintTask.MessageItemColumnRow1>
                                                    <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                        <label className="label-message-item-request">DATA</label>
                                                        <p className="typograph-message-item-request">{convertISODateToDateView(mensagem.dataOperacao)}</p>
                                                    </PrintTask.MessageItemColumnRow1>
                                                </PrintTask.MessageItemColumn1>
                                                <PrintTask.MessageItemColumn2 id="PrintRequest_MessageItemColumn2">
                                                    <PrintTask.MessageItemColumnRow2 id="PrintRequest_MessageItemColumnRow2">
                                                        <label className="label-message-item-request">DESCRIÇÃO</label>
                                                        <p className="typograph-message-item-request"><strong>Status Alterado: </strong>{mensagem.descricao}</p>
                                                    </PrintTask.MessageItemColumnRow2>
                                                </PrintTask.MessageItemColumn2>
                                            </PrintTask.MessageItem>
                                        )
                                    case 'assuntos':
                                        return (
                                            <PrintTask.MessageItem key={uuid()} id="PrintRequest_MessageItem">
                                                <PrintTask.MessageItemColumn1 id="PrintRequest_MessageItemColumn1">
                                                    <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                        <label className="label-message-item-request">OPERAÇÃO</label>
                                                        <p className="typograph-message-item-request">Assunto</p>
                                                    </PrintTask.MessageItemColumnRow1>
                                                    <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                        <label className="label-message-item-request">DATA</label>
                                                        <p className="typograph-message-item-request">{convertISODateToDateView(mensagem.dataOperacao)}</p>
                                                    </PrintTask.MessageItemColumnRow1>
                                                </PrintTask.MessageItemColumn1>
                                                <PrintTask.MessageItemColumn2 id="PrintRequest_MessageItemColumn2">
                                                    <PrintTask.MessageItemColumnRow2 id="PrintRequest_MessageItemColumnRow2">
                                                        <label className="label-message-item-request">DESCRIÇÃO</label>
                                                        <p className="typograph-message-item-request"><strong>Assunto Alterado: </strong>{capitalize(mensagem.descricao)}</p>
                                                    </PrintTask.MessageItemColumnRow2>
                                                </PrintTask.MessageItemColumn2>
                                            </PrintTask.MessageItem>
                                        )
                                    case 'mensagens':
                                        if (mensagem.ehMensagemCliente) {
                                            if (mensagem.ehAnexo) {
                                                return (
                                                    <PrintTask.MessageItem key={uuid()} id="PrintRequest_MessageItem">
                                                        <PrintTask.MessageItemColumn1 id="PrintRequest_MessageItemColumn1">
                                                            <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                                <label className="label-message-item-request">ENVIADO POR</label>
                                                                <p className="typograph-message-item-request">{mensagens.nome}</p>
                                                            </PrintTask.MessageItemColumnRow1>
                                                            <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                                <label className="label-message-item-request">DATA</label>
                                                                <p className="typograph-message-item-request">{convertISODateToDateView(mensagem.dataOperacao)}</p>
                                                            </PrintTask.MessageItemColumnRow1>
                                                        </PrintTask.MessageItemColumn1>
                                                        <PrintTask.MessageItemColumn3 id="PrintRequest_MessageItemColumn3">
                                                            <PrintTask.MessageItemColumnRow3 id="PrintRequest_MessageItemColumnRow3">
                                                                <label className="label-message-item-request">ANEXOS</label>
                                                                <p className="typograph-message-item-request">{mensagem.nome} - {bytesToSize(mensagem.tamanho)}</p>
                                                            </PrintTask.MessageItemColumnRow3>
                                                        </PrintTask.MessageItemColumn3>
                                                    </PrintTask.MessageItem>
                                                )
                                            }
                                            else {
                                                return (
                                                    <PrintTask.MessageItem key={uuid()} id="PrintRequest_MessageItem">
                                                        <PrintTask.MessageItemColumn1 id="PrintRequest_MessageItemColumn1">
                                                            <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                                <label className="label-message-item-request">ENVIADO POR</label>
                                                                <p className="typograph-message-item-request">{mensagens.nome}</p>
                                                            </PrintTask.MessageItemColumnRow1>
                                                            <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                                <label className="label-message-item-request">DATA</label>
                                                                <p className="typograph-message-item-request">{convertISODateToDateView(mensagem.dataOperacao)}</p>
                                                            </PrintTask.MessageItemColumnRow1>
                                                        </PrintTask.MessageItemColumn1>
                                                        <PrintTask.MessageItemColumn2 id="PrintRequest_MessageItemColumn2">
                                                            <PrintTask.MessageItemColumnRow2 id="PrintRequest_MessageItemColumnRow2">
                                                                <label className="label-message-item-request">MENSAGEM</label>
                                                                <p className="typograph-message-item-request">{ReactHtmlParser(mensagem.mensagem)}</p>
                                                            </PrintTask.MessageItemColumnRow2>
                                                        </PrintTask.MessageItemColumn2>
                                                        {mensagem.anexos && (
                                                            mensagem.anexos.length > 0 && (
                                                                <PrintTask.MessageItemColumn3 id="PrintRequest_MessageItemColumn3">
                                                                    <PrintTask.MessageItemColumnRow3 id="PrintRequest_MessageItemColumnRow3">
                                                                        <label className="label-message-item-request">ANEXOS</label>
                                                                        {mensagem.anexos.map(anexo => (
                                                                            <p key={uuid()} className="typograph-message-item-request">{anexo.nome} - {bytesToSize(anexo.tamanho)}</p>
                                                                        ))}
                                                                    </PrintTask.MessageItemColumnRow3>
                                                                </PrintTask.MessageItemColumn3>
                                                            )
                                                        )}
                                                    </PrintTask.MessageItem>
                                                )
                                            }
                                        }
                                        else {
                                            if (mensagem.ehAnexo) {
                                                <PrintTask.MessageItem key={uuid()} id="PrintRequest_MessageItem">
                                                    <PrintTask.MessageItemColumn1 id="PrintRequest_MessageItemColumn1">
                                                        <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                            <label className="label-message-item-request">ENVIADO POR</label>
                                                            <p className="typograph-message-item-request">{mensagem.nomeUsuario}</p>
                                                        </PrintTask.MessageItemColumnRow1>
                                                        <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                            <label className="label-message-item-request">DATA</label>
                                                            <p className="typograph-message-item-request">{convertISODateToDateView(mensagem.dataOperacao)}</p>
                                                        </PrintTask.MessageItemColumnRow1>
                                                    </PrintTask.MessageItemColumn1>
                                                    <PrintTask.MessageItemColumn3 id="PrintRequest_MessageItemColumn3">
                                                        <PrintTask.MessageItemColumnRow3 id="PrintRequest_MessageItemColumnRow3">
                                                            <label className="label-message-item-request">ANEXOS</label>
                                                            <p className="typograph-message-item-request">{mensagem.nome} - {bytesToSize(mensagem.tamanho)}</p>
                                                        </PrintTask.MessageItemColumnRow3>
                                                    </PrintTask.MessageItemColumn3>
                                                </PrintTask.MessageItem>
                                            }
                                            else {
                                                return (
                                                    <PrintTask.MessageItem key={uuid()} id="PrintRequest_MessageItem">
                                                        <PrintTask.MessageItemColumn1 id="PrintRequest_MessageItemColumn1">
                                                            <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                                <label className="label-message-item-request">ENVIADO POR</label>
                                                                <p className="typograph-message-item-request">{mensagem.nomeUsuario}</p>
                                                            </PrintTask.MessageItemColumnRow1>
                                                            <PrintTask.MessageItemColumnRow1 id="PrintRequest_MessageItemColumnRow1">
                                                                <label className="label-message-item-request">DATA</label>
                                                                <p className="typograph-message-item-request">{convertISODateToDateView(mensagem.dataOperacao)}</p>
                                                            </PrintTask.MessageItemColumnRow1>
                                                        </PrintTask.MessageItemColumn1>
                                                        <PrintTask.MessageItemColumn2 id="PrintRequest_MessageItemColumn2">
                                                            <PrintTask.MessageItemColumnRow2 id="PrintRequest_MessageItemColumnRow2">
                                                                <label className="label-message-item-request">MENSAGEM</label>
                                                                {/* <p className="typograph-message-item-request">{ReactHtmlParser(mensagem.mensagem)}</p> */}

                                                                <p className="typograph-message-item-request">{mensagem.mensagem}</p>
                                                            </PrintTask.MessageItemColumnRow2>
                                                        </PrintTask.MessageItemColumn2>
                                                        {mensagem.anexos && (
                                                            mensagem.anexos.length > 0 && (
                                                                <PrintTask.MessageItemColumn3 id="PrintRequest_MessageItemColumn3">
                                                                    <PrintTask.MessageItemColumnRow3 id="PrintRequest_MessageItemColumnRow3">
                                                                        <label className="label-message-item-request">ANEXOS</label>
                                                                        {mensagem.anexos.map(anexo => (
                                                                            <p key={uuid()} className="typograph-message-item-request">{anexo.nome} - {bytesToSize(anexo.tamanho)}</p>
                                                                        ))}
                                                                    </PrintTask.MessageItemColumnRow3>
                                                                </PrintTask.MessageItemColumn3>
                                                            )
                                                        )}
                                                    </PrintTask.MessageItem>
                                                )
                                            }
                                        }

                                    default:
                                        return null
                                }
                            })
                            :
                            null
                    }
                </PrintTask.Messages>
            </PrintTask.Content>
        </PrintTask>
    )
}