import { useState, forwardRef, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText';
import Slide from '@material-ui/core/Slide'
import InputLabel from "@material-ui/core/InputLabel"
import { CircularProgress } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import api from '../../../services/api'
import Grid from '@material-ui/core/Grid'
import { useSnackbar } from 'notistack'
import { CreateTasks } from './styles'
import { GrAttachment, VscFilePdf, RiCloseCircleFill, IoImageOutline } from 'react-icons/all'
import { limitChar } from '../../../functions'
import { v4 as uuid } from 'uuid'

import { Request } from '../DialogSendMessageEditor/styles'
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { object } from 'prop-types'
import { Tasks } from '../../../pages/Tarefas/styles'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogCreateTasks({
    idRequisicao,
    tipoTarefa,
    setOpenDialogTarefasAssociadas,
    openDialogCreateTasks,
    setOpenDialogCreateTasks
}) {
    const [error, setError] = useState(false)
    const [errosDeCampos, setErrosDeCampos] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [errorMinimoMensagem, setErrorMinimoMensagem] = useState('')
    const [textoBotaoArquivos, setTextoBotaoArquivos] = useState('Anexar Arquivos')
    const [inputs, setInputs] = useState(values => ({ RequisicaoId: idRequisicao }))
    const [codigoSetor, setCodigoSetor] = useState("")
    const [desativarComboResponsavel, setDesativarComboResponsavel] = useState(true);
    const [listaDeSetores, setListaDeSetores] = useState([])
    const [listaDeFuncionarios, setListaDeFuncionarios] = useState([])
    const [listaDeEntesEternos, setListaDeEntesEternos] = useState([])
    const [preCarregar, setPreCarregar] = useState({ setor: null, funcionario: null })
    const [attachs, setAttachs] = useState([])
    const [description, setDescription] = useState({
        htmlValue: "",
        editorState: EditorState.createEmpty()
    })
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const handleClose = (e) => {
        e?.preventDefault()
        setOpenDialogCreateTasks(false)
        setCodigoSetor('')
        setInputs(values => ({ RequisicaoId: idRequisicao }))
        setDescription({
            htmlValue: "",
            editorState: EditorState.createEmpty()
        })
        setErrosDeCampos([])
        setAttachs([])
        setListaDeFuncionarios([])
        setListaDeSetores([])

        if (tipoTarefa === 1)
            setOpenDialogTarefasAssociadas(true)
    }

    const onEditorStateChange = editorValue => {
        setErrorMinimoMensagem('');

        const editorStateInHtml = draftToHtml(
            convertToRaw(editorValue.getCurrentContent())
        )
        setInputs(values => ({ ...values, "Mensagem": editorStateInHtml }));
        setDescription({
            htmlValue: editorStateInHtml,
            editorState: editorValue
        })
    }

    const getSetores = () => {
        api.get(`/jucerja/v1/Setor`)
            .then(response => {
                setListaDeSetores(response.data ?? [])
            })
            .catch(error => {
                setError(true)
                setErrorMessage(error.toString())
            })
    }

    const getEntesExterno = () => {
        api.get(`/jucerja/v1/Tarefa/entesExternas`)
            .then(response => {
                setListaDeEntesEternos(response.data ?? [])
            })
            .catch(error => {
                setError(true)
                setErrorMessage(error.toString())
            })
    }

    const getFuncionariosSetor = () => {
        setListaDeFuncionarios([])
        setInputs(values => ({ ...values, "ResponsavelId": '' }))

        if (codigoSetor != '' && codigoSetor > 0) {
            api.get(`/jucerja/v1/Funcionario/${codigoSetor}`)
                .then(response => {
                    setListaDeFuncionarios(response.data ?? [])
                    setDesativarComboResponsavel(false)
                })
                .catch(error => {
                    setError(true)
                    setErrorMessage(error.toString())
                })

        } else {
            setDesativarComboResponsavel(true)
        }
    }

    const getError = (prop) => {
        return errosDeCampos.filter(x => x.Campo?.toUpperCase() === prop?.toUpperCase())[0]?.Mensagem
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleAttachmentTarefa = (event, inputMessage) => {
        Array.from(event.target.files).map((file, index) => {
            if (Boolean(file)) {
                if (Number(file.size) <= 10000000) {
                    if (
                        file.type === "application/pdf"
                        || file.type === "image/jpg"
                        || file.type === "image/jpeg"
                        || file.type === "image/jpe"
                        || file.type === "image/jfif"
                        || file.type === "image/png"
                        || file.type === "image/bmp"
                        || file.type === "image/gif"
                        || file.type === "application/pkcs7-signature"
                    ) {
                        const fileReader = new window.FileReader()
                        fileReader.onload = (fileLoad) => {
                            const { result } = fileLoad.target
                            setAttachs((prevState) => [...prevState, {
                                id: uuid(),
                                file: file,
                                name: file.name,
                                type: file.type,
                                url: result
                            }])
                        }

                        fileReader.readAsDataURL(file)
                    }
                    else {
                        enqueueSnackbar('A extensão do arquivo não é suportado', { variant: 'error' })
                    }
                }
                else {
                    enqueueSnackbar('O arquivo não pode ser maior que 10mb', { variant: 'error' })
                }
            }
        })
    }

    const handleDeleteAttach = (id) => {
        let newArrAttachs = []
        attachs.map(attach => {
            if (attach.id !== id) {
                newArrAttachs.push(attach)
            }
        })
        if (newArrAttachs.length === 0) {
            setAttachs([])
        }
        else {
            setAttachs(newArrAttachs)
        }
    }

    const handleSalvar = async () => {

        if (description.editorState.getCurrentContent().getPlainText().length < 10) {
            setErrorMinimoMensagem('Deve ser informado uma mensagem, de no mínimo 10 caracteres.');
            return;
        }

        setLoadingSubmit(true)
        let data = new FormData()

        if (attachs.length > 0) {
            attachs.map(attach => {
                data.append('Arquivos', attach.file)
            })
        }

        Object.keys(inputs).forEach(x => {
            data.append(x, inputs[x])
        })

        data.append('tipoTarefa', tipoTarefa)

        await api({
            method: 'post',
            url: '/jucerja/v1/tarefa',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data,
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setTextoBotaoArquivos(` ${percentCompleted}%`)
            }
        })
            .then(response => {
                setTextoBotaoArquivos("Anexar Arquivos")
                setLoadingSubmit(false)
                handleClose()
            })
            .catch(error => {

                setErrosDeCampos([])
                var camposErros = error?.response?.data?.messages[0]?.campos
                setLoadingSubmit(false)

                if (camposErros) {
                    setErrosDeCampos(camposErros);
                    return
                }

                switch (error?.response?.status) {
                    case 422:
                        enqueueSnackbar(error.response.data.messages[0].mensagem, { variant: 'error' })
                        break
                    default:
                        enqueueSnackbar('Não foi possível enviar sua mensagem. Por favor tente novamente', { variant: 'error' })
                        break
                }

                setTextoBotaoArquivos("Anexar Arquivos")
            })
    }

    const getTituloDialog = () => {
        switch (tipoTarefa) {
            case 1:
                return 'Criar Nova Tarefa'
            case 2:
                return 'Alterar Status de Verificação Interna'
            case 3:
                return 'Alterar Status Aguardando Órgão Externo'
            default:
                break
        }
    }

    useEffect(() => {
        getFuncionariosSetor();
    }, [codigoSetor]);

    useEffect(() => {
        let erroArquivo = getError("files")
        if (erroArquivo)
            enqueueSnackbar(erroArquivo, { variant: 'error' })
    }, [errosDeCampos])

    useEffect(() => {
        if (tipoTarefa !== 3 || !openDialogCreateTasks) return;

        api.get(`/jucerja/v1/setor/usuario-logado`)
            .then(response => {
                setCodigoSetor(response.data.id);
                setListaDeSetores([response.data]);
                setPreCarregar(item => ({ ...item, setor: response.data }))

                api.get(`/jucerja/v1/funcionario/logado`)
                    .then(response => {
                        setListaDeFuncionarios([response.data]);

                        setPreCarregar(item => ({ ...item, funcionario: response.data }))

                    })
                    .catch(error => {
                        setError(true)
                        setErrorMessage(error.toString())
                    })

            })
            .catch(error => {
                setError(true)
                setErrorMessage(error.toString())
            })
    }, [openDialogCreateTasks])

    useEffect(() => {
        if (preCarregar.setor && preCarregar.funcionario && openDialogCreateTasks && tipoTarefa === 3) {
            inputs.SetorId = preCarregar.setor.id;
            inputs.ResponsavelId = preCarregar.funcionario.referenciaUsuarioId;
        }

    }, [preCarregar])

    return (

        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogCreateTasks}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-associated-tasks-request-slide-title"
            aria-describedby="dialog-associated-tasks-request-dialog-slide-description"
            fullWidth={'lg'}
            id="dialog-create-tasks"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">{getTituloDialog()}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className="request-description-dialog"><strong>Solicitação: </strong> {idRequisicao}</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                        <FormControl variant="outlined">
                            <TextField
                                id="outlined-basic-titulo"
                                inputProps={{ maxLength: 40 }}
                                label="Título"
                                variant="outlined"
                                helperText={getError('titulo')}
                                name="titulo"
                                value={inputs.titulo || ''}
                                onChange={e => handleChange(e)}
                                error={getError('titulo')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <FormControl variant="outlined">
                            <InputLabel id="select-outlined-label-setor">Setor</InputLabel>
                            <Select
                                labelId="select-outlined-label-setor"
                                id="select-outlined-setor"
                                value={inputs.SetorId || ""}
                                error={getError("SetorId")}
                                onOpen={getSetores}
                                name="SetorId"
                                onChange={e => { handleChange(e); setCodigoSetor(e.target.value); }}
                                label="Setor"
                            >
                                <MenuItem value="">
                                    <em>Selecione uma opção</em>
                                </MenuItem>
                                {
                                    listaDeSetores.map(setor => (
                                        <MenuItem key={setor.id} value={setor.id}>{setor.descricao}</MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{getError("SetorId")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <FormControl variant="outlined">
                            <TextField
                                inputProps={{ maxLength: 20 }}
                                id="outlined-basic-ticket"
                                label="Ticket"
                                variant="outlined"
                                name="numeroTicket"
                                value={inputs.numeroTicket || ''}
                                onChange={e => handleChange(e)}
                                error={getError("numeroTicket")}
                                helperText={getError("numeroTicket")}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <FormControl variant="outlined">
                            <InputLabel id="select-outlined-label-prioridade">Prioridade</InputLabel>
                            <Select
                                labelId="select-outlined-label-prioridade"
                                id="select-outlined-prioridade"
                                name="prioridade"
                                error={getError("prioridade")}
                                value={inputs.prioridade || ''}
                                onChange={e => handleChange(e)}
                                label="Prioridade"
                            >
                                <MenuItem value="">
                                    <em>Selecione uma opção</em>
                                </MenuItem>
                                <MenuItem value="3">Alta</MenuItem>
                                <MenuItem value="1">Baixa</MenuItem>
                                <MenuItem value="2">Média</MenuItem>
                            </Select>
                            <FormHelperText>{getError("prioridade")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <FormControl variant="outlined">
                            <InputLabel id="select-outlined-label-responsavel">Responsável</InputLabel>
                            <Select
                                labelId="select-outlined-label-responsavel"
                                id="select-outlined-responsavel"
                                name="ResponsavelId"
                                error={getError("ResponsavelId")}
                                value={inputs.ResponsavelId || ''}
                                onChange={e => handleChange(e)}
                                disabled={desativarComboResponsavel}
                                label="Responsável"
                            >
                                <MenuItem value="">
                                    <em>Selecione uma opção</em>
                                </MenuItem>
                                {
                                    listaDeFuncionarios.map(fun => (
                                        <MenuItem key={fun.referenciaUsuarioId} value={fun.referenciaUsuarioId}>{fun.nome}</MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{getError("ResponsavelId")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <FormControl variant="outlined">
                            <InputLabel id="select-outlined-label-orgao-externo">Órgão Externo</InputLabel>
                            <Select
                                labelId="select-outlined-label-orgao-externo"
                                id="select-outlined-orgao-externo"
                                name="enteExternoId"
                                value={inputs.enteExternoId || ''}
                                onChange={e => handleChange(e)}
                                onOpen={getEntesExterno}
                                error={getError('enteExternoId')}
                                label="Órgão Externo"
                            >
                                <MenuItem value="">
                                    <em>Selecione uma opção</em>
                                </MenuItem>

                                {
                                    listaDeEntesEternos.map(ente => (
                                        <MenuItem key={ente.id} value={ente.id}>{ente.descricao}</MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{getError("enteExternoId")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CreateTasks id="CreateTasks">
                            <Request.SendMessageDialog id="Request_SendMessageDialog">
                                <Request.SendMessageContentDialog id="Request_SendMessageContentDialog">
                                    <Request.SendMessageContainerContentDialog id="Request_SendMessageContainerContentDialog">
                                        <Editor
                                            spellCheck
                                            toolbarHidden={false}
                                            editorState={description.editorState}
                                            onEditorStateChange={onEditorStateChange}
                                            localization={{
                                                locale: 'pt',
                                            }}
                                            toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded'/*, 'emoji'*/, 'image', 'remove', 'history'] }}
                                            readOnly={loadingSubmit}
                                        />
                                    </Request.SendMessageContainerContentDialog>
                                </Request.SendMessageContentDialog>
                            </Request.SendMessageDialog>
                            <FormHelperText error>{errorMinimoMensagem}</FormHelperText>
                            <div className="MuiButtonBase-root MuiButton-root MuiButton-text btn-attachment-item-form" style={{ cursor: "pointer", background: "var(--lightgreen)" }}>
                                <input
                                    accept=".jpg, jpeg, jpe, .jfif, .png, .bmp, .gif, .pdf, .p7s"
                                    style={{ display: "none" }}
                                    id="botao-file"
                                    type="file"
                                    multiple
                                    onChange={handleAttachmentTarefa}
                                />
                                <label htmlFor="botao-file" style={{ cursor: "pointer" }}>
                                    <GrAttachment /> {textoBotaoArquivos}
                                </label>

                            </div>
                            <CreateTasks.Attachments id="Request_Attachments">
                                <CreateTasks.TitleContainer id="Request_TitleContainer">
                                    <CreateTasks.TitleText id="Request_TitleText">Anexos Adicionados</CreateTasks.TitleText>
                                </CreateTasks.TitleContainer>
                                <CreateTasks.AttachmentContainer id="Request_AttachmentContainer">
                                    <CreateTasks.AttachmentContent id="Request_AttachmentContainer">


                                        {
                                            attachs.map(x =>
                                                x.type === "application/pdf" ?
                                                    <>
                                                        <CreateTasks.AttachmentItem id="Request_AttachmentItemPdf">
                                                            <CreateTasks.AttachmentItemImage onClick={() => ('')} id="Request_AttachmentItemImage"><VscFilePdf /></CreateTasks.AttachmentItemImage>
                                                            <CreateTasks.AttachmentItemText onClick={() => ('')} id="Request_AttachmentItemText">{limitChar(100, x.name)}</CreateTasks.AttachmentItemText>
                                                            <Button className="Request_AttachmentItemClose" onClick={() => handleDeleteAttach(x.id)}><RiCloseCircleFill /></Button>
                                                        </CreateTasks.AttachmentItem>
                                                    </>
                                                    :
                                                    <>
                                                        <CreateTasks.AttachmentItem id="Request_AttachmentItemImg">
                                                            <CreateTasks.AttachmentItemImage onClick={() => ('attach')} id="Request_AttachmentItemImage"><IoImageOutline /></CreateTasks.AttachmentItemImage>
                                                            <CreateTasks.AttachmentItemText onClick={() => ('attach')} id="Request_AttachmentItemText">{limitChar(100, x.name)}</CreateTasks.AttachmentItemText>
                                                            <Button className="Request_AttachmentItemClose" onClick={() => handleDeleteAttach(x.id)}><RiCloseCircleFill /></Button>
                                                        </CreateTasks.AttachmentItem>
                                                    </>
                                            )
                                        }

                                    </CreateTasks.AttachmentContent>
                                </CreateTasks.AttachmentContainer>
                            </CreateTasks.Attachments>
                        </CreateTasks>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>

                {
                    loadingSubmit
                        ?
                        <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                        :
                        <Button className="btn-primary-actions" onClick={handleSalvar}>{tipoTarefa === 1 ? 'Salvar' : 'Criar Tarefa e Alterar Status'}</Button>
                }
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}