import { useState } from 'react'
import Lottie from 'react-lottie'
import { Button } from '@material-ui/core'
import animationData from './errorresult.json'
import { Error } from './styles'
import CopyToClipboard from "@vigosan/react-copy-to-clipboard"
import { MdRefresh, FaCheckCircle } from 'react-icons/all'

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ errorMessage }) => {
    const [msgInternalButtonError, setMsgInternalButtonError] = useState('')

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <Error.Area>
            <Error id="Error">
                <Error.Animation id="Error_Animation">
                    <Lottie
                        options={defaultOptions}
                        style={{ width: '100%', height: '100%' }}
                        isStopped={false}
                    />
                </Error.Animation>
                <Error.MessageDefault id="Error_MessageDefault">Ops! Houve um problema e não foi possível carregar o conteúdo</Error.MessageDefault>
                <Error.Message id="Error_Message"><span>Detalhe do Erro</span></Error.Message>
                <Error.MessageErrorContent id="Error_MessageErrorContent">
                    <Error.ContainerErrorMessage id="Error_ContainerErrorMessage">
                        <Error.MessageErrorContentMessage id="Error.MessageErrorContentMessage">{errorMessage}</Error.MessageErrorContentMessage>
                    </Error.ContainerErrorMessage>
                    <CopyToClipboard
                        onCopy={({ success, text }) => {
                            let msg = success ? "Copiado!" : "Whoops, não foi copiado!"
                            setMsgInternalButtonError(msg)
                            setTimeout(() => { setMsgInternalButtonError('') }, 3000)
                        }}
                        render={({ copy }) => (
                            <Button
                                className="btn-copy-error-message"
                                onClick={() => copy(errorMessage)}
                                data-active={msgInternalButtonError === 'Copiado!' ? 'true' : 'false'}
                            >
                                {msgInternalButtonError === 'Copiado!' ? (<span className="span-copy-now">Copiado <FaCheckCircle /></span>) : 'Copiar'}
                            </Button>
                        )}
                    />
                </Error.MessageErrorContent>
                <Button className="btn-error-page" onClick={() => window.location.reload()}><MdRefresh /> Tentar Novamente</Button>
            </Error>
        </Error.Area>
    )
}