import { createRef } from 'react'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import { ptBR } from "@material-ui/core/locale"
import App from './App'
import GlobalStyle from './components/global-styles'
import './components/global-styles/css/normalize.css'
import './components/global-styles/fonts/Quicksand/Quicksand.css'
import { validaToken } from './services/auth'
import store from './store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';

const notistackRef = createRef()

const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key)
}

ReactDOM.render(
  <SnackbarProvider
    ref={notistackRef}
    action={(key) => (
      <Button className="btnCloseSnackBar" onClick={onClickDismiss(key)} component="span">
        <CloseIcon />
      </Button>
    )}
    autoHideDuration={4000}
    maxSnack={10}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <GlobalStyle />
    <ThemeProvider theme={createTheme(ptBR)}>
      <Provider store={store}>
        <App />
      </Provider>
      {
        window.addEventListener('focus', function () {
          validaToken();
        })
      }
    </ThemeProvider>
  </SnackbarProvider>,
  document.getElementById('root')
)