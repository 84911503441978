import styled from 'styled-components'

export const DialogDefaultText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;

    .mostrarAnimacao {
        opacity: 1;
        transition: opacity 0.6s, visibility 0.3s;
        visibility: show;
      }
      
      .esconderAnimacao {
        opacity: 0;
        transition: opacity 0.6s, visibility 0.3s;
        visibility: hidden;
      }
`

DialogDefaultText.Container = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
flex-direction: row;

line-height: 18px;
overflow: hidden;
text-align: left;
`

DialogDefaultText.Select = styled.div`
    width: calc(100% - 105px);

    span.react-loading-skeleton {
        height: 56px;
        width: 100%;
    }
`

DialogDefaultText.Button = styled.div`
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-submit-subject {
        background: var(--primary);
        background: -moz-linear-gradient( 330deg,var(--primary) 11%,var(--secondary) 100%);
        background: -webkit-linear-gradient( 330deg,var(--primary) 11%,var(--secondary) 100%);
        background: linear-gradient(330deg,var(--primary) 11%,var(--secondary) 100%);
        color: var(--white);
        text-transform: capitalize;
        height: 56px;
        width: 90px;
    }

    span.react-loading-skeleton {
        height: 56px;
        width: 100px;
    }
`

DialogDefaultText.Actions = styled.div`
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary-actions {
        width: 87px;
        height: 36px;
    }
    .mostrarAnimacao {
        opacity: 1;
      }
      
      .esconderAnimacao {
        opacity: 0;
        transition: opacity 0.6s, visibility 0.3s;
        visibility: hidden;
      }
`

DialogDefaultText.GridActions = styled.div`
    margin-right: 0.7rem;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary-actions {
        width: 87px;
        height: 36px;
    }
`

DialogDefaultText.Editor = styled.div`
.rdw-editor-toolbar {
    border-bottom: 1px solid #cecece;
}

.DraftEditor-editorContainer {
    width: calc(100% - 50px);
    border: 0;
    margin: 0 auto;
    height: 90px;
}

.DraftEditor-root {
    display: contents;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

main {
    height: 180px !important;
}

.rdw-editor-main {
    height: 230px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top: 18px;
}

.public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
    padding: 0 24px;
}
`