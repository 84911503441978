import styled from 'styled-components'

export const ComponenteFuncionario = styled.div`

.btn-arrow-back {
    z-index: 15;
    min-width: 0;
    width: 42px;
    height: 42px;
    align-items: center;
    justify-content: center;
    background: #bdbdbd4f;
    color: #383838;
    border-radius: 100%;
    padding: 0;
    font-size: 20px;
    margin: 0 15px 0 0;
}
`
ComponenteFuncionario.informacao = styled.div`
display: flex;
margin-bottom: 10px;
`

ComponenteFuncionario.title = styled.div`
font-size: 1.25rem;
font-weight: bold;
margin: 1rem 0 0 0;
width: 100%;
text-align: center;
`

ComponenteFuncionario.label = styled.div`
margin: 0 15px 0 0;
align-self: center;
font-weight: bold;
width:50px;
`

ComponenteFuncionario.grid = styled.div`
height:510px;
`

ComponenteFuncionario.distanciaDivisor = styled.div`
margin: 15px 0 15px 0;
`

ComponenteFuncionario.Actions = styled.div`
    margin-right: 0.7rem;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary-actions {
        width: 87px;
        height: 36px;
    }
    

    .mostrarAnimacao {
        opacity: 1;
        transition: opacity 0.6s, visibility 0.3s;
        visibility: show;
      }
      
      .esconderAnimacao {
        opacity: 0;
        transition: opacity 0.6s, visibility 0.3s;
        visibility: hidden;
      }
`