import styled from 'styled-components'

export const ComponenteRedistribuicao = styled.div`

.btn-arrow-back {
    z-index: 15;
    min-width: 0;
    width: 42px;
    height: 42px;
    align-items: center;
    justify-content: center;
    background: #bdbdbd4f;
    color: #383838;
    border-radius: 100%;
    padding: 0;
    font-size: 20px;
    margin: 0 15px 0 0;
}
`
ComponenteRedistribuicao.informacaoAtendente = styled.div`
width:100%;
display: flex
`

ComponenteRedistribuicao.informacao = styled.div`
width:100%;
margin-bottom: 20px;
`

ComponenteRedistribuicao.title = styled.div`
font-size: 1.25rem;
font-weight: bold;
margin: 1rem 0 0 0;
width: 100%;
text-align: center;
`

ComponenteRedistribuicao.label = styled.div`
margin: 0 70px 0 0;
align-self: center;
font-weight: bold;
width:50px;
`

ComponenteRedistribuicao.mensagemDialog = styled.div`
    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
    border-radius: 10px;
    background-color: var(--white);
    margin-bottom: 25px;
    overflow: hidden;
`

ComponenteRedistribuicao.mensagem = styled.div`
.rdw-editor-toolbar {
  border-bottom: 1px solid #cecece;
}

.DraftEditor-editorContainer {
  width: calc(100% - 50px);
  border: 0;
  margin: 0 auto;
  height: 90px;
}

.DraftEditor-root {
  display: contents;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

main {
  height: 180px !important;
}

.rdw-editor-main {
  height: 130px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 18px;
}

.public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  padding: 0 24px;
}
`

ComponenteRedistribuicao.grid = styled.div`
height: 510px;
`

ComponenteRedistribuicao.bottom = styled.div`
    width: 100%;
    background: var(--white);
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
    bottom: 0;
`

ComponenteRedistribuicao.Actions = styled.div`
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;

    .btn-error {
      color: var(--red) !important;
      text-transform: capitalize;
    }

    .mostrarAnimacao {
        opacity: 1;
      }
      
      .esconderAnimacao {
        opacity: 0;
        transition: opacity 0.6s, visibility 0.3s;
        visibility: hidden;
      }
`