import { forwardRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, AppBar, Slide, Box, Toolbar, Typography, CircularProgress, TextField, Button } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import PropTypes from 'prop-types'
import Pdf from "@mikecousins/react-pdf"
import { Request } from './styles'
import NoFiles from '../../animations/NoFiles'
import { AiOutlineSend, IoCloseCircleSharp, GoPlus } from 'react-icons/all'
import { v4 as uuid } from 'uuid'
import ImgPdfFile from '../../../assets/img/icon-pdf-preview.svg'
import api from '../../../services/api'
import { getRToken } from '../../../services/auth'
import { useDispatch } from 'react-redux'
import { storeMessage } from '../../../slices/requestMessageSlice'
import { crieRequisicaoObj } from '../../../functions'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    },
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    }
}))

export default function DialogAttachMessage({
    openDialogAttachment,
    setOpenDialogAttachment,
    handleCloseDialogAttachment,
    activeAttach,
    handleSendMessageTextAttachment,
    attachs,
    setActiveAttach,
    handleDeleteAttach,
    handleAttachment,
    handleSubmitAttachMessage,
    textInputSendMessageBox,
    idRequisicao
}) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [textMessage, setTextMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0)

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            handleSubmitMessageAndAttachment()
        }
    }

    const handleSubmitMessageAndAttachment = async () => {
        setLoading(true)

        dispatch(storeMessage(crieRequisicaoObj(idRequisicao, textMessage, 1, attachs)));

        setLoading(false)
        handleCloseDialogAttachment()

        return;
    }

    const CircularProgressWithLabel = props => (
        <Box
            id="circular-progress-send-attachment"
            sx={{ position: 'relative', display: 'inline-flex' }}
        >
            <CircularProgress
                variant="determinate"
                {...props}
                style={{ visibility: loading ? 'visible' : 'hidden', color: 'var(--green)', borderRadius: '100px' }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div">
                    {
                        loading
                            ?
                            <Button
                                className="btn-send-message-fast-request btnDefault btnDefaultPrimary"
                                disableRipple={true}
                                loading={loading ? 'true' : 'false'}
                            >
                                {`${Math.round(props.value)}%`}
                            </Button>
                            :
                            <Button
                                onClick={() => handleSubmitMessageAndAttachment()}
                                className="btn-send-message-fast-request btnDefault btnDefaultPrimary"
                            >
                                <AiOutlineSend id="icon-send-message" />
                            </Button>
                    }
                </Typography>
            </Box>
        </Box>
    )

    CircularProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    }

    useEffect(() => {
        if (textInputSendMessageBox !== "") {
            setTextMessage(textInputSendMessageBox)
        } else if (textInputSendMessageBox === "") {
            setTextMessage('')
        }
    }, [openDialogAttachment])

    return (
        <Dialog
            fullScreen
            open={openDialogAttachment}
            onClose={loading ? null : handleCloseDialogAttachment}
            TransitionComponent={Transition}
            id="dialog-attachment"
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseDialogAttachment}
                        aria-label="close"
                        disabled={loading}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>Pré-visualização</Typography>
                </Toolbar>
            </AppBar>
            <Request.Attachment id="Request.Attachment">
                <Request.AttachmentArea id="Request.AttachmentArea">
                    <Request.AttachmentPreviewActive id="Request.AttachmentPreviewActive">
                        {
                            activeAttach === null
                                ?
                                <div className="no-files-found-attachs">
                                    <NoFiles />
                                    <p>Não há arquivos para serem pré-visualizados</p>
                                </div>
                                :
                                activeAttach.type === "image/jpg"
                                    || activeAttach.type === "image/jpeg"
                                    || activeAttach.type === "image/jpe"
                                    || activeAttach.type === "image/jfif"
                                    || activeAttach.type === "image/png"
                                    || activeAttach.type === "image/bmp"
                                    || activeAttach.type === "image/gif"
                                    ?
                                    (<Request.ImgAttachmentPreviewActive src={activeAttach.url} />)
                                    :
                                    activeAttach.type === "application/pdf"
                                        ?
                                        (<Pdf file={activeAttach.url} page={1}>
                                            {({ pdfDocument, pdfPage, canvas }) => (
                                                <>
                                                    {!pdfDocument && <span><CircularProgress /></span>}
                                                    {canvas}
                                                    {Boolean(pdfDocument && pdfDocument.numPages)}
                                                </>
                                            )}
                                        </Pdf>)
                                        :
                                        <p>Não é possível pré-visualizar o arquivo selecionado</p>
                        }
                    </Request.AttachmentPreviewActive>
                </Request.AttachmentArea>
                <Request.AttachmentListAddAndMessageContainer id="Request.AttachmentListAddAndMessageContainer">
                    <Request.AttachmentListAddAndMessage id="AttachmentListAddAndMessage">
                        <Request.AttachmentListAddAndMessageBox id="AttachmentListAddAndMessageBox">
                            <TextField
                                id="txt-send-message-request"
                                variant="outlined"
                                value={textMessage}
                                onChange={e => setTextMessage(e.target.value)}
                                onKeyPress={handleKeyDown}
                                placeholder="Digite uma mensagem"
                                disabled={loading}
                            />
                            <CircularProgressWithLabel value={progress} />
                        </Request.AttachmentListAddAndMessageBox>
                        <Request.AttachmentListAddItems id="Request.AttachmentListAddItems">
                            <Request.AttachmentListAdd id="Request_AttachmentListAdd">
                                {
                                    attachs.map((attach) => (
                                        attach.type === "image/jpg"
                                            || attach.type === "image/jpeg"
                                            || attach.type === "image/jpe"
                                            || attach.type === "image/jfif"
                                            || attach.type === "image/png"
                                            || attach.type === "image/bmp"
                                            || attach.type === "image/gif"
                                            ?
                                            (<div key={uuid()} style={{ position: 'relative' }}>
                                                <Button className="btn-item-attachment-adding" style={{ backgroundImage: `url(${attach.url})` }} onClick={() => setActiveAttach(attach)} ></Button>
                                                {
                                                    loading
                                                        ?
                                                        null
                                                        :
                                                        <Button
                                                            onClick={() => handleDeleteAttach(attach.id)}
                                                            className="btn-remove-item-attachment"
                                                        >
                                                            <IoCloseCircleSharp />
                                                        </Button>
                                                }
                                            </div>)
                                            :
                                            attach.type === "application/pdf"
                                                ?
                                                (<div key={uuid()} style={{ position: 'relative' }}>
                                                    <Button className="btn-item-attachment-adding-type-pdf" onClick={() => setActiveAttach(attach)} >
                                                        <img src={ImgPdfFile} />
                                                    </Button>
                                                    {
                                                        loading
                                                            ?
                                                            null
                                                            :
                                                            <Button
                                                                onClick={() => handleDeleteAttach(attach.id)}
                                                                className="btn-remove-item-attachment"
                                                            >
                                                                <IoCloseCircleSharp />
                                                            </Button>
                                                    }
                                                </div>)
                                                :
                                                null
                                    ))
                                }
                            </Request.AttachmentListAdd>
                            {
                                loading
                                    ?
                                    null
                                    :
                                    <div id="btn-add-attachment">
                                        <input
                                            accept=".jpg, jpeg, jpe, .jfif, .png, .bmp, .gif, .pdf, .p7s"
                                            style={{ display: "none" }}
                                            id="icon-button-file"
                                            type="file"
                                            onChange={handleAttachment}
                                        />
                                        <label htmlFor="icon-button-file">
                                            <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="span"
                                                className="btn-add-more-attachments"
                                                onChange={loading ? null : handleAttachment}
                                            >
                                                <GoPlus />
                                                <span className="text-add-more-attachments">Adicionar Arquivos</span>
                                            </IconButton>
                                        </label>
                                    </div>
                            }
                        </Request.AttachmentListAddItems>
                    </Request.AttachmentListAddAndMessage>
                </Request.AttachmentListAddAndMessageContainer>
            </Request.Attachment>
        </Dialog>
    )
}