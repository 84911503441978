import Lottie from 'react-lottie'
import filedrop from './file-drop.json'

export default function FileDragDrop() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: filedrop,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  return (
    <Lottie
      options={defaultOptions}
      style={{ width: '100%', height: 'auto' }}
    />
  )
}