import { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { getObjectJwt } from './../../../services/auth'

export const SessionDiv = styled.div`
    bottom: 10px;
    text-align: center;
    display: block;
    width: 100%;
    color: rgb(113 128 150);

    @media (min-width: 993px){
      margin-top: 150px;
    }

`

export default function SessionCountdown() {
  const expSession = getObjectJwt()?.exp;
  const [countDown, setCountDown] = useState("00:00:00");

  const formatarDuasCasas = (numerico) => {
    return ("00" + numerico).slice(-2);
  }

  useEffect(() => {

    setTimeout(() => {

      if (expSession == null && !expSession)
        return;

      let dataExpSession = new Date(expSession * 1000);
      let dataAgr = new Date(Date.now());
      let dataExpMenosDataAgrEmMinutos = (dataExpSession - dataAgr) / 60000;
      let horas = Math.trunc(dataExpMenosDataAgrEmMinutos / 60);
      let minutos = Math.trunc(dataExpMenosDataAgrEmMinutos) % 60;
      let segundos = Math.trunc((dataExpMenosDataAgrEmMinutos - Math.trunc(dataExpMenosDataAgrEmMinutos)) * 60);

      setCountDown(`${formatarDuasCasas(horas)}:${formatarDuasCasas(minutos)}:${formatarDuasCasas(segundos)}`);
    }, 1000);
  }, [countDown]);

  return (
    <SessionDiv>
      <div>
        <span>Sessão expira em:</span>
      </div>
      <div>
        <span>{countDown}</span>
      </div>
    </SessionDiv>

  )
}