import styled from 'styled-components'

export const NoResults = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .btn-error-page {
        background: var(--red) !important;
        color: var(--white);
        padding: 12px 20px 10px 20px;

        svg {
            font-size: 20px;
            margin: 0 3px 0 0;
        }
    }
`

NoResults.Area = styled.div`
    /* flex: 1 1 auto;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    position: absolute;
    top: 90px;
    height: calc(100% - 110px);
    width: calc(100% - 20px);
    left: 10px;
    align-items: center;
    justify-content: center; */
`

NoResults.Animation = styled.div`
    width: calc(100% - 40px);
    max-width: 650px;
`

NoResults.MessageDefault = styled.p`
    margin: 0 0 25px 0;
    color: var(--primary);
    font-weight: 600;
    font-size: 21px;
`

NoResults.MessageErrorContent = styled.div`
    background: var(--white);
    border-radius: 18px;
    -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    width: calc(100% - 100px);
    max-height: 250px;
    margin: 0 0 24px 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    overflow: hidden;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-copy-error-message {
        position: absolute;
        bottom: -1px;
        right: -1px;
        background: var(--white);
        border: 1px solid #d0d0d0;
        border-top: 3px solid #9a9a9a;
        border-top-left-radius: 5px;
        border-top-right-radius: 0;
        text-transform: capitalize;
        font-weight: 600;

        &[data-active="true"] {
            border-top: 3px solid green;
            color: green;
            background: rgb(0 128 0 / 8%);
        }
    }
`

NoResults.MessageErrorContentMessage = styled.div`
    overflow: auto;
    width: 100%;
    max-height: 100px;
    margin: 30px 30px 50px 30px;
`

NoResults.Message = styled.p`
    span {
        font-weight: 700;
    }
`