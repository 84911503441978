import { useState, useEffect, forwardRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import FilledInput from "@material-ui/core/FilledInput"
import TextField from "@material-ui/core/TextField"
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Skeleton from 'react-loading-skeleton'
import { CreateTasks } from './styles'
import { Requests } from '../../../pages/Requisicoes/styles'
import { GrAttachment, VscFilePdf, RiCloseCircleFill, IoImageOutline } from 'react-icons/all'
import { AiOutlineSend } from 'react-icons/all'
import { capitalize, limitChar } from '../../../functions'

import { Request } from '../DialogSendMessageEditor/styles'
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogWaitingExternalOrgao({
    idRequisicao,
    openDialogWaitingExternalOrgao,
    setOpenDialogWaitingExternalOrgao
}) {
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState({
        htmlValue: "",
        editorState: EditorState.createEmpty()
    })

    const handleClose = (e) => {
        e.preventDefault()
        setOpenDialogWaitingExternalOrgao(false)
    }

    const onEditorStateChange = editorValue => {
        const editorStateInHtml = draftToHtml(
            convertToRaw(editorValue.getCurrentContent())
        )

        setDescription({
            htmlValue: editorStateInHtml,
            editorState: editorValue
        })
    }

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogWaitingExternalOrgao}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-associated-tasks-request-slide-title"
            aria-describedby="dialog-associated-tasks-request-dialog-slide-description"
            fullWidth={'lg'}
            id="dialog-create-tasks"
        >
            <DialogTitle style={{ 'cursor': 'move' }} id="alert-dialog-slide-title">{"Alterar Status Aguardando Órgão Externo"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className="request-description-dialog"><strong>Solicitação: </strong> 210608-000279</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl variant="outlined">
                            <TextField
                                id="outlined-basic-titulo"
                                label="Título"
                                variant="outlined"
                            // error={true}
                            // helperText="Incorrect entry."
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <FormControl variant="outlined">
                            <TextField
                                id="outlined-basic-ticket"
                                label="Ticket"
                                variant="outlined"
                            // error={true}
                            // helperText="Incorrect entry."
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <FormControl variant="outlined">
                            <InputLabel id="select-outlined-label-prioridade">Prioridade</InputLabel>
                            <Select
                                labelId="select-outlined-label-prioridade"
                                id="select-outlined-prioridade"
                                // value={age}
                                // onChange={handleChange}
                                label="Prioridade"
                            >
                                <MenuItem value="">
                                    <em>Selecione uma opção</em>
                                </MenuItem>
                                <MenuItem value={'Baixa'}>Baixa</MenuItem>
                                <MenuItem value={'Média'}>Média</MenuItem>
                                <MenuItem value={'Alta'}>Alta</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <FormControl variant="outlined">
                            <InputLabel id="select-outlined-label-orgao-externo">Órgão Externo</InputLabel>
                            <Select
                                labelId="select-outlined-label-orgao-externo"
                                id="select-outlined-orgao-externo"
                                // value={age}
                                // onChange={handleChange}
                                label="Órgão Externo"
                            >
                                <MenuItem value="">
                                    <em>Selecione uma opção</em>
                                </MenuItem>
                                <MenuItem value={'Baixa'}>Receita Federal</MenuItem>
                                <MenuItem value={'Média'}>Superintendência</MenuItem>
                                <MenuItem value={'Alta'}>Prefeitura</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CreateTasks id="CreateTasks">
                            <Request.SendMessageDialog id="Request_SendMessageDialog">
                                <Request.SendMessageContentDialog id="Request_SendMessageContentDialog">
                                    <Request.SendMessageContainerContentDialog id="Request_SendMessageContainerContentDialog">
                                        <Editor
                                            spellCheck
                                            toolbarHidden={false}
                                            editorState={description.editorState}
                                            onEditorStateChange={onEditorStateChange}
                                            localization={{
                                                locale: 'pt',
                                            }}
                                            toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded'/*, 'emoji'*/, 'image', 'remove', 'history'] }}
                                            readOnly={loading}
                                        />
                                    </Request.SendMessageContainerContentDialog>
                                </Request.SendMessageContentDialog>
                            </Request.SendMessageDialog>
                        </CreateTasks>
                        <div className="attachment-area-dialog-item">
                            <Button className="btn-attachment-item-form"><GrAttachment /> Anexar Arquivos</Button>
                        </div>
                        <CreateTasks.Attachments id="Request_Attachments">
                            <CreateTasks.TitleContainer id="Request_TitleContainer">
                                <CreateTasks.TitleText id="Request_TitleText">Anexos Adicionados</CreateTasks.TitleText>
                            </CreateTasks.TitleContainer>
                            <CreateTasks.AttachmentContainer id="Request_AttachmentContainer">
                                <CreateTasks.AttachmentContent id="Request_AttachmentContainer">
                                    <CreateTasks.AttachmentItem id="Request_AttachmentItemPdf">
                                        <CreateTasks.AttachmentItemImage onClick={() => ('')} id="Request_AttachmentItemImage"><VscFilePdf /></CreateTasks.AttachmentItemImage>
                                        <CreateTasks.AttachmentItemText onClick={() => ('')} id="Request_AttachmentItemText">{limitChar(100, 'arquivo.pdf')}</CreateTasks.AttachmentItemText>
                                        <Button className="Request_AttachmentItemClose" onClick={() => ('attach')}><RiCloseCircleFill /></Button>
                                    </CreateTasks.AttachmentItem>
                                    <CreateTasks.AttachmentItem id="Request_AttachmentItemImg">
                                        <CreateTasks.AttachmentItemImage onClick={() => ('attach')} id="Request_AttachmentItemImage"><IoImageOutline /></CreateTasks.AttachmentItemImage>
                                        <CreateTasks.AttachmentItemText onClick={() => ('attach')} id="Request_AttachmentItemText">{limitChar(100, 'arquivo.png')}</CreateTasks.AttachmentItemText>
                                        <Button className="Request_AttachmentItemClose" onClick={() => ('attach')}><RiCloseCircleFill /></Button>
                                    </CreateTasks.AttachmentItem>
                                </CreateTasks.AttachmentContent>
                            </CreateTasks.AttachmentContainer>
                        </CreateTasks.Attachments>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                <Button className="btn-primary-actions">Criar Tarefa e Alterar Status</Button>
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}