import { useState, useEffect, forwardRef, useRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from "@material-ui/core/InputLabel"

import FormControl from "@material-ui/core/FormControl"
import DateFnsUtils from "@date-io/date-fns"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers"
import ptBRLocale from "date-fns/locale/pt-BR"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { DialogAusencia } from './styles'
import api from '../../../services/api'
import TextField from "@material-ui/core/TextField"
import { Autocomplete, Chip, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid'
import { setRef, Grid } from '@material-ui/core'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogAusenciaConfirm({
    openDialogAusencia,
    setOpenDialogAusencia,
    idFuncionario,
    setAusenciaConfirmation,
    alterarAusenciaSelecionada,
    setAlterarAusenciaSelecionada
}) {
    const [dataInicioError, setDataInicioError] = useState(false);
    const [dataInicio, setDataInicio] = useState(undefined);
    const [dataFim, setDataFim] = useState(undefined);
    const [justificativa, setJustificativa] = useState('');
    const [loading, setLoading] = useState(false);
    const [limpar, setLimpar] = useState(false);
    const [tipo, setTipo] = useState(1);

    const handleClose = (e) => {
        e.preventDefault()

        if (loading) return;

        setOpenDialogAusencia(false);
        setAlterarAusenciaSelecionada(undefined);
    }

    const handleConfirm = async (e) => {
        e?.preventDefault();

        let ausencia = {
            tipoAusenciaFerias: tipo,
            usuarioId: idFuncionario,
            dataInicio: undefined,
            dataFim: undefined,
            id: alterarAusenciaSelecionada ? alterarAusenciaSelecionada.id : undefined,
            justificativa
        };

        if (!dataInicio || isNaN(dataInicio.getDate())) {
            setDataInicioError(true);
            return;
        } else {
            ausencia.dataInicio = dataInicio.toDateString();
        }

        if (dataFim && !isNaN(dataFim.getDate())) {
            ausencia.dataFim = dataFim.toDateString();
        }

        setLoading(true);

        setAusenciaConfirmation(ausencia);
    }

    const mapeieCamposQuandoAlteracao = () => {

        if (!alterarAusenciaSelecionada) return;

        let tipo = alterarAusenciaSelecionada.descricao == 'Férias' ? 1 : 2;

        setTipo(tipo);
        setDataInicio(new Date(alterarAusenciaSelecionada.dataInicio));

        if (alterarAusenciaSelecionada.justificativa)
            setJustificativa(alterarAusenciaSelecionada.justificativa);

        if (alterarAusenciaSelecionada.dataFim)
            setDataFim(new Date(alterarAusenciaSelecionada.dataFim));
    }

    useEffect(() => {
        setDataFim(undefined);
        setDataInicio(undefined);
        setJustificativa('');
        if (openDialogAusencia) {
            setLimpar(!limpar)
        } else {
            setLoading(false);
        }

        mapeieCamposQuandoAlteracao();
    }, [openDialogAusencia])

    useEffect(() => {
        if (!alterarAusenciaSelecionada)
            return;

        setOpenDialogAusencia(true);

    }, [alterarAusenciaSelecionada])

    return (
        <Draggable        
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogAusencia}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-ausencia"
        >
            <DialogTitle id="alert-dialog-slide-title">{alterarAusenciaSelecionada ? "Alterar ausência/férias" : "Cadastrar ausência/férias"}</DialogTitle>
            <DialogContent>
                <DialogAusencia id="DialogAusencia">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl variant="outlined">
                                <InputLabel id="select-outlined-label-Tipo">Tipo</InputLabel>
                                <Select
                                    labelId="select-outlined-label-tipo"
                                    id="select-outlined-tipo"
                                    value={tipo}
                                    name="tipoId"
                                    onChange={e => setTipo(e.target.value)}
                                    label="Tipo"
                                >
                                    <MenuItem key={1} value={1}>Férias</MenuItem>
                                    <MenuItem key={2} value={2}>Ausência</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} gap={20}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    invalidDateMessage="Data em formato inválido."
                                    margin="normal"
                                    id="datapicker-data-inicial"
                                    label="Data inicial"
                                    minDate={"01/01/2000"}
                                    maxDate={"01/01/2100"}
                                    format="dd/MM/yyyy"
                                    value={dataInicio ? dataInicio : null}
                                    onChange={(date) => { setDataInicio(date); setDataInicioError(false); }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    okLabel="Selecionar"
                                    cancelLabel="Cancelar"
                                    inputVariant="outlined"
                                />

                                <FormHelperText error={true}>{dataInicioError ? "Data início deve ser informada" : ""}</FormHelperText>
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    invalidDateMessage="Data em formato inválido."
                                    margin="normal"
                                    id="datapicker-data-fim"
                                    label="Data fim"
                                    minDate={"01/01/2000"}
                                    maxDate={"01/01/2100"}
                                    format="dd/MM/yyyy"
                                    value={dataFim ? dataFim : null}
                                    onChange={(date) => { setDataFim(date); }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    okLabel="Selecionar"
                                    cancelLabel="Cancelar"
                                    inputVariant="outlined"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                id="outlined-basic-titulo"
                                inputProps={{ maxLength: 1000 }}
                                multiline
                                rows={4}
                                label="Justificativa"
                                variant="outlined"
                                name="titulo"
                                value={justificativa}
                                onChange={e => { setJustificativa(e.target.value); }}
                            />
                        </Grid>
                    </Grid>
                </DialogAusencia>
            </DialogContent>
            <DialogActions>
                <DialogAusencia.Actions id="DialogAusencia.Actions">
                    <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
                        <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                    </div>
                    {
                        loading
                            ?
                            <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                            :
                            <Button className="btn-primary-actions" onClick={handleConfirm}> {alterarAusenciaSelecionada ? 'Alterar' : 'Cadastrar'}</Button>
                    }

                </DialogAusencia.Actions>
            </DialogActions>
        </Dialog >
        </Draggable>
    )
}