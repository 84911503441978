import styled from 'styled-components'

export const Reopen = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
`

Reopen.Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
`

Reopen.Select = styled.div`
    width: calc(100% - 105px);

    span.react-loading-skeleton {
        height: 56px;
        width: 100%;
    }
`

Reopen.Button = styled.div`
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-submit-subject {
        background: var(--primary);
        background: -moz-linear-gradient( 330deg,var(--primary) 11%,var(--secondary) 100%);
        background: -webkit-linear-gradient( 330deg,var(--primary) 11%,var(--secondary) 100%);
        background: linear-gradient(330deg,var(--primary) 11%,var(--secondary) 100%);
        color: var(--white);
        text-transform: capitalize;
        height: 56px;
        width: 90px;
    }

    span.react-loading-skeleton {
        height: 56px;
        width: 100px;
    }
`

Reopen.Actions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary-actions {
        padding: 6px 20px !important;
        color: var(--white) !important;
    }
`