import styled from 'styled-components'

export const Login = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: absolute;
`

Login.ImgLogoMob = styled.img`
    max-width: 194px;
    width: 100%;

    @media (min-width: 901px) {
        display: none;
    }
`

Login.LogoImgJucerja = styled.img`
    position: absolute;
    top: 40px;
    left: 40px;
`

Login.Apresentation = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background: var(--primary);
    background: -moz-linear-gradient(9deg, var(--quarterly) 0%, var(--secondary) 100%);
    background: -webkit-linear-gradient(9deg, var(--quarterly) 0%, var(--secondary) 100%);
    background: linear-gradient(9deg, var(--quarterly) 0%, var(--secondary) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#D8E4FF",endColorstr="#9ecaec",GradientType=1);

    @media (max-width: 900px) {
        display: none !important;
    }
`

Login.ApresentationTitle = styled.p`
    font-size: 25px;
    color: var(--white);
    font-family: var(--font-regular);
    padding: 0 60px;
    
    span {
        color: var(--secondary);
    }
`

Login.ApresentationImage = styled.img`
    width: calc(100% - 22px);
    max-width: 474px;
    height: auto;
`

Login.Form = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--white);

    @media (max-width: 900px) {
        width: 100%;
        justify-content: flex-start;
        margin: 25px 0px 0 0;
    }
`

Login.Logo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 380px;

    @media (max-width: 900px) {
        width: calc(100% - 40px);
        max-width: 400px;
    }
`

Login.LogoImage = styled.img`
    width: auto;
    height: 67px;
`

Login.LogoTitle = styled.p`
    font-size: 35px;
    color: var(--primary);
    margin: 0 0 0 12px;
`

Login.FormContent = styled.div`
    width: 100%;
    max-width: 380px;

    @media (max-width: 900px) {
        width: calc(100% - 40px);
        max-width: 400px;
    }
`

Login.FormContentTitle = styled.p`
    font-size: 45px;
    font-family: var(--font-regular);
    font-weight: 600;
    margin: 2rem 0 0 0;
    position: relative;
    left: -4px;
`

Login.FormContentDescription = styled.p`
    font-size: 18px;
    font-family: var(--font-regular);
    margin: 0 0 3rem 2px;
    position: relative;
    left: -4px;
`

Login.FormInputs = styled.div`
    button.btnLoginUser {
        width: 100% !important;
        position: relative;
        left: -1px !important;
    }
    
    .MuiFormControl-root {
        width: 100%;
    }

    span.MuiTypography-root.MuiFormControlLabel-label {
        font-family: var(--font-regular) !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnDefault.btnDefaultPrimary {
        width: 150px;
        height: 48px;
        position: relative;
        left: 7px;
    }

    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
        margin-bottom: 10px;
    }

    .MuiGrid-root.leftContentLogin {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .MuiGrid-root.rightContentLogin {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .MuiGrid-root.rightContentLogin a {
        padding: 0 8px 0 0;
    }

    @media (max-width: 959px) {
        .MuiGrid-root.rightContentLogin {
            justify-content: flex-start;
        }

        .MuiGrid-root.rightContentLogin a {
            padding: 0 0 0 7px;
            margin: 0 0 30px 0;
        }
    }
`

Login.Copyright = styled.div`
    font-family: var(--font-regular);
    font-size: 12px;
    margin: 40px 0 0 0;
    color: var(--alternative3);
`

Login.Errors = styled.div`
    background-color: #ffd9d9;
    border-left: 4px solid #e04e4e;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px 8px;
    margin: -25px 0 24px 0;
`

Login.ErrorTitle = styled.div`
    font-weight: 700;
    color: red;
    width: 100%;
    margin: 0 0 10px 0;
`

Login.ErrorItem = styled.div`
    width: 100%;
`