import styled from 'styled-components'

export const ConfiguracaoUsuarioComponente = styled.div`

.btn-arrow-back {
    z-index: 15;
    min-width: 0;
    width: 42px;
    height: 42px;
    align-items: center;
    justify-content: center;
    background: #bdbdbd4f;
    color: #383838;
    border-radius: 100%;
    padding: 0;
    font-size: 20px;
    margin: 0 15px 0 0;
}
`
ConfiguracaoUsuarioComponente.Acoes = styled.div`
  margin-top:20px;

  .spaceButton{
    margin-right: 20px;
  }
`