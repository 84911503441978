import { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FaRegUser, GrConfigure,IoExitOutline } from 'react-icons/all'
import Button from '@material-ui/core/Button'
import Popover from "@material-ui/core/Popover"
import CircularProgress from '@material-ui/core/CircularProgress'
import { UserIcon } from './styles.jsx'
import { userData } from '../../../services/services'
import { logout, obtenhaUsuario } from '../../../services/auth'

export default function UserIconComponent() {
    const [user, setUser] = useState({
        nome: '',
        login: ''
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const [loadingLogOff, setLoadingLogOff] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    const loadUserData = async () => {
        const usuario = obtenhaUsuario();
        setUser({
            nome: usuario.nome,
            login: usuario.login
        });
        // await userData()
        //     .then(response => {
        //         setUser({
        //             name: response.data.name,
        //             email: response.data.email,
        //             rules: response.data.rules
        //         })
        //     })
    }

    const logOff = () => {
        setLoadingLogOff(true)
        logout()
    }

    useEffect(() => {
        loadUserData()
    }, [])

    return (
        <>
            <Button className="btnNavBar btnUserIcons" onClick={handleClick}>
                <FaRegUser />
            </Button>
            <Popover
                id={id}
                className="popover-usericon-info"
                open={open}
                anchorEl={anchorEl}
                onClose={loadingLogOff ? null : handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <UserIcon id="UserIcon">
                    <UserIcon.Account id="UserIcon_Account">
                        <UserIcon.AccountContent id="UserIcon_AccountContent">
                            <UserIcon.AccountIcon id="UserIcon_AccountIcon" className="no-select">{Boolean(user.nome) ? user.nome === '' ? '-' : user.nome.slice(0, 1) : '-'}</UserIcon.AccountIcon>
                            <UserIcon.AccountInfo id="UserIcon_AccountInfo">
                                <UserIcon.AccountName id="UserIcon_AccountName" className="no-select">{Boolean(user.nome) ? user.nome === '' ? '-' : user.nome : '-'}</UserIcon.AccountName>
                                <UserIcon.AccountEmail id="UserIcon_AccountEmail" className="no-select">{Boolean(user.nome) ? user.login === '' ? '-' : user.login : '-'}</UserIcon.AccountEmail>
                            </UserIcon.AccountInfo>
                        </UserIcon.AccountContent>
                        <Link to={`/ConfiguracaoUsuario`}>
                        <Button className="btnLogout btnLogoutLoading no-select"><GrConfigure /> Configurações</Button>
                        </Link>
                        {
                            loadingLogOff
                                ?
                                <Button className="btnLogout btnLogoutLoading no-select"><CircularProgress size={19} /> <span id="text-logout-user">Saindo...</span></Button>
                                :
                                <Button className="btnLogout no-select" onClick={() => logOff()}><IoExitOutline /> Sair</Button>
                        }
                    </UserIcon.Account>
                </UserIcon>
            </Popover>
        </>
    )
}