import { useState, useEffect, forwardRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { ChangeSubject } from './styles'
import api from '../../../services/api'
import { v4 as uuid } from 'uuid'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogChangeSubject({
    idRequisicao,
    openDialogAssunto,
    setOpenDialogAssunto,
    getMensagens
}) {
    const { enqueueSnackbar } = useSnackbar()
    const [assunto, setAssunto] = useState('')
    const [listaAssuntos, setListaAssuntos] = useState([])
    const [loadingAssunto, setLoadingAssunto] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const handleChange = (event) => {
        setAssunto(event.target.value)
    }

    const handleClose = (e) => {
        e.preventDefault()
        setOpenDialogAssunto(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoadingSubmit(true)
        await api.put(`/jucerja/v1/requisicoes/${idRequisicao}/assunto/${assunto}`)
            .then(response => {
                setLoadingSubmit(false)
                enqueueSnackbar('Assunto alterado com sucesso', { variant: 'success' })
                getMensagens(false)
                setOpenDialogAssunto(false)
            })
            .catch(error => {
                setLoadingSubmit(false)
                if (error.response.status === 422) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            })
    }

    const getAssuntos = async () => {
        setLoadingAssunto(true)
        await api.get('/jucerja/v1/assuntos')
            .then(response => {
                setListaAssuntos(response.data)
                setLoadingAssunto(false)
            })
    }

    useEffect(() => {
        if (openDialogAssunto) {
            getAssuntos()
        }
    }, [openDialogAssunto])

    return (
        <Draggable        
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogAssunto}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-change-subject"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">{"Alterar Assunto"}</DialogTitle>
            <DialogContent>
                <ChangeSubject id="ChangeSubject">
                    <ChangeSubject.Container id="ChangeSubject_Container">
                        {
                            loadingAssunto
                                ?
                                <Skeleton />
                                :
                                <FormControl variant="outlined">
                                    <InputLabel id="select-subject-label">Assunto</InputLabel>
                                    <Select
                                        labelId="select-subject-label-label"
                                        id="select-subject-label"
                                        value={assunto}
                                        onChange={handleChange}
                                        label="Assunto"
                                    >
                                        <MenuItem value="">
                                            <em>Escolha uma opção</em>
                                        </MenuItem>
                                        {listaAssuntos.map(subject => (
                                            <MenuItem value={subject.id} key={uuid()}>{subject.descricao}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                        }
                    </ChangeSubject.Container>
                </ChangeSubject>
            </DialogContent>
            <DialogActions>
                <ChangeSubject.Actions id="ChangeSubject.Actions">
                    <Button onClick={handleClose} className="btn-close-actions">Fechar</Button>
                    {
                        loadingAssunto
                            ?
                            <Skeleton />
                            :
                            loadingSubmit
                                ?
                                <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                                :
                                <Button className="btn-primary-actions" onClick={handleSubmit}>Salvar</Button>
                    }

                </ChangeSubject.Actions>
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}