import { RiDeleteBin2Fill } from 'react-icons/all'
import Fab from '@mui/material/Fab';
import Badge from '@mui/material/Badge';
import { useDispatch } from 'react-redux'
import { removeMessage } from '../../../slices/requestMessageSlice';

export default function MessageDelete({
    mensagem,
    ehAnexo
}) {
    const dispatch = useDispatch();
    const removerMensagem = () => dispatch(removeMessage(mensagem.id))

    return (
        mensagem.memoria && (!ehAnexo || mensagem.mensagem.length <= 0) ?
            <Badge
                style={{ margin: '-35px', position: 'absolute' }}
                badgeContent={mensagem?.countDown}
                max={999}
                color="info">
                <Fab size="small" aria-label="add" onClick={removerMensagem}>
                    <RiDeleteBin2Fill />
                </Fab>
            </Badge>
            :
            <></>
    )
}