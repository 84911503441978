import styled from 'styled-components'

export const SendEmailContact = styled.div`

`

SendEmailContact.Action = styled.div`

`

SendEmailContact.Editor = styled.div`
    div#Request_SendMessageContentDialog {
        border: 1px solid #c4c4c4;
    }

    .rdw-editor-toolbar {
        border-bottom: 1px solid #c4c4c4 !important;
    }
`

SendEmailContact.Form = styled.div`
`

SendEmailContact.Actions = styled.div`

`

SendEmailContact.Attachments = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`

SendEmailContact.AttachmentContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0 15px 0;
`

SendEmailContact.AttachmentContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    max-height: 121px;
    overflow: auto;

    div#Request_AttachmentItemPdf {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--red);
        background: var(--white);
        color: var(--primary);
        margin: 0 10px 15px 0;
        border-radius: 100px;
        padding: 5px 10px;
        transition: all ease .35s;
        cursor: pointer;

        div#Request_AttachmentItemImage svg {
            color: var(--red);
        }

        &:hover {
            background: var(--red);
            transition: all ease .35s;

            div#Request_AttachmentItemImage svg {
                color: var(--white);
            }

            div#Request_AttachmentItemText {
                color: var(--white);
            }

            button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose svg {
                color: var(--white);
            }
        }
    }

    div#Request_AttachmentItemImg {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--yellow);
        background: var(--white);
        color: var(--yellow);
        margin: 0 10px 15px 0;
        border-radius: 100px;
        padding: 5px 10px;
        transition: all ease .35s;
        cursor: pointer;

        &:hover {
            background: var(--yellow);
            transition: all ease .35s;

            div#Request_AttachmentItemImage svg {
                color: var(--white);
            }

            div#Request_AttachmentItemText {
                color: var(--white);
            }

            button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose svg {
                color: var(--white);
            }
        }	
    }
`

SendEmailContact.AttachmentItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary);
    background: var(--white);
    color: var(--primary);
    margin: 0 15px 15px 0;
    border-radius: 100px;
    padding: 5px 10px;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose {
        padding: 0;
        min-width: 0;
        border-radius: 100%;
        font-size: 18px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose svg {
        color: var(--lightgrey);
    }
`

SendEmailContact.AttachmentItemImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

SendEmailContact.AttachmentItemText = styled.div`
    margin: 0 10px;
    color: var(--grey);
    font-size: 10px;
    -webkit-user-select: none; 
    -moz-user-select: none;     
    -ms-user-select: none;      
    user-select: none;
`

SendEmailContact.TitleContainer = styled.div`
    width: 100%;
`

SendEmailContact.TitleText = styled.p`
    margin: 0;
    padding: 15px 15px 0px 0px;
    font-weight: 600;
    color: var(--alternative3);
`   

export const ListChip = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px 0 0 0;
`

export const ChipItem = styled.button`
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background: ${(props) => (props.checked ? "var(--primary)" : "transparent")};
    color: ${(props) => (props.checked ? "white" : "var(--primary)")};
    transition: all ease 0.35s;
    cursor: pointer;
    :hover {
        background: var(--primary500);
        color: white;
        border-color: var(--primary500);
    }
`

ChipItem.Content = styled.div`
    display: flex;
    flex-direction: column;
    width: max-content;
    align-items: flex-start;
    justify-content: center;
    padding: 3px 8px;
`

ChipItem.Name = styled.div`
    font-size: 13px;
`

ChipItem.Size = styled.div`
    font-size: 10px;
`

ChipItem.Icon = styled.div`
    font-size: 21px;
    margin: 0 1px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
`