import { forwardRef, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Select from "@material-ui/core/Select"
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from 'notistack'
import { Reopen } from './styles'
import api from '../../../services/api'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogReopen({
    idRequisicao,
    openDialogReopen,
    setOpenDialogReopen
}) {
    const { enqueueSnackbar } = useSnackbar()
    const [loadingReabrir, setLoadingReabrir] = useState(false);
    const [motivosReabertura, setMotivosReabertura] = useState([])
    const [codigoMotivoReabertura, setCodigoMotivoReabertura] = useState("")
    const [erroMotivoReabertura, setErroMotivoReabertura] = useState('')

    const handleClose = (e) => {
        e?.preventDefault()
        setOpenDialogReopen(false)
    }

    const handleReabrir = () => {
        setLoadingReabrir(true);
        setErroMotivoReabertura('')
        api.put(`/jucerja/v1/requisicoes/${idRequisicao}/reabrir/${codigoMotivoReabertura}`)
            .then(response => {
                handleClose()

            })
            .catch(error => {
                const erroRetorno = error?.request?.response;

                if (erroRetorno) {
                    const erroConvertido = JSON.parse(erroRetorno);
                    const message = erroConvertido?.messages[0];

                    if (message && !message.campos)
                        enqueueSnackbar(message.mensagem, { variant: 'error' })

                }

                if (codigoMotivoReabertura === '') {
                    setErroMotivoReabertura("Deve informar um motivo")
                }

            })
            .finally(() => setLoadingReabrir(false))

    }

    useEffect(() => {
        if (!openDialogReopen)
            return;

        setCodigoMotivoReabertura('')
        setLoadingReabrir(false)

        api.get(`/jucerja/v1/motivoreabertura`)
            .then(response => {
                setMotivosReabertura(response.data)

            })
            .catch(error => {

            })

    }, [openDialogReopen])

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogReopen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-change-subject"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">Reabrir</DialogTitle>
            <DialogContent>
                <Reopen id="ChangeSubject">
                    <Reopen.Container id="ChangeSubject_Container">
                        <FormControl variant="outlined">
                            <InputLabel id="select-subject-label">Reabrir</InputLabel>
                            <Select
                                labelId="select-subject-label-label"
                                id="select-subject-label"
                                value={codigoMotivoReabertura}
                                onChange={(e) => setCodigoMotivoReabertura(e.target.value)}
                                label="Motivos Reabertura"
                                error={erroMotivoReabertura}
                            >
                                <MenuItem value="">
                                    <em>Escolha uma opção</em>
                                </MenuItem>
                                {
                                    motivosReabertura.map(x => (
                                        <MenuItem value={x.id} key={x.id}>{x.descricao}</MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{erroMotivoReabertura}</FormHelperText>
                        </FormControl>
                    </Reopen.Container>
                </Reopen>
            </DialogContent>
            <DialogActions>
                <Reopen.Actions>
                    <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                    {
                        loadingReabrir
                            ?
                            <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                            :
                            <Button className="btn-primary-actions" onClick={handleReabrir}>Reabrir</Button>
                    }
                </Reopen.Actions>
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}