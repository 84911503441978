import styled from 'styled-components'

export const AnexoRequest = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
flex-direction: row;

.btn{
    min-width: 0px;
    padding: 6px;
    font-size: 18px;
    border-radius: 100%;
    border: 1px solid;
    margin-right:10px;
    border-color: var(--dark-blue) !important;
    color: var(--dark-blue) !important;
}

.btn:hover{
    border-color: #e5a900 !important;
    color: #e5a900 !important;
}

`
