import Container from '../../components/layout/Container'
import Grid from '@material-ui/core/Grid'
import { useEffect, useRef, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../../components/elements-ui/Card"
import { Badge } from "../../components/elements-ui/Badge"
import { ActionTable } from "../../components/elements-ui/ActionTable"
import { useSnackbar } from 'notistack'
import { Button, Checkbox } from '@material-ui/core'
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from 'react-icons/all'
import { HiOutlineArrowsExpand, TiEquals } from 'react-icons/all'
import { Requests } from './styles'
import { Link } from 'react-router-dom'
import DialogConfirmation from '../../components/elements-ui/DialogConfirmation'
import api from '../../services/api'
import FilterRequests from '../../components/elements-ui/FilterRequests'

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
            display: "none"
        }
    }
}));


export default function Equalizar() {
    const { enqueueSnackbar } = useSnackbar()
    const [assuntos, setAssuntos] = useState([])
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(undefined);
    const [rowCountState, setRowCountState] = useState(rowCount || 0);
    const [selectionModel, setSelectionModel] = useState([]);
    const [rowsState, setRowsState] = useState({
        page: 1,
        pageSize: 50,
    });

    const [openEqualizarConfirm, setOpenEqualizarConfirm] = useState(false);
    const [equalizarConfirm, setEqualizarConfirm] = useState(false);

    const getAssuntos = async () => {
        setLoading(true);
        setRowCount(undefined);
        await api.get('/jucerja/v1/Assuntos')
            .then(response => {
                setAssuntos(response.data);
                setRowCount(response.data.length);
            })
            .catch(error => {
                enqueueSnackbar('Não foi possível carregar os assuntos.', { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const columns = [
        {
            field: 'descricao',
            headerName: 'Assunto',
            width: 200,
            flex: true
        },
        {
            "field": "excluido", "headerName": "Ativo", "width": 165, "editable": false,
            renderCell: (params) => {
                return <Badge type={!params.value} className="no-select">{params.value ? 'Não' : 'Sim'}</Badge>
            }
        },
        {
            "field": "habilitado", "headerName": "Habilitado", "width": 169, "editable": false,
            renderCell: (params) => {
                return <Badge type={params.value} className="no-select">{params.value ? 'Sim' : 'Não'}</Badge>
            }
        }
    ]


    useEffect(() => {
        if (rowsState.page === 0) {

            setRowsState(prev => ({ ...prev, page: 1 }));
            return;
        }

        getAssuntos();

    }, [rowsState.page])


    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    useEffect(async () => {
        if (!equalizarConfirm)
            return;

        await api.put(`/jucerja/v1/requisicoes/equalizar-solicitacoes`, { assuntos: selectionModel })
            .then(response => {
                debugger;
                let resultado = response.data;
                let inconsistencia = false;

                resultado.forEach(data => {
                    if (!inconsistencia)
                        inconsistencia = data.redistribuicoesFalha.lenght > 0;
                });

                if (inconsistencia) {
                    enqueueSnackbar('Assunto(s) equalizado(s) parcialmente', { variant: 'Warning' });
                }
                else
                    enqueueSnackbar('Assunto(s) equalizado(s) com sucesso!', { variant: 'success' });


                setSelectionModel([]);
            })
            .catch(error => {
                if (error.response.status == 404) {
                    enqueueSnackbar('Não há atendente(s) no(s) assunto(s) selecionado(s).', { variant: 'info' });
                    setSelectionModel([]);
                } else
                    enqueueSnackbar('Não foi possível equalizar os assunto(s) selecionado(s).', { variant: 'error' });
            })
            .finally(() => { setOpenEqualizarConfirm(false); });

        setEqualizarConfirm(false);

    }, [equalizarConfirm])

    return (
        <Container title="Equalizar Assuntos">
            <DialogConfirmation
                openDialog={openEqualizarConfirm}
                setOpenDialog={setOpenEqualizarConfirm}
                setConfirm={setEqualizarConfirm}
                txtConfirm="Sim"
                txtCancel="Não"
                txtTitle="Confirmar Equalização?"
                txtContainer={`Deseja realmente equalizar os assuntos selecionados?`}
                closeControlOutsideOnConfirm={true}
            />

            <Requests.Action id="Requests_Action">
                <Button onClick={() => setOpenEqualizarConfirm(true)} style={{ float: 'right' }} className="btnFilter" disabled={selectionModel.length <= 0}><TiEquals id="icon-link-nav" />Equalizar</Button>

            </Requests.Action>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                        <Card.Content>
                            <div style={{ height: 700, width: '100%' }}>
                                <DataGrid
                                    // className={classes.root}
                                    getRowId={row => row.id}
                                    rowCount={rowCountState}
                                    rows={assuntos}
                                    columns={columns}
                                    disableColumnMenu
                                    hideFooterPagination={loading}
                                    loading={loading}
                                    rowsPerPageOptions={[50]}
                                    pageSize={50}
                                    page={rowsState.page > 0 ? rowsState.page - 1 : 0}
                                    pagination
                                    hideFooterSelectedRowCount
                                    disableExtendRowFullWidth={false}
                                    paginationMode="server"
                                    onPageChange={(page) => {
                                        setRowsState((prev) => ({ ...prev, page: page + 1 }));
                                        setSelectionModel([]);
                                    }}
                                    onSelectionModelChange={(newSelectionModel) => {
                                        let listaSelecao = []

                                        newSelectionModel.forEach(element => {
                                            listaSelecao.push({ page: rowsState.page, valor: element });
                                        });

                                        setSelectionModel(listaSelecao.filter(x => x.page === rowsState.page).map(x => x.valor));
                                    }}
                                    selectionModel={selectionModel}
                                    checkboxSelection
                                    localeText={ptBR.props.MuiDataGrid.localeText}
                                />

                            </div>
                        </Card.Content>
                    </Card>
                </Grid>
            </Grid>
        </Container >
    )
}