import { useState, useRef, useEffect } from 'react'
import { useSnackbar } from 'notistack'

import { Task } from './styles'
import { v4 as uuid } from 'uuid'

import ContainerTask from "../../../components/layout/ContainerTask"
import DialogDetailTask from '../../../components/elements-ui/DialogDetailTask'
import PreviewFileImage from '../../../components/elements-ui/PreviewFileImage'
import HistoryPrintTask from '../../../components/elements-ui/HistoryPrintTask'
import DialogSendEmailContactTask from '../../../components/elements-ui/DialogSendEmailContactTask'
import DialogAttachMessageTask from '../../../components/elements-ui/DialogAttachMessageTask'
import DialogSendMessageEditorTask from '../../../components/elements-ui/DialogSendMessageEditorTask'
import TaskMessages from '../../../components/elements-ui/TaskMessages'
import SendMessageBoxTask from '../../../components/elements-ui/SendMessageBoxTask'
import NavBarMessagesTask from '../../../components/elements-ui/NavBarMessagesTask'
import DialogResponsibleChange from '../../../components/elements-ui/DialogResponsibleChange'
import DialogTaskAssociatedRequests from '../../../components/elements-ui/DialogTaskAssociatedRequests'
import DialogReopen from '../../../components/elements-ui/DialogReopen'
import DialogConfirmation from '../../../components/elements-ui/DialogConfirmation'

import api from '../../../services/api'

export default function Tarefa(props) {
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const [loadingSendMessage, setLoadingSendMessage] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [openDialogEditor, setOpenDialogEditor] = useState(false)
    const [openMoreOptions, setOpenMoreOptions] = useState(false)
    const [openDialogDetailRequest, setOpenDialogDetailRequest] = useState(false)
    const [openDialogSendEmailContactTask, setOpenDialogSendEmailContactTask] = useState(false)
    const anchorRef = useRef(null)
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    })
    const [value, setValue] = useState(0)
    const [openDialogAttachment, setOpenDialogAttachment] = useState(false)

    const [attachs, setAttachs] = useState([])
    const [activeAttach, setActiveAttach] = useState(null)

    const [openDialogPreviewFileImage, setOpenDialogPreviewFileImage] = useState(false)
    const [previewFile, setPreviewFile] = useState({
        urlFile: null,
        openClose: null
    })

    const [mensagens, setMensagens] = useState({
        id: null,
        nomeDemandante: null,
        nome: null,
        email: null,
        telefone: null,
        nire: null,
        cnpj: null,
        cpf: null,
        avaliacao: null,
        acoesPermitidas: [],
        status: [],
        assuntos: [],
        mensagens: [],
        arquivos: [],
        motivosReabertura: []
    })

    const [openDialogResponsibleChange, setOpenDialogResponsibleChange] = useState(false)
    const [openDialogTaskAssociatedRequests, setOpenDialogTaskAssociatedRequests] = useState(false)

    const [textInputSendMessageBox, setTextInputSendMessageBox] = useState('')

    const [openDialogFecharTarefa, setOpenDialogFecharTarefa] = useState(false)
    const [cancelDialogFecharTarefa, setCancelDialogFecharTarefa] = useState(false)
    const [confirmDialogFecharTarefa, setConfirmDialogFecharTarefa] = useState(false)

    const [openDialogCancelarTarefa, setOpenDialogCancelarTarefa] = useState(false)
    const [cancelDialogCancelaTarefa, setCancelDialogCancelarTarefa] = useState(false)
    const [confirmDialogCancelarTarefa, setConfirmDialogCancelarTarefa] = useState(false)

    const [openDialogDemandanteTarefa, setOpenDialogDemandanteTarefa] = useState(false)
    const [cancelDialogDemandanteTarefa, setCancelDialogDemandanteTarefa] = useState(false)
    const [confirmDialogDemandanteTarefa, setConfirmDialogDemandanteTarefa] = useState(false)

    const [openDialogReopen, setOpenDialogReopen] = useState(false)
    const [cancelDialogReopen, setCancelDialogReopen] = useState(false)
    const [confirmDialogReopen, setConfirmDialogReopen] = useState(false)

    const handleOpenDialogDetailTask = () => {
        setOpenDialogDetailRequest(true)
    }

    const handleAttachment = (event, inputMessage) => {
        Array.from(event.target.files).map((file, index) => {
            if (Boolean(file)) {
                if (Number(file.size) <= 10000000) {
                    if (
                        file.type === "application/pdf"
                        || file.type === "image/jpg"
                        || file.type === "image/jpeg"
                        || file.type === "image/jpe"
                        || file.type === "image/jfif"
                        || file.type === "image/png"
                        || file.type === "image/bmp"
                        || file.type === "image/gif"
                        || file.type === "application/pkcs7-signature"
                    ) {
                        const fileReader = new window.FileReader()
                        fileReader.onload = (fileLoad) => {
                            const { result } = fileLoad.target
                            setAttachs((prevState) => [...prevState, {
                                id: uuid(),
                                file: file,
                                name: file.name,
                                type: file.type,
                                url: result
                            }])

                            if (index === 0) {
                                setActiveAttach({
                                    id: uuid(),
                                    file: file,
                                    name: file.name,
                                    type: file.type,
                                    url: result
                                })
                            }
                        }

                        fileReader.readAsDataURL(file)
                        setTextInputSendMessageBox(inputMessage ?? '')
                        handleClickOpenDialogAttachment()
                    }
                    else {
                        enqueueSnackbar('A extensão do arquivo não é suportado', { variant: 'error' })
                    }
                }
                else {
                    enqueueSnackbar('O arquivo não pode ser maior que 10mb', { variant: 'error' })
                }
            }
        })
    }

    const handleDeleteAttach = (id) => {
        let newArrAttachs = []
        attachs.map(attach => {
            if (attach.id !== id) {
                newArrAttachs.push(attach)
            }
        })
        if (newArrAttachs.length === 0) {
            setActiveAttach(null)
            setAttachs([])
        }
        else {
            setActiveAttach(newArrAttachs[0])
            setAttachs(newArrAttachs)
        }
    }

    const clearAttachs = () => {
        setActiveAttach(null)
        setAttachs([])
    }

    const handleClickOpenDialogAttachment = () => {
        setOpenDialogAttachment(true)
    }

    const handleCloseDialogAttachment = () => {
        setOpenDialogAttachment(false)
        clearAttachs()
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            //postMensagem()
        }
    }

    const handleKeyDownAttach = event => {
        if (event.key === 'Enter') {
            handleSubmitAttachMessage()
        }
    }

    const handleSubmitAttachMessage = () => {
        //postMensagem()
        handleCloseDialogAttachment()
    }

    const handleSendMessageTextAttachment = () => {
        handleSendMessageText()
        handleCloseDialogAttachment()
    }

    const handleSendMessageText = () => {
        // setMessages([...messages, {
        //     message: textMessage
        // }])

        // setTextMessage('')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const handleToggle = () => {
        setOpenMoreOptions((prevOpen) => !prevOpen)
    }

    const handleCloseMoreOptions = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpenMoreOptions(false)
    }

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault()
            setOpenMoreOptions(false)
        }
    }

    const prevOpen = useRef(openMoreOptions)

    const handleClickOpen = () => {
        setOpenDialogEditor(true)
    }

    const handlePrintTask = () => {
        window.print()
    }

    const getMensagens = async (onload) => {
        setLoading(onload ? true : false)
        await api.get(`/jucerja/v1/Tarefa/detalhes/interacoes?numeroTarefa=${props.match.params.id}`)
            .then(response => {
                let mensagensFormat = []
                response.data.status.map(status => mensagensFormat.push(Object.assign(status, { tipo: 'status' })))

                response.data.mensagens.map(msg => {
                    let anexosMensagem = []
                    response.data.arquivos.map(anexo => {
                        if (anexo.mensagemId === msg.id) {
                            anexosMensagem.push(Object.assign(
                                anexo,
                                {
                                    tipo: 'mensagens',
                                    ehAnexo: true,
                                    extensao: anexo.nome.substr(anexo.nome.length - 3)
                                }
                            ))
                        }
                    })
                    mensagensFormat.push(
                        Object.assign(
                            msg,
                            {
                                tipo: 'mensagens',
                                anexos: anexosMensagem
                            }
                        )
                    )
                })

                response.data.arquivos.map(anexo => {
                    if (!anexo.mensagemId) {
                        mensagensFormat.push(
                            Object.assign(
                                anexo,
                                {
                                    tipo: 'mensagens',
                                    ehAnexo: true,
                                    extensao: anexo.nome.substr(anexo.nome.length - 3)
                                }
                            )
                        )
                    }
                })

                mensagensFormat.sort((a, b) => {
                    return new Date(a.dataOperacao) - new Date(b.dataOperacao);
                })

                setLoading(false)
                setError(false)
                setErrorMessage('')
                setMensagens(
                    Object.assign(response.data, {
                        mensagens: mensagensFormat,
                    })
                )
            })
            .catch(error => {
                setLoading(false)
                setError(true)
                setErrorMessage(error.toString())
            })
    }

    useEffect(() => {
        if (prevOpen.current === true && openMoreOptions === false) {
            anchorRef.current.focus()
        }
        prevOpen.current = openMoreOptions
    }, [openMoreOptions])

    useEffect(async () => {
        if (!confirmDialogFecharTarefa) return;

        setLoading(true)
        await api.put(`/jucerja/v1/Tarefa/fechamento/atendente?tarefaId=${props.match.params.id}`)
            .finally(() => { setLoading(false); setConfirmDialogFecharTarefa(false); })
            .catch(error => {

                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            });
    }, [confirmDialogFecharTarefa])

    useEffect(async () => {
        if (!confirmDialogCancelarTarefa) return;

        setLoading(true)
        await api.put(`/jucerja/v1/Tarefa/cancelamento/atendente?tarefaId=${props.match.params.id}`)
            .finally(() => { setLoading(false); setConfirmDialogCancelarTarefa(false); })
            .catch(error => {
                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            });
    }, [confirmDialogCancelarTarefa])

    useEffect(async () => {
        if (!confirmDialogDemandanteTarefa) return;

        setLoading(true)
        await api.put(`/jucerja/v1/Tarefa/aguardar-demandante/atendente?tarefaId=${props.match.params.id}`)
            .finally(() => { setLoading(false); setConfirmDialogDemandanteTarefa(false); })
            .catch(error => {

                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            });
    }, [confirmDialogDemandanteTarefa])

    useEffect(async () => {
        if (!confirmDialogReopen) return;

        setLoading(true)
        await api.put(`/jucerja/v1/Tarefa/${props.match.params.id}/reabrir`)
            .finally(() => { setLoading(false); setConfirmDialogReopen(false); })
            .catch(error => {

                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            });
    }, [confirmDialogReopen])

    return (
        <>
            <HistoryPrintTask mensagens={mensagens} />

            <PreviewFileImage openDialogPreviewFileImage={openDialogPreviewFileImage} setpreview={previewFile} />

            <DialogSendEmailContactTask
                openDialogSendEmailContactTask={openDialogSendEmailContactTask}
                setOpenDialogSendEmailContactTask={setOpenDialogSendEmailContactTask}
                idTask={props.match.params.id}
            />

            <DialogAttachMessageTask
                openDialogAttachment={openDialogAttachment}
                setOpenDialogAttachment={setOpenDialogAttachment}
                handleCloseDialogAttachment={handleCloseDialogAttachment}
                activeAttach={activeAttach}
                handleKeyDownAttach={handleKeyDownAttach}
                handleSendMessageTextAttachment={handleSendMessageTextAttachment}
                attachs={attachs}
                setActiveAttach={setActiveAttach}
                handleDeleteAttach={handleDeleteAttach}
                handleAttachment={handleAttachment}
                handleSubmitAttachMessage={handleSubmitAttachMessage}
                textInputSendMessageBox={textInputSendMessageBox}
                idTarefa={props.match.params.id}
            />

            <DialogSendMessageEditorTask
                idTarefa={props.match.params.id}
                openDialogEditor={openDialogEditor}
                setOpenDialogEditor={setOpenDialogEditor}
            />

            <DialogResponsibleChange
                idTarefa={props.match.params.id}
                openDialogResponsibleChange={openDialogResponsibleChange}
                setOpenDialogResponsibleChange={setOpenDialogResponsibleChange}
            />

            <DialogTaskAssociatedRequests
                idTarefa={props.match.params.id}
                openDialogTaskAssociatedRequests={openDialogTaskAssociatedRequests}
                setOpenDialogTaskAssociatedRequests={setOpenDialogTaskAssociatedRequests}
            />

            <DialogConfirmation
                openDialog={openDialogFecharTarefa}
                setCancel={setCancelDialogFecharTarefa}
                setConfirm={setConfirmDialogFecharTarefa}
                txtCancel="Não"
                setOpenDialog={setOpenDialogFecharTarefa}
                txtConfirm="Sim"
                txtTitle="Fechar Tarefa"
                txtContainer={`Confirmar o fechamento da tarefa #${props.match.params.id} ?`}
            />

            <DialogConfirmation
                openDialog={openDialogCancelarTarefa}
                setCancel={setCancelDialogCancelarTarefa}
                setConfirm={setConfirmDialogCancelarTarefa}
                txtCancel="Não"
                setOpenDialog={setOpenDialogCancelarTarefa}
                txtConfirm="Sim"
                txtTitle="Cancelar Tarefa"
                txtContainer={`Confirmar o cancelamento da tarefa #${props.match.params.id} ?`}
            />

            <DialogConfirmation
                openDialog={openDialogDemandanteTarefa}
                setCancel={setCancelDialogDemandanteTarefa}
                setConfirm={setConfirmDialogDemandanteTarefa}
                txtCancel="Não"
                setOpenDialog={setOpenDialogDemandanteTarefa}
                txtConfirm="Sim"
                txtTitle="Aguardar Demandante Tarefa"
                txtContainer={`Confirmar a alteração de status da tarefa #${props.match.params.id} para aguardando demandante ?`}
            />

            <DialogConfirmation
                openDialog={openDialogReopen}
                setCancel={setCancelDialogReopen}
                setConfirm={setConfirmDialogReopen}
                txtCancel="Não"
                setOpenDialog={setOpenDialogReopen}
                txtConfirm="Reabrir"
                txtTitle="Confirmar Ação"
                txtContainer={`Confirmar a reabertura da tarefa #${props.match.params.id} ?`}
            />

            <ContainerTask>
                <Task>

                    <NavBarMessagesTask
                        error={error}
                        loading={loading}
                        mensagens={mensagens}
                        handleOpenDialogDetailTask={handleOpenDialogDetailTask}
                        anchorRef={anchorRef}
                        openMoreOptions={openMoreOptions}
                        setOpenMoreOptions={setOpenMoreOptions}
                        handleToggle={handleToggle}
                        handleCloseMoreOptions={handleCloseMoreOptions}
                        handleListKeyDown={handleListKeyDown}
                        handlePrintTask={handlePrintTask}
                        setOpenDialogSendEmailContactTask={setOpenDialogSendEmailContactTask}
                        setLoading={setLoading}
                        setError={setError}
                        setErrorMessage={setErrorMessage}
                        setOpenDialogResponsibleChange={setOpenDialogResponsibleChange}
                        setOpenDialogTaskAssociatedRequests={setOpenDialogTaskAssociatedRequests}
                        setOpenDialogReopen={setOpenDialogReopen}
                        setOpenDialogTaskClose={setOpenDialogFecharTarefa}
                        setOpenDialogCancelarTarefa={setOpenDialogCancelarTarefa}
                        setOpenDialogDemandanteTarefa={setOpenDialogDemandanteTarefa}
                    />

                    <TaskMessages
                        idTarefa={props.match.params.id}
                        getMensagens={getMensagens}
                        error={error}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        loading={loading}
                        mensagens={mensagens}
                        handleAttachment={handleAttachment}
                    />

                    {
                        error
                            ?
                            null
                            :
                            mensagens.acoesPermitidas.map(acao => (
                                acao === "EnviarMensagem" && (
                                    <SendMessageBoxTask
                                        key={uuid()}
                                        idTarefa={props.match.params.id}
                                        mensagens={mensagens}
                                        loading={loading}
                                        handleAttachment={handleAttachment}
                                        handleClickOpen={handleClickOpen}
                                        toggleDrawer={toggleDrawer}
                                        state={state}
                                        value={value}
                                        setValue={setValue}
                                    />
                                )
                            ))
                    }

                </Task>
            </ContainerTask>
        </>
    )
}