import styled from 'styled-components'

export const NotificationCount = styled.div`

`

export const Notification = styled.div`

`

Notification.Content = styled.div`
`

Notification.Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

Notification.TopBox = styled.div`
    background: #f4f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    height: 50px;
    margin: 15px;
    border-radius: 6px;
`

Notification.TopTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
    margin: 0 5px 0 0;
`

Notification.TopCount = styled.div`
    background: var(--red);
    color: var(--white);
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    transform: scale(0.75);
`

Notification.List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 228px;
    overflow: auto;

    a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease .35s;

        &:hover {
            background: rgb(63 81 181 / 4%);
            transition: all ease .35s;
        }
    }
`

Notification.ListItem = styled.div`
    width: 100%;
    padding: 12px 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

Notification.ListItemIcon = styled.div`
    background: rgb(95 99 242 / 8%);
    color: #5F63F2;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin: 0 15px 0 0;
`

Notification.ListItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 47px);
`

Notification.ListItemDescription = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
`

Notification.ListItemDate = styled.div`
    font-size: 12px;
    color: #ADB4D2;
    margin-bottom: 0;
    text-align: left;
`

Notification.Bottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: #1b00ff;
        width: 100%;
        text-align: center;
        padding: 20px 0;
        font-weight: 500;
        font-size: 16px;
        transition: all ease .35s;

        &:hover {
            background: rgb(95 99 242 / 8%);
            transition: all ease .35s;
        }
    }
`

Notification.LinkNoNotification = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 228px;
`

Notification.NoNotificationContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    color: var(--purple);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    svg {
        font-size: 92px;
        color: var(--red);
        margin: -13px 0 9px 0;
    }
`