import React, { useState, useEffect, forwardRef } from "react"
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import FilePreviewer from 'react-file-previewer'
import { BsImageFill } from 'react-icons/bs'
import { PreviewFileImages } from './styles'
import IconImage from '../../../assets/img/icon-image-file-preview.svg'
import { limitChar } from '../../../functions'
import { IoMdDownload } from 'react-icons/io'
import { CircularProgress } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: 'var(--black)'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const PreviewFileImage = React.memo(({
    openDialogPreviewFileImage,
    setOpenDialogPreviewFileImage,
    preview,
    setPreview,
    loading,
    setLoading
})=> {
    const classes = useStyles()

    const handleClose = () => {
        setOpenDialogPreviewFileImage(false)
        setPreview({
            urlFile: null,
            openClose: null
        })
        setLoading(false)
    }

    const downloadFile = () => {
        if (preview.base64) {
            let a = document.createElement("a")
            a.href = preview.url
            a.download = preview.name
            a.click()
        }
        else {
            let file = preview.file
            let fr = new FileReader()
            fr.readAsDataURL(file)
            let blob = new Blob([file])
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = preview.name
            link.click()
        }
    }

    return (
        <Dialog
            id="dialog-fullscreen-visualization-preview-file"
            fullScreen
            open={openDialogPreviewFileImage}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        id="title-name-file-appbar"
                        variant="h6"
                        className={classes.title}
                    >
                        <img src={IconImage} id="icon-image-preview-file" />
                        {preview ? preview.name : null}
                    </Typography>
                    {
                        loading ?
                            <></> :
                            <Button autoFocus className="btn-download-file-previewer" onClick={() => downloadFile()}>
                                <IoMdDownload /> Baixar Arquivo
                            </Button>
                    }

                </Toolbar>
            </AppBar>
            <PreviewFileImages>
                <PreviewFileImages.Content>
                    {
                        loading
                            ?
                            <PreviewFileImages.ContentLoading id="PreviewFileImages_ContentLoading">
                                <CircularProgress size={60} style={{ color: 'var(--white)' }} />
                            </PreviewFileImages.ContentLoading>
                            :
                            preview
                                ?
                                <FilePreviewer
                                    file={{
                                        url: preview.url,
                                        name: "name"
                                    }}
                                />
                                :
                                null
                    }
                </PreviewFileImages.Content>
            </PreviewFileImages>
        </Dialog>
    )
});

export default PreviewFileImage;