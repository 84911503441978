import { useState, useEffect, forwardRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Draggable from 'react-draggable';
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import { useSnackbar } from 'notistack'
import Chip from "@material-ui/core/Chip"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import ChipInput from "material-ui-chip-input"
import _ from "lodash";
import { SendEmailContact, ListChip, ChipItem } from './styles'
import { v4 as uuid } from 'uuid'
import { GrAttachment, VscFilePdf, RiCloseCircleFill, IoImageOutline, MdCheckCircle } from 'react-icons/all'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Request } from '../DialogSendMessageEditor/styles'
import { Editor } from "react-draft-wysiwyg"

import FormHelperText from '@material-ui/core/FormHelperText';
import { EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { limitChar } from '../../../functions'
import api from '../../../services/api'
import Tooltip from '@material-ui/core/Tooltip'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const PrimarySwitch = withStyles({
    switchBase: {
        color: 'var(--primary)',
        '&$checked': {
            color: 'var(--primary)',
        },
        '&$checked + $track': {
            backgroundColor: 'var(--primary)',
        },
    },
    checked: {},
    track: {},
})(Switch)

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        color: 'var(--white)',
    },
}))(Tooltip)

export default function DialogSendEmailContactRequest({ openDialogSendEmailContactRequest, setOpenDialogSendEmailContactRequest, idRequisicao }) {
    const { enqueueSnackbar } = useSnackbar();
    const emailValido = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const [open, setOpen] = useState(false)
    const [dadosEnviarSolicitacao, setDadosEnviarSolicitacao] = useState({
        requisicaoId: 0,
        para: [],
        assunto: '',
        enviarInformacaoContato: false,
        incluirMensagemPrivadas: false,
        comentario: '',
        anexos: []
    });
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loading, setLoading] = useState(false);

    const [emailText, setEmailText] = useState('')

    const [errorAssunto, setErrorAssunto] = useState(false)
    const [errorHelperTextAssunto, setErrorHelperTextAssunto] = useState('')

    const [errorEmailText, setErrorEmailText] = useState(false)
    const [errorHelperTextEmailText, setErrorHelperTextEmailText] = useState('')

    const [errorEditor, setErrorEditor] = useState('');

    const [description, setDescription] = useState({
        htmlValue: "",
        value: "",
        editorState: EditorState.createEmpty()
    })

    const [anexos, setAnexos] = useState([])

    const handleSelected = (nome, checked) => {
        setAnexos((prevState) =>
            anexos.map((anexo) => {
                if (anexo.nome === nome) {
                    anexo.checked = !checked
                }
                return anexo
            })
        )
    }

    const onEditorStateChange = editorValue => {
        const editorStateInHtml = draftToHtml(
            convertToRaw(editorValue.getCurrentContent())
        )

        setDescription({
            htmlValue: editorStateInHtml,
            value: editorValue.getCurrentContent().getPlainText(),
            editorState: editorValue
        })
    }

    const handleClose = (e) => {
        e?.preventDefault()
        setOpen(false)
        setOpenDialogSendEmailContactRequest(false)
    }

    const handleDeleteEmail = (chipEmail) => () => {
        setDadosEnviarSolicitacao(
            { ...dadosEnviarSolicitacao, para: dadosEnviarSolicitacao.para.filter((email) => email !== chipEmail) }
        )
    }

    const handleKeyDownEmail = (event) => {
        setErrorEmailText(false)
        setErrorHelperTextEmailText('')

        let valEmail = _(event.target.value).trim();

        if (event.key === "Enter") {

            if (emailValido.test(valEmail)) {
                if (dadosEnviarSolicitacao.para.filter(x => x === valEmail).length <= 0)
                    setDadosEnviarSolicitacao({ ...dadosEnviarSolicitacao, para: [...dadosEnviarSolicitacao.para, _(event.target.value).trim()] });

                setEmailText('');
            } else {
                setErrorEmailText(true)
                setErrorHelperTextEmailText('O formato do email deve ser válido.')
            }
        }
    }

    const limpaFormulario = () => {
        setEmailText('')
        setErrorAssunto(false)
        setErrorHelperTextAssunto('')
        setErrorEmailText(false)
        setErrorHelperTextEmailText('')
        setErrorEditor('');

        setDadosEnviarSolicitacao({
            requisicaoId: 0,
            para: [],
            assunto: '',
            enviarInformacaoContato: false,
            incluirMensagemPrivadas: false,
            comentario: '',
            anexos: []
        })

        setDescription({
            htmlValue: "",
            value: "",
            editorState: EditorState.createEmpty()
        });
    }

    const validaForm = () => {
        if (dadosEnviarSolicitacao.para.length === 0) {
            setErrorEmailText(true)
            setErrorHelperTextEmailText('Por favor insira ao menos um e-mail válido')
            return false
        }
        else {
            setErrorEmailText(false)
            setErrorHelperTextEmailText('')
        }

        if (dadosEnviarSolicitacao.assunto === "") {
            setErrorAssunto(true)
            setErrorAssunto('Este campo não pode ficar vazio')
            return false
        }
        else {
            setErrorAssunto(false)
            setErrorAssunto('')
        }

        // if (description.value.length <= 0) {
        //     setErrorEditor('Deve ser informado o corpo do email')
        //     return false
        // }

        return (true)
    }

    const handleSubmit = async () => {

        if (!validaForm()) return;

        setLoadingSubmit(true);

        await api.post(`/jucerja/v1/Requisicoes/enviarparacontato`,
            {
                ...dadosEnviarSolicitacao,
                comentario: description.htmlValue,
                anexos: anexos.filter(x => x.checked).map(x => x.id)
            })
            .then(resultado => {
                handleClose();
                enqueueSnackbar("Solicitação enviada para contato.", { variant: 'success' })
            })
            .catch(error => {

                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            })
            .finally(() => {
                setLoadingSubmit(false);

            });


    }

    const carregarInformacoesRequisicao = async () => {
        await api.get(`/jucerja/v1/Requisicoes/enviarparacontato/${idRequisicao}`)
            .then(resultado => {
                let obj = resultado.data;
                setDadosEnviarSolicitacao(
                    {
                        ...dadosEnviarSolicitacao,
                        requisicaoId: obj.requisicaoId,
                        assunto: obj.assunto
                    });

                setAnexos(obj.anexos ?? []);
            })
            .catch(error => {

                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            })
            .finally(() => { setLoading(false); });
    }

    useEffect(() => {
        setOpen(openDialogSendEmailContactRequest ? true : false)
        limpaFormulario()

        if (openDialogSendEmailContactRequest)
            carregarInformacoesRequisicao();

    }, [openDialogSendEmailContactRequest])

    return (
        <Draggable        
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={open}
            TransitionComponent={Transition}

            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-detail-request"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">{"Enviar para Contato"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <HtmlTooltip title="Tecle enter para inserir o email." placement="top-end">
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Para"
                                multiline
                                className="multiline-textfield-with-chips"
                                value={emailText}
                                onChange={e => setEmailText(e.target.value)}
                                onKeyPress={handleKeyDownEmail}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <div id="textfield-with-chips">
                                            {dadosEnviarSolicitacao.para.map((email) => (
                                                <Chip
                                                    key={email}
                                                    label={email}
                                                    onDelete={handleDeleteEmail(email)}
                                                    className="chip-item-textfield-with"
                                                />
                                            ))}
                                        </div>
                                    )
                                }}
                                error={errorEmailText}
                                helperText={errorHelperTextEmailText}
                            />
                        </HtmlTooltip>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Assunto"
                            className="multiline-textfield-with-chips"
                            value={dadosEnviarSolicitacao.assunto}
                            onChange={e => setDadosEnviarSolicitacao({ ...dadosEnviarSolicitacao, assunto: e.target.value })}
                            onKeyPress={handleKeyDownEmail}
                            variant="outlined"
                            error={errorAssunto}
                            helperText={errorHelperTextAssunto}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                            control={<PrimarySwitch checked={dadosEnviarSolicitacao.enviarInformacaoContato} onChange={e => setDadosEnviarSolicitacao({ ...dadosEnviarSolicitacao, enviarInformacaoContato: e.target.checked })} name="enviarInformacoesContato" />}
                            label="Enviar informações do contato"
                        />
                        <FormControlLabel
                            control={<PrimarySwitch checked={dadosEnviarSolicitacao.incluirMensagemPrivadas} onChange={e => setDadosEnviarSolicitacao({ ...dadosEnviarSolicitacao, incluirMensagemPrivadas: e.target.checked })} name="incluirMensagensPrivadas" />}
                            label="Incluir mensagens privadas"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SendEmailContact id="CreateTasks">
                            <Request.SendMessageDialog id="Request_SendMessageDialog">
                                <Request.SendMessageContentDialog id="Request_SendMessageContentDialog">
                                    <Request.SendMessageContainerContentDialog id="Request_SendMessageContainerContentDialog">
                                        <Editor
                                            spellCheck
                                            toolbarHidden={false}
                                            editorState={description.editorState}
                                            onEditorStateChange={onEditorStateChange}
                                            localization={{
                                                locale: 'pt',
                                            }}
                                            toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded'/*, 'emoji'*/, 'image', 'remove', 'history'] }}
                                            readOnly={loading}
                                            error={true}
                                        />
                                    </Request.SendMessageContainerContentDialog>
                                </Request.SendMessageContentDialog>
                                <FormHelperText>{errorEditor}</FormHelperText>
                            </Request.SendMessageDialog>
                        </SendEmailContact>
                        <SendEmailContact.Attachments id="Request_Attachments">
                            <SendEmailContact.TitleContainer id="Request_TitleContainer">
                                <SendEmailContact.TitleText id="Request_TitleText">Anexos Adicionados</SendEmailContact.TitleText>
                            </SendEmailContact.TitleContainer>
                            <ListChip>
                                {anexos.map((anexo, index) => (
                                    <ChipItem
                                        key={uuid()}
                                        checked={anexo.checked ?? false}
                                        name={anexo.nome}
                                        onClick={() => handleSelected(anexo.nome, anexo.checked ?? false)}
                                    >
                                        {anexo.checked && (
                                            <ChipItem.Icon>
                                                <MdCheckCircle />
                                            </ChipItem.Icon>
                                        )}
                                        <ChipItem.Content checked={anexo.checked}>
                                            <ChipItem.Name checked={anexo.checked}>
                                                {anexo.nome}
                                            </ChipItem.Name>
                                            <ChipItem.Size checked={anexo.checked}>
                                                {anexo.tamanho} kb
                                            </ChipItem.Size>
                                        </ChipItem.Content>
                                    </ChipItem>
                                ))}
                            </ListChip>
                        </SendEmailContact.Attachments>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button id={"btn-close-dialog-contact-request"} onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                {
                    loadingSubmit
                        ?
                        <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                        :
                        <Button className="btn-primary-actions" onClick={handleSubmit}>Enviar</Button>
                }
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}