import styled from 'styled-components'

export const DialogAssuntoSetor = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
`

DialogAssuntoSetor.Container = styled.div`
padding:10px 0 0;
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
flex-direction: row;
color: var(--black);
font-weight: bold;
font-size: 18px;
line-height: 18px;
overflow: hidden;
text-align: left;
`

DialogAssuntoSetor.Select = styled.div`
    width: calc(100% - 105px);

    span.react-loading-skeleton {
        height: 56px;
        width: 100%;
    }
`

DialogAssuntoSetor.Button = styled.div`
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-submit-subject {
        background: var(--primary);
        background: -moz-linear-gradient( 330deg,var(--primary) 11%,var(--secondary) 100%);
        background: -webkit-linear-gradient( 330deg,var(--primary) 11%,var(--secondary) 100%);
        background: linear-gradient(330deg,var(--primary) 11%,var(--secondary) 100%);
        color: var(--white);
        text-transform: capitalize;
        height: 56px;
        width: 90px;
    }

    span.react-loading-skeleton {
        height: 56px;
        width: 100px;
    }
`

DialogAssuntoSetor.Actions = styled.div`
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary-actions {
        width: 87px;
        height: 36px;
    }
    .mostrarAnimacao {
        opacity: 1;
      }
      
      .esconderAnimacao {
        opacity: 0;
        transition: opacity 0.6s, visibility 0.3s;
        visibility: hidden;
      }
`