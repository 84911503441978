import { useState, forwardRef, useEffect, useRef } from 'react'
import Container from '../../../components/layout/Container'

import { Badge } from "../../../components/elements-ui/Badge"
import { useSnackbar } from 'notistack'
import FormControl from "@material-ui/core/FormControl"

import { ComponenteFuncionario } from './styles'
import { Button, Grid, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, withStyles } from '@material-ui/core'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import { Card } from "../../../components/elements-ui/Card"
import DialogConfirmation from '../../../components/elements-ui/DialogConfirmation'
import DialogSetorConfirm from '../../../components/elements-ui/DialogSetorConfirm'
import DialogAssuntoConfirm from '../../../components/elements-ui/DialogAssuntoConfirm'
import DialogAusenciaConfirm from '../../../components/elements-ui/DialogAusenciaConfirm'
import api from '../../../services/api'
import { convertISODateToDateView } from '../../../functions/index'
import Tooltip from '@material-ui/core/Tooltip'

import { Link } from 'react-router-dom'

import { BiArrowBack } from 'react-icons/all'

const PrimaryTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
    },
}))(Tooltip);

export default function Funcionario(props) {

    const { enqueueSnackbar } = useSnackbar();

    const idFuncionario = props.match.params.id;
    const [funcionario, setFuncionario] = useState({})
    const [carregarInfoFuncionario, setCarregarInfoFuncionario] = useState(false)

    const [rowSetorSelecionado, setRowSetorSelecionado] = useState(undefined);
    const [abrirMensagemConfirmacaoSetor, setAbrirMensagemConfirmacaoSetor] = useState(false);
    const [confirmacaoSetor, setConfirmacaoSetor] = useState(false);
    const [confirmacaoSetorTwo, setConfirmacaoSetorTwo] = useState(false);
    const [abrirSelecaoSetor, setAbrirSelecaoSetor] = useState(false);
    const [setorAdicionar, setSetorAdicionar] = useState(undefined);

    const [rowAssuntoSelecionado, setRowAssuntoSelecionado] = useState(undefined);
    const [abrirMensagemConfirmacaoAssunto, setAbrirMensagemConfirmacaoAssunto] = useState(false);
    const [confirmacaoAssunto, setConfirmacaoAssunto] = useState(false);
    const [confirmacaoAssuntoTwo, setConfirmacaoAssuntoTwo] = useState(false);
    const [abrirSelecaoAssunto, setAbrirSelecaoAssunto] = useState(false);
    const [assuntoAdicionar, setAssuntoAdicionar] = useState(false);

    const [rowAusenciaSelecionado, setRowAusenciaSelecionado] = useState(undefined);
    const [alterarAusenciaSelecionada, setAlterarAusenciaSelecionada] = useState(undefined);
    const [alterarSetorSelecionada, setAlterarSetorSelecionada] = useState(undefined);
    const [abrirMensagemConfirmacaoAusencia, setAbrirMensagemConfirmacaoAusencia] = useState(false);
    const [confirmacaoAusencia, setConfirmacaoAusencia] = useState(false);
    const [abrirSelecaoAusencia, setAbrirSelecaoAusencia] = useState(false);
    const [ausenciaAdicionar, setAusenciaAdicionar] = useState(undefined);


    const removerSetorFuncionario = (evento) => {
        if (rowSetorSelecionado)
            setAbrirMensagemConfirmacaoSetor(true);
    };

    const removerAssuntoFuncionario = (evento) => {
        if (rowAssuntoSelecionado)
            setAbrirMensagemConfirmacaoAssunto(true);
    };

    const removerAusenciaFuncionario = (evento) => {
        if (rowAusenciaSelecionado)
            setAbrirMensagemConfirmacaoAusencia(true);
    };

    const alterarAusenciaFuncionario = (evento) => {
        setAlterarAusenciaSelecionada(rowAusenciaSelecionado);
    };

    const alterarSetorFuncionario = (evento) => {
        setAlterarSetorSelecionada(rowSetorSelecionado);
    };

    const retireSetorSelecionado = () => {
        const idSetor = rowSetorSelecionado.id;
        let funcionarioAlterado = { ...funcionario };

        funcionarioAlterado.setores = funcionarioAlterado.setores.filter(x => x.id != idSetor);
        funcionarioAlterado.assuntos = funcionarioAlterado.assuntos.filter(x => x.setorId != idSetor);

        setFuncionario(funcionarioAlterado);
        setRowSetorSelecionado(undefined);

        if (rowAssuntoSelecionado && rowAssuntoSelecionado.setorId == idSetor)
            setRowAssuntoSelecionado(undefined);
    }

    const retireAssuntoSelecionada = () => {
        const idAssunto = rowAssuntoSelecionado.id;
        let funcionarioAlterado = { ...funcionario };

        funcionarioAlterado.assuntos = funcionarioAlterado.assuntos.filter(x => x.id != idAssunto);

        setFuncionario(funcionarioAlterado);
        setRowAssuntoSelecionado(undefined);
    }

    const retireAusenciaSelecionada = () => {
        const idAusencia = rowAusenciaSelecionado.id;
        let funcionarioAlterado = { ...funcionario };

        funcionarioAlterado.ausenciasFerias = funcionarioAlterado.ausenciasFerias.filter(x => x.id != idAusencia);

        setFuncionario(funcionarioAlterado);
        setRowAusenciaSelecionado(undefined);
    }

    const obtenhaObjetoPadraoSetor = (idSetor, ehSupervisor = false, respondeTarefa = false) => {
        const objAcaoSetor = {
            funcionarioId: idFuncionario,
            setores: [
                {
                    setor: idSetor,
                    supervisor: ehSupervisor
                }
            ],
            redistribuir: confirmacaoSetorTwo,
            respondeTarefa: respondeTarefa

        };

        return objAcaoSetor;
    }

    useEffect(() => {
        api.get(`/jucerja/v1/funcionario/dados_funcionario/${idFuncionario}`)
            .then(response => {
                setCarregarInfoFuncionario(true);
                setFuncionario(response.data);
            });
    }, [])

    useEffect(() => {
        if (!confirmacaoSetor && !confirmacaoSetorTwo) return;

        api.post(`/jucerja/v1/funcionario/remover-funcionario-setores`, obtenhaObjetoPadraoSetor(rowSetorSelecionado.id))
            .then(response => {
                retireSetorSelecionado();
            })
            .catch(() => {
                enqueueSnackbar("Houve um erro ao remover o setor.", { variant: 'error' });
            })
            .finally(() => {
                setAbrirMensagemConfirmacaoSetor(false);
            });

        setConfirmacaoSetor(false);
        setConfirmacaoSetorTwo(false);

    }, [confirmacaoSetor, confirmacaoSetorTwo])

    useEffect(() => {

        if (!setorAdicionar) return;

        let link = 'cadastro-funcionario-setores';
        let method = 'post'
        let obtenhaObjetoPadrao = obtenhaObjetoPadraoSetor(setorAdicionar.idSetor, setorAdicionar.ehSupervisor, setorAdicionar.respondeTarefa);

        if (alterarSetorSelecionada) {
            link = 'alterar-funcionario-setores';
            method = 'put';
        }

        api[method](`/jucerja/v1/funcionario/${link}`, obtenhaObjetoPadrao)
            .then(response => {
                let listaSetores = [...funcionario.setores];
                let entidade = { id: setorAdicionar.idSetor, supervisor: setorAdicionar.ehSupervisor, descricao: setorAdicionar.descricao, respondeTarefa: setorAdicionar.respondeTarefa };

                listaSetores.push(entidade);

                setFuncionario({ ...funcionario, setores: listaSetores });

                if (alterarSetorSelecionada) {
                    setRowSetorSelecionado(entidade);
                }
            })
            .catch(() => {
                enqueueSnackbar("Houve um erro ao adicionar o setor.", { variant: 'error' });
            })
            .finally(() => {
                setAbrirSelecaoSetor(false);
                setSetorAdicionar(undefined);
                setAlterarSetorSelecionada(undefined);
            });

    }, [setorAdicionar])


    useEffect(() => {

        if (!assuntoAdicionar) return;

        api.post(`/jucerja/v1/funcionario/funcionarioassunto`, { funcionarioId: idFuncionario, assuntos: [assuntoAdicionar.id] })
            .then(response => {
                let listaAssuntos = [...funcionario.assuntos];

                listaAssuntos.push({ ...assuntoAdicionar, relacaoId: response.data.pop().id });

                setFuncionario({ ...funcionario, assuntos: listaAssuntos });
            })
            .catch(() => {
                enqueueSnackbar("Houve um erro ao adicionar o assunto.", { variant: 'error' });
            })
            .finally(() => {
                setAbrirSelecaoAssunto(false);
                setAssuntoAdicionar(undefined);
            });

    }, [assuntoAdicionar])

    useEffect(() => {

        if (!confirmacaoAssunto && !confirmacaoAssuntoTwo) return;

        api.delete(`/jucerja/v1/funcionario/funcionarioassunto/${rowAssuntoSelecionado.relacaoId}${confirmacaoAssuntoTwo ? '?redistribuirRequisicoes=true' : ''}`)
            .then(response => {
                retireAssuntoSelecionada();
            })
            .catch(() => {
                enqueueSnackbar("Houve um erro ao remover o assunto.", { variant: 'error' });
            })
            .finally(() => {
                setAbrirMensagemConfirmacaoAssunto(false);
            });

        setConfirmacaoAssunto(false);
        setConfirmacaoAssuntoTwo(false);

    }, [confirmacaoAssunto, confirmacaoAssuntoTwo])

    useEffect(() => {
        if (!ausenciaAdicionar) return;

        api[alterarAusenciaSelecionada ? 'put' : 'post'](`/jucerja/v1/funcionario/ausenciaferias`, ausenciaAdicionar)
            .then(response => {
                let listaAusencias = [...funcionario.ausenciasFerias];

                if (alterarAusenciaSelecionada)
                    listaAusencias = listaAusencias.filter(x => x.id != alterarAusenciaSelecionada.id)

                listaAusencias.push({
                    dataInicio: ausenciaAdicionar.dataInicio,
                    dataFim: ausenciaAdicionar.dataFim,
                    descricao: ausenciaAdicionar.tipoAusenciaFerias == 1 ? 'Férias' : 'Ausência',
                    justificativa: ausenciaAdicionar.justificativa,
                    id: response.data.id
                });

                setFuncionario({ ...funcionario, ausenciasFerias: listaAusencias });
            })
            .catch((ex) => {
                if (ex && ex.response && ex.response.status == 422) {
                    ex.response.data.messages.forEach(element => {
                        enqueueSnackbar(element.campos[0].Mensagem, { variant: 'error' });
                    });
                } else
                    enqueueSnackbar("Houve um erro ao cadastrar ausência/férias.", { variant: 'error' });
            })
            .finally(() => {
                setAbrirSelecaoAusencia(false);
                setAusenciaAdicionar(undefined);
                setAlterarAusenciaSelecionada(undefined);
                setRowAusenciaSelecionado(undefined);
            });


    }, [ausenciaAdicionar])

    useEffect(() => {
        if (!confirmacaoAusencia) return;

        api.delete(`/jucerja/v1/funcionario/ausenciaferias/${rowAusenciaSelecionado.id}`)
            .then(response => {
                retireAusenciaSelecionada();
            })
            .catch(() => {
                enqueueSnackbar("Houve um erro ao remover ausência/férias.", { variant: 'error' });
            })
            .finally(() => {
                setAbrirMensagemConfirmacaoAusencia(false);
            });

        setConfirmacaoAusencia(false);

    }, [confirmacaoAusencia])

    return (

        <ComponenteFuncionario>
            <DialogConfirmation
                openDialog={abrirMensagemConfirmacaoSetor}
                setOpenDialog={setAbrirMensagemConfirmacaoSetor}
                setConfirm={setConfirmacaoSetor}
                // setConfirmTwo={setConfirmacaoSetorTwo}
                // txtConfirmTwo="Confirmar e Redistribuir"
                txtConfirm="Confirmar"
                txtCancel="Cancelar"
                txtTitle="Confirmar exclusão do setor"
                txtContainer={`Deseja realmente excluir o setor '${rowSetorSelecionado ? rowSetorSelecionado.descricao : ''}' ?`}
                closeControlOutsideOnConfirm={true}
            />

            <DialogConfirmation
                openDialog={abrirMensagemConfirmacaoAssunto}
                setOpenDialog={setAbrirMensagemConfirmacaoAssunto}
                // txtConfirmTwo="Confirmar e Redistribuir"
                setConfirm={setConfirmacaoAssunto}
                // setConfirmTwo={setConfirmacaoAssuntoTwo}
                txtConfirm="Confirmar"
                txtCancel="Cancelar"
                txtTitle="Confirmar exclusão do assunto"
                txtContainer={`Deseja realmente excluir o assunto '${rowAssuntoSelecionado ? rowAssuntoSelecionado.descricao : ''}' ?`}
                closeControlOutsideOnConfirm={true}
            />

            <DialogConfirmation
                openDialog={abrirMensagemConfirmacaoAusencia}
                setOpenDialog={setAbrirMensagemConfirmacaoAusencia}
                setConfirm={setConfirmacaoAusencia}
                txtConfirm="Confirmar"
                txtCancel="Cancelar"
                txtTitle="Confirmar exclusão da ausência/férias"
                txtContainer={`Deseja realmente excluir a '${rowAusenciaSelecionado?.descricao} - ${convertISODateToDateView(rowAusenciaSelecionado?.dataInicio, true)} ${rowAusenciaSelecionado?.dataFim ? `A ${convertISODateToDateView(rowAusenciaSelecionado?.dataFim, true)}` : ''}' ?`}
                closeControlOutsideOnConfirm={true}
            />

            <DialogSetorConfirm
                openDialogSetor={abrirSelecaoSetor}
                setOpenDialogSetor={setAbrirSelecaoSetor}
                setSetorConfirmation={setSetorAdicionar}
                listaDeSetoresDesconsiderar={funcionario.setores}
                alterarSetorSelecionada={alterarSetorSelecionada}
                setAlterarSetorSelecionada={setAlterarSetorSelecionada}
            />

            <DialogAssuntoConfirm
                openDialogAssunto={abrirSelecaoAssunto}
                setOpenDialogAssunto={setAbrirSelecaoAssunto}
                listaDeSetores={funcionario.setores}
                listaDeAssuntosDesconsiderar={funcionario.assuntos}
                setAssuntoConfirmation={setAssuntoAdicionar}
            />

            <DialogAusenciaConfirm
                openDialogAusencia={abrirSelecaoAusencia}
                setOpenDialogAusencia={setAbrirSelecaoAusencia}
                setAusenciaConfirmation={setAusenciaAdicionar}
                idFuncionario={idFuncionario}
                alterarAusenciaSelecionada={alterarAusenciaSelecionada}
                setAlterarAusenciaSelecionada={setAlterarAusenciaSelecionada}
            />

            <Container title={
                <>
                    <Link to="/Funcionarios">
                        <Button className="btn-arrow-back"><BiArrowBack /> </Button>
                    </Link>
                    Funcionário
                </>
            }>
                <Card>
                    <Card.Content>

                        <ComponenteFuncionario.informacao>
                            <ComponenteFuncionario.label>Nome:</ComponenteFuncionario.label>
                            <Chip label={funcionario.nome} variant="outlined" />
                        </ComponenteFuncionario.informacao>
                        <ComponenteFuncionario.informacao>
                            <ComponenteFuncionario.label>Email:</ComponenteFuncionario.label>
                            <Chip label={funcionario.email} variant="outlined" />
                        </ComponenteFuncionario.informacao>
                        <ComponenteFuncionario.informacao>
                            <ComponenteFuncionario.label>Login:</ComponenteFuncionario.label>
                            <Chip label={funcionario.login} variant="outlined" />
                        </ComponenteFuncionario.informacao>

                    </Card.Content>
                </Card>
                <Divider>
                    <Chip label="Configurações do Funcionário" />
                </Divider>
                <ComponenteFuncionario.distanciaDivisor></ComponenteFuncionario.distanciaDivisor>
                <Grid container layout={"row"} spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ComponenteFuncionario.grid>
                            <Card>
                                <ComponenteFuncionario.title>
                                    Setores
                                </ComponenteFuncionario.title>

                                <DataGrid
                                    onRowClick={e => { setRowSetorSelecionado(e.row); }}
                                    onSelectionModelChange={e => { if (e.length <= 0) { setRowSetorSelecionado(undefined) } }}
                                    getRowId={(r) => r.id}
                                    style={{ height: '400px' }}
                                    columns={
                                        [
                                            { "field": "descricao", "headerName": 'Descrição', "width": 345, "editable": false },
                                            {
                                                "field": "supervisor", "headerName": "Supervisor", "width": 165, "editable": false,
                                                valueGetter: (params) => params.value ? 'Sim' : 'Não',
                                                renderCell: (params) => {
                                                    return <Badge type={params.value == 'Sim'} className="no-select">{params.value}</Badge>
                                                }
                                            },
                                            {
                                                "field": "respondeTarefa", "headerName": "Apto", "width": 165, "editable": false,
                                                valueGetter: (params) => params.value ? 'Sim' : 'Não',
                                                renderCell: (params) => {
                                                    return <Badge type={params.value == 'Sim'} className="no-select">{params.value}</Badge>
                                                }
                                            }
                                        ]}
                                    rows={funcionario && funcionario.setores ? funcionario.setores : []}
                                    density={'small'}
                                    loading={false}
                                    localeText={ptBR.props.MuiDataGrid.localeText}
                                />
                                <ComponenteFuncionario.Actions>
                                    <div className={rowSetorSelecionado ? "mostrarAnimacao" : "esconderAnimacao"}>
                                        <Button className="btn-close-actions" onClick={removerSetorFuncionario}>{"remover"}</Button>
                                    </div>
                                    <div className={rowSetorSelecionado ? "mostrarAnimacao" : "esconderAnimacao"}>
                                        <Button className="btn-primary-actions" onClick={alterarSetorFuncionario}>{"Alterar"}</Button>
                                    </div>
                                    <Button className="btn-primary-actions" onClick={e => setAbrirSelecaoSetor(true)}>{"adicionar"}</Button>
                                </ComponenteFuncionario.Actions>
                            </Card>

                        </ComponenteFuncionario.grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ComponenteFuncionario.grid>
                            <Card>
                                <ComponenteFuncionario.title>
                                    Assuntos
                                </ComponenteFuncionario.title>
                                <DataGrid
                                    getRowId={(r) => r.id}
                                    onRowClick={e => setRowAssuntoSelecionado(e.row)}
                                    onSelectionModelChange={e => { if (e.length <= 0) { setRowAssuntoSelecionado(undefined) } }}
                                    style={{ height: '400px' }}
                                    columns={
                                        [
                                            { "field": "descricao", "headerName": 'Descrição', "width": 400, "editable": false }
                                        ]}
                                    rows={funcionario && funcionario.assuntos ? funcionario.assuntos : []}
                                    density={'small'}
                                    loading={false}
                                    localeText={ptBR.props.MuiDataGrid.localeText}
                                />
                                <ComponenteFuncionario.Actions>

                                    <div className={rowAssuntoSelecionado ? "mostrarAnimacao" : "esconderAnimacao"}>
                                        <Button className="btn-close-actions" onClick={removerAssuntoFuncionario}>{"remover"}</Button>
                                    </div>
                                    <div className={(funcionario && funcionario.setores ? funcionario.setores : []).length > 0 ? "mostrarAnimacao" : "esconderAnimacao"}>
                                        <Button className="btn-primary-actions" onClick={e => setAbrirSelecaoAssunto(true)} >{"adicionar"}</Button>
                                    </div>
                                </ComponenteFuncionario.Actions>
                            </Card>

                        </ComponenteFuncionario.grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ComponenteFuncionario.grid>
                            <Card>
                                <ComponenteFuncionario.title>
                                    Ausências/Férias
                                </ComponenteFuncionario.title>

                                <DataGrid
                                    getRowId={(r) => r.id}
                                    style={{ height: '400px' }}
                                    onRowClick={e => setRowAusenciaSelecionado(e.row)}
                                    onSelectionModelChange={e => { if (e.length <= 0) { setRowAusenciaSelecionado(undefined) } }}
                                    columns={
                                        [
                                            { "field": "descricao", "headerName": 'Tipo', "width": 120, "editable": false },
                                            { "field": "dataInicio", "headerName": "Início", "width": 125, filterable: false, "editable": false, valueGetter: (param) => param.value ? new Date(param.value) : undefined, valueFormatter: (param) => param.value ? convertISODateToDateView(param.value, true) : undefined },
                                            { "field": "dataFim", "headerName": "Fim", "width": 125, filterable: false, "editable": false, valueGetter: (param) => param.value ? new Date(param.value) : undefined, valueFormatter: (param) => param.value ? convertISODateToDateView(param.value, true) : undefined },
                                            { "field": "justificativa", "headerName": "Justificativa", "width": 175, filterable: false, "editable": false, renderCell: (params) => { return <PrimaryTooltip title={params.value} placement="top"><span>{params.value}</span></PrimaryTooltip> } }
                                        ]}
                                    rows={funcionario && funcionario.ausenciasFerias ? funcionario.ausenciasFerias : []}
                                    density={'small'}
                                    loading={false}
                                    localeText={ptBR.props.MuiDataGrid.localeText}
                                />
                                <ComponenteFuncionario.Actions>
                                    <div className={rowAusenciaSelecionado ? "mostrarAnimacao" : "esconderAnimacao"}>
                                        <Button className="btn-close-actions" onClick={removerAusenciaFuncionario}>{"remover"}</Button>
                                    </div>
                                    <div className={rowAusenciaSelecionado ? "mostrarAnimacao" : "esconderAnimacao"}>
                                        <Button className="btn-primary-actions" onClick={alterarAusenciaFuncionario}>{"Alterar"}</Button>
                                    </div>
                                    <Button className="btn-primary-actions" onClick={e => setAbrirSelecaoAusencia(true)}>{"adicionar"}</Button>
                                </ComponenteFuncionario.Actions>
                            </Card>
                        </ComponenteFuncionario.grid>
                    </Grid>
                </Grid>
            </Container>

        </ComponenteFuncionario>
    )
}