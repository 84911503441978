import Container from '../../components/layout/Container'
import Grid from '@material-ui/core/Grid'
import { useEffect, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../../components/elements-ui/Card"
import { Badge } from "../../components/elements-ui/Badge"
import { ActionTable } from "../../components/elements-ui/ActionTable"
import { Button } from '@material-ui/core'
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from 'react-icons/all'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../services/api'

const PrimaryTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
    },
}))(Tooltip);


export default function Setores() {
    const [setores, setSetores] = useState([])
    const [loading, setLoading] = useState(true);

    const dadosSetor = [
        {
            id: 10,
            setor: 'RedeSim',
            supervisor: 'Maria das Gracas S.',
            ativo: true
        }
    ]

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 110
        },
        {
            field: 'setor',
            headerName: 'Setor',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'supervisor',
            headerName: 'Supervisor',
            width: 200,
        },
        {
            field: 'ativo',
            headerName: 'Ativo',
            width: 150,
            renderCell: (params) => {
                return <Badge type={params.value == "Sim"} className="no-select">{params.value}</Badge>
            }
        },
        {
            field: 'acoes',
            headerName: 'Ações',
            width: 129,
            filterable: false,
            renderCell: (params) => {
                return (
                    <ActionTable>
                        {/* <Link to="/configuracoes">
                            <PrimaryTooltip title="Visualizar" placement="top">
                                <Button className="btn-open"><AiOutlineEye/></Button>
                            </PrimaryTooltip>
                        </Link> */}
                        <Link to={`/Setor/${params.row.id}`}>
                            <PrimaryTooltip title="Editar" placement="top">
                                <Button className="btn-edit"><AiOutlineEdit /></Button>
                            </PrimaryTooltip>
                        </Link>
                        {/* <PrimaryTooltip title="Deletar" placement="top">
                            <Button onClick={() => handleDelete(1)} className="btn-delete"><AiOutlineDelete/></Button>
                        </PrimaryTooltip> */}
                    </ActionTable>
                )
            }
        },
    ]


    useEffect(() => {
        api.get('/jucerja/v1/Setor/setores-configuracao')
            .then(response => {

                setSetores(response.data);
            })
            .catch(error => {
            })
            .finally(() => setLoading(false));
    }, [])


    return (
        <Container title="Setores">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                        <Card.Content>
                            <div style={{ height: 700, width: '100%' }}>
                                <DataGrid
                                    loading={loading}
                                    key={row => ''.concat(row.id, row.supervisor)}
                                    getRowId={row => ''.concat(row.id, row.supervisor)}
                                    rows={setores}
                                    columns={columns}
                                    pageSize={50}
                                    rowsPerPageOptions={[50]}
                                    disableExtendRowFullWidth={false}
                                    disableSelectionOnClick
                                    localeText={ptBR.props.MuiDataGrid.localeText}
                                />

                            </div>
                        </Card.Content>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}