import { HubConnectionBuilder } from '@microsoft/signalr'

let solicitacaoConnect = undefined;
let tarefaConnect = undefined;

export const getConnectSolicitacao = (idRequisicao) => {
    if (solicitacaoConnect !== undefined)
        solicitacaoConnect.stop();

    const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_URL}/faleConoscoHub?chave=${idRequisicao}`)
        .withAutomaticReconnect()
        .build();

    solicitacaoConnect = connection;

    return solicitacaoConnect;
}

export const getConnectTarefa = (idTarefa) => {

    if (tarefaConnect !== undefined)
        tarefaConnect.stop();

    const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_URL}/faleConoscoTarefaHub?chave=${idTarefa}`)
        .withAutomaticReconnect()
        .build();

    tarefaConnect = connection;

    return tarefaConnect;
}