import { forwardRef, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from "@material-ui/core/TextField"
import PropTypes from 'prop-types'
import { VscFilePdf, RiCloseCircleFill, IoImageOutline, CgClose, GrAttachment, AiTwotoneLock, AiOutlineUnlock, IoChatboxEllipsesSharp, IoChatboxEllipsesOutline, AiOutlineSend, BsFileText } from 'react-icons/all'
import { StandardT } from './styles'

import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useEffect } from 'react'
import api from '../../../services/api'
import Draggable from 'react-draggable'
import { Paper } from '@material-ui/core'
import _ from 'lodash'
import { createRef } from 'react'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const PrimaryRadio = withStyles({
    root: {
        color: 'var(--primary)',
        '&$checked': {
            color: 'var(--primary)',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />)

export default function DialogStandardText({
    idAssunto,
    setOpenDialogEditor,
    openDialogStandardText,
    setOpenDialogStandardText,
    setDescription
}) {
    const [standardText, setStandardText] = useState('')
    const [listaDeOpcoes, setListaDeOpcoes] = useState([])
    const [listaDeOpcoesFiltrada, setListaDeOpcoesFiltrada] = useState([])
    const [codBackColor, setCodBackColor] = useState(0);

    const handleChange = (event) => {
        setStandardText(event.target.value)
    }

    const handleClose = () => {
        setOpenDialogStandardText(false)
        setOpenDialogEditor(true)
    }

    const handleSubmit = () => {
        const contentBlock = htmlToDraft(standardText)
        const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
        )
        const defaultEditorState = EditorState.createWithContent(contentState)

        setDescription({
            htmlValue: standardText,
            editorState: defaultEditorState
        })

        handleClose()
    }

    useEffect(async () => {

        if (!openDialogStandardText) return;

        setListaDeOpcoes([]);
        setStandardText('');
        await api.get(`/jucerja/v1/textopadrao/assunto/${idAssunto}`)
            .then(response => {
                const listaDePadroes = response.data;

                if (listaDePadroes && listaDePadroes.length > 0) {
                    setListaDeOpcoes(listaDePadroes);
                    setListaDeOpcoesFiltrada(listaDePadroes);
                    setStandardText(listaDePadroes[0].descricao);
                    setCodBackColor(listaDePadroes[0].id);
                }
            })

    }, [openDialogStandardText])

    return (
        <Draggable        
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
    <Dialog
        hideBackdrop
        disableBackdropClick
            open={openDialogStandardText}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="dialog-editor-message-request dialog-standard-text"
            maxWidth="lg"
            id="dialog-standard-text"
        >
            <DialogTitle style={{ 'cursor': 'move' }} id="alert-dialog-slide-title">
                Adicionar Texto Padrão
                <Button onClick={handleClose} className="btn-close-dialog-message-request"><CgClose /></Button>
            </DialogTitle>
            <DialogContent
                style={{ overflow: 'hidden' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                        <FormControl>
                            <TextField
                                id="filled-adornment-requisicao"
                                type="text"
                                label="Desc. Texto Padrão"
                                variant="outlined"
                                onChange={e => setListaDeOpcoesFiltrada(listaDeOpcoes.filter(x => x.titulo.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || x.descricao.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1))}
                            // value={obtenhaValorFiltro(colunaRequisicao)}
                            />
                        </FormControl>

                        <FormControl component="fieldset">
                            <StandardT.Search>
                                {
                                    listaDeOpcoesFiltrada.map(x =>
                                        <StandardT.Option style={codBackColor === x.id ? { backgroundColor: '#f3f3f3' } : {}} onClick={() => { setStandardText(x.descricao); setCodBackColor(x.id) }} title={x.titulo}>
                                            <StandardT.span >
                                                {x.titulo}
                                            </StandardT.span>
                                        </StandardT.Option>
                                    )
                                }
                                {/* <RadioGroup aria-label="gender" name="gender1" value={standardText} onChange={handleChange}>
                                    {
                                        listaDeOpcoesFiltrada.map(x =>
                                            <StandardT.Option>
                                                <FormControlLabel
                                                    value={x.descricao}
                                                    control={<PrimaryRadio />}
                                                    label={x.titulo}
                                                />
                                            </StandardT.Option>
                                        )
                                    }
                                </RadioGroup>  */}
                            </StandardT.Search>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                        <StandardT id="StandardT">
                            <StandardT.Label id="StandardT_Label">Texto</StandardT.Label>
                            <StandardT.Content id="StandardT_Content">
                                <StandardT.Text id="StandardT_Text">{standardText}</StandardT.Text>
                            </StandardT.Content>
                        </StandardT>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} className="btn-close-actions">Cancelar</Button>
                <Button onClick={() => handleSubmit()} className="btn-primary-actions">Adicionar</Button>
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}