import { useState, forwardRef, useEffect, useRef } from 'react'
import Container from '../../../components/layout/Container'

import { Badge } from "../../../components/elements-ui/Badge"
import { useSnackbar } from 'notistack'
import FormControl from "@material-ui/core/FormControl"

import { ComponenteSetor } from './styles'
import { Button, Grid, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, withStyles } from '@material-ui/core'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Card } from "../../../components/elements-ui/Card"
import DialogDefaultTextConfirm from '../../../components/elements-ui/DialogDefaultTextConfirm'
import DialogConfirmation from '../../../components/elements-ui/DialogConfirmation'
import DialogAssuntoSetorConfirm from '../../../components/elements-ui/DialogAssuntoSetorConfirm'
import _ from 'lodash'
import api from '../../../services/api'
import { convertISODateToDateView } from '../../../functions/index'

import { Link } from 'react-router-dom'

import { BiArrowBack } from 'react-icons/all'
import DialogAssuntoAssociacaoConfirm from '../../../components/elements-ui/DialogAssuntoAssociacaoConfirm'

export default function Setor(props) {
    const setorId = props.match.params.id;

    const { enqueueSnackbar } = useSnackbar();

    const [setor, setSetor] = useState({ id: 0, descricao: '', ativo: '', supervisor: [], assuntos: [], textosPadroes: [] });

    const [selectedRowAssunto, setSelectedRowAssunto] = useState(undefined);
    const [openDialogAssuntoSetorConfirm, setOpenDialogAssuntoSetorConfirm] = useState(false);
    const [editModeAssuntoContent, setEditModeAssuntoContent] = useState(false);
    const [showRemoveAssuntoMessage, setShowRemoveAssuntoMessage] = useState(false);
    const [assuntoSetorConfirm, setAssuntoSetorConfirm] = useState(undefined);
    const [confirmRemoveAssuntoMessage, setConfirmRemoveAssuntoMessage] = useState(false);
    const [openDialogDefaultText, setOpenDialogDefaultText] = useState(false);
    const [supervisores, setSupervisores] = useState([]);

    const [openDialogAssuntoAssociacao, setOpenDialogAssuntoAssociacao] = useState(false);
    const [assuntoAssociacaoConfirmation, setAssuntoAssociacaoConfirmation] = useState(undefined);

    useEffect(async () => {
        const promiseSetor = api.get(`/jucerja/v1/Setor/setores-configuracao/?id=${setorId}`).then(response => response.data);
        const promiseSupervisores = api.get(`/jucerja/v1/Setor/supervisores/?setorId=${setorId}`).then(response => response.data);
        const promiseAssuntos = api.post(`/jucerja/v1/assuntos/assunto-setor`, [Number(setorId)]).then(response => response.data);
        // const promiseTextos = api.get(`/jucerja/v1/textopadrao/assunto/setor/${setorId}`).then(response => response.data);

        // const [setorResponse, assuntosResponse, textosResponse] = await Promise.all([promiseSetor, promiseAssuntos, promiseTextos]);

        const [setorResponse, assuntosResponse, supervisores] = await Promise.all([promiseSetor, promiseAssuntos, promiseSupervisores]);

        setSupervisores(supervisores);

        const setor = {
            ...setorResponse[0],
            supervisor: setorResponse.filter(x => x.supervisor && x.supervisor.length > 0).map(x => x.supervisor),
            assuntos: assuntosResponse,
            textosPadroes: []
            // textosPadroes: textosResponse
        }

        setSetor(setor);
    }, [])

    useEffect(() => {
        if (!assuntoSetorConfirm) return;

        if (assuntoSetorConfirm.id) {
            api.put(`/jucerja/v1/Assuntos/alterar-assunto`, assuntoSetorConfirm)
                .then(response => {
                    const setorAtualizado = response.data;

                    let assuntos = [...setor.assuntos].filter(x => x.id != assuntoSetorConfirm.id);

                    let assunto = { ...assuntoSetorConfirm, mensagemAutomatica: setorAtualizado.mensagemAutomatica, mensagemId: setorAtualizado.mensagemId };

                    setSelectedRowAssunto(assunto);

                    assuntos.push(assunto);

                    setSetor({ ...setor, assuntos });

                })
                .catch(e => { enqueueSnackbar("Houve um erro ao alterar o assunto.", { variant: 'error' }); })
                .finally(() => { setOpenDialogAssuntoSetorConfirm(false); setAssuntoSetorConfirm(undefined); enqueueSnackbar("Assunto foi atualizado com sucesso.", { variant: 'success' }); });

        } else {
            api.post(`/jucerja/v1/Assuntos/novos-assunto`, { setores: [setorId], descricao: assuntoSetorConfirm.descricao, mensagemAutomatica: assuntoSetorConfirm.mensagemAutomatica })
                .then(response => {
                    const resultado = response.data[0];
                    let assuntosAtuais = [...setor.assuntos];

                    assuntosAtuais.push({ ...resultado, setorId: setorId, ativo: 'Não', quantidadeAtendentes: 0 });

                    setSetor({ ...setor, assuntos: assuntosAtuais });

                })
                .catch(e => enqueueSnackbar("Houve um erro ao adicionar um novo assunto.", { variant: 'error' }))
                .finally(() => { setOpenDialogAssuntoSetorConfirm(false); setAssuntoSetorConfirm(undefined); enqueueSnackbar("Assunto foi cadastrado com sucesso.", { variant: 'success' }); });
        }

    }, [assuntoSetorConfirm])

    useEffect(() => {
        if (!confirmRemoveAssuntoMessage) return;

        setConfirmRemoveAssuntoMessage(false);

        api.delete(`/jucerja/v1/Assuntos/remover-assunto?assuntoId=${selectedRowAssunto.id}`)
            .then(response => {
                setSetor({ ...setor, assuntos: setor.assuntos.filter(x => x.id != selectedRowAssunto.id) });

            })
            .catch(e => enqueueSnackbar("Houve um erro ao excluir o assunto.", { variant: 'error' }))
            .finally(() => {

                setShowRemoveAssuntoMessage(false);
                enqueueSnackbar("Assunto foi removido com sucesso.", { variant: 'success' });
            });
    }, [confirmRemoveAssuntoMessage])

    useEffect(() => {
        if (!assuntoAssociacaoConfirmation) return;

        let assuntoAssociacao = { AssuntoId: assuntoAssociacaoConfirmation.id, SetorId: setorId }

        api.put(`/jucerja/v1/Assuntos/associar-assunto-setor`, assuntoAssociacao)
            .then(response => {

                let assuntosRef = [...setor.assuntos]
                let assunto = { ...assuntoAssociacaoConfirmation, SetorId: setorId, mensagemAutomatica: '', mensagemId: undefined, quantidadeAtendentes: 0, ativo: 'Não', desativado: true };

                // setSelectedRowAssunto(assunto);

                assuntosRef.push(assunto);

                setSetor({ ...setor, assuntos: assuntosRef });

            })
            .catch(e => { enqueueSnackbar("Houve um erro ao associar o assunto.", { variant: 'error' }); })
            .finally(() => {
                setOpenDialogAssuntoAssociacao(false); setAssuntoAssociacaoConfirmation(undefined);
                enqueueSnackbar("Assunto foi associado com sucesso.", { variant: 'success' });
            });



    }, [assuntoAssociacaoConfirmation])

    return (

        <ComponenteSetor>

            <DialogDefaultTextConfirm
                openDialogDefaultText={openDialogDefaultText}
                setOpenDialogDefaultText={setOpenDialogDefaultText}
                idAssunto={selectedRowAssunto?.id}
            />

            <DialogAssuntoSetorConfirm
                openDialogAssuntoSetorConfirm={openDialogAssuntoSetorConfirm}
                setOpenDialogAssuntoSetorConfirm={setOpenDialogAssuntoSetorConfirm}
                setAssuntoSetorConfirm={setAssuntoSetorConfirm}
                supervisores={supervisores}
                assuntoSetor={editModeAssuntoContent && selectedRowAssunto ? selectedRowAssunto : undefined}
            />

            <DialogConfirmation
                openDialog={showRemoveAssuntoMessage}
                setOpenDialog={setShowRemoveAssuntoMessage}
                setConfirm={setConfirmRemoveAssuntoMessage}
                txtConfirm="Sim"
                txtCancel="Não"
                txtTitle="Confirmar exclusão do assunto"
                txtContainer={`Deseja realmente excluir '${selectedRowAssunto?.descricao} com ${selectedRowAssunto?.quantidadeAtendentes} atendentes' ?`}
                closeControlOutsideOnConfirm={true}
            />

            <DialogAssuntoAssociacaoConfirm
                openDialogAssuntoAssociacao={openDialogAssuntoAssociacao}
                setOpenDialogAssuntoAssociacao={setOpenDialogAssuntoAssociacao}
                setAssuntoAssociacaoConfirmation={setAssuntoAssociacaoConfirmation}
            />

            <Container title={
                <>
                    <Link to="/Setores">
                        <Button className="btn-arrow-back"><BiArrowBack /> </Button>
                    </Link>
                    Setor
                </>
            }>
                <Card>
                    <Card.Content>

                        <ComponenteSetor.informacao>
                            <ComponenteSetor.label>Código:</ComponenteSetor.label>
                            <Chip label={setor.id} variant="outlined" />
                        </ComponenteSetor.informacao>

                        <ComponenteSetor.informacao>
                            <ComponenteSetor.label>Descrição:</ComponenteSetor.label>
                            <Chip label={setor.setor} variant="outlined" />
                        </ComponenteSetor.informacao>

                        <ComponenteSetor.informacao>
                            <ComponenteSetor.label>Ativo:</ComponenteSetor.label>
                            <Chip label={setor.ativo} variant="outlined" />
                        </ComponenteSetor.informacao>

                        <ComponenteSetor.informacao>

                            <ComponenteSetor.label>Supervisor(es):</ComponenteSetor.label>
                            <div>
                                {
                                    setor.supervisor && setor.supervisor.length > 0 ?
                                        setor.supervisor.map(x => <Chip label={x} variant="outlined" />)
                                        :
                                        <Chip label='Não há supervisor(es)' variant="outlined" />
                                }
                            </div>
                        </ComponenteSetor.informacao>

                    </Card.Content>
                </Card>
                <Divider>
                    <Chip label="Configurações do Setor" />
                </Divider>
                <ComponenteSetor.distanciaDivisor></ComponenteSetor.distanciaDivisor>
                <Grid container layout={"row"} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ComponenteSetor.grid>
                            <Card>
                                <ComponenteSetor.title>
                                    Assuntos
                                </ComponenteSetor.title>

                                <DataGrid
                                    onRowClick={e => { setSelectedRowAssunto(e.row); }}
                                    onSelectionModelChange={e => { if (e.length <= 0) { setSelectedRowAssunto(undefined) } }}
                                    getRowId={(r) => r.id}
                                    style={{ height: '400px' }}
                                    columns={
                                        [
                                            { "field": "descricao",flex: 1, "headerName": 'Assunto', "width": 200, "editable": false,
                                            renderCell: (param) =>
                                            <Tooltip title={param.value} placement="top">
                                                <span>{param.value}</span>
                                            </Tooltip> },
                                            { "field": "quantidadeAtendentes",
                                            align:'left', headerAlign:'left', "headerName": 'Atendentes', "width": 167, "type": 'number', "editable": false },
                                            {
                                                "field": "ativo", "headerName": "Ativo", "width": 165, "editable": false,
                                                renderCell: (params) => {
                                                    return <Badge type={params.value == 'Sim'} className="no-select">{params.value}</Badge>
                                                }
                                            },
                                            {
                                                "field": "desativado", "headerName": "Habilitado", "width": 169, "editable": false,
                                                renderCell: (params) => {
                                                    return <Badge type={!params.value} className="no-select">{params.value ? 'Não' : 'Sim'}</Badge>
                                                }
                                            }
                                        ]}
                                    rows={setor && setor.assuntos ? setor.assuntos : []}
                                    density={'small'}
                                    loading={false}
                                    localeText={ptBR.props.MuiDataGrid.localeText}
                                />
                                <ComponenteSetor.Actions>
                                    <div className={selectedRowAssunto ? "mostrarAnimacao" : "esconderAnimacao"}>
                                        <Button className="btn-secondary-actions" onClick={e => { setOpenDialogDefaultText(true); }}>{"Texto padrão"}</Button>
                                    </div>
                                    <div className={selectedRowAssunto ? "mostrarAnimacao" : "esconderAnimacao"}>
                                        <Button className="btn-close-actions" onClick={e => setShowRemoveAssuntoMessage(true)}>{"remover"}</Button>
                                    </div>
                                    <div className={selectedRowAssunto ? "mostrarAnimacao" : "esconderAnimacao"}>
                                        <Button className="btn-primary-actions" onClick={e => { setEditModeAssuntoContent(true); setOpenDialogAssuntoSetorConfirm(true); }}>{"Alterar"}</Button>
                                    </div>
                                    <Button className="btn-primary-actions" onClick={e => { setEditModeAssuntoContent(false); setOpenDialogAssuntoSetorConfirm(true); }}>{"Novo"}</Button>
                                    <Button className="btn-primary-actions" onClick={e => { setOpenDialogAssuntoAssociacao(true); }}>{"Associar"}</Button>
                                </ComponenteSetor.Actions>
                            </Card>

                        </ComponenteSetor.grid>
                    </Grid>
                </Grid>
            </Container>

        </ComponenteSetor >
    )
}