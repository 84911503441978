import styled from 'styled-components'

export const DuvidaComponente = styled.div`

.btn-arrow-back {
    z-index: 15;
    min-width: 0;
    width: 42px;
    height: 42px;
    align-items: center;
    justify-content: center;
    background: #bdbdbd4f;
    color: #383838;
    border-radius: 100%;
    padding: 0;
    font-size: 20px;
    margin: 0 15px 0 0;
}

.ck-editor__editable {
    min-height: 500px;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 11px;
}

.MuiInputBase-root{
    height:56px
}
`

DuvidaComponente.AttachmentContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    max-height: 121px;
    overflow: auto;

    div#Request_AttachmentItemPdf {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--red);
        background: var(--white);
        color: var(--primary);
        margin: 0 10px 15px 0;
        border-radius: 100px;
        padding: 5px 10px;
        transition: all ease .35s;
        cursor: pointer;

        div#Request_AttachmentItemImage svg {
            color: var(--red);
        }

        &:hover {
            background: var(--red);
            transition: all ease .35s;

            div#Request_AttachmentItemImage svg {
                color: var(--white);
            }

            div#Request_AttachmentItemText {
                color: var(--white);
            }

            button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose svg {
                color: var(--white);
            }
        }
    }

    div#Request_AttachmentItemImg {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--yellow);
        background: var(--white);
        color: var(--yellow);
        margin: 0 10px 15px 0;
        border-radius: 100px;
        padding: 5px 10px;
        transition: all ease .35s;
        cursor: pointer;

        &:hover {
            background: var(--yellow);
            transition: all ease .35s;

            div#Request_AttachmentItemImage svg {
                color: var(--white);
            }

            div#Request_AttachmentItemText {
                color: var(--white);
            }

            button.MuiButtonBase-root.MuiButton-root.MuiButton-text.Request_AttachmentItemClose svg {
                color: var(--white);
            }
        }	
    }
`

DuvidaComponente.SendMessageActionsDialog = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 420px) {
        flex-direction: column;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        font-size: 21px;
        margin-right: 10px;
        background: var(--lightgreen);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach-image {
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        font-size: 21px;
        background: var(--lightorange);
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach svg#icon-attach-message path {
        stroke: #004e34;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-send-attach-image svg#icon-attach-image-message {
        color: #402400;
    }

    .rdw-option-wrapper[title="Monospace"],
    .rdw-option-wrapper[title="Sobrescrito"],
    .rdw-option-wrapper[title="Subscrito"],
    .rdw-fontsize-wrapper[aria-label="rdw-font-size-control"]
    {
        display: none !important;
    }
`

DuvidaComponente.Attachments = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`

DuvidaComponente.Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;

      button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background: #FFFFFF;
        border: 1px solid #E3E3E3;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 100px;
        padding: 8px 25px 6px 25px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text svg {
        margin: 0 7px 0 0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text span.MuiButton-label {
        text-transform: capitalize;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnPagination {
        min-width: 0;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`