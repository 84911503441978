import styled from 'styled-components'

export const PrintTask = styled.div`
    position: relative;
    width: 100%;
    background: var(--white);
    overflow: auto;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 10000;
    
    label.label-message-item-request {
        margin: 0;
        font-size: 13px;
        font-weight: 700;
        color: var(--primary);
    }

    p.typograph-message-item-request {
        margin: 0;
    }
`

PrintTask.NumberRequest = styled.div`

`

PrintTask.Content = styled.div`
    max-width: 993px;
    width: calc(100% - 50px);
    padding: 25px 0 25px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

PrintTask.Logo = styled.img`
    width: 194px;
    height: auto;
`

PrintTask.Title = styled.div`
    font-size: 1.85rem;
    font-weight: 700;
    color: var(--primary);
    margin: 20px 0 20px 0;
`

PrintTask.Information = styled.div`
    margin: 30px 0 0 0;
`

PrintTask.SubTitle = styled.div`
    padding: 10px 0;
    font-weight: 600;
    font-size: 21px;
`

PrintTask.Group = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`

PrintTask.Email = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 15px 15px 0;

    svg {
        padding: 0 5px 0 0;
        position: relative;
        top: 2px;
    }
`

PrintTask.Phone = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 15px 15px 0;

    svg {
        padding: 0 5px 0 0;
        position: relative;
        top: 2px;
    }
`

PrintTask.GroupData = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`

PrintTask.GroupDataItem = styled.div`
    margin: 0 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    label {
        margin: 0;
        font-size: 13px;
        font-weight: 700;
        color: var(--primary);
    }
`

PrintTask.DateOpen = styled.div`
    margin: 0 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    label {
        margin: 0;
        font-size: 13px;
        font-weight: 700;
        color: var(--primary);
    }

    p {
        margin: 2px 0 0 0;
    }
`

PrintTask.Evaluation = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 40px 0 0 0;
`

PrintTask.EvaluationInformation = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: -10px 0 0 0;
`

PrintTask.EvaluationStars = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 51px 0 0;

    p svg {
        position: relative;
        top: 2px;
    }

    &> span {
        font-weight: 600;
        margin: 0 0 0 5px;
    }
`

PrintTask.Typograph = styled.div`
    width: 100%;
    padding: 0px 0px 3px 0;
`

PrintTask.Messages = styled.div`
    margin: 40px 0 0 0;
`

PrintTask.MessageItem = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e0e0e0;
    margin: 0 0 25px 0;
`

PrintTask.MessageItemColumn1 = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
`

PrintTask.MessageItemColumnRow1 = styled.div`
    margin: 0 55px 15px 0;
`

PrintTask.MessageItemColumn2 = styled.div`
    margin: 0 55px 15px 0;
`

PrintTask.MessageItemColumnRow2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

PrintTask.MessageItemColumn3 = styled.div`
    margin: 0 55px 15px 0;
`

PrintTask.MessageItemColumnRow3 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`