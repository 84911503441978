import { useState, useEffect, forwardRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { AnexoRequest } from './styles'
import PreviewFileImage from '../PreviewFileImage'
import { useSnackbar } from 'notistack'
import Person from '../../../assets/img/identity-card.png'
import ReactStars from 'react-stars'
import { BiCalendarEvent, HiOutlineMail, FiPhone } from 'react-icons/all'
import { MdSubject, AiOutlineUser, FaTasks, AiOutlineMessage, RiMapPinUserLine, BsPerson, SiOpslevel, FiGitPullRequest, AiOutlineDownload, AiOutlineEye } from 'react-icons/all'
import { capitalize, convertISODateToDateView, formataTelefone, formataCNPJ, formataCPF, limitChar } from '../../../functions'
import { ItemSelected } from '../ItemSelected'
import { v4 as uuid } from 'uuid'
import api from '../../../services/api'
import Skeleton from 'react-loading-skeleton'
import { DataGrid, ptBR } from "@mui/x-data-grid"
import { Grid } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const PrimaryTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
    },
}))(Tooltip);

export default function DialogAnexoRequest({
    arquivos,
    openDialogAnexosRequest,
    setOpenAnexosDaSolicitacao
}) {

    const { enqueueSnackbar } = useSnackbar()
    const [openDialogPreviewFileImage, setOpenDialogPreviewFileImage] = useState(false)
    const [loading, setLoading] = useState(true);
    const [preview, setPreview] = useState({
        urlFile: null,
        openClose: null
    })

    const handleClose = (e) => {
        e?.preventDefault()
        setOpenAnexosDaSolicitacao(false)
    }

    const previewFile = async (idAnexo) => {
        setOpenDialogPreviewFileImage(true)
        setLoading(true)
        await api.get(`/jucerja/v1/arquivos/${idAnexo}`)
            .then(response => {
                let extensionFile = response.data.extensao.includes('pdf') ? 'application/pdf' : `image/${response.data.extensao.replace('.', '')}`

                setPreview({
                    base64: true,
                    file: response.data.arquivo.files,
                    url: `data:${extensionFile};base64,${response.data.arquivo.files}`,
                    name: response.data.nome
                })
                setLoading(false)
            })
            .catch(error => {
                setOpenDialogPreviewFileImage(false)
                setLoading(false)
                enqueueSnackbar(error.toString(), { variant: 'error' })
            })
    }

    const downloadFile = async (idAnexo) => {
        document.querySelector(`[btn-download-attach-id="${idAnexo}"]`).classList.add("displayNone")
        document.querySelector(`[btn-download-loading-attach-id="${idAnexo}"]`).classList.remove("displayNone")
        await api.get(`/jucerja/v1/arquivos/${idAnexo}`)
            .then(response => {
                let extensionFile = response.data.extensao.includes('pdf') ? 'application/pdf' : `image/${response.data.extensao.replace('.', '')}`
                let a = document.createElement("a")
                a.href = `data:${extensionFile};base64,${response.data.arquivo.files}`
                a.download = response.data.nome
                a.click()

                document.querySelector(`[btn-download-attach-id="${idAnexo}"]`).classList.remove("displayNone")
                document.querySelector(`[btn-download-loading-attach-id="${idAnexo}"]`).classList.add("displayNone")
            })
            .catch(error => {
                document.querySelector(`[btn-download-attach-id="${idAnexo}"]`).classList.remove("displayNone")
                document.querySelector(`[btn-download-loading-attach-id="${idAnexo}"]`).classList.add("displayNone")
                enqueueSnackbar(error.toString(), { variant: 'error' })
            })
    }

    const downloadTodosArquivos = () => {
        arquivos.forEach(obj => downloadFile(obj.id));
    }

    const tamanhoArquivo = (x) => {
        const units = [
            { tipo: 'bytes', cor: '#ffb2ee' },
            { tipo: 'KB', cor: '#ccb2ff' },
            { tipo: 'MB', cor: '#b2b8ff' },
            { tipo: 'GB', cor: '#b2fff4' },
            { tipo: 'GB', cor: '#b2ffc3' },
            { tipo: 'TB', cor: '#ffceb2' }
        ];
        let l = 0, n = parseInt(x, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return ({ valor: n.toFixed(n < 10 && l > 0 ? 1 : 0), tipo: units[l].tipo, cor: units[l].cor });
    }

    return (
        <Dialog
            open={openDialogAnexosRequest}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-anexos-request-slide-title"
            aria-describedby="dialog-anexos-request-dialog-slide-description"
            id="dialog-anexos-request"
            fullWidth
            maxWidth="md"
        >
            <DialogContent>
                <AnexoRequest id="AnexosRequest">
                    <PreviewFileImage
                        openDialogPreviewFileImage={openDialogPreviewFileImage}
                        setOpenDialogPreviewFileImage={setOpenDialogPreviewFileImage}
                        preview={preview}
                        setPreview={setPreview}
                        loading={loading}
                        setLoading={setLoading}
                    />
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <DataGrid
                            // onRowClick={e => { setSelectedRowDefaultText(e.row); onRowSelect(e.row); }}
                            // onSelectionModelChange={e => { if (e.length <= 0) { setSelectedRowDefaultText(undefined) } }}

                            getRowId={(r) => r.id}
                            style={{ height: '500px', width: '100%' }}
                            columns={
                                [
                                    { "field": "nome", "headerName": 'Nome Arquivo', "width": 250, "align": "center", "headerAlign": 'center', "editable": false },
                                    {
                                        "field": "ehMensagemCliente", "headerName": 'Origem', "width": 150, "align": "center", "headerAlign": 'center', "editable": false,
                                        renderCell: (params) => {
                                            return <span>{params.value ? 'Cliente' : 'Atendente'}</span>
                                        }
                                    },
                                    {
                                        "field": "tamanho", "headerName": 'Tamanho', "width": 200, "align": "center", "headerAlign": 'center', "editable": false,
                                        renderCell: (params) => {
                                            let arquivoInfo = tamanhoArquivo(params.value);
                                            return <span>{arquivoInfo.valor} <span style={{ color: arquivoInfo.cor }}>{arquivoInfo.tipo}</span></span>
                                        }
                                    },
                                    {
                                        "field": "id", "headerName": "Ações", "width": 200, "align": "center", "headerAlign": 'center', "editable": false,
                                        renderCell: (params) => {
                                            return <>
                                                <span style={{}}>
                                                    <PrimaryTooltip title="Visualizar" placement="top">
                                                        <Button className="btn"><AiOutlineEye onClick={e => previewFile(params.value)} style={{ fontSize: '1.6em', cursor: 'pointer' }} /></Button>
                                                    </PrimaryTooltip>
                                                    <PrimaryTooltip title="Baixar" placement="top">
                                                        <Button className="btn"><AiOutlineDownload onClick={e => downloadFile(params.value)} style={{ fontSize: '1.6em', cursor: 'pointer' }} /></Button>
                                                    </PrimaryTooltip>
                                                </span></>
                                        }
                                    },
                                ]}
                            disableColumnSelector
                            disableSelectionOnClick
                            hideFooter={true}
                            rows={arquivos ?? []}
                            density={'small'}
                            localeText={ptBR.props.MuiDataGrid.localeText}
                        /></Grid>
                </AnexoRequest>
            </DialogContent>
            <DialogActions style={{ display: 'inline' }}>
                <Button onClick={downloadTodosArquivos} color="primary">Baixar Todos</Button>
                <Button onClick={handleClose} style={{ float: 'right' }} color="primary">Fechar</Button>
            </DialogActions>
        </Dialog >
    )
}