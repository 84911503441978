import { useState, useEffect, forwardRef, useRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Select from "@material-ui/core/Select"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { DialogAssuntoAssociacao } from './styles'
import api from '../../../services/api'
import TextField from "@material-ui/core/TextField"
import { Autocomplete, Chip, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid'
import { setRef, Grid } from '@material-ui/core'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogAssuntoAssociacaoConfirm({
    openDialogAssuntoAssociacao,
    setOpenDialogAssuntoAssociacao,
    setAssuntoAssociacaoConfirmation
}) {
    const [errorAssunto, setErrorAssunto] = useState(false);
    const [assunto, setAssunto] = useState({ id: undefined, descricao: '' });
    const [listaAssuntos, setListaAssuntos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limpar, setLimpar] = useState(false);

    const handleChangeAssunto = (e, v) => {
        setErrorAssunto(false);
        setAssunto({ ...assunto, id: v ? v?.id : undefined, descricao: v ? v?.descricao : '' });
    }

    const handleClose = (e) => {
        e.preventDefault();

        if (loading) return;

        setOpenDialogAssuntoAssociacao(false);
    }

    const handleConfirm = async (e) => {
        e?.preventDefault();

        if (!assunto.id) {
            setErrorAssunto(true);
            return
        }

        setLoading(true);
        setAssuntoAssociacaoConfirmation(assunto);
    }

    const getAssuntos = async () => {
        await api.get('/jucerja/v1/assuntos/assuntos-associar-setor')
            .then(response => {
                setListaAssuntos(response.data);
            })
    }

    useEffect(() => {
        if (openDialogAssuntoAssociacao) {
            setLimpar(!limpar);
            setAssunto({ id: undefined, descricao: '' });
            getAssuntos();
        } else {
            setLoading(false);
        }
    }, [openDialogAssuntoAssociacao])

    return (
        <Draggable        
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogAssuntoAssociacao}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-setor"
        >
            <DialogTitle id="alert-dialog-slide-title">{"Selecionar assunto para Associação"}</DialogTitle>
            <DialogContent>
                <DialogAssuntoAssociacao id="DialogSetor">
                    <DialogAssuntoAssociacao.Container id="DialogSetor_Container">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                < Autocomplete
                                    key={limpar}
                                    title='Assuntos'
                                    id="tags-standard"
                                    options={listaAssuntos}
                                    getOptionLabel={(option) => option.descricao}
                                    onChange={(e, v) => handleChangeAssunto(e, v)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={errorAssunto}
                                            variant="standard"
                                            label="Selecione os assunto"
                                        />
                                    )}
                                />

                            </Grid>
                        </Grid>
                    </DialogAssuntoAssociacao.Container>
                </DialogAssuntoAssociacao>
            </DialogContent>
            <DialogActions>
                <DialogAssuntoAssociacao.Actions id="DialogSetor.Actions">
                    <div className={!loading ? "mostrarAnimacao" : "esconderAnimacao"}>
                        <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                    </div>
                    {
                        loading
                            ?
                            <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                            :
                            <Button className="btn-primary-actions" onClick={handleConfirm}>Confirmar</Button>
                    }

                </DialogAssuntoAssociacao.Actions>
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}