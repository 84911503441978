import { useState, useEffect, React } from 'react'

import { GiSandsOfTime, BiMessageSquareDetail, RiUserVoiceLine, BiMessageCheck, BiMessageError, ImClipboard, BiMessageDots, RiUserUnfollowLine, AiOutlineEdit } from 'react-icons/all'
import CountUp from 'react-countup'
import { Button, Grid, Grow, Slide } from '@material-ui/core'
import { Autocomplete, TextField, Chip, Stack } from '@mui/material';
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { BarChart, Bar, LabelList, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer, Label, LineChart, Line, Area, ComposedChart } from "recharts"
import _ from 'lodash';
import { v4 as uuid } from 'uuid'
import { NavLink, Link } from 'react-router-dom'

import { Graphs } from '../../components/elements-ui/Graphs'
import { Kpi } from '../../components/elements-ui/KPI'
import { getRToken } from '../../services/auth'
import Container from '../../components/layout/Container'
import api from '../../services/api'

import nmr_requisicoes_assuntos from './mock/nmr_requisicoes_assuntos.json'
import columnsTarefasAssociadas from './mock/columnsTarefasAssociadas.json'
import columnsTarefasAssociadasSetor from './mock/columnsTarefasAssociadasSetor.json'
import columnsRequisicoesAssociadas from './mock/columnsRequisicoesAssociadas.json'
import columnsRequisicoesAssociadasAssunto from './mock/columnsRequisicoesAssociadasAssunto.json'
import rowsTarefasAssociadas from './mock/rowsTarefasAssociadas.json'
import rowsTarefasAssociadasSetor from './mock/rowsTarefasAssociadasSetor.json'
import rowsRequisicoesAssociadas from './mock/rowsRequisicoesAssociadas.json'
import rowsRequisicoesAssociadasAssuntos from './mock/rowsRequisicoesAssociadasAssuntos.json'
import notas_avaliacoes from './mock/notas_avaliacoes.json'
import requisicoes_tempo_atendimento from './mock/requisicoes_tempo_atendimento.json'

import AvaliacaoMedia from './avaliacaoMedia'
import QuantitativoAvaliacoesAtendente from './quantitativoAvaliacoesAtendente'
import AvaliacoesMotivo from './avaliacoesMotivo'
import RequisicoesStatus from './requisicoesStatus'
import { getPerfisUsuario } from '../../services/auth'
import { convertISODateToDateView } from '../../functions'


export default function Dashboard(props) {
    const perfilRoute = props.match.params.perfil ?? getPerfisUsuario()[0];
    const [ehAtendente, setEhAtendente] = useState(false);
    const [ehSupervisor, setEhSupervisor] = useState(false);
    const [ehCliente, setEhCliente] = useState(false);
    const [requisicoesAguardandoRespostaValue, setRequisicoesAguardandoRespostaValue] = useState(0)
    const [quantidadeInteracoesValue, setQuantidadeInteracoesValue] = useState(0)
    const [quantidadeRequisicoesFinalizadasValue, setQuantidadeRequisicoesFinalizadasValue] = useState(0)
    const [requisicoesAtrasadasValue, setRequisicoesAtrasadasValue] = useState(0)
    const [avaliacaoMediaValue, setAvaliacaoMediaValue] = useState(0)

    const [notasAvaliacoesList, setNotasAvaliacoesList] = useState([])
    const [requisicoesPorAssuntoEStatusList, setRequisicoesPorAssuntoEStatusList] = useState([])
    const [requisicoesPorAssuntoETempoList, setRequisicoesPorAssuntoETempoList] = useState([])
    const [requisicoesPorMotivosList, setRequisicoesPorMotivosList] = useState([])

    const [listaMediaAvalicaoRequisicao, setListaMediaAvalicaoRequisicao] = useState([])
    const [quantidadeRequisicoesSemResponsavel, setQuantidadeRequisicoesSemResponsavel] = useState(0)

    const [mostrarRequisicoesSemResponsavel, setMostrarRequisicoesSemResponsavel] = useState(false);
    const [mostrarRequisicoesAguardandoResposta, setMostrarRequisicoesAguardandoResposta] = useState(false);
    const [mostrarQuantidadeIteracoes, setMostrarQuantidadeIteracoes] = useState(false);
    const [mostrarQuantidadeRequisicoesFinalizadas, setMostrarQuantidadeRequisicoesFinalizadas] = useState(false);
    const [mostrarRequisicoesAtrasadas, setMostrarRequisicoesAtrasadas] = useState(false);
    const [mostrarAvaliacaoMedia, setMostrarAvaliacaoMedia] = useState(false);

    const [atualizacaoEfetuada, setAtualizacaoEfetuada] = useState(undefined);

    const [listaValoresMediaAvaliacaoSetorSupervisor, setListaValoresMediaAvaliacaoSetorSupervisor] = useState([])
    const [listaAssuntosMediaAvaliacaoSetorSupervisor, setListaAssuntosMediaAvaliacaoSetorSupervisor] = useState([])
    const [listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados, setListaAssuntosMediaAvaliacaoSetorSupervisorSelecionados] = useState([])
    const [listaValoresMediaAvaliacaoSetorSupervisorSelecionados, setListaValoresMediaAvaliacaoSetorSupervisorSelecionados] = useState([])

    const [listaReqFinalizadosAtendentesTrintaDias, setListaReqFinalizadosAtendentesTrintaDias] = useState([])
    const [listaAtendentesTrintaDiasSelecionados, setListaAtendentesTrintaDiasSelecionados] = useState([])
    const [listaReqFinalizadosAtendentesTrintaDiasDados, setListaReqFinalizadosAtendentesTrintaDiasDados] = useState([])
    const [listaAtendentesTrintaDiasDistinct, setListaAtendentesTrintaDiasDistinct] = useState([])
    const [listaSolicitacoesDeAtiviasAtendente, setListaSolicitacoesDeAtiviasAtendente] = useState([])
    const [listaQuantidadeDeIntegracoesDoAtendentes, setListaQuantidadeDeIntegracoesDoAtendentes] = useState([]);
    const [listaTempoMedioRespostaAtendente, setListaTempoMedioRespostaAtendente] = useState([]);

    const data = [

        {
            "id": 1176,
            "Charles Santos de Andrade": 0.35454545454545453,
            "media": 0.7083333333333334,
            "dataOperacao": "2023-02-24T00:00:00",
            "dataOperacaoFormatada": "24/02/23"
        },
        {
            "id": 1176,
            "Charles Santos de Andrade": 0.55454545454545453,
            "media": 0.45454545454545453,
            "dataOperacao": "2023-02-27T00:00:00",
            "dataOperacaoFormatada": "27/02/23"
        },
        {
            "id": 1176,
            "Charles Santos de Andrade": 0.65454545454545453,
            "media": 0.45454545454545453,
            "dataOperacao": "2023-02-28T00:00:00",
            "dataOperacaoFormatada": "28/02/23"
        },
        {
            "id": 1176,
            "Charles Santos de Andrade": 0.95454545454545453,
            "media": 0.45454545454545453,
            "dataOperacao": "2023-03-01T00:00:00",
            "dataOperacaoFormatada": "01/03/23"
        },
        {
            "id": 1176,
            "Charles Santos de Andrade": 0.45454545454545453,
            "media": 0.45454545454545453,
            "dataOperacao": "2023-03-02T00:00:00",
            "dataOperacaoFormatada": "02/03/23"
        }
    ];


    const atualizeStateTrocaPerfil = () => {
        setEhAtendente(perfilRoute === 'Atendente')
        setEhSupervisor(perfilRoute === 'Supervisor')
        setEhCliente(perfilRoute === 'Cliente')

        setMostrarRequisicoesSemResponsavel(false);
        setMostrarRequisicoesAguardandoResposta(false);
        setMostrarQuantidadeIteracoes(false);
        setMostrarQuantidadeRequisicoesFinalizadas(false);
        setMostrarRequisicoesAtrasadas(false);
        setMostrarAvaliacaoMedia(false);

        setAtualizacaoEfetuada(!atualizacaoEfetuada);
    }

    const getQuantidadeRequisicoesSemResponsavel = async () => {

        if (ehAtendente || ehCliente) return;

        setQuantidadeRequisicoesSemResponsavel(0);
        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/sem-responsavel`)
            .then(response => {

                const resultado = response.data;

                if (resultado && resultado.quantidade > 0)
                    setQuantidadeRequisicoesSemResponsavel(resultado.quantidade)
            })
            .finally(x => setMostrarRequisicoesSemResponsavel(true));
    }

    const getRequisicoesAguardandoResposta = async () => {
        setRequisicoesAguardandoRespostaValue(0);
        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/quantidade-aguardando-resposta`)
            .then(response => {
                setRequisicoesAguardandoRespostaValue(response.data.quantidade);
            })
            .finally(x => setMostrarRequisicoesAguardandoResposta(true));
    }

    const getQuantidadeInteracoes = async () => {
        setQuantidadeInteracoesValue(0);
        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/quantidade-interacoes`)
            .then(response => {
                setQuantidadeInteracoesValue(response.data.quantidade);
            })
            .finally(x => setMostrarQuantidadeIteracoes(true));
    }

    const getRequisicoesFinalizadas = async () => {
        setQuantidadeRequisicoesFinalizadasValue(0);
        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/quantidade-finalizadas-dia`)
            .then(response => {
                setQuantidadeRequisicoesFinalizadasValue(response.data.quantidade);
            })
            .finally(x => setMostrarQuantidadeRequisicoesFinalizadas(true));
    }

    const getAvaliacaoMedia = async () => {
        setAvaliacaoMediaValue(0);
        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/media-nota`)
            .then(response => {
                setAvaliacaoMediaValue(response.data.media);
            })
            .finally(x => setMostrarAvaliacaoMedia(true));
    }

    const getAvaliacaoPorNota = async () => {
        const valorPadrao = [
            { "nota 1": 0, name: "nota 1" },
            { "nota 2": 0, name: "nota 2" },
            { "nota 3": 0, name: "nota 3" },
            { "nota 4": 0, name: "nota 4" },
            { "nota 5": 0, name: "nota 5" }];

        setNotasAvaliacoesList(valorPadrao);

        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/avaliacao-nota`)
            .then(response => {

                const listaConvertida = response.data.map(x => ({ [`nota ${x.nota}`]: x.quantidadeRequisicao, name: `nota ${x.nota}` }));


                listaConvertida.forEach(x => {
                    let prop = Object.keys(x)[0];
                    let propObj = valorPadrao.filter(x => x[prop] !== undefined)[0]
                    propObj[prop] = x[prop]
                })

                if (listaConvertida && listaConvertida.length > 0) {
                    setNotasAvaliacoesList([]);
                    setNotasAvaliacoesList(valorPadrao);
                }
            })
    }

    const getRequisicoesPorAssuntoEStatus = async () => {
        const valorPadrao = [
            { "assuntoDescricao": "", "Aberta": 0, "Aguardando solicitante": 0, "Andamento": 0, "Em verificação interna": 0 },
            { "assuntoDescricao": "", "Aberta": 0, "Aguardando solicitante": 0, "Andamento": 0, "Em verificação interna": 0 }];

        setRequisicoesPorAssuntoEStatusList(valorPadrao);
        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/assunto-status`)
            .then(response => {
                const propsPadroes = ["Aberta", "Aguardando solicitante", "Andamento", "Em verificação interna"]

                const listaAgrupada = _(response.data)
                    .groupBy(x => x.assuntoDescricao)
                    .map(
                        (value, key) => {
                            return Object.assign(
                                { assuntoDescricao: key, total: _.sum(value.map(x => x.quantidadeRequisicao)) },
                                _.chain(value).keyBy('statusDescricao').mapValues('quantidadeRequisicao').value()
                            )
                        }

                    )
                    .value()

                listaAgrupada.forEach(x => {
                    propsPadroes.forEach(y => {
                        if (x[y] === undefined) x[y] = 0;
                    })
                });

                if (listaAgrupada && listaAgrupada.length)
                    setRequisicoesPorAssuntoEStatusList(listaAgrupada && listaAgrupada.length > 0 ? listaAgrupada : valorPadrao)
            })
    }

    const getRequisicoesPorAssuntoETempo = async () => {
        const valorPadrao = [
            { "assuntoDescricao": "", 'Maior que 48h': 0, 'Entre 24h e 48h': 0, 'Menos que 24h': 0, "Total": 0 },
            { "assuntoDescricao": "", 'Maior que 48h': 0, 'Entre 24h e 48h': 0, 'Menos que 24h': 0, "Total": 0 }];

        let valorRequisicoesAtrasadas = 0;

        setRequisicoesAtrasadasValue(valorRequisicoesAtrasadas);
        setRequisicoesPorAssuntoETempoList(valorPadrao);

        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/quantidade-assunto-tempo`)
            .then(response => {
                const propsPadroes = ['Maior que 48h', 'Entre 24h e 48h', 'Menos que 24h'];
                const listaAgrupada = _(response.data)
                    .groupBy(x => x.assuntoDescricao)
                    .map(
                        (value, key) => {
                            return Object.assign(
                                { assuntoDescricao: key, total: _.sum(value.map(x => x.quantidade)) },
                                _.chain(value).keyBy('agrupador').mapValues('quantidade').value()
                            )
                        }

                    )
                    .value()

                listaAgrupada.forEach(x => {
                    propsPadroes.forEach(y => {
                        if (x[y] === undefined) x[y] = 0;

                        if (y === propsPadroes[0]) valorRequisicoesAtrasadas += x[y];
                    })
                })


                if (listaAgrupada && listaAgrupada.length > 0)
                    setRequisicoesPorAssuntoETempoList(listaAgrupada)
            })
            .finally(() => {
                setRequisicoesAtrasadasValue(valorRequisicoesAtrasadas);
                setMostrarRequisicoesAtrasadas(true);
            });
    }

    const getAvaliacaoPorMotivos = async () => {
        const valorPadrao = [{ motivo: '', total: 0 }, { motivo: '', total: 0 }];
        setRequisicoesPorMotivosList(valorPadrao);

        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/total-avaliacoes-motivo`)
            .then(response => {


                const resultado = response.data;

                if (resultado && resultado.length > 0)
                    setRequisicoesPorMotivosList(resultado)
            })
    }

    const getListaMediaAvalicaoRequisicao = async () => {
        if (ehAtendente) return;

        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/media-nota-finalizada-atendente`)
            .then(response => {

                const resultado = response.data;

                if (resultado && resultado.length > 0)
                    setListaMediaAvalicaoRequisicao(resultado)
            })
    }

    const getListaSolicitacoesDeAtiviasAtendente = async () => {
        if (ehAtendente) return;
        await api.get(`/jucerja/v1/requisicoes/solicitacoesativasatendente`)
            .then(response => {

                const resultado = response.data;

                if (resultado && resultado.length > 0)
                    setListaSolicitacoesDeAtiviasAtendente(resultado)
            })
    }

    const getAvaliacaoPorMediaNota = async () => {

        if (ehAtendente || ehCliente) return;

        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/media-avaliacoes-setor`)
            .then(response => {
                const resultado = response.data;

                if (resultado && resultado.length > 0) {
                    setListaValoresMediaAvaliacaoSetorSupervisor(resultado);
                    setListaAssuntosMediaAvaliacaoSetorSupervisor(_(resultado.map(x => x.assunto)).uniqBy().value().map(x => ({ id: uuid(), title: x })))
                }
            })
    }

    const getFinalizadosAtendentesPorTrintaDias = async () => {
        if (ehAtendente || ehCliente) return;

        setListaAtendentesTrintaDiasDistinct([]);
        setListaReqFinalizadosAtendentesTrintaDias([]);
        await api.get(`/jucerja/v1/dashboard/${perfilRoute}/requisicoes/atendentes-finalizadas-trintadias`)
            .then(response => {


                const resultado = response.data;

                if (resultado && resultado.length > 0) {
                    setListaReqFinalizadosAtendentesTrintaDias(resultado);
                    setListaAtendentesTrintaDiasDistinct(_.uniqBy(resultado.map(x => x.nomeAtendente)).map(x => ({ id: uuid(), title: x })));
                }
            })
    }

    const getListaQuantidadeDeIntegracoesDoAtendentes = async () => {
        if (!ehSupervisor) return;
        await api.get(`/jucerja/v1/dashboard/supervisor/quantidade-interacoes-atendente`)
            .then(response => {
                const resultado = response.data;

                if (resultado && resultado.length > 0)
                    setListaQuantidadeDeIntegracoesDoAtendentes(resultado.map(x => ({ [x.atendente]: x.quantidade, quantidade: x.quantidade, atendente: x.atendente })))

            })
    }

    const getListaTempoMedioRespostaAtendentes = async () => {
        if (!ehSupervisor) return;
        await api.get(`/jucerja/v1/dashboard/supervisor/tempo-medio-resposta-atendente`)
            .then(response => {
                const resultado = response.data;

                if (resultado && resultado.length > 0){
                    let sanitizacao = resultado.map(x => ({ [x.atendente]: x.media, data: new Date(x.dataOperacao) , dataFormatada: (new Date(x.dataOperacao)).toLocaleDateString('pt-BR',{ day: '2-digit', month: '2-digit', year: '2-digit' }), atendente: x.atendente }));
                    setListaTempoMedioRespostaAtendente(sanitizacao.sort((a,b)=> a.data - b.data));
                }

            })
    }

    const adicioneAtendentesFinalizadosAtendentesPorTrintaDias = (e, v) => {
        setListaAtendentesTrintaDiasSelecionados(v.map(x => x.title));
    }

    const adicioneAssuntosMediaAvaliacaoSetorSupervisorSelecionados = (e, v) => {
        setListaAssuntosMediaAvaliacaoSetorSupervisorSelecionados(v.map(x => x.title));
    }

    const renderLabel = (props) => {
        const { content, ...rest } = props;

        return <Label {...rest} fontSize="12" fill="Black" fontWeight="Bold" />;
    };

    const obtenhaCorRandom = () => {
        let h = getRandomIntInclusive(0, 360);
        let s = 73;
        let l = 77;
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const f = n => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }

    const getRandomIntInclusive = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        const listaDadosSelecionados = listaReqFinalizadosAtendentesTrintaDias.filter(x =>
            listaAtendentesTrintaDiasSelecionados.indexOf(x.nomeAtendente) > -1
        );

        const agrupamentos = _(listaDadosSelecionados)
            .groupBy(x => x.dataFinalizacaoFormatado)
            .map(
                (value, key) => {
                    let objCompleto = Object.assign(
                        { dataFinalizacao: key },
                        _.chain(value).keyBy('nomeAtendente').mapValues('quantidade').value()
                    );

                    const listaNaoContem = listaAtendentesTrintaDiasSelecionados.filter(x => value.filter(y => y.nomeAtendente === x).length <= 0);

                    listaNaoContem.forEach(x => {
                        objCompleto = Object.assign(objCompleto, { [x]: 0 });
                    });

                    return objCompleto;
                }

            )
            .value();

        setListaReqFinalizadosAtendentesTrintaDiasDados(agrupamentos);

    }, [listaAtendentesTrintaDiasSelecionados])

    useEffect(() => {
        if (listaAtendentesTrintaDiasDistinct && listaAtendentesTrintaDiasDistinct.length > 0)
            adicioneAtendentesFinalizadosAtendentesPorTrintaDias(null, [listaAtendentesTrintaDiasDistinct[0]])
    }, [listaAtendentesTrintaDiasDistinct])

    useEffect(() => {
        const listaDadosSelecionados = listaValoresMediaAvaliacaoSetorSupervisor.filter(x =>
            listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.indexOf(x.assunto) > -1
        );

        const agrupamentos = _(listaDadosSelecionados)
            .groupBy(x => x.dataAvaliacaoFormatada)
            .map(
                (value, key) => {
                    let dataStr = key.split('/').reverse().join('');
                    let objCompleto = Object.assign(
                        { dataAvaliacao: key, dataInt: Number(dataStr) },
                        _.chain(value).keyBy('assunto').mapValues('media').value()
                    );

                    const listaNaoContem = listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.filter(x => value.filter(y => y.assunto === x).length <= 0);

                    listaNaoContem.forEach(x => {
                        objCompleto = Object.assign(objCompleto, { [x]: 0 });
                    });

                    return objCompleto;
                }

            )
            .value();

        const agrupamentosOrdenados = _(agrupamentos).orderBy(['dataInt'], ['asc']).value();


        setListaValoresMediaAvaliacaoSetorSupervisorSelecionados(agrupamentosOrdenados);

    }, [listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados])

    useEffect(() => {
        if (listaAssuntosMediaAvaliacaoSetorSupervisor && listaAssuntosMediaAvaliacaoSetorSupervisor.length > 0)
            adicioneAssuntosMediaAvaliacaoSetorSupervisorSelecionados(null, [listaAssuntosMediaAvaliacaoSetorSupervisor[0]])
    }, [listaAssuntosMediaAvaliacaoSetorSupervisor])

    useEffect(() => {
        atualizeStateTrocaPerfil();
    }, [perfilRoute]);

    useEffect(() => {

        if (atualizacaoEfetuada == undefined) return;

        getRequisicoesAguardandoResposta();
        getQuantidadeInteracoes();
        getRequisicoesFinalizadas();
        getAvaliacaoMedia();

        getAvaliacaoPorNota();
        getRequisicoesPorAssuntoEStatus();
        getRequisicoesPorAssuntoETempo();
        getAvaliacaoPorMotivos();

        getListaMediaAvalicaoRequisicao();
        getQuantidadeRequisicoesSemResponsavel();
        getAvaliacaoPorMediaNota();
        getFinalizadosAtendentesPorTrintaDias();
        getListaSolicitacoesDeAtiviasAtendente();
        getListaQuantidadeDeIntegracoesDoAtendentes();
        getListaTempoMedioRespostaAtendentes();
    }, [atualizacaoEfetuada]);

    return (
        <Container title={`Dashboard ${perfilRoute}`}>
            <Grid container layout={"row"} spacing={4}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    <Kpi id="Kpi">

                        {
                            !ehAtendente && !ehCliente
                                ?
                                <Slide direction="up" in={mostrarRequisicoesSemResponsavel}>
                                    <Kpi.Card id="Kpi_Card" className="Kpi_Card_1">
                                        <Kpi.Content id="Kpi_Content">
                                            <Kpi.Counter id="Kpi.Counter" className="RiUserUnfollowLine">
                                                <CountUp
                                                    start={0}
                                                    end={quantidadeRequisicoesSemResponsavel}
                                                    duration={2.75}
                                                    separator="."
                                                    id="RiUserUnfollowLine"
                                                />
                                            </Kpi.Counter>
                                            <Kpi.Icon id="Kpi_Icon">
                                                <RiUserUnfollowLine id="RiUserUnfollowLine" />
                                                <Kpi.Title id="Kpi_Title">Solicitações sem responsável</Kpi.Title>
                                            </Kpi.Icon>
                                        </Kpi.Content>
                                    </Kpi.Card>
                                </Slide>
                                :
                                <></>
                        }
                        <Slide direction="up" in={mostrarRequisicoesAguardandoResposta}>
                            <Kpi.Card id="Kpi_Card" className="Kpi_Card_1" >
                                <Kpi.Content id="Kpi_Content">
                                    <Kpi.Counter id="Kpi.Counter" className="BiMessageSquareDetail">
                                        <CountUp
                                            start={0}
                                            end={requisicoesAguardandoRespostaValue}
                                            duration={2.75}
                                            separator="."
                                            id="BiMessageSquareDetail"
                                        />
                                    </Kpi.Counter>
                                    <Kpi.Icon id="Kpi_Icon">
                                        <BiMessageDots id="BiMessageSquareDetail" />
                                        <Kpi.Title id="Kpi_Title">Solicitações Aguardando Resposta</Kpi.Title>
                                    </Kpi.Icon>
                                </Kpi.Content>
                            </Kpi.Card>
                        </Slide>
                        {
                            !ehSupervisor
                                ?
                                <>
                                    <Slide direction="up" in={mostrarQuantidadeIteracoes}>
                                        <Kpi.Card id="Kpi_Card" className="Kpi_Card_2">
                                            <Kpi.Content id="Kpi_Content">
                                                <Kpi.Counter id="Kpi.Counter" className="RiUserVoiceLine">
                                                    <CountUp
                                                        start={0}
                                                        end={quantidadeInteracoesValue}
                                                        duration={2.75}
                                                        separator="."
                                                        id="RiUserVoiceLine"
                                                    />
                                                </Kpi.Counter>
                                                <Kpi.Icon id="Kpi_Icon">
                                                    <RiUserVoiceLine id="RiUserVoiceLine" />
                                                    <Kpi.Title id="Kpi_Title">Interações Hoje</Kpi.Title>
                                                </Kpi.Icon>
                                            </Kpi.Content>
                                        </Kpi.Card>
                                    </Slide>

                                </>
                                : <></>
                        }
                        <Slide direction="up" in={mostrarQuantidadeRequisicoesFinalizadas}>
                            <Kpi.Card id="Kpi_Card" className="Kpi_Card_3">
                                <Kpi.Content id="Kpi_Content">
                                    <Kpi.Counter id="Kpi.Counter" className="BiMessageCheck">
                                        <CountUp
                                            start={0}
                                            end={quantidadeRequisicoesFinalizadasValue}
                                            duration={2.75}
                                            separator="."
                                            id="BiMessageCheck"
                                        />
                                    </Kpi.Counter>
                                    <Kpi.Icon id="Kpi_Icon">
                                        <BiMessageCheck id="BiMessageCheck" />
                                        <Kpi.Title id="Kpi_Title">Solicitações Finalizadas</Kpi.Title>
                                    </Kpi.Icon>
                                </Kpi.Content>
                            </Kpi.Card>
                        </Slide>
                        <Slide direction="up" in={mostrarRequisicoesAtrasadas}>
                            <Kpi.Card id="Kpi_Card" className="Kpi_Card_4">
                                <Kpi.Content id="Kpi_Content">
                                    <Kpi.Counter id="Kpi.Counter" className="BiMessageError">
                                        <CountUp
                                            start={0}
                                            end={requisicoesAtrasadasValue}
                                            duration={2.75}
                                            separator="."
                                            id="Kpi.Counter"
                                        />
                                    </Kpi.Counter>
                                    <Kpi.Icon id="Kpi_Icon">
                                        <BiMessageError id="BiMessageError" />
                                        <Kpi.Title id="Kpi_Title">Solicitações Atrasadas</Kpi.Title>
                                    </Kpi.Icon>
                                </Kpi.Content>
                            </Kpi.Card>
                        </Slide>
                        <Slide direction="up" in={mostrarAvaliacaoMedia}>
                            <Kpi.Card id="Kpi_Card" className="Kpi_Card_5">
                                <Kpi.Content id="Kpi_Content">
                                    <Kpi.Counter id="Kpi.Counter" className="ImClipboard">
                                        <CountUp
                                            start={0}
                                            end={avaliacaoMediaValue}
                                            decimals={1}
                                            duration={2.75}
                                            separator="."
                                        />
                                    </Kpi.Counter>
                                    <Kpi.Icon id="Kpi_Icon">
                                        <ImClipboard id="ImClipboard" />
                                        <Kpi.Title id="Kpi_Title">Avaliação Média</Kpi.Title>
                                    </Kpi.Icon>
                                </Kpi.Content>
                            </Kpi.Card>
                        </Slide>
                    </Kpi>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Graphs.Card id="Graphs_Card">
                        <Graphs.Content id="Graphs_Content">
                            <Graphs.Title id="Graphs_Title">Solicitações X Assuntos por Status</Graphs.Title>
                            <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer width="100%" height="100%">

                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={requisicoesPorAssuntoEStatusList}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <YAxis dataKey="total" type="number" domain={[0, 'dataMax + 3']} />
                                        <XAxis dataKey="assuntoDescricao" tick={false} />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="Aberta" stackId={1} fill="#a3ef9a" />
                                        <Bar dataKey="Andamento" stackId={1} fill="#efec9a" />
                                        <Bar dataKey="Aguardando solicitante" stackId={1} fill="#9aefe9" />
                                        <Bar dataKey="Em verificação interna" stackId={1} fill="#9aa3ef" />
                                        <Bar dataKey="Aguardando órgão externo" stackId={1} fill="#e89aef" >
                                            <LabelList
                                                dataKey="total"
                                                position="center"
                                                content={renderLabel}
                                            />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </Graphs.Content>
                    </Graphs.Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Graphs.Card id="Graphs_Card">
                        <Graphs.Content id="Graphs_Content">
                            <Graphs.Title id="Graphs_Title">Avaliações</Graphs.Title>
                            <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={notasAvaliacoesList}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="nota 1" stackId="1" fill="#ef9a9a" />
                                        <Bar dataKey="nota 2" stackId="1" fill="#f48fb1 " />
                                        <Bar dataKey="nota 3" stackId="1" fill="#ce93d8 " />
                                        <Bar dataKey="nota 4" stackId="1" fill="#9fa8da " />
                                        <Bar dataKey="nota 5" stackId="1" fill="#c5e1a5 " />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </Graphs.Content>
                    </Graphs.Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Graphs.Card id="Graphs_Card">
                        <Graphs.Content id="Graphs_Content">
                            <Graphs.Title id="Graphs_Title">Nº Avaliações X Motivos</Graphs.Title>
                            <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={650}
                                        height={300}
                                        data={requisicoesPorMotivosList}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="motivo" tick={false} />
                                        <YAxis dataKey="total" type="number" domain={[0, 'dataMax + 3']} />
                                        <Tooltip />
                                        <Bar dataKey="total" stackId="1" fill="#17caf9">
                                            <LabelList
                                                dataKey="total"
                                                position="center"
                                                content={renderLabel}
                                            />
                                            {
                                                requisicoesPorMotivosList.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={obtenhaCorRandom()} />
                                                ))
                                            }

                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </Graphs.Content>
                    </Graphs.Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Graphs.Card id="Graphs_Card">
                        <Graphs.Content id="Graphs_Content">
                            <Graphs.Title id="Graphs_Title">Nº Solicitações X Tempo Atendimento</Graphs.Title>
                            <div style={{ width: '100%', height: 300 }}>

                                <ResponsiveContainer width="100%" height="100%">

                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={requisicoesPorAssuntoETempoList}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <YAxis dataKey="total" type="number" domain={[0, 'dataMax + 3']} />
                                        <XAxis dataKey="assuntoDescricao" tick={false} />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="Menos que 24h" stackId={1} fill="#a3ef9a" />
                                        <Bar dataKey="Entre 24h e 48h" stackId={1} fill="#efec9a" />
                                        <Bar dataKey="Maior que 48h" stackId={1} fill="#efa19a">
                                            <LabelList
                                                dataKey="total"
                                                position="center"
                                                content={renderLabel}
                                            />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>


                            </div>
                        </Graphs.Content>
                    </Graphs.Card>
                </Grid>
                {
                    !ehAtendente && !ehCliente
                        ?
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Graphs.Card id="Graphs_Card">
                                <Graphs.Content id="Graphs_Content">
                                    <Graphs.Title id="Graphs_Title">Nota Média Atendentes</Graphs.Title>
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            getRowId={(r) => r.nome}
                                            columns={
                                                [
                                                    { "field": "nome", "headerName": 'Atendente', "minWidth": 190, "editable": false },
                                                    { "field": "nota", "headerName": "Nota", "width": 125, type: 'number', "editable": false },
                                                    { "field": "quantidadeRequisicao", "headerName": "Finalizadas", "width": 165, type: 'number', "editable": false }
                                                ]}
                                            rows={listaMediaAvalicaoRequisicao}
                                            density={'small'}
                                            disableSelectionOnClick
                                            loading={false}
                                            localeText={ptBR.props.MuiDataGrid.localeText}
                                        />
                                    </div>
                                </Graphs.Content>
                            </Graphs.Card>
                        </Grid>
                        :
                        <></>
                }
                {
                    !ehAtendente && !ehCliente
                        ?
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Graphs.Card id="Graphs_Card">
                                <Graphs.Content id="Graphs_Content">
                                    <Graphs.Title id="Graphs_Title">Sol. Finalizadas atendentes</Graphs.Title>
                                    <div style={{ height: 300, width: '100%', padding: "0 0 90px" }}>
                                        <ResponsiveContainer>
                                            <LineChart
                                                data={listaReqFinalizadosAtendentesTrintaDiasDados}

                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="dataFinalizacao" />
                                                <YAxis yAxisId="left" />
                                                <Tooltip />

                                                {
                                                    listaAtendentesTrintaDiasSelecionados.map(x => {
                                                        const cor = obtenhaCorRandom();
                                                        return <Line key={uuid()} yAxisId="left" type="monotone" dataKey={x} stroke={cor} fill={cor} activeDot={{ r: 8 }} />
                                                    })
                                                }

                                            </LineChart>
                                        </ResponsiveContainer>
                                        {
                                            listaAtendentesTrintaDiasDistinct && listaAtendentesTrintaDiasDistinct.length > 0
                                                ?
                                                <Autocomplete
                                                    title='Atendentes'
                                                    multiple
                                                    id="tags-standard"
                                                    options={listaAtendentesTrintaDiasDistinct}
                                                    getOptionLabel={(option) => option.title}
                                                    defaultValue={[listaAtendentesTrintaDiasDistinct[0]]}
                                                    getOptionDisabled={(options) => (listaAtendentesTrintaDiasSelecionados.length >= 4 ? true : false)}
                                                    onChange={(e, v) => adicioneAtendentesFinalizadosAtendentesPorTrintaDias(e, v)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Selecione os atendentes"
                                                            placeholder="Atendentes"
                                                        />
                                                    )}
                                                />
                                                :
                                                <></>
                                        }

                                    </div>
                                </Graphs.Content>
                            </Graphs.Card>
                        </Grid> :
                        <></>
                }
                {
                    !ehAtendente && !ehCliente
                        ?
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Graphs.Card id="Graphs_Card">
                                <Graphs.Content id="Graphs_Content">
                                    <Graphs.Title id="Graphs_Title">Média  das Avaliações</Graphs.Title>
                                    <div style={{ height: 300, width: '100%', padding: "0 0 90px" }}>
                                        <ResponsiveContainer>
                                            <LineChart
                                                data={listaValoresMediaAvaliacaoSetorSupervisorSelecionados}

                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="dataAvaliacao" />
                                                <YAxis yAxisId="left" />
                                                <Tooltip />

                                                {
                                                    listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.map(x => {
                                                        const cor = obtenhaCorRandom();
                                                        return <Line key={uuid()} yAxisId="left" type="monotone" dataKey={x} stroke={cor} fill={cor} activeDot={{ r: 8 }} />
                                                    })
                                                }

                                            </LineChart>
                                        </ResponsiveContainer>
                                        {
                                            listaAssuntosMediaAvaliacaoSetorSupervisor && listaAssuntosMediaAvaliacaoSetorSupervisor.length > 0
                                                ?
                                                <Autocomplete
                                                    title='Atendentes'
                                                    multiple
                                                    id="tags-standard"
                                                    options={listaAssuntosMediaAvaliacaoSetorSupervisor}
                                                    getOptionLabel={(option) => option.title}
                                                    defaultValue={[listaAssuntosMediaAvaliacaoSetorSupervisor[0]]}
                                                    getOptionDisabled={(options) => (listaAssuntosMediaAvaliacaoSetorSupervisorSelecionados.length >= 4 ? true : false)}
                                                    onChange={(e, v) => adicioneAssuntosMediaAvaliacaoSetorSupervisorSelecionados(e, v)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Selecione os assuntos"
                                                            placeholder="Assuntos"
                                                        />
                                                    )}
                                                />
                                                :
                                                <></>
                                        }

                                    </div>
                                </Graphs.Content>
                            </Graphs.Card>
                        </Grid> :
                        <></>
                }
                {
                    !ehAtendente && !ehCliente
                        ?
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Graphs.Card id="Graphs_Card">
                                <Graphs.Content id="Graphs_Content">
                                    <Graphs.Title id="Graphs_Title">Atendentes X Quant. Solicitações ativas</Graphs.Title>
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            getRowId={(r) => r.usuarioId}
                                            columns={
                                                [
                                                    { "field": "atendente", "headerName": "Atendente", "editable": false, flex: 1 },
                                                    { "field": "setor", "headerName": "Setor", "editable": false, flex: 1 },
                                                    {
                                                        "field": "link", "headerName": "Quant.", "editable": false, flex: 1,
                                                        renderCell: (params) => {
                                                            const filtro = `{"filtros":[{"Coluna":[5],"Comparador":0,"Valores":["${params.row.setorId}"]},{"Coluna":[4],"Comparador":0,"Valores":["${params.row.usuarioId}"]},{"Coluna":[2],"Comparador":0,"Valores":["1","2"]}],"firstResult":1,"maxResults":10,"gerarDadosComplementares":true,"paginaAtual":1,"qtdeRegistros":10}`;

                                                            return <><NavLink
                                                                activeClassName="active" to={`/requisicoes/(${filtro})`}>
                                                                <div style={{
                                                                    "height": "25px",
                                                                    "width": "25px",
                                                                    "line-height": "24px",
                                                                    "background-color": "#0ac5d4",
                                                                    "border-radius": "100%",
                                                                    "color": "white",
                                                                    "text-align": "center"
                                                                }}>{params.row.quantidade}</div>
                                                            </NavLink></>
                                                        }
                                                    }
                                                ]}
                                            rows={listaSolicitacoesDeAtiviasAtendente}
                                            density={'small'}
                                            disableSelectionOnClick
                                            loading={false}
                                            localeText={ptBR.props.MuiDataGrid.localeText}
                                        />
                                    </div>
                                </Graphs.Content>
                            </Graphs.Card>
                        </Grid>
                        :
                        <></>
                }

                {
                    ehSupervisor
                        ?
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Graphs.Card id="Graphs_Card">
                                <Graphs.Content id="Graphs_Content">
                                    <Graphs.Title id="Graphs_Title">Quant. Interações dos Atendentes</Graphs.Title>
                                    <div style={{ width: '100%', height: 300 }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={listaQuantidadeDeIntegracoesDoAtendentes}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <YAxis dataKey="quantidade" type="number" domain={[0, 'dataMax + 3']} />
                                                <XAxis dataKey="atendente" tick={false} />
                                                <Tooltip />
                                                <Legend />
                                                {
                                                    listaQuantidadeDeIntegracoesDoAtendentes.map(x => <Bar dataKey={x.atendente} stackId={1} fill={obtenhaCorRandom()} />)
                                                }
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Graphs.Content>
                            </Graphs.Card>
                        </Grid>
                        :
                        <></>
                }
                {
                    ehSupervisor
                        ?
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Graphs.Card id="Graphs_Card">
                                <Graphs.Content id="Graphs_Content">
                                    <Graphs.Title id="Graphs_Title">Tempo Médio de Resposta Atendente</Graphs.Title>
                                    <div style={{ width: '100%', height: 300 }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart
                                                width={500}
                                                height={300}
                                                data={listaTempoMedioRespostaAtendente}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="dataFormatada"/>
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                {
                                                   _.uniq(listaTempoMedioRespostaAtendente.map(x=> x.atendente))
                                                        .map(nome =>
                                                            <Line type="monotone" dataKey={nome} stroke={obtenhaCorRandom()} activeDot={{ r: 8 }} />
                                                        )
                                                }
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Graphs.Content>
                            </Graphs.Card>
                        </Grid>
                        :
                        <></>
                }
            </Grid>
        </Container >
    )
}