import { useState, useRef, useEffect } from 'react'
import { useSnackbar } from 'notistack'

import { Request } from './styles'
import { v4 as uuid } from 'uuid'

import ContainerRequest from "../../../components/layout/ContainerRequest"
import DialogDetailRequest from '../../../components/elements-ui/DialogDetailRequest'
import PreviewFileImage from '../../../components/elements-ui/PreviewFileImage'
import HistoryPrintRequest from '../../../components/elements-ui/HistoryPrintRequest'
import DialogSendEmailContactRequest from '../../../components/elements-ui/DialogSendEmailContactRequest'
import DialogAttachMessage from '../../../components/elements-ui/DialogAttachMessage/'
import DialogSendMessageEditor from '../../../components/elements-ui/DialogSendMessageEditor/'
import RequestMessages from '../../../components/elements-ui/RequestMessages/'
import SendMessageBox from '../../../components/elements-ui/SendMessageBox/'
import NavBarMessagesRequest from '../../../components/elements-ui/NavBarMessagesRequest/'
import DialogChangeSubject from '../../../components/elements-ui/DialogChangeSubject'
import DialogFinalization from '../../../components/elements-ui/DialogFinalization'
import DialogAssociatedTasks from '../../../components/elements-ui/DialogAssociatedTasks'
import DialogCreateTasks from '../../../components/elements-ui/DialogCreateTasks'
import DialogInternalVerification from '../../../components/elements-ui/DialogInternalVerification'
import DialogWaitingExternalOrgao from '../../../components/elements-ui/DialogWaitingExternalOrgao'
import DialogAssociateTask from '../../../components/elements-ui/DialogAssociateTask'
import DialogReopen from '../../../components/elements-ui/DialogReopen'
import DialogConfirmation from '../../../components/elements-ui/DialogConfirmation'

import api from '../../../services/api'
import { removeDuplicatesArray } from '../../../functions'
import DialogAnexoRequest from '../../../components/elements-ui/DialogAnexoRequest'

export default function Requisicao(props) {
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const [loadingSendMessage, setLoadingSendMessage] = useState(false)
    const [arquivos, setArquivos] = useState([])
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [openDialogEditor, setOpenDialogEditor] = useState(false)
    const [openMoreOptions, setOpenMoreOptions] = useState(false)
    const [openDialogDetailRequest, setOpenDialogDetailRequest] = useState(false)
    const [openAnexosDaSolicitacao, setOpenAnexosDaSolicitacao] = useState(false)
    const [openDialogSendEmailContactRequest, setOpenDialogSendEmailContactRequest] = useState(false)
    const anchorRef = useRef(null)
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false
    })
    const [value, setValue] = useState(0)
    const [openDialogAttachment, setOpenDialogAttachment] = useState(false)

    const [attachs, setAttachs] = useState([])
    const [activeAttach, setActiveAttach] = useState(null)

    const [previewFile, setPreviewFile] = useState({
        urlFile: null,
        openClose: null
    })

    const [mensagens, setMensagens] = useState({
        id: null,
        nome: null,
        email: null,
        telefone: null,
        nire: null,
        cnpj: null,
        cpf: null,
        avaliacao: null,
        acoesPermitidas: [],
        status: [],
        assuntos: [],
        mensagens: [],
        arquivos: [],
        motivosReabertura: []
    })


    const [openDialogReopen, setOpenDialogReopen] = useState(false)
    const [openDialogAssunto, setOpenDialogAssunto] = useState(false)
    const [openDialogTarefasAssociadas, setOpenDialogTarefasAssociadas] = useState(false)
    const [openDialogCreateTasks, setOpenDialogCreateTasks] = useState(false)
    const [openDialogInternalVerification, setOpenDialogInternalVerification] = useState(false)
    const [openDialogWaitingExternalOrgao, setOpenDialogWaitingExternalOrgao] = useState(false)
    const [openDialogTarefaAssociada, setOpenDialogTarefaAssociada] = useState(false)
    const [tipoTarefa, setTipoTarefa] = useState(1);

    const [textInputSendMessageBox, setTextInputSendMessageBox] = useState('')

    const [openAguardandoSolicitante, setOpenAguardandoSolicitante] = useState(false)


    const [openDialogChangeStatusAndamentoRequest, setOpenDialogChangeStatusAndamentoRequest] = useState(false)
    const [cancelDialogChangeStatusAndamento, setCancelDialogChangeStatusAndamento] = useState(false)
    const [confirmDialogChangeStatusAndamento, setConfirmDialogChangeStatusAndamento] = useState(false)

    const [openDialogChangeStatusVerificacaoInterna, setOpenDialogChangeStatusVerificacaoInterna] = useState(false)
    const [cancelDialogChangeStatusVerificacaoInterna, setCancelDialogChangeStatusVerificacaoInterna] = useState(false)
    const [confirmDialogChangeStatusVerificacaoInterna, setConfirmDialogChangeStatusVerificacaoInterna] = useState(false)

    const [openDialogChangeStatusOrgaoExterno, setOpenDialogChangeStatusOrgaoExterno] = useState(false)
    const [cancelDialogChangeStatusOrgaoExterno, setCancelDialogChangeStatusOrgaoExterno] = useState(false)
    const [confirmDialogChangeStatusOrgaoExterno, setConfirmDialogChangeStatusOrgaoExterno] = useState(false)

    const [openFinalizarRequisicao, setOpenFinalizarRequisicao] = useState(false)
    const [idUltimoAssuntoRequisicao, setIdUltimoAssuntoRequisicao] = useState(0)

    const handleOpenDialogDetailRequest = () => {
        setOpenDialogDetailRequest(true)
    }

    const handleAttachment = (event, inputMessage) => {
        Array.from(event.target.files).map((file, index) => {
            if (Boolean(file)) {
                if (Number(file.size) <= 10000000) {
                    if (
                        file.type === "application/pdf"
                        || file.type === "image/jpg"
                        || file.type === "image/jpeg"
                        || file.type === "image/jpe"
                        || file.type === "image/jfif"
                        || file.type === "image/png"
                        || file.type === "image/bmp"
                        || file.type === "image/gif"
                        || file.type === "application/pkcs7-signature"
                    ) {
                        const fileReader = new window.FileReader()
                        fileReader.onload = (fileLoad) => {
                            const { result } = fileLoad.target
                            setAttachs((prevState) => [...prevState, {
                                id: uuid(),
                                file: file,
                                name: file.name,
                                type: file.type,
                                url: result
                            }])

                            if (index === 0) {
                                setActiveAttach({
                                    id: uuid(),
                                    file: file,
                                    name: file.name,
                                    type: file.type,
                                    url: result
                                })
                            }
                        }

                        fileReader.readAsDataURL(file)
                        setTextInputSendMessageBox(inputMessage ?? '')
                        handleClickOpenDialogAttachment()
                    }
                    else {
                        enqueueSnackbar('A extensão do arquivo não é suportado', { variant: 'error' })
                    }
                }
                else {
                    enqueueSnackbar('O arquivo não pode ser maior que 10mb', { variant: 'error' })
                }
            }
        })
    }

    const handleDeleteAttach = (id) => {
        let newArrAttachs = []
        attachs.map(attach => {
            if (attach.id !== id) {
                newArrAttachs.push(attach)
            }
        })
        if (newArrAttachs.length === 0) {
            setActiveAttach(null)
            setAttachs([])
        }
        else {
            setActiveAttach(newArrAttachs[0])
            setAttachs(newArrAttachs)
        }
    }

    const clearAttachs = () => {
        setActiveAttach(null)
        setAttachs([])
    }

    const handleClickOpenDialogAttachment = () => {
        setOpenDialogAttachment(true)
    }

    const handleCloseDialogAttachment = () => {
        setOpenDialogAttachment(false)
        clearAttachs()
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            //postMensagem()
        }
    }

    const handleKeyDownAttach = event => {
        if (event.key === 'Enter') {
            handleSubmitAttachMessage()
        }
    }

    const handleSubmitAttachMessage = () => {
        //postMensagem()
        handleCloseDialogAttachment()
    }

    const handleSendMessageTextAttachment = () => {
        handleSendMessageText()
        handleCloseDialogAttachment()
    }

    const handleSendMessageText = () => {
        // setMessages([...messages, {
        //     message: textMessage
        // }])

        // setTextMessage('')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const handleToggle = () => {
        setOpenMoreOptions((prevOpen) => !prevOpen)
    }

    const handleCloseMoreOptions = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpenMoreOptions(false)
    }

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault()
            setOpenMoreOptions(false)
        }
    }

    const prevOpen = useRef(openMoreOptions)

    const handleClickOpen = () => {
        setOpenDialogEditor(true)
    }

    const handlePrintRequest = () => {
        window.print()
    }

    const getMensagens = async (onload) => {
        setLoading(onload ? true : false)
        await api.get(`/jucerja/v1/Requisicoes/detalhes/interacoes?numeroRequisicao=${props.match.params.id}`)
            .then(response => {
                let mensagensFormat = []
                response.data.status.map(status => mensagensFormat.push(Object.assign(status, { tipo: 'status' })))
                response.data.assuntos.map(status => mensagensFormat.push(Object.assign(status, { tipo: 'assuntos' })))

                setIdUltimoAssuntoRequisicao(response.data.assuntos.pop().id)

                response.data.mensagens.map(msg => {
                    let anexosMensagem = []
                    response.data.arquivos.map(anexo => {
                        if (anexo.mensagemId === msg.id) {
                            anexosMensagem.push(Object.assign(
                                anexo,
                                {
                                    tipo: 'mensagens',
                                    ehAnexo: true,
                                    extensao: anexo.nome.substr(anexo.nome.length - 3)
                                }
                            ))
                        }
                    })
                    mensagensFormat.push(
                        Object.assign(
                            msg,
                            {
                                tipo: 'mensagens',
                                anexos: anexosMensagem
                            }
                        )
                    )
                })

                response.data.arquivos.map(anexo => {
                    if (!anexo.mensagemId) {
                        mensagensFormat.push(
                            Object.assign(
                                anexo,
                                {
                                    tipo: 'mensagens',
                                    ehAnexo: true,
                                    extensao: anexo.nome.substr(anexo.nome.length - 3)
                                }
                            )
                        )
                    }
                })

                setArquivos(response.data.arquivos);

                mensagensFormat.sort((a, b) => {
                    return new Date(a.dataOperacao) - new Date(b.dataOperacao);
                })

                setLoading(false)
                setError(false)
                setErrorMessage('')
                setMensagens(
                    Object.assign(response.data, {
                        mensagens: mensagensFormat,
                    })
                )
            })
            .catch(error => {
                setLoading(false)
                setError(true)
                setErrorMessage(error.toString())
            })
    }

    useEffect(() => {
        if (prevOpen.current === true && openMoreOptions === false) {
            anchorRef.current.focus()
        }
        prevOpen.current = openMoreOptions
    }, [openMoreOptions])

    useEffect(async () => {

        if (!openAguardandoSolicitante) return;

        setLoading(true)
        await api.put(`/jucerja/v1/Requisicoes/aguardarsolicitante?requisicaoid=${props.match.params.id}`)
            .finally(() => { setLoading(false); setOpenAguardandoSolicitante(false); })
            .catch(error => {

                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            });
    }, [openAguardandoSolicitante])

    useEffect(async () => {

        if (!confirmDialogChangeStatusAndamento) return;

        setLoading(true)
        await api.put(`/jucerja/v1/Requisicoes/${props.match.params.id}/statusandamento`)
            .finally(() => { setLoading(false); setConfirmDialogChangeStatusAndamento(false); })
            .catch(error => {

                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            });

    }, [confirmDialogChangeStatusAndamento])

    useEffect(async () => {

        if (!confirmDialogChangeStatusVerificacaoInterna) return;

        setLoading(true)
        await api.put(`/jucerja/v1/Requisicoes/${props.match.params.id}/statussolicitacao/6`)
            .finally(() => { setLoading(false); setConfirmDialogChangeStatusVerificacaoInterna(false); setOpenDialogChangeStatusVerificacaoInterna(false); })
            .catch(error => {

                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            });

    }, [confirmDialogChangeStatusVerificacaoInterna])

    useEffect(async () => {

        if (!confirmDialogChangeStatusOrgaoExterno) return;

        setLoading(true)
        await api.put(`/jucerja/v1/Requisicoes/${props.match.params.id}/statussolicitacao/5`)
            .finally(() => { setLoading(false); setConfirmDialogChangeStatusOrgaoExterno(false); setOpenDialogChangeStatusOrgaoExterno(false); })
            .catch(error => {

                if (error.response.status === 422 || error.response.status === 500) {
                    enqueueSnackbar(error.response.data.messages[0].mensagem.toString(), { variant: 'error' })
                }
                else {
                    enqueueSnackbar(error.toString(), { variant: 'error' })
                }
            });

    }, [confirmDialogChangeStatusOrgaoExterno])

    return (
        <>
            <DialogReopen
                idRequisicao={props.match.params.id}
                openDialogReopen={openDialogReopen}
                setOpenDialogReopen={setOpenDialogReopen}
            />

            <DialogSendEmailContactRequest
                openDialogSendEmailContactRequest={openDialogSendEmailContactRequest}
                setOpenDialogSendEmailContactRequest={setOpenDialogSendEmailContactRequest}
                idRequisicao={props.match.params.id}
            />

            <DialogChangeSubject
                idRequisicao={props.match.params.id}
                openDialogAssunto={openDialogAssunto}
                setOpenDialogAssunto={setOpenDialogAssunto}
                getMensagens={getMensagens}
            />

            <DialogFinalization
                idRequisicao={props.match.params.id}
                openDialogFinalization={openFinalizarRequisicao}
                setOpenDialogFinalization={setOpenFinalizarRequisicao}
            />

            <HistoryPrintRequest mensagens={mensagens} />

            <DialogDetailRequest
                openDialogDetailRequest={openDialogDetailRequest}
                stateChanger={setOpenDialogDetailRequest}
                mensagens={mensagens}
            />

            <DialogAnexoRequest
                arquivos={arquivos}
                openDialogAnexosRequest={openAnexosDaSolicitacao}
                setOpenAnexosDaSolicitacao={setOpenAnexosDaSolicitacao} />

            <PreviewFileImage setpreview={previewFile} />

            <DialogAttachMessage
                openDialogAttachment={openDialogAttachment}
                setOpenDialogAttachment={setOpenDialogAttachment}
                handleCloseDialogAttachment={handleCloseDialogAttachment}
                activeAttach={activeAttach}
                handleKeyDownAttach={handleKeyDownAttach}
                handleSendMessageTextAttachment={handleSendMessageTextAttachment}
                attachs={attachs}
                setActiveAttach={setActiveAttach}
                handleDeleteAttach={handleDeleteAttach}
                handleAttachment={handleAttachment}
                handleSubmitAttachMessage={handleSubmitAttachMessage}
                textInputSendMessageBox={textInputSendMessageBox}
                idRequisicao={props.match.params.id}
            />

            <DialogSendMessageEditor
                idRequisicao={props.match.params.id}
                openDialogEditor={openDialogEditor}
                setOpenDialogEditor={setOpenDialogEditor}
                idAssunto={idUltimoAssuntoRequisicao}
            />

            <DialogAssociatedTasks
                idRequisicao={props.match.params.id}
                openDialogTarefasAssociadas={openDialogTarefasAssociadas}
                setOpenDialogTarefasAssociadas={setOpenDialogTarefasAssociadas}
                setOpenDialogCreateTasks={(b) => { setTipoTarefa(1); setOpenDialogCreateTasks(b); }}
                setOpenDialogTarefaAssociada={setOpenDialogTarefaAssociada}
                acoesPermitidas={mensagens.acoesPermitidas}
            />

            <DialogAssociateTask
                idRequisicao={props.match.params.id}
                openDialogTarefaAssociada={openDialogTarefaAssociada}
                setOpenDialogTarefaAssociada={setOpenDialogTarefaAssociada}
                setOpenDialogTarefasAssociadas={setOpenDialogTarefasAssociadas}
            />

            <DialogCreateTasks
                idRequisicao={props.match.params.id}
                tipoTarefa={tipoTarefa}
                setOpenDialogTarefasAssociadas={setOpenDialogTarefasAssociadas}
                openDialogCreateTasks={openDialogCreateTasks}
                setOpenDialogCreateTasks={setOpenDialogCreateTasks}
            />

            <DialogInternalVerification
                openDialogInternalVerification={openDialogInternalVerification}
                setOpenDialogInternalVerification={setOpenDialogInternalVerification}
            />

            <DialogWaitingExternalOrgao
                idRequisicao={props.match.params.id}
                openDialogWaitingExternalOrgao={openDialogWaitingExternalOrgao}
                setOpenDialogWaitingExternalOrgao={setOpenDialogWaitingExternalOrgao}
            />

            <DialogWaitingExternalOrgao
                idRequisicao={props.match.params.id}
                openDialogWaitingExternalOrgao={openDialogWaitingExternalOrgao}
                setOpenDialogWaitingExternalOrgao={setOpenDialogChangeStatusAndamentoRequest}
            />

            <DialogConfirmation
                openDialog={openDialogChangeStatusAndamentoRequest}
                setCancel={setCancelDialogChangeStatusAndamento}
                setConfirm={setConfirmDialogChangeStatusAndamento}
                txtCancel="Não"
                setOpenDialog={setOpenDialogChangeStatusAndamentoRequest}
                txtConfirm="Sim"
                txtTitle="Andamento"
                txtContainer={`Confirmar a alteração de status da Solicitação #${props.match.params.id} para Andamento ?`}
            />

            <DialogConfirmation
                openDialog={openDialogChangeStatusVerificacaoInterna}
                setCancel={setCancelDialogChangeStatusVerificacaoInterna}
                setConfirm={setConfirmDialogChangeStatusVerificacaoInterna}
                txtCancel="Não"
                setOpenDialog={setOpenDialogChangeStatusVerificacaoInterna}
                txtConfirm="Sim"
                txtTitle="Em Verificação Interna"
                txtContainer={`Confirmar a alteração de status da Solicitação #${props.match.params.id} para Em Verificação Interna ?`}
            />

            <DialogConfirmation
                openDialog={openDialogChangeStatusOrgaoExterno}
                setCancel={setCancelDialogChangeStatusOrgaoExterno}
                setConfirm={setConfirmDialogChangeStatusOrgaoExterno}
                txtCancel="Não"
                setOpenDialog={setOpenDialogChangeStatusOrgaoExterno}
                txtConfirm="Sim"
                txtTitle="Aguardando Órgão Externo"
                txtContainer={`Confirmar a alteração de status da Solicitação #${props.match.params.id} para Aguardando Órgão Externo ?`}
            />

            <ContainerRequest>
                <Request id="Request">

                    <NavBarMessagesRequest
                        error={error}
                        loading={loading}
                        mensagens={mensagens}
                        handleOpenDialogDetailRequest={handleOpenDialogDetailRequest}
                        anchorRef={anchorRef}
                        openMoreOptions={openMoreOptions}
                        setOpenMoreOptions={setOpenMoreOptions}
                        handleToggle={handleToggle}
                        handleCloseMoreOptions={handleCloseMoreOptions}
                        handleListKeyDown={handleListKeyDown}
                        handlePrintRequest={handlePrintRequest}
                        setOpenDialogSendEmailContactRequest={setOpenDialogSendEmailContactRequest}
                        setLoading={setLoading}
                        setError={setError}
                        setErrorMessage={setErrorMessage}
                        setOpenDialogAssunto={setOpenDialogAssunto}
                        setOpenDialogTarefasAssociadas={setOpenDialogTarefasAssociadas}
                        setOpenDialogInternalVerification={setOpenDialogChangeStatusVerificacaoInterna}
                        setOpenDialogWaitingExternalOrgao={setOpenDialogChangeStatusOrgaoExterno}
                        setOpenDialogChangeStatusAndamentoRequest={setOpenDialogChangeStatusAndamentoRequest}
                        setOpenDialogReopen={setOpenDialogReopen}
                        setOpenAguardandoSolicitante={setOpenAguardandoSolicitante}
                        setOpenFinalizarRequisicao={setOpenFinalizarRequisicao}
                        setOpenAnexosDaSolicitacao={setOpenAnexosDaSolicitacao}
                        arquivos={arquivos}
                    />

                    <RequestMessages
                        idRequisicao={props.match.params.id}
                        getMensagens={getMensagens}
                        error={error}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        loading={loading}
                        mensagens={mensagens}
                        handleAttachment={handleAttachment}
                    />
                    {
                        error
                            ?
                            null
                            :
                            mensagens.acoesPermitidas.map(acao => (
                                acao === "EnviarMensagem" && (
                                    <SendMessageBox
                                        key={uuid()}
                                        idRequisicao={props.match.params.id}
                                        mensagens={mensagens}
                                        loading={loading}
                                        handleAttachment={handleAttachment}
                                        handleClickOpen={handleClickOpen}
                                        toggleDrawer={toggleDrawer}
                                        state={state}
                                        value={value}
                                        setValue={setValue}
                                    />
                                )
                            ))
                    }
                </Request>
            </ContainerRequest>
        </>
    )
}