import Container from '../../components/layout/Container'
import Grid from '@material-ui/core/Grid'
import { useEffect, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../../components/elements-ui/Card"
import { Badge } from "../../components/elements-ui/Badge"
import { ActionTable } from "../../components/elements-ui/ActionTable"
import { Button, Checkbox } from '@material-ui/core'
import { AiFillCaretDown, AiOutlineEdit, AiOutlineDelete, AiFillCaretUp } from 'react-icons/all'
import { CgAdd } from 'react-icons/all'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../services/api'
import DialogConfirmation from '../../components/elements-ui/DialogConfirmation'
import { Requests } from '../Requisicoes/styles'
import DialogSecaoConfirm from '../../components/elements-ui/DialogSecaoConfirm'
import _ from 'lodash'
import { SecaoComponente } from './styles'

const PrimaryTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
    },
}))(Tooltip);

export default function Secao() {
    const { enqueueSnackbar } = useSnackbar();

    const [abrirExclusaoSecao, setAbrirExclusaoSecao] = useState(false);
    const [confirmarExclusaoSecao, setConfirmarExclusaoSecao] = useState(false);

    const [abrirCadastroSecao, setAbrirCadastroSecao] = useState(false);
    const [confirmarCadastroSecao, setConfirmarCadastroSecao] = useState(undefined);

    const [rowSecaoSelecionado, setRowSecaoSelecionado] = useState(undefined);

    const [registros, setRegistros] = useState([]);
    const [loading, setLoading] = useState(false);

    let funcaoAtualizarLista = _.debounce(() => {
        setLoading(true);
        setConfirmarCadastroSecao(rowSecaoSelecionado);
    }, 1000);

    const getRegistros = async () => {
        await api.get('/jucerja/v1/Secao')
            .then(response => {
                setRegistros(response.data);

                if (rowSecaoSelecionado)
                    setRowSecaoSelecionado(response.data.filter(r => r.id == rowSecaoSelecionado.id)[0])
            })
            .catch(error => {
            })
            .finally(() => {
                setLoading(false);
            });

        setLoading(false);
    }

    const subirRegistro = async () => {
        somarSubtrairOrdem(-1)
    }

    const descerRegistro = async () => {
        somarSubtrairOrdem(1);
    }

    const somarSubtrairOrdem = (quant) => {
        
        if (!rowSecaoSelecionado || loading)
            return;

        const index = registros.indexOf(rowSecaoSelecionado);
        let lista = [...registros];
        let alterou = false;

        if (quant < 0 && index > 0) {
            lista.splice(index, 1); // remove o objeto da posição atual
            lista.splice(index - 1, 0, rowSecaoSelecionado); // insere o objeto na posição anterior
            alterou = !alterou;
        } else if (index < lista.length - 1 && quant > 0) {
            lista.splice(index, 1); // remove o objeto da posição atual
            lista.splice(index + 1, 0, rowSecaoSelecionado); // insere o objeto na posição posterior
            alterou = !alterou;
        }

        setRegistros(lista);

        if (alterou) {
            funcaoAtualizarLista()
        }
    }

    const columns = [
        {
            field: 'titulo',
            headerName: 'Título',
            width: 300,
            renderCell: (param) =>
                <PrimaryTooltip title={param.value} placement="top">
                    <span>{param.value}</span>
                </PrimaryTooltip>
        },
        {
            field: 'resumo',
            headerName: 'Resumo',
            minWidth: 200,
            flex: 1,
            renderCell: (param) =>
                <PrimaryTooltip title={param.value} placement="top">
                    <span>{param.value}</span>
                </PrimaryTooltip>
        },
        {
            "field": "ativo", "headerName": "Ativo", "width": 165, "editable": false,
            valueGetter: (params) => params.value ? 'Sim' : 'Não',
            renderCell: (params) => {
                return <Badge type={params.value == 'Sim'} className="no-select">{params.value}</Badge>
            }
        },
        {
            field: 'acoes',
            headerName: 'Ações',
            width: 129,
            filterable: false,
            renderCell: (params) => {
                return (
                    <ActionTable>
                        {/* <Link to="/configuracoes">
                            <PrimaryTooltip title="Visualizar" placement="top">
                                <Button className="btn-open"><AiOutlineEye/></Button>
                            </PrimaryTooltip>
                        </Link> */}
                        <PrimaryTooltip title="Editar" placement="top">
                            <Button onClick={() => {
                                setAbrirCadastroSecao(!abrirCadastroSecao);
                            }
                            } className="btn-edit"><AiOutlineEdit /></Button>
                        </PrimaryTooltip>
                        <PrimaryTooltip title="Deletar" placement="top">
                            <Button onClick={() => {
                                setAbrirExclusaoSecao(!abrirExclusaoSecao);
                            }}
                                className="btn-delete"><AiOutlineDelete /></Button>
                        </PrimaryTooltip>
                    </ActionTable>
                )
            }
        },
    ]


    useEffect(() => {

        getRegistros();

    }, [])

    useEffect(() => {
        if (!confirmarCadastroSecao)
            return;

        let method;
        let action;
        let payload
        let ehCadastro = !(confirmarCadastroSecao?.id);

        if (ehCadastro) {
            payload = {...confirmarCadastroSecao, ordem : registros.length + 1};
            method = 'post';
            action = '/jucerja/v1/Secao'
        } else {
            payload = registros.map(x => ({ id: x.id, ordem: registros.indexOf(x) + 1 }));
            method = 'put';
            action = '/jucerja/v1/Secao/ordem/alterar'
        }
        
        api[method](action, payload)
            .then(response => {
                if (ehCadastro)
                    enqueueSnackbar(ehCadastro ? `Cadastro realizado com sucesso para a Seção de Título: ${confirmarCadastroSecao.titulo}.` : 'Atualização realizada para a lista de ordens.', { variant: 'success' });
                getRegistros();
            })
            .catch(error => {
                enqueueSnackbar(`Houve um erro ao ${ehCadastro ? 'Cadastrar' : 'Atualizar'} seção.`, { variant: 'error' });
            })
            .finally(() => {
                setAbrirCadastroSecao(false);
                setLoading(false);
            });

        setConfirmarCadastroSecao(undefined);

    }, [confirmarCadastroSecao])

    useEffect(() => {
        if (!confirmarExclusaoSecao)
            return;

        setLoading(true);

        api.delete(`/jucerja/v1/Secao/${rowSecaoSelecionado?.id}`)
            .then(response => {

                enqueueSnackbar(`Exclusão realizada com sucesso.`, { variant: 'success' });
                getRegistros();
                setRowSecaoSelecionado(undefined);
            })
            .catch(error => {
                enqueueSnackbar(`Houve um erro ao excluir seção.`, { variant: 'error' });
            })
            .finally(() => {
                setAbrirExclusaoSecao(false);
            });

    }, [confirmarExclusaoSecao])

    return (
        <>
            <DialogConfirmation
                openDialog={abrirExclusaoSecao}
                setOpenDialog={setAbrirExclusaoSecao}
                setConfirm={setConfirmarExclusaoSecao}
                txtConfirm="Confirmar"
                txtCancel="Cancelar"
                txtTitle="Confirmar exclusão da Seção"
                txtContainer={`Deseja realmente excluir a seção de id '${rowSecaoSelecionado?.id}' e título '${rowSecaoSelecionado?.titulo}'?`}
                closeControlOutsideOnConfirm={true}
            />

            <DialogSecaoConfirm
                openDialogSecao={abrirCadastroSecao}
                setOpenDialogSecao={setAbrirCadastroSecao}
                secaoEditar={rowSecaoSelecionado}
                setSecaoConfirmation={setConfirmarCadastroSecao}
            />

            <Container title="Seções">
                <Grid container >

                    <Card>
                        <Card.Content>
                            <div style={{ display: 'flex' }}>
                                <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <div style={{ height: 600, width: '100%' }}>
                                        <DataGrid
                                            getRowId={row => row.id}
                                            onRowClick={e => { setRowSecaoSelecionado(e.row); }}
                                            onSelectionModelChange={e => { if (e.length <= 0) { setRowSecaoSelecionado(undefined) } }}
                                            // rowCount={rowCountState}
                                            rows={registros}
                                            columns={columns}
                                            disableColumnMenu
                                            hideFooterPagination={true}
                                            loading={loading}
                                            // rowsPerPageOptions={[50]}
                                            // pageSize={50}
                                            // page={rowsState.page > 0 ? rowsState.page - 1 : 0}
                                            // pagination
                                            hideFooterSelectedRowCount
                                            disableExtendRowFullWidth={false}
                                            paginationMode="server"
                                            // onPageChange={(page) => {
                                            //     setRowsState((prev) => ({ ...prev, page: page + 1 }));
                                            // }}
                                            localeText={ptBR.props.MuiDataGrid.localeText}
                                        />

                                    </div>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <SecaoComponente.Actions>
                                            {/* <div className={rowSecaoSelecionado ? "mostrarAnimacao" : "esconderAnimacao"}>
                                                <Button style={{ background: 'var(--red)', color: 'white' }} onClick={e => setAbrirExclusaoSecao(!abrirExclusaoSecao)}><AiOutlineDelete />{"Excluir"}</Button>
                                            </div>
                                            <div className={rowSecaoSelecionado ? "mostrarAnimacao" : "esconderAnimacao"}>
                                                <Button className="btn-primary-actions" style={{ background: 'var(--yellow)', color: 'white' }} onClick={e => setAbrirCadastroSecao(!abrirCadastroSecao)}><AiOutlineEdit />{"Alterar"}</Button>
                                            </div>
                                            */}
                                            <Button className="btnFilter" onClick={e => {
                                                setRowSecaoSelecionado(undefined);
                                                setAbrirCadastroSecao(!abrirCadastroSecao)
                                            }} style={{ background: '#66c55b', color: 'white' }} ><CgAdd id="icon-link-nav" />{'Adicionar'}</Button>
                                        </SecaoComponente.Actions>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ position: 'relative', borderLeft: '1px solid #e0e0e0' }}>
                                    <div style={{
                                        textAlign: 'center',
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}>
                                        <div id="increment-count">
                                            <AiFillCaretUp style={{ fontSize: '50px', cursor: 'pointer', color: `${loading ? 'grey' : 'black'}` }} onClick={subirRegistro} />
                                        </div>
                                        <span className='no-select'>{rowSecaoSelecionado ? registros.indexOf(rowSecaoSelecionado) + 1 : ''}</span>
                                        <div id="decrement-count">
                                            <AiFillCaretDown style={{ fontSize: '50px', cursor: 'pointer', color: `${loading ? 'grey' : 'black'}` }} onClick={descerRegistro} />
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </Card.Content>
                    </Card>


                </Grid>
            </Container >
        </>
    )
}