import axios from 'axios'
import { validaToken, getToken } from './auth'

const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
})

api.interceptors.request.use(async config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    validaToken()
  }
  return config
})

export default api