import styled from 'styled-components'

export const LoadMessagesChat = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

LoadMessagesChat.Animation = styled.div`
    margin: 0;
    max-width: 450px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

LoadMessagesChat.Message = styled.p`
    font-weight: 600;
    color: var(--primary);
`
