import styled from 'styled-components'

export const StandardT = styled.div`
    width: '100%';
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    border: 2px solid var(--primary);
    border-radius: 15px;
    height: 100%;
`

StandardT.Label = styled.div`
    background: var(--white);
    color: var(--primary);
    position: absolute;
    left: 20px;
    font-size: 14px;
    font-weight: 700;
    padding: 0 10px;
    top: -9px;
`

StandardT.Content = styled.div`
    width: 100%;
    padding: 20px;
`

StandardT.Text = styled.div`
    max-height: 350px;
    min-height: 200px;
    overflow: auto;
    width: 100%;
    -webkit-user-select: none;  
    -moz-user-select: none;     
    -ms-user-select: none;      
    user-select: none;          
`
StandardT.Search = styled.div`
    max-height: 350px;     
    overflow-x: hidden;
    overflow-y: auto;     
`
StandardT.Option = styled.div`
    margin-bottom: 3px;
    word-wrap: break-word;
    border: 1px solid #c4c4c4;
    padding: 0.41em 16px;
    border-radius: 16px;
    cursor: pointer;
`
StandardT.span = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
`