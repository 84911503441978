import styled from 'styled-components'

export const DetailTask = styled.div`
    width: 100%;
`

DetailTask.Info = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @media (max-width: 764px) {
        flex-direction: column !important;
    }
`
DetailTask.Icon = styled.div`
    border-radius: 100%;
    background: #edeef7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 128px;
    margin: 0 30px 0 0;

    img {
        width: 84px;
        height: auto;
    }

    @media (max-width: 764px) {
        margin: 0 0 15px 0 !important;
    }
`

DetailTask.Img = styled.img`
    
`

DetailTask.Information = styled.div`
    width: calc(100% - 155px);

    @media (max-width: 764px) {
        width: 100% !important;
    }
`
DetailTask.Name = styled.div`
    font-weight: 600;
    font-size: 21px;
`
DetailTask.Email = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;

    svg {
        padding: 0 5px 0 0;
        color: var(--dark-orange);
    }

    @media (max-width: 764px) {
        margin: 0 0 15px 0 !important;
    }
`
DetailTask.Phone = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;

    svg {
        padding: 0 5px 0 0;
        color: var(--green);
    }

    @media (max-width: 764px) {
        margin: 0 0 15px 0 !important;
    }
`

DetailTask.CPF = styled.div`
    label {
        margin: 0;
        font-size: 13px;
        font-weight: 700;
        color: var(--primary);
    }

    p {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
    }

    @media (max-width: 764px) {
        width: 100% !important;
        margin: 0 0 15px 0 !important;
    }
`
DetailTask.Nire = styled.div`
    label {
        margin: 0;
        font-size: 13px;
        font-weight: 700;
        color: var(--primary);
    }

    p {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
    }

    @media (max-width: 764px) {
        width: 100% !important;
        margin: 0 0 15px 0 !important;
    }
`

DetailTask.CNPJ = styled.div`
    label {
        margin: 0;
        font-size: 13px;
        font-weight: 700;
        color: var(--primary);
    }

    p {
        min-width: 160px;
        margin: 0;
        font-size: 16px;
        font-weight: 400;
    }

    @media (max-width: 764px) {
        width: 100% !important;
        margin: 0 0 15px 0 !important;
    }
`

DetailTask.Assessment = styled.div`
    margin: 25px 0 0 0;
`
DetailTask.AssessmentTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-purple);
    margin: 0 0 10px 0;
`
DetailTask.AssessmentText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`
DetailTask.AssessmentInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`
DetailTask.AssessmentInfoNote = styled.div`
    margin: 0 30px 0 0;
    font-weight: 600;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &> div {
        position: relative;
        top: -2px;
        padding: 0 0 0 4px;
    }

    &> span {
        font-size: 17px;
        position: relative;
        top: -2px;
        margin: 0 0 0 4px;
    }
`

DetailTask.AssessmentInfoDate = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    color: var(--primary);

    svg {
        color: var(--primary);
        margin: 0 5px 0 0;
    }
`
DetailTask.AssessmentInformations = styled.div`
    margin: 15px 0 0 0;
`
DetailTask.AssessmentInformationsTitle = styled.div`
    font-weight: 900;
    margin: 0 0 5px 0;
`
DetailTask.AssessmentInformationsComment = styled.div`
    
`

DetailTask.InfoContact = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0;

    &> div {
        margin: 0 40px 0 0;
    }

    @media (max-width: 764px) {
        flex-wrap: wrap !important;
    }
`
DetailTask.InfoPJ = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 450px;
    margin: 20px 0 0 0;

    &> div {
        padding: 0 25px 0 0;
        width: calc(33.33333333333333% - 25px);
    }
`