import styled from 'styled-components'

export const ContainerComponent = styled.div`
    /* background: var(--white); */
    /* box-shadow: 0px 1px 9px -1px rgb(0 0 0 / 25%); */
    /* position: relative;
    top: 100px;
    left: 335px;
    width: calc(100% - 362px);
    border-radius: 10px;
    overflow: auto;
    margin-top: 23px; */
    
    position: relative;
    top: 95px;
    left: 335px;
    width: calc(100% - 335px);
    border-radius: 10px;
    overflow: auto;
    /* height: calc(100% - 183px); */

    @media (max-width: 993px) {
        top: 80px;
        width: calc(100%  - 20px);
        left: 10px;
        height: auto;
        margin-top: 0px;
    }
`

ContainerComponent.Content = styled.div`
    padding: 0 30px; 
    overflow: hidden;
    
    @media (max-width: 993px) {
        padding: 20px;
    }

    button.btnUrlBackContainer {
        padding: 0;
        min-width: 0;
        margin: 0 15px 0 0;
        border-radius: 100%;
        position: relative;
        top: -4px;
    }
`

ContainerComponent.Copyright = styled.div`
    font-size: 12px;
    color: var(--alternative3);
    position: relative;
    left: 365px;
    width: calc(100% - 365px);
    bottom: -135px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-bottom: 30px;
    
    @media (max-width: 993px) {
        width: calc(100% - 32px);
        left: 32px;
        margin: -30px 0 9px 0;
        padding-bottom: 25px;
    }
`

ContainerComponent.ImgIconUrlBack = styled.img`

`