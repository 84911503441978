import styled from 'styled-components'

export const Card = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 0 15px 0;
    background: var(--white);
    -webkit-transition: all ease .35s;
    transition: all ease .35s;
    border-bottom: 2px solid transparent;
    border-radius: 15px;
    overflow: hidden; 
`

Card.Content = styled.div`
    padding: 20px;
`

Card.Title = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0 0 1.75rem 0;
    width: 100%;
    text-align: left;
`

Card.Actions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0 25px 0;
`