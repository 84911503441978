import styled from 'styled-components'

export const PreviewFileImages = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

PreviewFileImages.Content = styled.div`
    width: calc(100% - 40px);
    max-width: 1024px;
    margin: 100px 0 0 0;
    position: relative;

    .preview-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
    }

    .preview-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .preview-file {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-pdf__Document {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .preview-bar {
        position: absolute;
        top: -75px;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        z-index: 9999;
        width: calc(100% - 17px);
        max-width: 1024px;
    }

    .preview-bar-left button.preview-button {
        background: var(--primary);
        color: var(--white);
        border: 0;
        border-radius: 100%;
        padding: 0;
        min-width: 0;
        width: 42px;
        height: 42px;
        margin: 0;
        cursor: pointer;
    }

    .preview-bar-left span.preview-pagecount {
        margin: 0 10px;
        padding: 0;
        position: relative;
        color: var(--white);
    }

    .preview-icons {
        position: absolute;
        top: -75px;
        right: 0;
        width: max-content;
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .preview-icons button.preview-button {
        background: var(--primary);
        color: var(--white);
        border: 0;
        border-radius: 100%;
        padding: 0;
        min-width: 0;
        width: 42px;
        height: 42px;
        margin: 0 6px;
    }

    .preview-bar-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .preview-bar-left button.preview-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .preview-icons button.preview-button {
        cursor: pointer;
    }

    .preview-bar-left button.preview-button[type="button"][disabled] {
        background: var(--grey);
        cursor: inherit;
    }

    .preview-bar .preview-bar-left:first-child {
        display: none;
    }

    .preview-bar-left button.preview-button:first-child {
        display: none;
    }
`

PreviewFileImages.ContentLoading = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`