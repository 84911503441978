import { useState, useEffect, useRef, React } from 'react'
import PropTypes from 'prop-types';
import Container from '../../components/layout/Container'

import { Badge } from "../../components/elements-ui/Badge"
import { useSnackbar } from 'notistack'
import FormControl from "@material-ui/core/FormControl"
import Tooltip from '@mui/material/Tooltip';

import { ConfiguracaoUsuarioComponente } from './styles'
import { Button, Grid, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, withStyles, TextField, Checkbox, Select, InputLabel, Input, FormHelperText, CircularProgress } from '@material-ui/core'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import Divider from '@mui/material/Divider'

import FormControlLabel from '@mui/material/FormControlLabel';
import { Card } from "../../components/elements-ui/Card"

import { Link, useHistory } from 'react-router-dom'

import { AiOutlineDownload, AiOutlineEdit, AiOutlineEye, BiArrowBack, BsBoxArrowInDown } from 'react-icons/all'
import { Autocomplete } from '@mui/material'
import { ActionTable } from '../../components/elements-ui/ActionTable'
import _ from 'lodash'
import { useDispatch } from 'react-redux';
import { updateCountDown } from '../../slices/requestMessageSlice';

export default function ConfiguracaoUsuario(props) {
    const dispatch = useDispatch();
    const [countDown, setCountDown] = useState(localStorage.getItem('countDown') ?? 0);
    const history = useHistory();
    const funcaoSetCountDown = _.debounce(() => {
        localStorage.setItem('countDown', countDown);
        dispatch(updateCountDown());
    }, 500)


    const handleChange = (event) => {
        if (event.target.value && Number(event.target.value) >= 0) {
            setCountDown(event.target.value)
        }
    }

    useEffect(() => {
        funcaoSetCountDown();
    }, [countDown])
    return (

        <ConfiguracaoUsuarioComponente>

            <Container title={
                <>
                    <Button className="btn-arrow-back" onClick={() => history.goBack()}><BiArrowBack /> </Button>
                    {'Configuração do Usuário'}
                </>
            }>
                <Card>
                    <Card.Content>
                        <Grid container spacing={4}>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Divider textAlign="left">{'Configuração Solicitações'}</Divider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <TextField
                                    required
                                    id="usuário-countdown"
                                    type="number"
                                    label="Tempo antes de enviar mensagem"
                                    variant="outlined"
                                    value={countDown}
                                    onChange={e => handleChange(e)}
                                    name="nome"
                                />

                            </Grid>
                        </Grid>
                    </Card.Content>
                </Card>

            </Container>

        </ConfiguracaoUsuarioComponente >
    )
}