import styled from 'styled-components'

export const SecaoComponente = styled.div`

`

SecaoComponente.Actions = styled.div`
    margin-right: 0.7rem;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    

    .mostrarAnimacao {
        opacity: 1;
        transition: opacity 0.6s, visibility 0.3s;
        visibility: show;
      }
      
      .esconderAnimacao {
        opacity: 0;
        transition: opacity 0.6s, visibility 0.3s;
        visibility: hidden;
      }

      button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background: #FFFFFF;
        border: 1px solid #E3E3E3;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 100px;
        padding: 8px 25px 6px 25px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text svg {
        margin: 0 7px 0 0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text span.MuiButton-label {
        text-transform: capitalize;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnPagination {
        min-width: 0;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`