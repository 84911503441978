import styled, { css } from 'styled-components'

export const ComponentMenu = styled.div`
    margin-bottom: 150px;

    ${props => props.drawer === true && css`
            width: 100%;
            display: flex;
            flex-direction: column; 
        `
    }

    ${props => props.drawer === false && css`
            
        `
    }

    @media (max-width: 993px) {
        display: ${props => props.drawer ? 'flex' : 'none'};
    }

    @media(min-width: 994px) {
        position: fixed;
        top: 100px;
        left: 20px;
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: calc(100% - 102px);
        overflow: auto;
        /* margin-top: 30px; */
    }
`

ComponentMenu.UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 250px;
    text-align: center;
`

ComponentMenu.UserInfoImage = styled.div`
    width: 84px;
    height: 84px;
    background: var(--white);
    border-radius: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 25px 0 0 0;
`

ComponentMenu.UserName = styled.p`
    font-size: 22px;
    font-family: var(--font-futura-md-bt);
    line-height: 18px;
    margin: 22px 0 0 0;
`

ComponentMenu.UserEmail = styled.p`
    font-size: 13px;
    margin: 5px 0 70px 0;
    word-break: break-all;
`

ComponentMenu.MenuList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 40px;

    a {
        display: flex;
        width: calc(100% - 5px);
        border-left: 5px solid var(--background-body);
        padding: 17px 0;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 19px;
        font-weight: 600;
        color: var(--alternative3);
        transition: all ease 0.35s;
        border: 1px solid rgb(221 217 255 / 0%);
        border-radius: 10px;
        position: relative;
        cursor: pointer;
        
        svg#caret-link-nav {
            position: absolute;
            left: 0;
            font-size: 21px;
        }

        svg#icon-link-nav {
            padding: 0 0 0 22px;
        }

        div#align-menu{
            padding: 0 0 0 88px
        }

        &:hover {
            color: var(--primary);
            background: #F6F5FF;
            box-shadow: 0px 1px 9px -1px rgb(0 0 0 / 12%);
            border-radius: 10px;
            border: 1px solid rgb(221 217 255 / 56%);
        }
            svg {
            margin: 0 20px 0 20px;
            font-size: 30px;
        }

        &.active {
            color: var(--primary);
            background: #F6F5FF;
            box-shadow: 0px 1px 9px -1px rgb(0 0 0 / 12%);
            border-radius: 10px;
            border: 1px solid rgb(221 217 255 / 56%);
        }
    }
`

ComponentMenu.Link = styled.div`
    width: 100%;

    .MuiCollapse-wrapperInner {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .MuiCollapse-wrapperInner a.waves-effect.waves-blue {
        width: calc(100% - 40px);
    }
`