import { useState, useEffect } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { BiMessageRounded, IoGitPullRequestOutline, IoPersonOutline, BiVerticalCenter, MdSubject, IoIosArrowBack, IoIosArrowForward, GrAttachment, FaTasks, AiOutlineMessage, RiMapPinUserLine } from 'react-icons/all'
import { v4 as uuid } from 'uuid'
import { Button, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Skeleton from 'react-loading-skeleton'
import { Chip } from '@mui/material';

import Container from '../../components/layout/Container'
import ErrorRequest from '../../components/animations/ErrorRequest'
import NoResult from '../../components/animations/NoResults'
import FilterTasks from '../../components/elements-ui/FilterTasks'
import DialogConfirmation from '../../components/elements-ui/DialogConfirmation'
import DialogFilterConfirm from '../../components/elements-ui/DialogFilterConfirm'
import { Tasks } from './styles'

import { capitalize, limitChar, convertISODateToDateView } from '../../functions'
import api from '../../services/api'

export default function Tarefas(props) {
    let itemsLoading = [];

    const { enqueueSnackbar } = useSnackbar()
    const [filtroUrl, setFiltroUrl] = useState(Boolean(props.match.params.filtro))
    const [aplicarFiltro, setAplicarFiltro] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [paginaAtual, setPaginaAtual] = useState(1)
    const [qtdeRegistros, setQtdeRegistros] = useState(10)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(Number(Math.round(totalRegistros / qtdeRegistros)))
    const [tarefas, setTarefas] = useState([])

    const [salvarFiltro, setSalvarFiltro] = useState({});
    const [openDialogFilterConfirm, setOpenDialogFilterConfirm] = useState(false);
    const [dialogFilterConfirm, setDialogFilterConfirm] = useState(false);
    const [filtroDescricao, setFiltroDescricao] = useState('');

    const [filtrosUsuario, setFiltrosUsuario] = useState([]);

    const [openFiltroDeleteConfirm, setOpenFiltroDeleteConfirm] = useState(false);
    const [filtroDeleteConfirm, setFiltroDeleteConfirm] = useState(false);
    const [filtroDeleteObj, setFiltroDeleteObj] = useState({});

    const getTarefas = async (type) => {
        let options = {
            filtros: [],
            opcao: Number(props.match.params.tipo),
            firstResult: type ? 1 : ((paginaAtual - 1) * qtdeRegistros) + 1,
            maxResults: qtdeRegistros
        }

        if (props.match.params.tipo === "todas") {
            options = {
                filtros: [],
                firstResult: paginaAtual,
                maxResults: qtdeRegistros
            }
        }

        if (Object.keys(aplicarFiltro).length > 0) {
            aplicarFiltro.firstResult = options.firstResult;
            aplicarFiltro.maxResults = qtdeRegistros;
            aplicarFiltro.paginaAtual = paginaAtual;
            aplicarFiltro.qtdeRegistros = qtdeRegistros;
            options = aplicarFiltro;

            const path = generatePath(`/tarefas/\\(:filtro\\)`, { filtro: JSON.stringify(aplicarFiltro) });
            props.history.replace(path);
        } else if (!filtroUrl && !props.match.params.tipo) {
            const path = generatePath(`/requisicoes`);
            props.history.replace(path);
        } else if (props.match.params.filtro) {
            options = JSON.parse(props.match.params.filtro);

            setPaginaAtual(options.paginaAtual);
            setQtdeRegistros(options.qtdeRegistros);

            setAplicarFiltro(options);
        }

        setLoading(true)
        await api.post('/jucerja/v1/Tarefa/filtro', options)
            .then(async response => {
                setTarefas(response.data.tarefasAtendente ?? [])
                setTotalRegistros(response.data.totalRecords ?? 0)
                setTotalPaginas(Number(Math.ceil((Number(response.data.totalRecords ?? 0) / Number(qtdeRegistros)))))
                await consultarFiltrosUsuario();
                setLoading(false)
                setError(false)
            })
            .catch(error => {
                setLoading(false)
                setError(true)
                setErrorMessage(error.toString())
            })
    }

    const consultarFiltrosUsuario = async () => {
        await api.get(`/jucerja/v1/filtro/2`)
            .then(response => {
                setFiltrosUsuario(response.data.reverse());
            })
            .catch(error => {
                enqueueSnackbar('Não foi possível consultar lista de filtros do usuário.', { variant: 'error' })
            });
    }

    const LoadingTarefas = () => (
        <a key={uuid()} className="no-select requests-listitem">
            <Button className="btn-acesso-requisicao" disableRipple={true} disableTouchRipple={true}>
                <Tasks.ListItemContent id="Request_ListItemContent">
                    <Tasks.ListItemInformations id="Requests_ListItemInformations">
                        <Tasks.ListItemInfo id="Request_ListItemInfo">
                            <Tasks.ListItemTitle id="Request_ListItemTitle"><Skeleton width={200} height={30} /></Tasks.ListItemTitle>
                            <Tasks.ListItemDescription id="Request_ListItemDescription"><Skeleton width={250} height={30} style={{ position: 'relative', top: '10px' }} /></Tasks.ListItemDescription>
                        </Tasks.ListItemInfo>
                        <Tasks.ListItemReq id="Request_ListItemReq">
                            <Tasks.ListItemNumberRequest id="Request_ListItemNumberRequest"><Skeleton width={280} height={30} /></Tasks.ListItemNumberRequest>
                            <Tasks.ListItemData id="Request_ListItemData"><Skeleton width={50} height={30} /></Tasks.ListItemData>
                        </Tasks.ListItemReq>
                    </Tasks.ListItemInformations>
                    <Tasks.ListItemSubInfo id="Request_ListItemSubInfo" style={{ width: '100%' }}>
                        <Skeleton width={180} height={30} style={{ position: 'relative', top: '-10px', width: '100%' }} />
                    </Tasks.ListItemSubInfo>
                </Tasks.ListItemContent>
            </Button>
        </a>
    )

    for (var i = 0; i < 4; i++) {
        itemsLoading.push(LoadingTarefas())
    }

    useEffect(() => {
        if (filtroUrl && Object.keys(aplicarFiltro).length > 0) {
            setFiltroUrl(false);
            return;
        }

        if (paginaAtual == 1)
            getTarefas(false);

        setPaginaAtual(1);
    }, [aplicarFiltro])

    useEffect(() => {
        if (paginaAtual == 1)
            getTarefas(false);

        getTarefas(1);
    }, [aplicarFiltro])

    useEffect(() => {
        getTarefas(false)
    }, [paginaAtual])

    useEffect(() => {
        setPaginaAtual(1)
        getTarefas(true)
    }, [props.match.params.tipo])


    useEffect(() => {

        if (!salvarFiltro || Object.keys(salvarFiltro).length <= 0)
            return;

        setOpenDialogFilterConfirm(true);


    }, [salvarFiltro])

    useEffect(async () => {
        if (!dialogFilterConfirm) {
            setSalvarFiltro({});
        } else {
            setLoading(true);

            await api.post(`/jucerja/v1/filtro`, {
                "descricao": filtroDescricao,
                "parametro": JSON.stringify(salvarFiltro),
                "tipoFiltroId": 2
            })
                .then(async response => {
                    setAplicarFiltro(salvarFiltro);
                    await consultarFiltrosUsuario();
                })
                .catch(error => {
                    enqueueSnackbar('Não foi possível cadastrar o filtro para o usuário.', { variant: 'error' });
                })
                .finally(() => { setLoading(false); setOpenDialogFilterConfirm(false); });
        }

        setDialogFilterConfirm(false);
    }, [dialogFilterConfirm])

    useEffect(async () => {
        if (!filtroDeleteConfirm)
            return;

        await api.delete(`/jucerja/v1/filtro/${filtroDeleteObj.id}`)
            .then(response => {
                setFiltrosUsuario(filtrosUsuario.filter(f => f.id != filtroDeleteObj.id));
            })
            .catch(error => {
                enqueueSnackbar('Não foi possível excluir o filtro para o usuário.', { variant: 'error' });
            })
            .finally(() => { setOpenFiltroDeleteConfirm(false); });

        setFiltroDeleteConfirm(false);

    }, [filtroDeleteConfirm])

    return (
        <Container title="Tarefas">
            <DialogConfirmation
                openDialog={openFiltroDeleteConfirm}
                setOpenDialog={setOpenFiltroDeleteConfirm}
                setConfirm={setFiltroDeleteConfirm}
                txtConfirm="Sim"
                txtCancel="Não"
                txtTitle="Confirmar exclusão do filtro"
                txtContainer={`Deseja realmente excluir o filtro '${filtroDeleteObj.descricao}' ?`}
                closeControlOutsideOnConfirm={true}
            />

            <DialogFilterConfirm
                openDialogFilterConfirm={openDialogFilterConfirm}
                setDialogFilterConfirm={setDialogFilterConfirm}
                setOpenDialogFilterConfirm={setOpenDialogFilterConfirm}
                setFiltroDescricao={setFiltroDescricao}
                filtroDescricao={filtroDescricao}
            />
            <Tasks id="Tasks">
                {
                    error
                        ?
                        <ErrorRequest errorMessage={errorMessage} />
                        :
                        <>
                            <Tasks.Action id="Tasks_Action">
                                {
                                    loading
                                        ?
                                        <Skeleton width={116} height={40} />
                                        :
                                        <>
                                            <Grid
                                                spacing={1}
                                                container
                                                direction="row">

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    <div>
                                                        <div style={{display: 'inline-flex', padding:'0 5px 10px 0'}}>
                                                        <FilterTasks  aplicarFiltro={aplicarFiltro} setAplicarFiltro={setAplicarFiltro} setSalvarFiltro={setSalvarFiltro} />
                                                        </div>
                                                        {
                                                            filtrosUsuario.map(filtro => (
                                                                <>
                                                                    <Chip
                                                                        key={filtro.id}
                                                                        style={{margin:'0 5px 0 0'}}
                                                                        label={filtro.descricao}
                                                                        onClick={() => setAplicarFiltro(JSON.parse(filtro.parametro))}
                                                                        onDelete={() => { setFiltroDeleteObj(filtro); setOpenFiltroDeleteConfirm(true); }}
                                                                        // deleteIcon={<DeleteIcon />}
                                                                        variant={filtro.parametro == JSON.stringify({ ...aplicarFiltro, paginaAtual: 1 }) ? 'filled' : "outlined"}

                                                                    />
                                                                </>
                                                            ))
                                                        }
                                                        </div>
                                                    <Tasks.Pagination id="Tasks_Pagination">
                                                        {
                                                            loading
                                                                ?
                                                                <Skeleton width={180} height={30} />
                                                                :
                                                                tarefas.length === 0
                                                                    ?
                                                                    null
                                                                    :
                                                                    <>
                                                                        Página {paginaAtual} de {totalPaginas} de {totalRegistros} {totalRegistros <= 1 ? 'registro' : 'registros'}
                                                                        <Button className="btnPagination btnPaginationPrev" onClick={() => setPaginaAtual(paginaAtual - 1)} disabled={paginaAtual === 1 ? true : false}><IoIosArrowBack /></Button>
                                                                        <Button className="btnPagination btnPaginationNext" onClick={() => setPaginaAtual(paginaAtual + 1)} disabled={paginaAtual === totalPaginas ? true : false}><IoIosArrowForward /></Button>
                                                                    </>
                                                        }
                                                    </Tasks.Pagination>
                                                </Grid>


                                            </Grid>
                                        </>
                                }

                            </Tasks.Action>
                            <Tasks.List id="Tasks_List" loading={String(loading)}>
                                {
                                    loading
                                        ?
                                        itemsLoading
                                        :
                                        tarefas.length === 0 ? <NoResult /> :
                                            tarefas.map(tarefa => (
                                                <Link key={uuid()} to={`/tarefa/${tarefa.idTarefa}`} className="no-select requests-listitem">
                                                    <Button className="btn-acesso-requisicao">
                                                        <Tasks.ListItemContent id="Request_ListItemContent">
                                                            <Tasks.ListItemInformations id="Requests_ListItemInformations">
                                                                <Tasks.ListItemInfo id="Request_ListItemInfo">
                                                                    <Tasks.ListItemTitle id="Request_ListItemTitle">{tarefa.assunto}</Tasks.ListItemTitle>
                                                                    <Tasks.Subject id="Requests_Subject" ><MdSubject /> {capitalize(tarefa.setor)}</Tasks.Subject>
                                                                    <Tasks.ListItemDescription id="Request_ListItemDescription">{limitChar(100, tarefa.previaMensagem)}</Tasks.ListItemDescription>
                                                                </Tasks.ListItemInfo>
                                                                <Tasks.ListItemReq id="Request_ListItemReq">
                                                                    <Tasks.ListItemNumberRequest id="Request_ListItemNumberRequest">{tarefa.idTarefa}</Tasks.ListItemNumberRequest>
                                                                    <Tasks.ListItemData id="Request_ListItemData">{convertISODateToDateView(tarefa.dataAbertura)}</Tasks.ListItemData>
                                                                </Tasks.ListItemReq>
                                                            </Tasks.ListItemInformations>
                                                            <Tasks.ListItemSubInfo id="Request_ListItemSubInfo">
                                                                <Tasks.ListItemStatus id="Request_ListItemStatus" task="tipo1">{tarefa.status}</Tasks.ListItemStatus>
                                                                <Tasks.ListItemStatus id="Request_ListItemStatus" task="tipo2"><RiMapPinUserLine id="RiMapPinUserLine" /><span style={{ color: !tarefa.nomeAtendente ? 'red' : null }}> {capitalize(tarefa.nomeAtendente ?? 'SEM ATENDENTE')}</span></Tasks.ListItemStatus>
                                                                {/* <Tasks.ListItemAttach id="Request_ListItemAttach"><IoPersonOutline /><span style={{ color: !tarefa.nomeAtendente ? 'red' : null }}> {capitalize(tarefa.nomeAtendente ?? 'SEM ATENDENTE')}</span></Tasks.ListItemAttach> */}
                                                                <Tasks.ListItemTask id="Request_ListItemTask"><BiVerticalCenter /> {capitalize(tarefa.prioridade)}</Tasks.ListItemTask>
                                                                <Tasks.ListItemMessage id="Request_ListItemMessage"><IoGitPullRequestOutline /> {tarefa.quantidadeRequisicao} solicitações</Tasks.ListItemMessage>
                                                                <Tasks.ListItemMessage id="Request_ListItemMessage"><BiMessageRounded /> {tarefa.quantidadeMensagensAposUltimoAlteracaoStatus} novas mensagens</Tasks.ListItemMessage>
                                                            </Tasks.ListItemSubInfo>
                                                        </Tasks.ListItemContent>
                                                    </Button>
                                                </Link>))
                                }
                            </Tasks.List>
                        </>
                }
            </Tasks>
        </Container>
    )
}