import styled from 'styled-components'

export const Kpi = styled.div`
    @media (max-width: 1199px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: stretch;
        flex-wrap: wrap;
    }

    @media (max-width: 600px) {
        .Kpi_Card_1,
        .Kpi_Card_2,
        .Kpi_Card_3,
        .Kpi_Card_4,
        .Kpi_Card_5 {
            width: 100%;
        }

        .Kpi_Card_1,
        .Kpi_Card_2,
        .Kpi_Card_3,
        .Kpi_Card_4 {
            margin-bottom: 20px;
        }
    }

    @media (min-width: 601px) and (max-width: 763px) {
        .Kpi_Card_1 {
            width: calc((100% / 2) - 10px);
            margin: 0 10px 20px 0;
        }

        .Kpi_Card_2 {
            width: calc((100% / 2) - 10px);
            margin: 0 0 20px 10px;
        }

        .Kpi_Card_3 {
            width: calc((100% / 2) - 10px);
            margin: 0 10px 20px 0;
        }

        .Kpi_Card_4 {
            width: calc((100% / 2) - 10px);
            margin: 0 0 20px 10px;
        }

        .Kpi_Card_5 {
            width: 100%;
            margin-left: 0px;
        }
    }

    @media (min-width: 764px) and (max-width: 1199px) {
        .Kpi_Card_1 {
            width: calc((100% / 3) - 10px);
            margin: 0 10px 20px 0;
        }

        .Kpi_Card_2 {
            width: calc((100% / 3) - 20px);
            margin: 0 10px 20px 10px;
        }

        .Kpi_Card_3 {
            width: calc((100% / 3) - 10px);
            margin: 0 0 20px 10px;
        }

        .Kpi_Card_4 {
            width: calc((100% / 2) - 10px);
            margin-right: 10px;
        }
        .Kpi_Card_5 {
            width: calc((100% / 2) - 10px);
            margin-left: 10px;
        }
    }

    @media (min-width: 1200px) {
       display: grid;
       grid-template-columns: repeat(5, 1fr);
       grid-gap: 20px;
    }
`

Kpi.Card = styled.div`
    width: 100%;
    height: 100%;
    background: var(--white);
    -webkit-transition: all ease .35s;
    transition: all ease .35s;
    border-bottom: 2px solid transparent;
    border-radius: 15px;
    overflow: hidden;
    /* padding-top: 5px; */
`

Kpi.Content = styled.div`
    padding: 10px 15px;

    .RiUserUnfollowLine span {
        color: var(--dark-yellow);
    }

    .BiMessageSquareDetail span {
        color: var(--dark-orange);
    }
    
    .RiUserVoiceLine span {
            color: var(--dark-blue);
    }
    
    .BiMessageCheck span {
        color: var(--dark-gree);
    }
    
    .BiMessageError span {
        color: var(--red);
    }

    .ImClipboard span {
        color: var(--dark-purple);
    }
`

Kpi.Icon = styled.div`
    position: relative;
    font-size: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    svg {
        min-width: 31px;
        max-width: 31px;
        margin: 0 10px 0 0;
        position: relative;
        top: -3px;
    }

`

Kpi.Title = styled.div`
    font-size: 0.85rem;
    min-height: 40px;
`

Kpi.Counter = styled.div`
    font-size: 2.8rem;
    font-weight: bold;
    letter-spacing: -4px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    margin: 5px 0 5px 0;
`