import styled from 'styled-components'

export const Requests = styled.div`

`

Requests.Action = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 40px 0;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnFilter {
        background: #FFFFFF;
        border: 1px solid #E3E3E3;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        border-radius: 100px;
        padding: 8px 25px 6px 25px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnFilter svg {
        margin: 0 7px 0 0;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnFilter span.MuiButton-label {
        text-transform: capitalize;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnPagination {
        min-width: 0;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
Requests.Pagination = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    -webkit-user-select: none;  
    -moz-user-select: none;     
    -ms-user-select: none;      
    user-select: none;       

    @media (max-width: 600px) {
        margin: 15px 0 0 0;
        font-size: 13px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnPagination.btnPaginationPrev {
        margin: 0 0 0 15px;
    }
`
Requests.List = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    a.requests-listitem {
        width: 100%;
        margin: 0 0 15px 0;
        background: var(--white);
        transition: all ease .35s;
        border-bottom: 2px solid transparent;
        border-radius: 10px;
        overflow: hidden;

        &:hover {
            transform: ${props => props.loading === "true" ? 'inherit' : 'scale(1.019)'};
            transition: all ease .35s;
            border-radius: 8px;
            box-shadow: 0px 0px 11px -4px rgb(0 0 0 / 25%);
            border-bottom: ${props => props.loading === "true" ? '3px solid transparent' : '3px solid var(--blue)'};
        }

        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-acesso-requisicao {
            width: 100%;
            text-transform: none;
            padding: 0;
            background-color: ${props => props.loading === "true" ? 'var(--white)' : 'inherit'};
        }
    }
`
Requests.ListItem = styled.div`
    width: 100%;
    margin: 0 0 15px 0;
    background: var(--white);
    transition: all ease .35s;
    border-bottom: 2px solid transparent;

    &:hover {
        transform: scale(1.019);
        transition: all ease .35s;
        border-radius: 8px;
        box-shadow: 0px 0px 11px -4px rgb(0 0 0 / 25%);
        border-bottom: 3px solid var(--blue);
    }
`
Requests.ListItemContent = styled.div`
    width: 100%;
    padding: 10px 13px 2px 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

Requests.ListItemInformations = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`

Requests.ListItemInfo = styled.div`
    width: calc(100% - 165px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 600px) {
        width: 100%;
    }
`
Requests.ListItemTitle = styled.div`
    color: var(--black);
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    padding: 0;
    margin: 0 0 10px 0;
`
Requests.ListItemDescription = styled.div`
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    color: var(--lightgrey);
    text-align: justify;
`
Requests.ListItemSubInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 2px 0 0 0;
    width: 100%;
    flex-wrap: wrap;
    margin: 5px 0 10px 0;
`
Requests.ListItemStatus = styled.div`
    background: var(--bg-green);
    padding: 5px 10px 6px 10px;
    color: var(--dark-gree);
    font-weight: 700;
    font-size: 11px;
    border-radius: 4px;
    margin: 0px 10px 10px 0;
`
Requests.ListItemResponsavel = styled.div`
    background: var(--bg-purple);
    padding: 5px 10px 6px 10px;
    color: var(--purple);
    font-weight: 700;
    font-size: 11px;
    border-radius: 4px;
    margin: 0px 10px 10px 0;
`
Requests.ListItemAttach = styled.div`
    font-size: 13px;
    color: #7b7b7b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 10px 10px 0;

    svg path {
        fill: rgb(255 255 255 / 13%);
        opacity: 0.51;
    }

    svg {
        margin: 0 5px 0 0;
    }
`
Requests.ListItemTask = styled.div`
    font-size: 13px;
    color: #7b7b7b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 10px 10px 0;

    svg {
        font-size: 13px;
        color: #7b7b7b;
    }

    svg {
        margin: 0 5px 0 0;
    }
`
Requests.ListItemMessage = styled.div`
    font-size: 13px;
    color: #7b7b7b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 10px 10px 0;

    svg {
        margin: 0 5px 0 0;
    }
`
Requests.ListItemReq = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 165px;
    padding: 0 0 0 0;

    @media (max-width: 600px) {
        width: 100%;
        align-items: center !important;
        margin: 16px 0 0 0;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
    }
`
Requests.ListItemNumberRequest = styled.div`
    background: var(--bg-brown);
    color: var(--dark-brown);
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0 0 10px 0;
    font-weight: 700;
`
Requests.ListItemData = styled.div`
    font-size: 12px;
    font-weight: 500;

    @media (max-width: 600px) {
        height: 27px;
    }
`

Requests.Subject = styled.p`
    margin: 0 0 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    color: var(--blue);
    text-align: left;
    font-size: 13px;
    line-height: 13px;

    svg {
        margin: 0 5px 0 0;
    }
`