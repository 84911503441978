import Lottie from 'react-lottie'
import animationData from './loading-chat.json'

export default function LoadingMessagesChat() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }
    
    return (
        <Lottie 
          options={defaultOptions}
          style={{ width: '100%', height: 'auto' }}
        />
    )
  }