import styled from 'styled-components'

export const AssociatedTasks = styled.div`
    .btn-acesso-requisicao-selecionado {
        background: var(--primary) !important;
        transition: all ease .35s;
    }

    .btn-acesso-requisicao-selecionado div#Request_ListItemTitle {
        color: var(--white);
        transition: all ease .35s;
    }

    .btn-acesso-requisicao-selecionado div#Request_ListItemTask {
        color: var(--white);
        transition: all ease .35s;
    }

    .btn-acesso-requisicao-selecionado div#Request_ListItemTask svg {
        color: var(--white);
        transition: all ease .35s;
    }

    .btn-acesso-requisicao-selecionado div#Request_ListItemMessage {
        color: var(--white);
        transition: all ease .35s;
    }

    .btn-acesso-requisicao-selecionado div#Request_ListItemData {
        color: var(--white);
        transition: all ease .35s;
    }

    div#Request_CheckSelected {
        color: var(--bg-green);
        font-size: 2.3rem;
        position: relative;
    }

    div#Request_ListItemSubInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .list-items-tasks {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`

AssociatedTasks.Form = styled.div`
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-acesso-requisicao {
        -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
        border-radius: 10px;
        margin: 0 0 20px 0;
        text-transform: none;
        padding: 0;
        width: 100%;
    }

    div#AssociatedTasks_Form {
        width: 100%;
    }

    div#Request_ListItemSubInfo {
        margin: 0;
    }
`

AssociatedTasks.Actions = styled.div`

`

AssociatedTasks.Pagination = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    -webkit-user-select: none;  
    -moz-user-select: none;     
    -ms-user-select: none;      
    user-select: none;
    margin-bottom: 15px;    

    @media (max-width: 600px) {
        margin: 15px 0 0 0;
        font-size: 13px;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnPagination.btnPaginationPrev {
        margin: 0 0 0 15px;
    }
`