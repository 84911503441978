import { useState, forwardRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { AssociatedTasks } from './styles'
import { Requests } from '../../../pages/Requisicoes/styles'
import { MdSubject, SiOpslevel, FiGitPullRequest } from 'react-icons/all'
import ErrorRequest from '../../../components/animations/ErrorRequest'
import NoResult from '../../../components/animations/NoResults'
import api from '../../../services/api'
import { v4 as uuid } from 'uuid'
import { capitalize, limitChar, convertISODateToDateView, acaoPermitida } from '../../../functions'
import Skeleton from 'react-loading-skeleton'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogAssociatedTasks({
    idRequisicao,
    openDialogTarefasAssociadas,
    setOpenDialogTarefasAssociadas,
    setOpenDialogCreateTasks,
    setOpenDialogTarefaAssociada,
    acoesPermitidas
}) {
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [tarefas, setTarefas] = useState([])
    const [loading, setLoading] = useState(true);

    const handleClose = () => {
        setOpenDialogTarefasAssociadas(false)
    }

    const createTask = () => {
        setOpenDialogTarefasAssociadas(false)
        setOpenDialogCreateTasks(true)
    }

    const handleOpenAssociateTask = () => {
        setOpenDialogTarefasAssociadas(false)
        setOpenDialogTarefaAssociada(true)
    }

    const getTarefas = () => {
        api.get(`/jucerja/v1/Tarefa/${idRequisicao}/tarefaassociadarequisicao`)
            .then(response => {
                setTarefas(response.data.tarefasAtendente ?? [])
            })
            .catch(error => {
                setError(true)
                setErrorMessage(error.toString())
            })
            .finally(() => setLoading(false))
    }

    const getTarefasLoad = () => {
        let itens = [];

        for (let index = 0; index < 3; index++) {
            itens.push(
                <Button className="btn-acesso-requisicao">
                    <Requests.ListItemContent id="Request_ListItemContent">
                        <Requests.ListItemInformations id="Requests_ListItemInformations">
                            <Requests.ListItemInfo id="Request_ListItemInfo">
                                <Requests.ListItemTitle id="Request_ListItemTitle"><Skeleton width={560} height={18} /></Requests.ListItemTitle>
                                <Requests.Subject id="Requests_Subject" ><Skeleton width={760} height={18} /></Requests.Subject>
                            </Requests.ListItemInfo>
                            <Requests.ListItemReq id="Request_ListItemReq">
                                <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest"><Skeleton width={50} height={30} /></Requests.ListItemNumberRequest>
                                <Requests.ListItemData id="Request_ListItemData"><Skeleton width={100} height={20} /></Requests.ListItemData>
                            </Requests.ListItemReq>
                        </Requests.ListItemInformations>
                        <Requests.ListItemSubInfo id="Request_ListItemSubInfo">
                            <Requests.ListItemTask id="Request_ListItemTask"><Skeleton width={100} height={18} /></Requests.ListItemTask>
                            <Requests.ListItemTask id="Request_ListItemTask"><Skeleton width={150} height={18} /></Requests.ListItemTask>
                            <Requests.ListItemAttach id="Request_ListItemAttach"><Skeleton width={350} height={18} /></Requests.ListItemAttach>
                            <Requests.ListItemTask id="Request_ListItemTask"><Skeleton width={50} height={18} /></Requests.ListItemTask>
                        </Requests.ListItemSubInfo>
                    </Requests.ListItemContent>
                </Button>
            );
        }
        return itens;

    }

    useEffect(() => {
        if (openDialogTarefasAssociadas)
            getTarefas()
    }, [openDialogTarefasAssociadas])

    return (
        <Draggable   
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogTarefasAssociadas}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-associated-tasks-request-slide-title"
            aria-describedby="dialog-associated-tasks-request-dialog-slide-description"
            id="dialog-associated-tasks"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">{"Tarefas Associadas"}</DialogTitle>
            <DialogContent>
                <AssociatedTasks id="AssociatedTasks">
                    <AssociatedTasks.Form id="AssociatedTasks_Form">

                        {
                            loading ? getTarefasLoad() :
                                error
                                    ?
                                    <ErrorRequest errorMessage={errorMessage} />
                                    :
                                    <>
                                        {
                                            tarefas.length === 0 ? <NoResult /> :
                                                tarefas.map(tarefa => (
                                                    <Link key={uuid()} to={`/tarefa/${tarefa.idTarefa}`} className="no-select requests-listitem">
                                                        <Button className="btn-acesso-requisicao">
                                                            <Requests.ListItemContent id="Request_ListItemContent">
                                                                <Requests.ListItemInformations id="Requests_ListItemInformations">
                                                                    <Requests.ListItemInfo id="Request_ListItemInfo">
                                                                        <Requests.ListItemTitle id="Request_ListItemTitle">{tarefa.assunto}</Requests.ListItemTitle>
                                                                        <Requests.Subject id="Requests_Subject" ><MdSubject />{limitChar(100, tarefa.previaMensagem)}</Requests.Subject>
                                                                    </Requests.ListItemInfo>
                                                                    <Requests.ListItemReq id="Request_ListItemReq">
                                                                        <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest">{tarefa.idTarefa}</Requests.ListItemNumberRequest>
                                                                        <Requests.ListItemData id="Request_ListItemData">{convertISODateToDateView(tarefa.dataAbertura)}</Requests.ListItemData>
                                                                    </Requests.ListItemReq>
                                                                </Requests.ListItemInformations>
                                                                <Requests.ListItemSubInfo id="Request_ListItemSubInfo">
                                                                    <Requests.ListItemStatus id="Request_ListItemStatus">{tarefa.status}</Requests.ListItemStatus>
                                                                    <Requests.ListItemStatus id="Request_ListItemStatus2">{tarefa.setor}</Requests.ListItemStatus>
                                                                    <Requests.ListItemAttach id="Request_ListItemAttach"><SiOpslevel />{capitalize(tarefa.nomeAtendente)}</Requests.ListItemAttach>
                                                                    <Requests.ListItemTask id="Request_ListItemTask"><SiOpslevel />{capitalize(tarefa.prioridade)}</Requests.ListItemTask>
                                                                    <Requests.ListItemMessage id="Request_ListItemMessage"><FiGitPullRequest /> {tarefa.quantidadeRequisicao} {tarefa.quantidadeRequisicao <= 1 ? 'Solicitação' : 'Solicitações'}</Requests.ListItemMessage>
                                                                </Requests.ListItemSubInfo>
                                                            </Requests.ListItemContent>
                                                        </Button>
                                                    </Link>
                                                ))
                                        }
                                        <Button className={`btn-acesso-requisicao`} style={{ opacity: 0, height: '1px', overflow: 'auto' }} key={uuid()}>
                                            <Requests.ListItemContent id="Request_ListItemContent">
                                                <Requests.ListItemInformations id="Requests_ListItemInformations">
                                                    <Requests.ListItemInfo id="Request_ListItemInfo">
                                                        <Requests.ListItemTitle id="Request_ListItemTitle">asdfasdfasdf</Requests.ListItemTitle>
                                                        <Requests.Subject id="Requests_Subject" ><MdSubject />asdfasdfasdf</Requests.Subject>
                                                    </Requests.ListItemInfo>
                                                    <Requests.ListItemReq id="Request_ListItemReq">
                                                        <Requests.ListItemNumberRequest id="Request_ListItemNumberRequest">asdfasdfasddfasdf</Requests.ListItemNumberRequest>
                                                        <Requests.ListItemData id="Request_ListItemData">asdfasdfasddfasdf</Requests.ListItemData>
                                                    </Requests.ListItemReq>
                                                </Requests.ListItemInformations>
                                                <Requests.ListItemSubInfo id="Request_ListItemSubInfo">
                                                    <div style={{ display: 'flex' }}>
                                                        <Requests.ListItemStatus id="Request_ListItemStatus">asdfasdfasdfasdfasdf</Requests.ListItemStatus>
                                                        <Requests.ListItemStatus id="Request_ListItemStatus2">asdfadsfasdfasdfasdf</Requests.ListItemStatus>
                                                    </div>
                                                    <Requests.ListItemAttach id="Request_ListItemAttach"><SiOpslevel />asdfasdfasdfasdf</Requests.ListItemAttach>
                                                    <Requests.ListItemTask id="Request_ListItemTask"><SiOpslevel />asdfasdfasdfasdfasdf</Requests.ListItemTask>
                                                    <Requests.ListItemMessage id="Request_ListItemMessage"><FiGitPullRequest /> asdfasddfasdfasdfasdf</Requests.ListItemMessage>
                                                </Requests.ListItemSubInfo>
                                            </Requests.ListItemContent>
                                        </Button>
                                    </>
                        }
                    </AssociatedTasks.Form>
                </AssociatedTasks>
            </DialogContent>
            <DialogActions>

                <Button onClick={() => handleClose()} className="btn-close-actions">Cancelar</Button>
                {
                    acaoPermitida('AssociarRequisicaoTarefa', acoesPermitidas,
                        <Button onClick={() => handleOpenAssociateTask()} className="btn-secondary-actions">Associar Tarefa</Button>
                    )
                }
                {
                    acaoPermitida('CriarTarefas', acoesPermitidas,
                        <Button onClick={() => createTask()} className="btn-primary-actions">Criar Tarefa</Button>
                    )
                }
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}