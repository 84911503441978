import React, { useState, useEffect } from "react";
import DialogConfirmation from "../DialogConfirmation";
import { useSelector, useDispatch } from 'react-redux'
import { decrementAllMessagesToZero, clearError } from "../../../slices/requestMessageSlice";
import { useSnackbar } from 'notistack';

const MessageHandleSendAll = () => {
  const messagesCountDown = useSelector((state) => state.requestMessage.messages);  
  const error = useSelector(state => state.requestMessage.error);
  const dispatch = useDispatch();  
  const { enqueueSnackbar } = useSnackbar()
  const [mensagemAviso, setMensagemAviso] = useState("No fechamento da página, as mensagens que não foram enviadas serão perdidas, deseja enviar todas as mensagens antes do fechamento?");
  const [showProcessamentoMensagens, setShowProcessamentoMensagens] = useState(false);
  const [confirmEnvidoTotalMensagens, setConfirmEnvidoTotalMensagens] = useState(false);

  const handleBeforeUnloadWindowClose = (event) => {
    if (messagesCountDown && messagesCountDown.length > 0) {
      event.preventDefault();
      event.returnValue = '';
      setShowProcessamentoMensagens(true);
    }
  }

  useEffect(() => {

    if (confirmEnvidoTotalMensagens) {
      setMensagemAviso(`Faltam apenas ${messagesCountDown.length} mensagens para serem enviadas...`)

      if (messagesCountDown.length <= 0) {
        setConfirmEnvidoTotalMensagens(false);
        setShowProcessamentoMensagens(false);
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnloadWindowClose);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnloadWindowClose);
    };
  }, [messagesCountDown]);

  useEffect(() => {

    if (!confirmEnvidoTotalMensagens) return;


    dispatch(decrementAllMessagesToZero())

  }, [confirmEnvidoTotalMensagens]);

  useEffect(() => {

    if (error == null) return;

    enqueueSnackbar(error, { variant: 'error' });

    dispatch(clearError());

  }, [error]);

  return (
    <DialogConfirmation
      openDialog={showProcessamentoMensagens}
      setOpenDialog={setShowProcessamentoMensagens}
      setConfirm={setConfirmEnvidoTotalMensagens}
      txtConfirm="Sim"
      txtCancel="Não"
      txtTitle="Processar mensagens não enviadas"
      txtContainer={mensagemAviso}
      disableBackdropClick
      closeControlOutsideOnConfirm
    />
  );
};

export default MessageHandleSendAll;