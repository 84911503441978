import { Link } from "react-router-dom"
import { Breadcrumb } from './styles'
import { v4 as uuid } from 'uuid'

export default function Breadcrumbs({ crumbs }) {
  return (
    <Breadcrumb>
      {
        crumbs === undefined
        ?
          null
        :
          crumbs.map(({ name, path }, key) =>
            key + 1 === crumbs.length 
            ? 
              (
                <Link key={uuid()} className="disabled actual" to="">
                  {name}
                </Link>
              ) 
            : 
              (
                <Link key={uuid()} to={path}>
                  {name}
                </Link>
              )
          )
      }
    </Breadcrumb>
  )
}
