import Container from '../../../components/layout/Container'
import Grid from '@material-ui/core/Grid'
import { useEffect, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../../../components/elements-ui/Card"
import { Badge } from "../../../components/elements-ui/Badge"
import { ActionTable } from "../../../components/elements-ui/ActionTable"
import { Button, Checkbox, FormControl, TextField } from '@material-ui/core'
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete, CgAdd, BiArrowBack, VscFilePdf, RiCloseCircleFill, IoImageOutline, GrAttachment } from 'react-icons/all'
import { HiOutlineArrowsExpand } from 'react-icons/all'
import { Link, useHistory } from 'react-router-dom';
import { Autocomplete, Chip, Stack, Divider } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../../services/api'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { v4 as uuid } from 'uuid'

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers"
import ptBRLocale from "date-fns/locale/pt-BR"
import DateFnsUtils from "@date-io/date-fns"
import { DuvidaComponente } from './styles'
import { Request } from '../../../components/elements-ui/DialogSendMessageEditor/styles'
import { limitChar } from '../../../functions'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        color: 'var(--white)',
    },
}))(Tooltip)

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Duvida(props) {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar()
    const [codigoArtigo, setCodigoArtigo] = useState(props.match.params.id);
    const [artigo, setArtigo] = useState({});
    const [artigoAtual, setArtigoAtual] = useState({});
    const [secoes, setSecoes] = useState([]);
    const [dataEditor, setDataEditor] = useState();
    const [loading, setLoading] = useState(false);

    const carregueArtigo = async () => {

        setLoading(true);
        await api.get(`/jucerja/v1/artigo/${codigoArtigo}`)
            .then(response => {
                setArtigo(response.data);
            })
            .catch(error => {
                // setLoading(false)
                // setError(true)
                // setErrorMessage(error.toString())
            })
            .finally(() => {
                setLoading(false);
            });

        setLoading(false);
    }

    const carregarSecoes = async () => {
        await api.get(`/jucerja/v1/secao`)
            .then(response => {
                setSecoes([...response.data, { id: 9, titulo: "Seção 001 Priscila Oliveira" }]);
            })
            .catch(error => {
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleChangeSecao = async (e, v) => {
        setArtigoAtual((artigo) => ({ ...artigo, secao: v }))
    }

    const handleAttachment = event => {
        Array.from(event.target.files).map((file, index) => {
            if (Boolean(file)) {
                if (Number(file.size) <= 10000000) {
                    if (
                        file.type === "application/pdf"
                        || file.type === "image/jpg"
                        || file.type === "image/jpeg"
                        || file.type === "image/jpe"
                        || file.type === "image/jfif"
                        || file.type === "image/png"
                        || file.type === "image/bmp"
                        || file.type === "image/gif"
                        || file.type === "application/pkcs7-signature"
                    ) {
                        const fileReader = new window.FileReader()
                        fileReader.onload = (fileLoad) => {
                            const { result } = fileLoad.target

                            setArtigoAtual(artA=> ({...artA, arquivosArtigo:[...artA?.arquivosArtigo,{extensao:file.type, nome:file.name}]}));
                            // setAttachs((prevState) => [...prevState, {
                            //     id: uuid(),
                            //     file: file,
                            //     name: file.name,
                            //     type: file.type,
                            //     url: result
                            // }])
                        }

                        fileReader.readAsDataURL(file)
                    }
                    else {
                        enqueueSnackbar('A extensão do arquivo não é suportado', { variant: 'error' })
                    }
                }
                else {
                    enqueueSnackbar('O arquivo não pode ser maior que 10mb', { variant: 'error' })
                }
            }
        })
    }

    useEffect(async () => {
        Editor
            .create(document.querySelector('#editor'), {
                autosave: {
                    waitingTime: 200, // in ms
                    save(editor) {
                        debugger;
                        editor.getData();
                    }
                }

            })
            .then(x => {
                setDataEditor(x.data);
            })
            .catch(error => {
                console.log(error);
            });

        await carregarSecoes();
    }, [])

    useEffect(async () => {
        if (!codigoArtigo || codigoArtigo < 0)
            return;

        await carregueArtigo();

    }, [codigoArtigo]);

    useEffect(async () => {
        if (!artigo || !artigo.secao || artigoAtual.secao)
            return;

        let length = artigo.artigoHistoricos.length;
        let artigoH = artigo.artigoHistoricos[length - 1];

        setArtigoAtual({
            secao: artigo.secao,
            titulo: artigoH.titulo,
            url: artigoH.url,
            conteudo: artigoH.conteudo,
            dataOperacao: artigoH.dataOperacao,
            arquivosArtigo: artigoH.arquivosArtigo
        });

        dataEditor.set(artigoH.conteudo);

    }, [artigo]);

    return (
        <DuvidaComponente>
            <Container title={
                <>
                    {/* <Link to="/Redistribuicoes"> */}
                    <Button className="btn-arrow-back" onClick={() => history.goBack()}><BiArrowBack /> </Button>
                    {/* </Link> */}
                    Dúvida
                </>} >
                <Card>
                    <Card.Content>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl variant="outlined">
                                    <TextField
                                        id="outlined-basic-titulo"
                                        label="Título"
                                        variant="outlined"
                                        value={artigoAtual.titulo ?? ""}
                                        onChange={e => setArtigoAtual(art => ({ ...art, titulo: e.target.value }))}
                                    // error={true}
                                    // helperText="Incorrect entry."
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                < Autocomplete
                                    style={{ height: '56px' }}
                                    title='Seções'
                                    id="tags-standard-secoes"
                                    options={secoes}
                                    getOptionLabel={(option) => option.titulo}
                                    onChange={(e, v) => handleChangeSecao(e, v)}
                                    value={secoes.filter(x => x.id == artigoAtual?.secao?.id)[0] ?? null}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            // error={errorAssunto}
                                            variant="outlined"
                                            label="Selecione a Seção"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <TextField
                                    id="filled-adornment-senha"
                                    type="text"
                                    value={artigoAtual.url ?? ""}
                                    label="Url"
                                    variant="outlined"
                                    onChange={e => setArtigoAtual(art => ({ ...art, url: e.target.value }))}
                                // onChange={e => setUsuario(e.target.value)}
                                // onKeyPress={handleKeyDown}
                                // error={erroUsuario}
                                // helperText={errorUsuarioHelper}
                                />
                            </Grid>
                            <MuiPickersUtilsProvider locale={ptBRLocale} utils={DateFnsUtils}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>

                                    <FormControl variant="outlined">

                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="datapicker-data-publicacao"
                                            label="Data da Publicação"
                                            format="dd/MM/yyyy"
                                            inputVariant="outlined"
                                            value={artigoAtual.dataOperacao ? new Date(artigoAtual.dataOperacao) : null}
                                            onChange={(date) => setArtigoAtual(art => ({ ...art, dataOperacao: date ? date.getTime() : "" }))}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date"
                                            }}
                                            okLabel="Selecionar"
                                            cancelLabel="Cancelar"
                                        />
                                    </FormControl>
                                </Grid>
                            </MuiPickersUtilsProvider>

                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Divider textAlign="center"><Chip label="Conteúdo" /></Divider>
                            </Grid> */}
                            
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div id="editor"></div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack
                                    direction="row"
                                    divider={<Divider orientation="vertical" flexItem />}
                                    maxWidth
                                    spacing={2}
                                >

                                    <DuvidaComponente.SendMessageActionsDialog>
                                        <Request.SendMessageActionsAttachmentDialog id="Request_SendMessageActionsAttachmentDialog">
                                            <HtmlTooltip title="Anexar Arquivos" placement="top">
                                                <Button className="btn-send-attach btn-send-attach-mobile" >
                                                    <input
                                                        accept=".jpg, jpeg, jpe, .jfif, .png, .bmp, .gif, .pdf, .p7s"
                                                        id="input-file-send-message-editor"
                                                        type="file"
                                                        multiple
                                                        onClick={e => (e.target.value = null)}
                                                        onChange={handleAttachment}
                                                    // onBlur={() => setPreview(null)}
                                                    />
                                                    <GrAttachment id="icon-attach-message" />
                                                </Button>
                                            </HtmlTooltip>
                                        </Request.SendMessageActionsAttachmentDialog>
                                    </DuvidaComponente.SendMessageActionsDialog>

                                    <Item style={{width:'100%'}}>
                                        <DuvidaComponente.Attachments id="Request_Attachments">
                                            <Request.TitleContainer id="Request_TitleContainer">
                                                <Request.TitleText id="Request_TitleText">Anexos Adicionados</Request.TitleText>
                                            </Request.TitleContainer>
                                            <Request.AttachmentContainer id="Request_AttachmentContainer">
                                                <DuvidaComponente.AttachmentContent id="Request_AttachmentContainer">
                                                    {artigoAtual?.arquivosArtigo?.map(attach => (
                                                        attach.extensao.toUpperCase().indexOf('PDF') > -1
                                                            ?
                                                            <Request.AttachmentItem key={uuid()} id="Request_AttachmentItemPdf">
                                                                <Request.AttachmentItemImage id="Request_AttachmentItemImage"><VscFilePdf /></Request.AttachmentItemImage>
                                                                <Request.AttachmentItemText id="Request_AttachmentItemText">{limitChar(100, attach.nome)}</Request.AttachmentItemText>
                                                                <Button className="Request_AttachmentItemClose" ><RiCloseCircleFill /></Button>
                                                            </Request.AttachmentItem>
                                                            :
                                                            <Request.AttachmentItem key={uuid()} id="Request_AttachmentItemImg">
                                                                <Request.AttachmentItemImage id="Request_AttachmentItemImage"><IoImageOutline /></Request.AttachmentItemImage>
                                                                <Request.AttachmentItemText id="Request_AttachmentItemText">{limitChar(100, attach.nome)}</Request.AttachmentItemText>
                                                                <Button className="Request_AttachmentItemClose" ><RiCloseCircleFill /></Button>
                                                            </Request.AttachmentItem>
                                                    ))}
                                                </DuvidaComponente.AttachmentContent>
                                            </Request.AttachmentContainer>
                                        </DuvidaComponente.Attachments>

                                    </Item>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Card.Content>
                </Card>

            </ Container >
        </DuvidaComponente >
    )
}