import styled from 'styled-components'

export const Badge = styled.div`
    width: 100%;
    background-color: transparent;
    border: ${props => props.type ? '1px solid var(--green)' : '2px solid var(--red)'};
    color: ${props => props.type ? 'var(--green)' : 'var(--red)'};
    padding: 5px 10px;
    text-transform: uppercase;
    transition: all ease .35s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 12px 0 12px;
    height: 20px;
    font-weight: 500;
    border-radius: 100px;
    font-size: 13px;

    // &:hover {
    //     color: var(--white);
    //     background-color: ${props => props.type ? 'var(--green)' : 'var(--red)'};
    // }
`