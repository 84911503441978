import { configureStore } from '@reduxjs/toolkit'
import requestMessageSliceReducers, {startInterval} from './slices/requestMessageSlice'

const store = configureStore({
    reducer: {
        requestMessage: requestMessageSliceReducers
    },
})

store.dispatch(startInterval());

export default store;