import { useState, useEffect, forwardRef } from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'
import { Finalization } from './styles'
import api from '../../../services/api'
import { v4 as uuid } from 'uuid'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogFinalization({
    idRequisicao,
    openDialogFinalization,
    setOpenDialogFinalization
}) {
    const { enqueueSnackbar } = useSnackbar()
    const [tipoResolucao, settipoResolucao] = useState('')
    const [listaTiposResolucao, setListaTiposResolucao] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [erroSelecioneUmaOpcao, setErroSelecioneUmaOpcao] = useState('')

    const handleChange = (event) => {
        settipoResolucao(event.target.value)
    }

    const handleClose = (e) => {
        e.preventDefault()
        setOpenDialogFinalization(false)
    }

    const handleSubmit = async (e) => {

        e.preventDefault()
        setLoadingSubmit(true)
        setErroSelecioneUmaOpcao('');
        await api.put(`/jucerja/v1/requisicoes/${idRequisicao}/finalizacao/${tipoResolucao}`)
            .then(response => {
                setLoadingSubmit(false)
                enqueueSnackbar(`Solicitação ${idRequisicao} finalizada com sucesso!`, { variant: 'success' })
                setOpenDialogFinalization(false)
            })
            .catch(error => {

                setLoadingSubmit(false)

                if (error.response.status === 404 && tipoResolucao.length <= 0) {
                    setErroSelecioneUmaOpcao("Deve ser selecionado um tipo de resolução para a finalizacao.");
                }
                else {

                    if (error.response.status === 422) {

                        const finalizeRequisicaoErro = error.response.data.messages[0].campos.filter(x => x.Campo === 'FinalizeRequisicao');
                        const erroGenerico = error.response.data?.messages[0]?.campos;

                        let mensagemErro = error.response.data.messages[0].mensagem.toString();

                        if (finalizeRequisicaoErro && finalizeRequisicaoErro.length > 0) {
                            mensagemErro = finalizeRequisicaoErro[0].Mensagem;
                        } else if (erroGenerico && erroGenerico.length > 0) {
                            mensagemErro = erroGenerico[0].Mensagem;
                        }

                        enqueueSnackbar(mensagemErro, { variant: 'error' })
                    }
                    else {

                        enqueueSnackbar(error.toString(), { variant: 'error' })
                    }
                }
            })
    }

    const getTiposResolucao = async () => {
        setLoading(true)
        await api.get('/jucerja/v1/TiposResolucao')
            .then(response => {
                setListaTiposResolucao(response.data)
                setLoading(false)
            })
    }

    useEffect(() => {
        if (openDialogFinalization) {
            getTiposResolucao()
        }
    }, [openDialogFinalization])

    return (
        <Draggable
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogFinalization}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-change-subject"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">{`Finalizar Solicitação #${idRequisicao}`}</DialogTitle>
            <DialogContent>
                <Finalization id="Finalization">
                    <Finalization.Container id="Finalization_Container">
                        {
                            loading
                                ?
                                <Skeleton />
                                :
                                <FormControl variant="outlined">
                                    <InputLabel id="select-subject-label">Tipos de Resolução</InputLabel>
                                    <Select
                                        labelId="select-subject-label-label"
                                        id="select-subject-label"
                                        value={tipoResolucao}
                                        onChange={handleChange}
                                        label="Tipo Resolução"
                                        error={erroSelecioneUmaOpcao.length > 0}
                                    >
                                        <MenuItem value="">
                                            <em>Escolha uma opção</em>
                                        </MenuItem>
                                        {listaTiposResolucao.map(subject => (
                                            <MenuItem value={subject.id} key={uuid()}>{subject.descricao}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{erroSelecioneUmaOpcao}</FormHelperText>
                                </FormControl>
                        }
                    </Finalization.Container>
                </Finalization>
            </DialogContent>
            <DialogActions>
                <Finalization.Actions id="Finalization.Actions">
                    <Button onClick={handleClose} className="btn-close-actions">Cancelar</Button>
                    {
                        loading
                            ?
                            <Skeleton />
                            :
                            loadingSubmit
                                ?
                                <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: 'var(--white)' }} /></Button>
                                :
                                <Button className="btn-primary-actions" onClick={handleSubmit}>Finalizar</Button>
                    }

                </Finalization.Actions>
            </DialogActions>
        </Dialog>
        </Draggable>
    )
}