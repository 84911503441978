import { createRef, useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Request } from './styles'
import LoadingMessagesChat from '../../animations/LoadingMessagesChat'

import FileDragDrop from '../../animations/FileDragDrop'
import { FileDragDropStyle } from '../../animations/FileDragDrop/styles'
import { LoadMessagesChat } from '../../animations/LoadingMessagesChat/styles'
import { v4 as uuid } from 'uuid'
import { convertISODateToDateView } from '../../../functions'
import ReactHtmlParser from 'react-html-parser'
import { MdRefresh } from 'react-icons/md'
import { Button } from "@material-ui/core"
import CopyToClipboard from "@vigosan/react-copy-to-clipboard"

import { Error } from '../../../components/animations/Error/styles'
import ErrorResult from '../../../components/animations/Error'
import { FaCheckCircle } from 'react-icons/all'

import AttendantMessage from '../AttendantMessage'
import AttendantMessagePrivate from '../AttendantMessagePrivate'
import AttendantMessageReminder from '../AttendantMessageReminder'
import ClientMessage from '../ClientMessage'
import { getConnectSolicitacao } from '../../../services/hubSolicitacaoETarefa'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash';

import { HubConnectionBuilder } from '@microsoft/signalr'

export default function RequestMessages({
    idRequisicao,
    getMensagens,
    error,
    errorMessage,
    setErrorMessage,
    loading,
    mensagens,
    handleAttachment
}) {
    const messagesCountDown = useSelector((state) => state.requestMessage.messages?.filter(m => m.idRequisicao == idRequisicao && m.countDown > 0))
    const { enqueueSnackbar } = useSnackbar()
    const [msgInternalButtonError, setMsgInternalButtonError] = useState('')
    const [acao, setAcao] = useState('')
    const [dragAtivo, setDragAtivo] = useState(false)

    const desmontarAnimacao = _.debounce(() => {
        setDragAtivo(false);
    }, 200);

    useEffect(() => {
        const connection = getConnectSolicitacao(idRequisicao);

        connection.start()
            .then(result => {
                console.log('Connected!')

                connection.on('getRequisicaoMensagem', message => {
                    // TODO: Para a segunda versão utilizaremos o message, mas por enquanto manteremos assim por questões de viabilidade
                    getMensagens(false)
                })
            })
            .catch(e => enqueueSnackbar('Conexão falhou: ' + e, { variant: 'error' }))
    }, [])

    useEffect(() => {
        getMensagens(true)
    }, [])

    useEffect(() => {
        mensagens.acoesPermitidas.map(acao => {
            if (acao === "EnviarMensagem") {
                setAcao('EnviarMensagem')
            }
        })
    }, [mensagens])

    return (
        <Request.ListOfMessages id="Request_ListOfMessages">
            {
                error
                    ?
                    <Error.Area>
                        <Error id="Error">
                            <Error.Animation id="Error_Animation"><ErrorResult /></Error.Animation>
                            <Error.MessageDefault id="Error_MessageDefault">Ops! Houve um problema e não foi possível carregar o conteúdo. Tente novamente clicando no botão abaixo.</Error.MessageDefault>
                            <Error.Message id="Error_Message"><span>Detalhe do Erro</span></Error.Message>
                            <Error.MessageErrorContent id="Error_MessageErrorContent">
                                <Error.MessageErrorContentMessage id="Error.MessageErrorContentMessage">{errorMessage}</Error.MessageErrorContentMessage>
                                <CopyToClipboard
                                    onCopy={({ success, text }) => {
                                        let msg = success ? "Copiado!" : "Whoops, não foi copiado!"
                                        setMsgInternalButtonError(msg)
                                        setTimeout(() => { setMsgInternalButtonError('') }, 3000)
                                    }}
                                    render={({ copy }) => (
                                        <Button
                                            className="btn-copy-error-message"
                                            onClick={() => copy(errorMessage)}
                                            data-active={msgInternalButtonError === 'Copiado!' ? 'true' : 'false'}
                                        >
                                            {msgInternalButtonError === 'Copiado!' ? (<span className="span-copy-now">Copiado <FaCheckCircle /></span>) : 'Copiar'}
                                        </Button>
                                    )}
                                />
                            </Error.MessageErrorContent>
                            <Button className="btn-error-page" onClick={() => window.location.reload()}><MdRefresh /> Tentar Novamente</Button>
                        </Error>
                    </Error.Area>
                    :
                    <Request.ListOfMessagesInside
                        id="Request_ListOfMessagesInside"
                        type={loading ? 'loading' : 'noloading'}
                        acao={acao}
                        onDragEnter={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDragAtivo(true);
                        }}
                        onDragOver={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            desmontarAnimacao();
                        }}
                        onDrop={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleAttachment({ target: { files: e.dataTransfer.files } });
                        }}

                        onDropCapture={e => { }}
                    >
                        <Request.Messages id="Request_Messages" >
                            {
                                dragAtivo ?
                                    <FileDragDropStyle id="LoadMessagesChat">
                                        <FileDragDropStyle.Animation id="LoadMessagesChat_Animation">
                                            <FileDragDrop />
                                        </FileDragDropStyle.Animation>
                                        <LoadMessagesChat.Message id="LoadMessagesChat_Message">anexe arquivos ao solta-los sobre a tela</LoadMessagesChat.Message>
                                    </FileDragDropStyle>
                                    :
                                    loading
                                        ?
                                        <LoadMessagesChat id="LoadMessagesChat">
                                            <LoadMessagesChat.Animation id="LoadMessagesChat_Animation">
                                                <LoadingMessagesChat />
                                            </LoadMessagesChat.Animation>
                                            <LoadMessagesChat.Message id="LoadMessagesChat_Message">carregando mensagens</LoadMessagesChat.Message>
                                        </LoadMessagesChat>
                                        :
                                        [...mensagens.mensagens, ...messagesCountDown].map(mensagem => {
                                            switch (mensagem.tipo) {
                                                case 'status':
                                                    return (
                                                        <Request.MessageStatus key={uuid()} id="Request_MessageStatus">
                                                            <Request.MessageDate id="Request_MessageDate">{convertISODateToDateView(mensagem.dataOperacao)} {mensagem.nomeUsuario ? `- ${mensagem.nomeUsuario}` : null}</Request.MessageDate>
                                                            <Request.MessageBadge type={String(mensagem.descricao).toUpperCase()} id="Request_MessageBadge">{ReactHtmlParser(mensagem.descricao)}</Request.MessageBadge>
                                                        </Request.MessageStatus>
                                                    )

                                                case 'assuntos':
                                                    return (
                                                        <Request.MessageStatus key={uuid()} id="Request_MessageStatus">
                                                            <Request.MessageDate id="Request_MessageDate">{convertISODateToDateView(mensagem.dataOperacao)} {mensagem.nomeUsuario ? `- ${mensagem.nomeUsuario}` : null}</Request.MessageDate>
                                                            <Request.MessageBadge type="assuntos" id="Request_MessageBadge"><strong>Assunto Alterado:</strong> {ReactHtmlParser(mensagem.descricao)}</Request.MessageBadge>
                                                        </Request.MessageStatus>
                                                    )

                                                case 'mensagens':
                                                    if (mensagem.ehMensagemCliente) {
                                                        return (
                                                            <ClientMessage
                                                                key={mensagem.id}
                                                                mensagem={mensagem}
                                                            />
                                                        )
                                                    }
                                                    else {
                                                        switch (mensagem.tipoPrivacidade) {
                                                            case 1:
                                                                return (
                                                                    <AttendantMessage
                                                                        key={mensagem.id}
                                                                        mensagem={mensagem}
                                                                    />
                                                                )
                                                            case 2:
                                                                return (
                                                                    <AttendantMessageReminder
                                                                        key={mensagem.id}
                                                                        mensagem={mensagem}
                                                                    />

                                                                )
                                                            case 3:
                                                                return (
                                                                    <AttendantMessagePrivate
                                                                        key={mensagem.id}
                                                                        mensagem={mensagem}
                                                                    />
                                                                )
                                                            default:
                                                                return null
                                                        }
                                                    }

                                                default:
                                                    return null
                                            }
                                        })
                            }
                        </Request.Messages>
                    </Request.ListOfMessagesInside>
            }
        </Request.ListOfMessages>
    )
}