import Container from '../../components/layout/Container'
import TableEmployees from '../../components/elements-ui/TableEmployees'
import Grid from '@material-ui/core/Grid'

export default function Funcionarios() {
    return (
        <Container title="Funcionários">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TableEmployees />
                </Grid>
            </Grid>
        </Container>
    )
}