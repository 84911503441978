import { useState, useEffect, forwardRef } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import api from '../../../services/api'
import { useSnackbar } from 'notistack'
import Draggable from 'react-draggable'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogResponsibleChange({
    idTarefa,
    openDialogResponsibleChange,
    setOpenDialogResponsibleChange
}) {
    const [listaDeSetores, setListaDeSetores] = useState([])
    const [listaDeFuncionarios, setListaDeFuncionarios] = useState([])
    const [desativarComboResponsavel, setDesativarComboResponsavel] = useState(true)
    const [setor, setSetor] = useState('')
    const [funcionario, setFuncionario] = useState('')
    const [loading, setLoading] = useState(false);
    const [errosDeCampos, setErrosDeCampos] = useState([])
    const { enqueueSnackbar } = useSnackbar()

    const clearFields = () => {
        setSetor('')
        setFuncionario('')
    }

    const handleClose = () => {
        setOpenDialogResponsibleChange(false)
        setErrosDeCampos([])
    }

    const onClickAlterar = async () => {
        setLoading(true);

        const dataResponsavel = {
            Id: idTarefa,
            SetorId: setor,
            FuncionarioId: funcionario
        };

        await api.put(`/jucerja/v1/tarefa/alterar-responsavel`, dataResponsavel)
            .then(response => {
                handleClose()
            })
            .catch(error => {
                setErrosDeCampos([])
                var camposErros = error?.response?.data?.messages[0]?.campos
                setLoading(false)

                if (camposErros) {
                    setErrosDeCampos(camposErros);
                    const tarefaAlterarResponsaval = error?.response?.data?.messages[0]?.campos?.filter(x => x.Campo === "TarefaAlterarResponsavel")[0]
                    if (tarefaAlterarResponsaval)
                        enqueueSnackbar(tarefaAlterarResponsaval.Mensagem, { variant: 'error' })
                    return
                }

                switch (error?.response?.status) {
                    case 422:
                        enqueueSnackbar(error.response.data.messages[0].mensagem, { variant: 'error' })
                        break
                    default:
                        enqueueSnackbar('Não foi possível enviar sua mensagem. Por favor tente novamente', { variant: 'error' })
                        break
                }
            })
            .finally(() => setLoading(false))

    }

    const getSetores = () => {
        api.get(`/jucerja/v1/Setor`)
            .then(response => {
                setListaDeSetores(response.data ?? [])
            })
            .catch(error => {
                // setError(true)
                // setErrorMessage(error.toString())
            })
    }

    const getError = (prop) => {
        return errosDeCampos.filter(x => x.Campo?.toUpperCase() === prop?.toUpperCase())[0]?.Mensagem
    }

    useEffect(() => {
        setDesativarComboResponsavel(true);
        setFuncionario('')

        if (setor != '' && setor > 0) {
            api.get(`/jucerja/v1/Funcionario/${setor}`)
                .then(response => {
                    setListaDeFuncionarios(response.data ?? [])
                    setDesativarComboResponsavel(false)
                })
                .catch(error => {
                    // setError(true)
                    // setErrorMessage(error.toString())
                })

        }
    }, [setor])

    useEffect(() => {
        if (openDialogResponsibleChange) {
            clearFields()
            getSetores()

        }
    }, [openDialogResponsibleChange])

    return (
        <Draggable      
        handle={'[class*="MuiDialog-root"]'} 
        cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
            hideBackdrop
            disableBackdropClick
            open={openDialogResponsibleChange}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-detail-request-slide-title"
            aria-describedby="dialog-detail-request-dialog-slide-description"
            id="dialog-detail-request"
        >
            <DialogTitle style={{ cursor: 'move' }} id="alert-dialog-slide-title">Alterar Responsável</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <FormControl variant="outlined">
                            <InputLabel id="select-subject-label">Setor</InputLabel>
                            <Select
                                labelId="select-subject-label-label"
                                id="select-subject-label"
                                value={setor}
                                error={getError("SetorId")}
                                name="SetorId"
                                onChange={e => { setSetor(e.target.value); }}
                                label="Setor"
                            >
                                <MenuItem value="">
                                    <em>Escolha uma opção</em>
                                </MenuItem>
                                {
                                    listaDeSetores.map(setor => (
                                        <MenuItem key={setor.id} value={setor.id}>{setor.descricao}</MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{getError("SetorId")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <FormControl variant="outlined">
                            <InputLabel id="select-subject-label">Funcionário</InputLabel>
                            <Select
                                labelId="select-subject-label-label"
                                id="select-subject-label"
                                value={funcionario}
                                error={getError("FuncionarioId")}
                                onChange={e => { setFuncionario(e.target.value); }}
                                disabled={desativarComboResponsavel}
                                label="Funcionario"
                            >
                                <MenuItem value="">
                                    <em>Escolha uma opção</em>
                                </MenuItem>
                                {
                                    listaDeFuncionarios.map(fun => (
                                        <MenuItem key={fun.id} value={fun.id}>{fun.nome}</MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{getError("FuncionarioId")}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} className="btn-close-actions">Cancelar</Button>{
                    loading
                        ?
                        <Button className="btn-primary-actions"><CircularProgress size={22} style={{ color: '#fff' }} /></Button>
                        :
                        <Button className="btn-primary-actions" onClick={() => onClickAlterar()}>Alterar</Button>
                }</DialogActions>
        </Dialog>
        </Draggable>
    )
}