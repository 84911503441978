import Container from '../../components/layout/Container'
import Grid from '@material-ui/core/Grid'
import { useEffect, useRef, useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid"
import { Card } from "../../components/elements-ui/Card"
import { Badge } from "../../components/elements-ui/Badge"
import { ActionTable } from "../../components/elements-ui/ActionTable"
import { Button, Checkbox } from '@material-ui/core'
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete, CgAdd } from 'react-icons/all'
import { HiOutlineArrowsExpand } from 'react-icons/all'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../services/api'
import FilterRequests from '../../components/elements-ui/FilterRequests'
import { DuvidasComponente } from './styles'
import { convertISODateToDateView } from '../../functions'

const PrimaryTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'var(--white)',
    },
}))(Tooltip);

export default function Duvidas() {
    const [registros, setRegistros] = useState([])
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(undefined);
    const [rowCountState, setRowCountState] = useState(rowCount || 0);
    const [rowsState, setRowsState] = useState({
        page: 1,
        pageSize: 10,
    });

    const getRegistros = async () => {
        let options = {
            firstResult: ((rowsState.page - 1) * rowsState.pageSize) + 1,
            maxResults: rowsState.pageSize
        }

        setLoading(true);
        setRowCount(undefined);
        await api.post('/jucerja/v1/artigo/listagem-artigos', options)
            .then(response => {
                setRegistros(response.data.artigos);
                setRowCount(response.data.totalRecords);
            })
            .catch(error => {
                // setLoading(false)
                // setError(true)
                // setErrorMessage(error.toString())
            })
            .finally(() => {
                setLoading(false);
            });

        setLoading(false);
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 100
        },
        {
            field: 'titulo',
            headerName: 'Título',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'secao',
            headerName: 'Seção',
            width: 180,
        },
        {
            field: 'dataPublicacao',
            headerName: 'Publicação',
            valueGetter: (params) => convertISODateToDateView(params.value),
            width: 195
        },
        {
            field: "ativo",
            headerName: "Ativo",
            width: 165,
            editable: false,
            valueGetter: (params) => params.value ? 'Sim' : 'Não',
            renderCell: (params) => {
                return <Badge type={params.value == 'Sim'} className="no-select">{params.value}</Badge>
            }
        },
        {
            field: 'acao',
            headerName: 'Ações',
            width: 120,
            filterable: false,
            renderCell: (params) => {
                return (
                    <ActionTable>
                        {/* <Link to="/configuracoes">
                            <PrimaryTooltip title="Visualizar" placement="top">
                                <Button className="btn-open"><AiOutlineEye/></Button>
                            </PrimaryTooltip>
                        </Link> */}
                        <Link to={`/duvida/${params.row.id}`}>
                        <PrimaryTooltip title="Editar" placement="top">
                            <Button className="btn-edit"><AiOutlineEdit /></Button>
                        </PrimaryTooltip>
                        </Link>
                        {
                            !params.row.ativo
                                ?
                                <PrimaryTooltip title="Deletar" placement="top">
                                    <Button className="btn-delete"><AiOutlineDelete /></Button>
                                </PrimaryTooltip>
                                :
                                <></>
                        }

                    </ActionTable>
                )
            }
        }
    ]


    useEffect(() => {
        if (rowsState.page === 0) {

            setRowsState(prev => ({ ...prev, page: 1 }));
            return;
        }

        getRegistros();

    }, [rowsState.page])


    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    return (
        <Container title="Dúvidas">
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                        <Card.Content>
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid
                                    getRowId={row => row.id}
                                    rowCount={rowCountState}
                                    rows={registros}
                                    columns={columns}
                                    disableColumnMenu
                                    hideFooterPagination={loading}
                                    loading={loading}
                                    pageSize={rowsState.pageSize}
                                    page={rowsState.page > 0 ? rowsState.page - 1 : 0}
                                    pagination
                                    hideFooterSelectedRowCount
                                    disableExtendRowFullWidth={false}
                                    paginationMode="server"
                                    onPageChange={(page) => {
                                        setRowsState((prev) => ({ ...prev, page: page + 1 }));
                                    }}
                                    localeText={ptBR.props.MuiDataGrid.localeText}
                                />

                            </div>
                            <DuvidasComponente.Actions>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{
                                    display: "contents"
                                }}>
                                    <Link
                                        to="/Duvida/0"
                                    >
                                        <Button className="btnAdicionar" style={{ background: '#66c55b', color: 'white' }} ><CgAdd id="icon-link-nav" />{'Adicionar'}</Button>
                                    </Link>
                                </Grid>
                            </DuvidasComponente.Actions>
                        </Card.Content>
                    </Card>
                </Grid>
            </Grid>
        </Container >
    )
}