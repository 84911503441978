import Lottie from 'react-lottie'
import animationData from './noresults.json'
import { NoResults } from './styles'

export default function NoResult() {
  const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    }
  
  return (
    <NoResults.Area>
      <NoResults id="NoResults">
        <NoResults.Animation id="NoResults_Animation">
          <Lottie 
            options={defaultOptions}
            style={{ maxWidth: '320px', width: '100%', height: 'auto' }}
          />
        </NoResults.Animation>
        <NoResults.MessageDefault id="NoResults_MessageDefault">Nenhum resultado encontrado no momento</NoResults.MessageDefault>
      </NoResults>
    </NoResults.Area>
  )
}